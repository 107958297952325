import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import secureLocalStorage from 'react-secure-storage';

import { grey } from '@mui/material/colors';

import { Stack, Typography, Button, Grid, Box, IconButton } from '@mui/material';

import { Icon } from '@iconify/react/dist/iconify.js';

import api from "../../services/api"

//-----------------------------------------------------------------

export default function SendData() {
    const [loading, setLoading] = useState(false) 

    const data = secureLocalStorage.getItem('transport-user')

    const [locate, setLocate] = useState()

    const navigate = useNavigate()

    useEffect(()=>{
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocate({
                    "lat": position.coords.latitude,
                    "long": position.coords.longitude
                });
            },
            (error) => {
                // Erro ao obter a localização
                console.error(error);
            }
          );          
    }, [])

    function handleSave(){
        const tracker = {
            "imagem": data.photo,
            "entregador": data.usuario,
            "telefone": data.telefone,
            ...locate        
        }
    
        const uploadData = data.inventory;
    
        // Ensure uploadData.rastreamento is an array
        if (uploadData?.rastreamento) {
            uploadData.rastreamento = [];
        }
    
        uploadData.rastreamento.push(tracker);

        save(uploadData)
    }    

    function save(propsData){
        setLoading(true)

        const option = {
            headers: { 'Content-Type': ['application/json'] }
        }

        api.post(`/save_jpeg/${propsData?._id}`, [{foto: propsData.photo}], option)
        .then(
            response=>{
                propsData.photo = response.data.foto
                uploadInventory(propsData)
            },
            error=>{
                setLoading(false)
            }
        )
    }

    function uploadInventory(propsData){
        const option = {
            headers: { 'Content-Type': ['application/json'] }
        }

        api.post(`/registro?tr=icd3vatgr@23!`, propsData, option)
        .then(
            response=>{

            },
            error=>{
                setLoading(false)
            }
        )
    }

    return (
        <>
            <Stack spacing={10} sx={{minWidth: {xs: '100%', md: '30vw'}, height: '100%'}}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <IconButton onClick={e=>{navigate('/transport/upload-foto')}}>
                        <Icon icon="iconamoon:arrow-left-2-bold"/>
                    </IconButton>
                    <Typography variant="h1">Confirmar Dados</Typography>
                </Stack>

                <Stack alignItems="center">
                    <Box
                        component="img"
                        src={data?.photo}
                        sx={{
                            marginTop: 2,
                            borderRadius: 3, 
                            width: '100px', 
                            height: '100px'
                        }}
                    ></Box>
                </Stack>

                <Grid container rowSpacing={2}>
                    <Grid item md={3}>Identificador</Grid>
                    <Grid item md={9} sx={{fontWeight: 'bold'}}>{data?.inventory?.identificador}</Grid>
                    <Grid item md={3}>Nome</Grid>
                    <Grid item md={9} sx={{fontWeight: 'bold'}}>{data?.usuario}</Grid>
                    <Grid item md={3}>Telefone</Grid>
                    <Grid item md={9} sx={{fontWeight: 'bold'}}>{data?.telefone}</Grid>
                </Grid>

                <Stack justifyContent="center">
                    <Button
                        variant="contained"
                        startIcon={<Icon icon="fa:send" width={12}/>}
                        sx={{
                            borderRadius: 5
                        }}
                        onClick={e=>{handleSave()}}
                    >
                        Salvar
                    </Button>
                </Stack>
            </Stack>
        </>
    );
}
