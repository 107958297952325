import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import ReactLoading from 'react-loading'
import Swal from 'sweetalert2'

import './style.css'
import AlertLogo from '../../assets/alertLogo.png'
import api from '../../services/api'
import b64Resize from '../../services/b64Resize'

function EILink() {
    const {identificador} = useParams()
    const [windowLoading, setWindowLoading] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)
    const [invLoading, setInvLoading] = useState(false)
    const [invInfo, setInvInfo] = useState([])
    const [invItems, setInvItems] = useState([])
    const [invCheck, setInvCheck] = useState(false)
    const [infLabels, setInvLabels] = useState([
        'inf_compl_1',
        'inf_compl_2',
        'inf_compl_3',
        'inf_compl_4',
        'inf_compl_5',
        'inf_compl_6',
        'inf_compl_7',
        'inf_compl_8',
        'inf_compl_9',
        'inf_compl_10',
    ])

    useEffect(() => {
        getInventory()
    },[])

    async function getInfLabels(idConta) {
        await api.get(`/conta_config/${idConta}`).then(
            response => {
                let data = response.data[0] ? response.data[0] : []
                let lista = [
                    data.info_compl_1 && data.info_compl_1 != '' ? data.info_compl_1 : 'inf_compl_1' ,
                    data.info_compl_2 && data.info_compl_2 != '' ? data.info_compl_2 : 'inf_compl_2' ,
                    data.info_compl_3 && data.info_compl_3 != '' ? data.info_compl_3 : 'inf_compl_3' ,
                    data.info_compl_4 && data.info_compl_4 != '' ? data.info_compl_4 : 'inf_compl_4' ,
                    data.info_compl_5 && data.info_compl_5 != '' ? data.info_compl_5 : 'inf_compl_5' ,
                    data.info_compl_6 && data.info_compl_6 != '' ? data.info_compl_6 : 'inf_compl_6' ,
                    data.info_compl_7 && data.info_compl_7 != '' ? data.info_compl_7 : 'inf_compl_7' ,
                    data.info_compl_8 && data.info_compl_8 != '' ? data.info_compl_8 : 'inf_compl_8' ,
                    data.info_compl_9 && data.info_compl_9 != '' ? data.info_compl_9 : 'inf_compl_9' ,
                    data.info_compl_10 && data.info_compl_10 != '' ? data.info_compl_10 : 'inf_compl_10' 
                ]
                setInvLabels(lista)
            }
        )
    }

    function getInventory() {
        setInvLoading(true)
        api.get(`/registroPage/*/*/externo/*/*/${identificador}/*/*/${0 * 30}`).then(
            async response => {
                if(response.data[response.data.length - 1].total_reg == 0) {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Não existe nenhum inventário relacionado a este identificador',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }else{
                    setInvInfo(response.data)
                    await getInfLabels(response.data[0].id_conta._id)
                    api.get(`/registro_tag/*/${response.data[0]._id}/0`).then(
                        response2 => {
                            setInvItems(response2.data)
                        },
                        response2 => {}
                    ).finally(()=>{setInvLoading(false)})
                }
            },
            response => {}
        )
    }

    function askMarcarEncontrado(inv, index) {
        Swal.fire({
            title: "Atenção !",
            html: `Deseja marcar este item como encontrado?<br/>Será necessário a inserção de uma foto`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            async result => {
                if(result.isConfirmed) {
                    window.document.getElementById(`itemImage_${index}`).click()
                }
            }
        )
    }

    function changeObservation(value, inv, index) {
        let invmodify = invItems.map((it, ind) => {
            if(ind == index) {
                it['observacao'] = value
            }
            return it
        }).filter((it, ind) => {return it})
        setInvItems(invmodify)
    }

    function marcarEncontrado(file, inv, index) {
        var reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onloadend = async function(e, file) {
            var b64 = await b64Resize(this.result, 500, 500)
            let invmodify = invItems.map((it, ind) => {
                if(ind == index) {
                    it['b64'] = b64
                    it['encontrado'] = '1'
                }
                return it
            }).filter((it, ind) => {return it})
            setInvItems(invmodify)
        }
    }

    function askMarcarAusente(inv, index) {
        Swal.fire({
            title: "Atenção !",
            html: `Deseja marcar este item como ausente?<br/>A foto será removida do item`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            async result => {
                if(result.isConfirmed) {
                    marcarAusente(inv, index)
                }
            }
        )
    }

    function marcarAusente(inv, index) {
        let invmodify = invItems.map((it, ind) => {
            if(ind == index) {
                it['b64'] = ''
                it['encontrado'] = '0'
            }
            return it
        }).filter((it, ind) => {return it})
        setInvItems(invmodify)
    }

    async function recursiveSaveFoto(list, index) {
        let lista = list.filter((item) => {return item})
        
        if(lista[index].b64 && lista[index].b64 != '') {
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            await api.post(`/save_jpeg/${invInfo[0].id_conta._id}`, [{foto: lista[index].b64}], option).then(
                async response => {
                    let fotoRes = response.data[0].id_foto

                    lista[index]['foto'] = fotoRes

                    if(lista.length == (index + 1)) {
                        setInvItems(lista)
                    }else{
                        await recursiveSaveFoto(lista, (index + 1))
                    }
                },
                response => {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Algo deu errado ao salvar imagem, por favor tente novamente mais tarde',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
        }else {
            if(lista.length == (index + 1)) {
                setInvItems(lista)
            }else{
                await recursiveSaveFoto(lista, index + 1)
            }
        }
    }

    function invConclude() {
        let htmlText = `Deseja finalizar este inventário?<br/>`
        htmlText += `Após a conclusão o inventário não poderá ser alterado<br/>`
        let faltantes = invItems.filter(it => (it.encontrado=='0'))
        if(faltantes.length > 0) {
            htmlText += `<b>Ainda há ${faltantes.length} itens ausentes!</b><br/>`
        }
        if(!invCheck) {
            Swal.fire({
                title: "Ops... !",
                html: 'Confirme a sua responsabilidade pelo Inventário',
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })
        }else if(invInfo[0].status == '1') {
            Swal.fire({
                title: "Ops... !",
                html: 'Este inventário já foi concluido<br/>Nenhuma alteração será aplicada',
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })
        }else {
            Swal.fire({
                title: "Atenção !",
                html: htmlText,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Sim",
                cancelButtonText: "Não"
            }).then(
                async result => {
                    if(result.isConfirmed) {
                        let backup = invInfo
                        setLoadingSave(true)
                        window.scrollTo(0,0)
                        let option = {
                            headers: { 'Content-Type': ['application/json'] }
                        }

                        await recursiveSaveFoto(invItems, 0)

                        /*Swal.fire({
                            input: 'textarea',
                            inputLabel: 'Observação',
                            inputPlaceholder: 'Insira uma observação (opicional)',
                            inputAttributes: {
                              'aria-label': 'Type your message here'
                            },
                            showCancelButton: true,
                            confirmButtonText: "Continuar",
                            cancelButtonText: "Cancelar"
                        }).then(
                            async result=> {
                                if (result.isConfirmed) {*/
    
                                    await setInvInfo(invInfo.map((inf) => {
                                        //inf['observacao'] = result.value
                                        inf['status'] = '1'
                                        return inf
                                    }).filter((item)=>{
                                        if(item._id) {
                                            return item
                                        }
                                    }))
    
                                    await api.get(`/conta_config/${invInfo[0].id_conta._id}`).then(
                                        async response3 => {
                                            if(response3.data[0].api_modo == 'local') {
                                                let dt      = new Date();
                                                let hoje    =  dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2)
                                                let hora    = ("0" + dt.getHours()).slice(-2) + ":" + ("0" +dt.getMinutes()).slice(-2) + ":" + ("0" +dt.getSeconds()).slice(-2)
                                                let dt_registro = hoje + " " + hora
                                                let sendAPIList = {
                                                    pedido: [],
                                                    tags: invItems.map(item => {
                                                        item['id_item'] = item.externo.id_item
                                                        return item
                                                    })
                                                }
                                                await axios.post(`${response3.data[0].api_endereco}/inventario/${invInfo[0].id_conta._id}/${invInfo[0].identificador}/*/*/${dt_registro}/*/*/*/${invInfo[0].externo.id_nivel_loc_3}/*`, sendAPIList,option).then(
                                                    async response4 => {
                                                        await api.put(`/registro?tr=icd3vatgr@23!`, [invInfo[0]],option).then(
                                                            async response => {
                                                                await api.post('/registro_tag_picking', invItems,option).then(
                                                                    async response2 => {
                                                                        Swal.fire({
                                                                            title: "Concluido!",
                                                                            text: 'Inventário realizado com successo',
                                                                            icon: 'success',
                                                                            showConfirmButton: false,
                                                                            timerProgressBar: true,
                                                                            timer: '2500'
                                                                        })
                                                                    },
                                                                    response2 => {
                                                                        Swal.fire({
                                                                            title: "Ops... !",
                                                                            text: 'Houve um erro ao salvar itens do registro de inventário',
                                                                            icon: 'error',
                                                                            showConfirmButton: false,
                                                                            timerProgressBar: true,
                                                                            timer: '2500'
                                                                        })
                                                                    },
                                                                )
                                                            },
                                                            response => {
                                                                Swal.fire({
                                                                    title: "Ops... !",
                                                                    text: 'Houve um erro ao salvar informações do registro de inventário',
                                                                    icon: 'error',
                                                                    showConfirmButton: false,
                                                                    timerProgressBar: true,
                                                                    timer: '2500'
                                                                })
                                                            },
                                                        )
                                                    },
                                                    async response4 => {
                                                        await setInvInfo(invInfo.map((inf) => {
                                                            //inf['observacao'] = result.value
                                                            inf['status'] = '0'
                                                            return inf
                                                        }).filter((item)=>{
                                                            if(item._id) {
                                                                return item
                                                            }
                                                        }))
                                                        Swal.fire({
                                                            title: "Ops... !",
                                                            text: 'Houve um erro ao salvar informações do registro de inventário',
                                                            icon: 'error',
                                                            showConfirmButton: false,
                                                            timerProgressBar: true,
                                                            timer: '2500'
                                                        })
                                                    },
                                                )
                                            }else{
                                                await api.put(`/registro?tr=icd3vatgr@23!`, [invInfo[0]],option).then(
                                                    async response => {
                                                        await api.post('/registro_tag_picking', invItems,option).then(
                                                            async response2 => {
                                                                Swal.fire({
                                                                    title: "Concluido!",
                                                                    text: 'Inventário realizado com successo',
                                                                    icon: 'success',
                                                                    showConfirmButton: false,
                                                                    timerProgressBar: true,
                                                                    timer: '2500'
                                                                })
                                                            },
                                                            response2 => {
                                                                Swal.fire({
                                                                    title: "Ops... !",
                                                                    text: 'Houve um erro ao salvar itens do registro de inventário',
                                                                    icon: 'error',
                                                                    showConfirmButton: false,
                                                                    timerProgressBar: true,
                                                                    timer: '2500'
                                                                })
                                                            },
                                                        )
                                                    },
                                                    response => {
                                                        Swal.fire({
                                                            title: "Ops... !",
                                                            text: 'Houve um erro ao salvar informações do registro de inventário',
                                                            icon: 'error',
                                                            showConfirmButton: false,
                                                            timerProgressBar: true,
                                                            timer: '2500'
                                                        })
                                                    },
                                                )
                                            }
                                        },
                                        response3 => {
                                            Swal.fire({
                                                title: "Ops... !",
                                                text: 'Houve um erro ao carregar informações da conta',
                                                icon: 'error',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '2500'
                                            })
                                        }
                                    ).finally(() => {
                                        setLoadingSave(false)
                                    })
                                /*}else{
                                    setLoadingSave(false)
                                }
                            }
                        )*/
                    }
                }
            )
        }
    }

    return (
        <div className="mainContainer"
            style={JSON.parse(sessionStorage.getItem('collors'))}
        >
            <div id="background">
                {loadingSave ?
                    <div className='extra'>
                        <div style={{marginLeft: 'calc(50vw - 75px)', marginTop: 'calc(50vh - 75px)', minHeight: 'auto', height: '200px'}}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <ReactLoading type={'bars'} color={'#8C0B3B'} height={150} width={150}/>
                            </div>
                        </div>
                    </div>
                :''}
                <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"} style={{marginLeft: '30px', marginRight: '30px'}}>
                    <div className="title" id="colection">
                        <h1>Inventários Externos</h1>
                    </div>
                    <div
                        className='alertWindow'
                    >
                        <div style={{margin: '0 auto'}}>
                            <img src={AlertLogo}/>
                        </div>
                        <div>
                            <p className='paragraph'>Todos os bens marcados como encontrado devem possuir uma foto.</p>
                            <p className='paragraph'>Confira todo o inventário antes de concluir.</p>
                            <p className='paragraph'>Este inventário externo só pode ser respondido uma vez.</p>
                            <p className='paragraph'>Após conclusão, inventario ficará disponivel apenas para visualização.</p>
                        </div>
                    </div>
                    <div className="invHeader">
                        <h1>Identificador: {identificador}</h1>
                    </div>
                    {!invLoading?
                        <div>
                            {invInfo[0] && invInfo[0].status == '0' ?
                                <div style={{marginTop:'20px'}}>
                                    <input type={'checkbox'} value={invCheck} onClick={e=>{setInvCheck(!invCheck)}}/> Eu <b>{invInfo[0] ? invInfo[0].externo.nome_responsavel : ''}</b> assumo responsabilidade pelo inventário aqui realizado
                                </div>
                                :
                                <div style={{marginTop:'20px',width: '100%', textAlign:"center"}}>
                                    <h2>Este inventário já foi realizado</h2>
                                </div>
                            }
                        </div>
                    :''}
                    {invLoading?
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <ReactLoading type={'bars'} color={'#8C0B3B'} height={150} width={150}/>
                        </div>
                    :
                        <div>
                            <div>
                                {invItems.map((inv, index) => {
                                    if(inv.total_reg == undefined) {
                                        let info = inv.encontrado == '0' ? 'Ausente' : 'Encontrado'
                                        let color = inv.encontrado == '0' ? '#B40C0C' : '#4EA90B'
                                        return (
                                            <div key={index} className='listInvExt'>
                                                <div style={{marginBottom:'5px'}}><label>{index + 1}</label></div>
                                                <div className='listBroke'>
                                                    <div>
                                                        <div>{inv.descricao}</div>
                                                        <div>{inv.tag}</div>
                                                    </div>
                                                    {invInfo[0] && invInfo[0].status == '0' ? 
                                                        <div className='fieldfoto'>
                                                            {inv.b64 && inv.b64 != '' ?
                                                                <img src={inv.b64} style={{margin:'auto'}}/>
                                                            :
                                                                'Sem Foto'
                                                            }
                                                        </div>
                                                    :
                                                        <div className='fieldfoto'>
                                                            {inv.foto && inv.foto != '' ?
                                                                <img src={`${process.env.REACT_APP_API_URL}/${inv.foto}`} style={{margin:'auto'}}/>
                                                            :
                                                                'Sem Foto'
                                                            }
                                                        </div>
                                                    }
                                                    <div className='fieldValues'>
                                                        <label>INF. COMPL:</label>
                                                        <div><b>{infLabels[0]}</b>: {inv.inf_compl_1}</div>
                                                        <div><b>{infLabels[1]}</b>: {inv.inf_compl_2}</div>
                                                        <div><b>{infLabels[2]}</b>: {inv.inf_compl_3}</div>
                                                        <div><b>{infLabels[3]}</b>: {inv.inf_compl_4}</div>
                                                        <div><b>{infLabels[4]}</b>: {inv.inf_compl_5}</div>
                                                        <div><b>{infLabels[5]}</b>: {inv.inf_compl_6}</div>
                                                        <div><b>{infLabels[6]}</b>: {inv.inf_compl_7}</div>
                                                        <div><b>{infLabels[7]}</b>: {inv.inf_compl_8}</div>
                                                        <div><b>{infLabels[8]}</b>: {inv.inf_compl_9}</div>
                                                    </div>
                                                    <div className='fieldValues'>
                                                        <label>OBSERVAÇÃO:</label>
                                                        {invInfo[0] && invInfo[0].status == '0' ?
                                                            <textarea value={inv.observacao} onChange={e=>{changeObservation(e.target.value, inv, index)}}></textarea>
                                                        :
                                                            <textarea value={inv.observacao} readOnly></textarea>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='listBroke'>
                                                    <div>
                                                        {invInfo[0] && invInfo[0].status == '0' ?
                                                            <div>
                                                                {inv.encontrado == '0' ?
                                                                    <button style={{minWidth: '200px',width: '100%'}} onClick={e=>{askMarcarEncontrado(inv, index)}}>Marcar como encontrado</button>
                                                                :
                                                                    <button style={{minWidth: '200px',width: '100%'}} onClick={e=>{askMarcarAusente(inv, index)}}>Marcar como ausente</button>
                                                                }
                                                                <input id={`itemImage_${index}`} style={{display: 'none'}} type='file' accept='image/*' onChange={e=> {
                                                                    e.preventDefault()
                                                                    if(e.target.files[0]) {
                                                                        marcarEncontrado(e.target.files[0], inv, index)
                                                                    }
                                                                }}/>
                                                            </div>
                                                        :''}
                                                    </div>
                                                    <div>
                                                        <div className='statusAlert' style={{background: color}}>{info}</div>
                                                        <div>{inv.dt_leitura}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    }
                    {!invLoading?
                            <div>
                                {invInfo[0] && invInfo[0].status == '0' ?
                                    <button className='conclude' onClick={e=> {invConclude()}}>FINALIZAR</button>
                                :null}
                            </div>
                        :null}
                </div>
            </div>
        </div>
    )
}

export default EILink