import { useEffect, useState } from "react";

import { Autocomplete, FormControl, FormGroup, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js";

import { cyan, purple } from "@mui/material/colors"

import LineChart from "../../../../components/ReCharts/LineChart";

import api from "../../../../services/api";

//--------------------------------------------------------
const fakeDATA = [
    [
        { name: '01/07', estoque: 40, hoje: 24},
        { name: '02/07', estoque: 30, hoje: 13},
        { name: '03/07', estoque: 20, hoje: 98},
        { name: '04/07', estoque: 27, hoje: 39},
        { name: '05/07', estoque: 18, hoje: 48},
        { name: '06/07', estoque: 23, hoje: 38},
        { name: '08/07', estoque: 34, hoje: 43},
        { name: '09/07', estoque: 44, hoje: 46},
        { name: '10/07', estoque: 44, hoje: 27}
    ],
    [
        { name: '01/07', estoque: 35, hoje: 19 },
        { name: '02/07', estoque: 29, hoje: 15 },
        { name: '03/07', estoque: 22, hoje: 64 },
        { name: '04/07', estoque: 28, hoje: 34 },
        { name: '05/07', estoque: 25, hoje: 38 },
        { name: '06/07', estoque: 21, hoje: 29 },
        { name: '08/07', estoque: 33, hoje: 37 },
        { name: '09/07', estoque: 42, hoje: 49 },
        { name: '10/07', estoque: 43, hoje: 28 }
    ],
    [
        { name: '01/07', estoque: 50, hoje: 31 },
        { name: '02/07', estoque: 38, hoje: 18 },
        { name: '03/07', estoque: 25, hoje: 72 },
        { name: '04/07', estoque: 30, hoje: 48 },
        { name: '05/07', estoque: 26, hoje: 57 },
        { name: '06/07', estoque: 29, hoje: 45 },
        { name: '08/07', estoque: 36, hoje: 51 },
        { name: '09/07', estoque: 48, hoje: 62 },
        { name: '10/07', estoque: 50, hoje: 37 }
    ],
    [
        { name: '01/07', estoque: 42, hoje: 28 },
        { name: '02/07', estoque: 34, hoje: 14 },
        { name: '03/07', estoque: 21, hoje: 58 },
        { name: '04/07', estoque: 25, hoje: 36 },
        { name: '05/07', estoque: 22, hoje: 42 },
        { name: '06/07', estoque: 27, hoje: 33 },
        { name: '08/07', estoque: 32, hoje: 41 },
        { name: '09/07', estoque: 45, hoje: 52 },
        { name: '10/07', estoque: 46, hoje: 29 }
    ],
    [
        { name: '01/07', estoque: 44, hoje: 27 },
        { name: '02/07', estoque: 31, hoje: 17 },
        { name: '03/07', estoque: 23, hoje: 63 },
        { name: '04/07', estoque: 29, hoje: 40 },
        { name: '05/07', estoque: 20, hoje: 44 },
        { name: '06/07', estoque: 25, hoje: 34 },
        { name: '08/07', estoque: 30, hoje: 42 },
        { name: '09/07', estoque: 41, hoje: 53 },
        { name: '10/07', estoque: 43, hoje: 30 }
    ],
    [
        { name: '01/07', estoque: 37, hoje: 22 },
        { name: '02/07', estoque: 28, hoje: 13 },
        { name: '03/07', estoque: 18, hoje: 71 },
        { name: '04/07', estoque: 24, hoje: 37 },
        { name: '05/07', estoque: 19, hoje: 47 },
        { name: '06/07', estoque: 23, hoje: 33 },
        { name: '08/07', estoque: 34, hoje: 39 },
        { name: '09/07', estoque: 44, hoje: 55 },
        { name: '10/07', estoque: 45, hoje: 27 }
    ],
    [
        { name: '01/07', estoque: 41, hoje: 23 },
        { name: '02/07', estoque: 33, hoje: 16 },
        { name: '03/07', estoque: 19, hoje: 65 },
        { name: '04/07', estoque: 26, hoje: 42 },
        { name: '05/07', estoque: 21, hoje: 46 },
        { name: '06/07', estoque: 28, hoje: 31 },
        { name: '08/07', estoque: 36, hoje: 43 },
        { name: '09/07', estoque: 47, hoje: 58 },
        { name: '10/07', estoque: 48, hoje: 33 }
    ],
    [
        { name: '01/07', estoque: 39, hoje: 25},
        { name: '02/07', estoque: 32, hoje: 14},
        { name: '03/07', estoque: 17, hoje: 68},
        { name: '04/07', estoque: 23, hoje: 39},
        { name: '05/07', estoque: 20, hoje: 49},
        { name: '06/07', estoque: 25, hoje: 37},
        { name: '08/07', estoque: 35, hoje: 40},
        { name: '09/07', estoque: 46, hoje: 54},
        { name: '10/07', estoque: 47, hoje: 29}
    ],
    [
        { name: '01/07', estoque: 43, hoje: 26 },
        { name: '02/07', estoque: 34, hoje: 12 },
        { name: '03/07', estoque: 21, hoje: 66 },
        { name: '04/07', estoque: 27, hoje: 41 },
        { name: '05/07', estoque: 22, hoje: 45 },
        { name: '06/07', estoque: 26, hoje: 35 },
        { name: '08/07', estoque: 38, hoje: 44 },
        { name: '09/07', estoque: 49, hoje: 60 },
        { name: '10/07', estoque: 50, hoje: 32 }
    ],
    [
        { name: '01/07', estoque: 40, hoje: 24 },
        { name: '02/07', estoque: 30, hoje: 13 },
        { name: '03/07', estoque: 20, hoje: 98 },
        { name: '04/07', estoque: 27, hoje: 39 },
        { name: '05/07', estoque: 18, hoje: 48 },
        { name: '06/07', estoque: 23, hoje: 38 },
        { name: '08/07', estoque: 34, hoje: 43 },
        { name: '09/07', estoque: 44, hoje: 46 },
        { name: '10/07', estoque: 44, hoje: 27 }
    ]
];


export default function ItemsByLevels(){
    const id_conta = sessionStorage.getItem('idConta')

    const [openSelectCategoria, setOpenSelectCategoria] = useState(false)

    const [categoria, setCategoria] = useState()

    const [data, setData] = useState()

    const [nivel1, setNivel1] = useState()

    const [nivel2, setNivel2] = useState()

    const [nivel3, setNivel3] = useState()

    const [nivel4, setNivel4] = useState()

    // Lista 
    const [categoriaList, setCategoriaList] = useState([])

    const [nivel1List, setNivel1List] = useState([])

    const [nivel2List, setNivel2List] = useState([])

    const [nivel3List, setNivel3List] = useState([])

    const [nivel4List, setNivel4List] = useState([])

    useEffect(()=>{
        function getCategorias(){
            const descricao = "*"
            api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/${descricao}/*/*`).then(
                response=>{
                    response.data.pop()
                    if(response.data.length){
                        setCategoria(response.data[0])
                        setCategoriaList(response.data)
                    }
                }
            )
        }

        getCategorias()
    },[])

    useEffect(()=>{
        function getMovimentation(){
            api.get(`/reg_mov_itens/${id_conta}/*/*/*/${nivel1 ?? '*'}/${nivel2 ?? '*'}/${nivel3 ?? '*'}/${nivel4 ?? '*'}`).then(
                response=>{
                    const responseFilter = response.data.filter(item => item?.id_item?.id_categoria === categoria._id)
                    const aggroupByData = responseFilter.reduce((acc, item) => {
                        const date = formatDate(item.dt_movimentacao);
                    
                        if (!acc[date]) {
                            acc[date] = {
                                name: date,
                                estoque: 0,
                                hoje: item?.qtde_lido || 0
                            };
                        } else {
                            acc[date].hoje += item?.qtde_lido || 0; 
                        }
                    
                        return acc;
                    }, {});

                    totalByNivel(aggroupByData)
                }
            )
        }

        if(categoria && nivel1){
            getMovimentation()
        }
    },[categoria, nivel1, nivel2, nivel3, nivel4])

    useEffect(() => {
        function nivel1Get() {
            api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
            ).then(
                response => {
                    setNivel1List(response.data)
                    if(response.data.length){
                        setNivel1(response.data[0]._id)
                    }
                }
            )
        }

        nivel1Get()
    }, [])

    useEffect(() => {
        function nivel2Get(nivel1) {
            if (nivel1 && nivel1 !== "*") {
                const idConta = sessionStorage.getItem('idConta');
                const nivel1Path = nivel1 || '*';
        
                api.get(`/nivel_loc2/${idConta}/${nivel1Path}/*/*/*/*`)
                .then(
                    response => {
                        setNivel2List(response.data)
                        if(!response?.data.find(item => item._id === nivel2)){
                            setNivel2()
                            setNivel3()
                            setNivel4()
                        }
                    }
                )
            } else {
                setNivel2List([]);
            }
        } 

        nivel2Get(nivel1)
    }, [nivel1])

    useEffect(() => {
        function nivel3Get(nivel2) {
            if (nivel2 && nivel2 !== "*") {
                const idConta = sessionStorage.getItem('idConta');
                const nivel2Path = nivel2 || '*';
        
                api.get(`/nivel_loc3/${idConta}/${nivel2Path}/*/*/*/*`)
                    .then(response => {
                        setNivel3List(response.data);
                        if(!response?.data.find(item => item._id === nivel3)){
                            setNivel3()
                            setNivel4()
                        }
                    })
                    .catch(error => {
                        console.error("Error fetching data:", error);
                        // Optional: handle error state, show user feedback, etc.
                    });
            } else {
                setNivel3List([]);
            }
        }

        nivel3Get(nivel2)
    }, [nivel2])
    
    useEffect(() => {
        function nivel4Get(nivel3) {
            if (nivel3 && nivel3 !== "*") {
                const idConta = sessionStorage.getItem('idConta');
                const nivel3Path = nivel3 || '*';
        
                api.get(`/nivel_loc4/${idConta}/${nivel3Path}/*/*/*/*`)
                    .then(response => {
                        setNivel4List(response.data);
                    })
            } else {
                setNivel4List([]);
            }
        }    

        nivel4Get(nivel3)
    }, [nivel3])    

    function formatDate(dataISO){
        const data = new Date(dataISO);
        const dia = data.getUTCDate().toString().padStart(2, '0');  // Obter o dia com dois dígitos
        const mes = (data.getUTCMonth() + 1).toString().padStart(2, '0');  // Obter o mês (começa em 0, por isso +1)
        const formatoDDMM = `${dia}/${mes}`;
        return formatoDDMM
    }

    function totalByNivel(movItems){
        const id_nivel_loc_1 = nivel1 ?? "*"
        const id_nivel_loc_2 = nivel2 ?? "*"
        const id_nivel_loc_3 = nivel3 ?? "*"
        const id_nivel_loc_4 = nivel4 ?? "*"

        api.get(`/movItensEstoque/*/${id_conta}/${categoria._id}/${id_nivel_loc_1}/${id_nivel_loc_2}/${id_nivel_loc_3}/${id_nivel_loc_4}`).then(
            response=>{
                if(response.data?.length){
                    response.data.map((item)=>{
                        const date = formatDate(item.dt_movimentacao);
                        if (movItems[date]) {
                            movItems[date].estoque = item?.qtde_estoque || 0
                        }else{
                            movItems[date] = {
                                name: date,
                                hoje: 0,
                                estoque: item?.qtde_estoque || 0
                            }
    
                        }
                    })
                }
                    
                const formatArray = Object.values(movItems).flat()
                
                setData(formatArray)
            }
        )
    }

    return (
        <>
            <Stack 
                component={Paper} 
                spacing={3} 
                sx={{
                    p: 3, 
                    borderRadius: 2
                }}
            >
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <IconButton size="small" sx={{borderRadius: 1, backgroundColor: purple[50], color: purple[600]}}>
                            <Icon icon="mi:bar-chart-alt"/>
                        </IconButton>
                        <Stack direction="row" spacing={1}>
                            <Typography variant="h4">Produto</Typography>
                            <Typography variant="h4" sx={{fontWeight: 600}}>{categoria?.descricao || 'Selecionado'}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <FormGroup fullWidth sx={{minWidth: '35ch'}}>
                            <Typography>Categoria</Typography>
                            <Autocomplete
                                options={categoriaList}
                                getOptionLabel={(option) => option.descricao}
                                value={categoriaList.find(cat => cat === categoria) || null}
                                onChange={(event, newValue) => {
                                    setCategoria(newValue ? newValue : null);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                noOptionsText={'Não encontrado'}
                            />
                        </FormGroup>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1}>
                    {nivel1 && nivel1List && (
                        <FormControl sx={{minWidth: 100}}>
                            <InputLabel id="nivel-1-label">Nivel 1</InputLabel>
                            <Select
                                labelId="nivel-1-label"
                                id="nivel-1-select"
                                label="Nivel 1"
                                sx={{borderRadius: 100}}
                                value={nivel1}
                                onChange={e=>{setNivel1(e.target.value)}}
                            >
                                {nivel1List.map((item, index)=>(
                                    <MenuItem key={index} value={item._id}>{item.descricao}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    <FormControl sx={{display: nivel1 ? "": "none", minWidth: 100}}>
                        <InputLabel id="demo-simple-select-label">Nivel 2</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Nivel 2"
                            sx={{borderRadius: 100}}
                            value={nivel2}
                            onChange={e=>{setNivel2(e.target.value)}}
                        >
                            <MenuItem value={null}></MenuItem>
                            {nivel2List.map((item, index)=>(
                                <MenuItem value={item._id}>{item.descricao}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{display: nivel2 ? "": "none", minWidth: 100}}>
                        <InputLabel id="demo-simple-select-label">Nivel 3</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Nivel 3"
                            sx={{borderRadius: 100}}
                            value={nivel3}
                            onChange={e=>{setNivel3(e.target.value)}}
                        >
                            <MenuItem value={null}></MenuItem>
                            {nivel3List.map((item, index)=>(
                                <MenuItem value={item._id}>{item.descricao}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{display: nivel3 ? "": "none", minWidth: 100}}>
                        <InputLabel id="demo-simple-select-label">Nivel 4</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Nivel 4"
                            sx={{borderRadius: 100}}
                            value={nivel4}
                            onChange={e=>{setNivel4(e.target.value)}}
                        >
                            <MenuItem value={null}></MenuItem>
                            {nivel4List.map((item, index)=>(
                                <MenuItem value={item._id}>{item.descricao}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>

                <LineChart width="100%" propsData={data && data.length ? data : null} series={['estoque', 'hoje']} propsColors={['#7599ff', '#64c8ff']}/>
            </Stack>
        </>
    )
}