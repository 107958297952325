import React, {useState, useCallback, useEffect} from 'react'
import ReactLoading from 'react-loading'
import Swal from 'sweetalert2'
import useWebSocket from 'react-use-websocket'
import {HiOutlineStatusOnline, HiOutlineStatusOffline} from 'react-icons/hi'
import {FaFilePdf, FaFileCsv, FaSearch} from 'react-icons/fa'
import {FiSearch} from 'react-icons/fi'
import {AiFillEdit, AiOutlineCloseCircle, AiOutlineCheckCircle} from 'react-icons/ai'
import {BiTrash} from 'react-icons/bi'

import api from '../../services/api'

import Menu from '../../components/Menu'
import ExportCSV from '../../components/exportCSV'
import ExportPDF from '../../components/exportPDF'
import { CSVLink } from 'react-csv'

import { Container, Row, Col, Button, Table, Form, InputGroup } from 'react-bootstrap';

import { useTranslation } from 'react-i18next'

function PrintOrder() {
    const { t } = useTranslation();

    const [printOrderList, setPrintOrderList] = useState([])
    const [selectedOrder, setSelectedOrder] = useState('')
    const [division, setDivision] = useState(1)
    const [pageOrder, setPageOrder] = useState(0)
    const [pageEpcs, setPageEpcs] = useState(0)
    const [upd, setUpd] = useState(true)
    const [userList, setUserList] = useState([])
    const [userFilter, setUserFilter] = useState('*')
    const [dataInicio, setDataInicio] = useState(dates(0, '-'))
    const [dataFim, setDataFim] = useState(dates(1, '+'))
    const [concludeFilter, setConcludeFilter] = useState('*')

    const [editVoidReal, setEditVoidReal] = useState('')
    const [editPerdasContabilizadas, setEditPerdasContabilizadas] = useState('')
    const [editNLote, setEditNLote] = useState('')
    const [editNLoteInterno, setEditNLoteInterno] = useState('')
    const [editNLoteCliente, setEditNLoteCliente] = useState('')
    const [editVoidEstimado, setEditVoidEstimado] = useState('')
    const [editQtdLote, setEditQtdLote] = useState('')

    const [editIndex, setEditIndex] = useState(-1)
    const [pageObservacoes, setPageObservacoes] = useState(0)
    const [popUpsList, setPopUpsList] = useState([])
    const [codeBarVerify, setCodeBarVerify] = useState('')
    const [search, setSearch] = useState('')
    const [searchByOrder, setSearchByOrder] = useState('')
    const [validInput, setValidInput] = useState('')
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        getUserList()
    }, [])
    
    useEffect(() => {
        setPageEpcs(0)
        setPageObservacoes(0)
    }, [search])
    
    useEffect(() => {
        setPageOrder(0)
    }, [searchByOrder])

    function submitTeste(event) {
        event.preventDefault()
        let search = ''
        if(searchByOrder != '') {
            search = ssccToEpc(searchByOrder)
        }
        getPrintOrderList(search)

    }
    
    const  getSocketUrl  =  useCallback ( ( )  =>  { 
        return  new  Promise ( resolve  =>  {
            resolve (`${process.env.REACT_APP_API_WSS}/${sessionStorage.getItem('idConta')}/${getID()}`)
        } )
    }, [])

    function getID(){	
        return 'xxxxxxxx-yxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
            return v.toString(16)
        })
    } 
    const socket = useWebSocket(getSocketUrl,{
        onOpen: (evt) => {
            console.log(`Janela: ${evt.target.url.replace(process.env.REACT_APP_API_WSS ,'')} conectado`)
        },

        onMessage: async(evt) => {
            let printOrder = printOrderList
            let jsonData = JSON.parse(evt.data)
            let index = printOrder.findIndex((order) => (order._id == jsonData.info.search._id))
            if(index > -1) {
                switch (jsonData.type) {
                    case 'leitura':
                        printOrder[index].epcs_impresso.push(jsonData.info.update.$push.epcs_impresso)
                        printOrder[index].read_by_ribbon += 1
                        if(jsonData.info.update.$push.observacao) {
                            if(jsonData.info.update.concluido) {
                                printOrder[index].concluido = jsonData.info.update.concluido
                                printOrder[index].dt_termino_ordem = jsonData.info.update.dt_termino_ordem
                            }else{
                                printOrder[index].dt_inicio_ordem = jsonData.info.update.dt_inicio_ordem
                            }
                            printOrder[index].observacao.push(jsonData.info.update.$push.observacao)
                            printOrder[index].status_secundario = jsonData.info.update.status_secundario
                        }
                        break
                    case 'perdas':
                        printOrder[index].qtd_perdas_lidas = jsonData.info.update.qtd_perdas_lidas
                        printOrder[index].read_by_ribbon += 1
                        if(jsonData.info.update.$push.observacao) {
                            printOrder[index].observacao.push(jsonData.info.update.$push.observacao)
                        }
                        break
                    case 'excluir':
                        printOrder.splice(index,1)
                        break
                    case 'cancelar':
                        printOrder[index].epcs = []
                        printOrder[index].epcs_impresso = []
                        printOrder[index].epcs_refazer = []
                        printOrder[index].observacao.push(jsonData.info.update.$push.observacao)
                        printOrder[index].cancelado = jsonData.info.update.cancelado
                        printOrder[index].status_secundario = jsonData.info.update.status_secundario
                        break
                    case 'editar':
                        printOrder[index].qtd_void_real = jsonData.info.update.qtd_void_real
                        printOrder[index].qtd_perdas_contabilizadas = jsonData.info.update.qtd_perdas_contabilizadas
                        printOrder[index].num_lote = jsonData.info.update.num_lote
                        printOrder[index].lote_interno = jsonData.info.update.lote_interno
                        printOrder[index].lote_correios = jsonData.info.update.lote_correios
                        printOrder[index].qtd_por_lote = jsonData.info.update.qtd_por_lote
                        printOrder[index].qtd_void_esperada = jsonData.info.update.qtd_void_esperada
                        break
                    case 'imprimir':
                        printOrder[index].expedido = jsonData.info.update.expedido
                        printOrder[index].status_secundario = jsonData.info.update.status_secundario
                        break
                    case 'concluir':
                        printOrder[index].concluido = jsonData.info.update.concluido
                        printOrder[index].dt_termino_ordem = jsonData.info.update.dt_termino_ordem
                        printOrder[index].qtd_perdas_lidas = jsonData.info.update.qtd_perdas_lidas
                        printOrder[index].observacao.push(jsonData.info.update.$push.observacao)
                        printOrder[index].status_secundario = jsonData.info.update.status_secundario
                        break
                    case 'obs':
                        printOrder[index].observacao.push(jsonData.info.update.$push.observacao)
                        if(jsonData.info.update.qtd_ribbon) {
                            printOrder[index].read_by_ribbon = jsonData.info.update.read_by_ribbon
                            printOrder[index].qtd_ribbon = jsonData.info.update.qtd_ribbon
                        }
                        break
                    case 'refazer':
                        printOrder[index].epcs = jsonData.info.update.epcs
                        printOrder[index].epcs_impresso = jsonData.info.update.epcs_impresso
                        printOrder[index].epcs_refazer = [...printOrder[index].epcs_refazer, ...jsonData.info.update.$push.epcs_refazer.$each]
                        printOrder[index].observacao.push(jsonData.info.update.$push.observacao)
                        break
                    case 'validar':
                        printOrder[index].epcs_impresso.push(jsonData.info.update.$push.epcs_impresso)
                        if(jsonData.info.update.$push.observacao) {
                            if(jsonData.info.update.concluido) {
                                printOrder[index].concluido = jsonData.info.update.concluido
                                printOrder[index].dt_termino_ordem = jsonData.info.update.dt_termino_ordem
                            }else{
                                printOrder[index].dt_inicio_ordem = jsonData.info.update.dt_inicio_ordem
                            }
                            printOrder[index].observacao.push(jsonData.info.update.$push.observacao)
                        }
                        break
                    default:
                        break
                }
    
                await setPrintOrderList(printOrder)
                await forceToUpdate()
            }
            /*
            let printerLista = printOrderList
            let ppsList = popUpsList
            let tags = await evt.data.split("|")
            let epcs = await tags.map(tag => {
                let item = tag.split(';')
                if(item[0] == 'p') {
                    verifyCodeBar(item[1])
                }
                return (item[1])
            })
            await epcs.map(epc => {
                let orderIndex = printOrderList.findIndex((order) => (order.epcs.indexOf(epc) >= 0))
                if(orderIndex >= 0) {
                    if(printerLista[orderIndex].epcs_impresso.indexOf(epc) < 0) {
                        printerLista[orderIndex].epcs_impresso.push(epc)

                        if(printerLista[orderIndex].epcs_impresso.length == 1) {
                            ppsList.push({
                                epc,
                                identificador: printerLista[orderIndex].identificador,
                                type: 'init',
                                title: 'Verificar inicio de leitura',
                                text: 'Faça a leitura do código de barra da primeira etiqueta impressa'
                            })
                            initnotask(orderIndex, 'Primeira leitura realizada')
                        }

                        if(printerLista[orderIndex].epcs_impresso.length == printerLista[orderIndex].epcs.length) {
                            ppsList.push({
                                epc,
                                type: 'conclude',
                                title: 'Verificar termino de leitura',
                                text: 'Faça a leitura do código de barra da ultima etiqueta impressa'
                            })
                            concludenotask(orderIndex, 'Leitura concluida')
                        }

                        let options = {
                            headers: { 'Content-Type': ['application/json'], "Access-Control-Allow-Origin": "*" }
                        }

                        let reg = [{
                            _id: printerLista[orderIndex]._id,
                            epcs_impresso: printerLista[orderIndex].epcs_impresso
                        }]

                        api.post(`/ordem_impressao`, reg,options).then(
                            response => {},
                            response => {
                                console.log('Não salvou')
                            }
                        )
                    }
                }
            })
            await setPopUpsList(ppsList)
            await setPrintOrderList(printerLista)
            await forceToUpdate()
            */
        },

        onClose: (evt) => {
            console.log(`Janela: ${evt.target.url.replace(process.env.REACT_APP_API_WSS,'')} desconectado`)
        }
    })
    
    useEffect(async () => {
        await getPrintOrderList()
    }, [pageOrder])
    

    function forceToUpdate(){
        setUpd(!upd)
    }

    async function getPrintOrderList(search='') {
        setLoading(true)
        let route = `/ordem_impressao_hoje/${sessionStorage.getItem('idConta')}/${userFilter}/*/${dataInicio}/${dataFim}/${concludeFilter}/${pageOrder}`
        if(search && search != ''){route += `?epcSearch=${search}`}
        api.get(route).then(
        //api.get(`/ordem_impressao/${sessionStorage.getItem('idConta')}/${userFilter}/*/${dataInicio}/${dataFim}/${concludeFilter}/${pageOrder}`).then(
            async response => {
                await setPrintOrderList(response.data)
                await setLoading(false)
            },
            response => {
                Swal.fire({
                    title: "Ops... !",
                    text: 'Erro de requisição',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
                setLoading(false)
            }
        )
    }
    
    function getUserList() {
        api.get(`/usuario/${sessionStorage.getItem('idConta')}/*/*/*/*`).then(
            response => {
                setUserList(response.data)
            }
        )
    }

    async function askDownloadArchives(index) {
        let printerLista = printOrderList

        if(printerLista[index].expedido) {
            Swal.fire({
                title: 'Atenção!',
                text: "Deseja baixar novamente esta ordem de impressão?\nInforme a chave de permissão",
                icon: "warning",
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                preConfirm: (reason) => {
                    return reason.toUpperCase()
                },
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar"
            }).then(
                result => {
                    if(result.isConfirmed) {
                        if(result.value == 'ICGA123') {
                            downloadArchives(printerLista[index])
                        }else{
                            Swal.fire({
                                title: "Ops... !",
                                text: 'Chave de permissão não confere',
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }
                    }
                }
            )
        }else{
            printerLista[index].expedido = true
            printerLista[index].status_secundario = 'IMPRESSAO ENVIADA'
            let reg = {
                type: 'imprimir',
                info: {
                    search: {
                        _id: printerLista[index]._id,
                        id_conta: sessionStorage.getItem('idConta')
                    },
                    update: {
                        expedido: true,
                        status_secundario: 'IMPRESSAO ENVIADA'
                    }
                }
            }

            socket.sendJsonMessage(reg)

            downloadArchives(printerLista[index])
            await setPrintOrderList(printerLista)
            await forceToUpdate()
        }
    }

    function downloadArchives(printOrder) {
        setTimeout(() => {window.open(printOrder.arquivo_bat)}, 300)
        setTimeout(() => {window.open(printOrder.arquivo_grf)}, 600)
        setTimeout(() => {window.open(printOrder.arquivo_csv)}, 900)
    }

    function deleteOrder(item) {
        Swal.fire({
            title: 'Atenção!',
            text: "Deseja realmente excluir esta ordem de impressão?\nInforme a chave de permissão",
            icon: "warning",
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            preConfirm: (reason) => {
                return reason.toUpperCase()
            },
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        }).then(
            result => {
                if(result.isConfirmed) {
                    if(result.value == 'ONLY-G') {
                        let reg = {
                            type: 'excluir',
                            info: {
                                search: {
                                    _id: item._id,
                                    id_conta: sessionStorage.getItem('idConta')
                                }
                            }
                        }
                        socket.sendJsonMessage(reg)
                        Swal.fire({
                            title: "Sucesso !",
                            text: 'Ordem de impressão excluida com sucesso',
                            icon: 'success',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }else{
                        Swal.fire({
                            title: "Ops... !",
                            text: 'Chave de permissão não confere',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }
                }
            }
        )
    }

    function cancelOrder(item) {
        Swal.fire({
            title: 'Atenção!',
            text: "Deseja realmente cancelar esta ordem de impressão?\nInforme a chave de permissão",
            icon: "warning",
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            preConfirm: (reason) => {
                return reason.toUpperCase()
            },
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        }).then(
            result => {
                if(result.isConfirmed) {
                    Swal.fire({
                        title: 'Motivo',
                        text: "Informe o motivo do cancelamento da ordem",
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        preConfirm: (reason) => {
                            return reason.toUpperCase()
                        },
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar"
                    }).then(
                        reason => {
                            if(result.value == 'ICGA123') {
                                let reg = {
                                    type: 'cancelar',
                                    info: {
                                        search: {
                                            _id: item._id,
                                            id_conta: sessionStorage.getItem('idConta')
                                        },
                                        update: {
                                            epcs: [],
                                            epcs_impresso: [],
                                            epcs_refazer: [],
                                            cancelado: 1,
                                            status_secundario: 'ORDEM CANCELADA',
                                            $push: {
                                                observacao: {
                                                    obs: 'Ordem cancelada: ' + reason.value.trim()
                                                }
                                            }
                                        }
                                    }
                                }
                                socket.sendJsonMessage(reg)
                                Swal.fire({
                                    title: "Sucesso !",
                                    text: 'Ordem de impressão excluida com sucesso',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                            }else{
                                Swal.fire({
                                    title: "Ops... !",
                                    text: 'Chave de permissão não confere',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                            }
                        }
                    )
                }
            }
        )
    }

    function orderNumberListGet() {
        let list = []
        let qtdpages = Math.ceil((printOrderList[printOrderList.length - 1].total_ordens / 30))
        
        if(qtdpages >= 2 && qtdpages <= 5) {
            for(let i= 1; i <= qtdpages; i++) {
                list.push(i)
            }
        }else if(qtdpages > 5) {
            if(pageOrder <= 1) {
                for(let i= 1; i <= 5; i++) {
                    list.push(i)
                }
            } else if(pageOrder > 1 && pageOrder < (qtdpages - 2)) {
                for(let i= (pageOrder - 1); i <= (pageOrder + 3); i++) {
                    list.push(i)
                }
            } else {
                for(let i= (qtdpages - 4); i <= qtdpages; i++) {
                    list.push(i)
                }
            }
        }
        return list
    }

    function epcsNumberListGet() {
        let list = []
        let val = printOrderList[selectedOrder].epcs.filter((val) => {
            let filtered = search.replace('(', '').replace(')', '')
            if(
                search == '' ||
                val.search(filtered) >= 0 ||
                epcToSscc(val).replace('(', '').replace(')', '').search(filtered) >= 0
            ) {
                return val
            }
        })
        let qtdpages = Math.ceil((val.length / 1000))
        
        if(qtdpages >= 2 && qtdpages <= 5) {
            for(let i= 1; i <= qtdpages; i++) {
                list.push(i)
            }
        }else if(qtdpages > 5) {
            if(pageEpcs <= 1) {
                for(let i= 1; i <= 5; i++) {
                    list.push(i)
                }
            } else if(pageEpcs > 1 && pageEpcs < (qtdpages - 2)) {
                for(let i= (pageEpcs - 1); i <= (pageEpcs + 3); i++) {
                    list.push(i)
                }
            } else {
                for(let i= (qtdpages - 4); i <= qtdpages; i++) {
                    list.push(i)
                }
            }
        }
        return list
    }
    
    function obsNumberListGet() {
        let list = []
        let qtdpages = Math.ceil((printOrderList[selectedOrder].observacao.length / 1000))
        
        if(qtdpages >= 2 && qtdpages <= 5) {
            for(let i= 1; i <= qtdpages; i++) {
                list.push(i)
            }
        }else if(qtdpages > 5) {
            if(pageObservacoes <= 1) {
                for(let i= 1; i <= 5; i++) {
                    list.push(i)
                }
            } else if(pageObservacoes > 1 && pageObservacoes < (qtdpages - 2)) {
                for(let i= (pageObservacoes - 1); i <= (pageObservacoes + 3); i++) {
                    list.push(i)
                }
            } else {
                for(let i= (qtdpages - 4); i <= qtdpages; i++) {
                    list.push(i)
                }
            }
        }
        return list
    }

    const headersCSV = [
        'TAG',
        'STATUS',
        'IDENTIFICADOR',
        'QUANTIDADE ESPERADA',
        'QUANTIDADE IMPRESSA'
    ]

    function csv() {
        let body = []
        printOrderList.map((order) => {
            order.epcs.map((epc) => {
                body.push([
                    epc,
                    order.epcs_impresso.includes(epc) ? 'Encontrado' : 'Aguardando Leitura',
                    order.identificador,
                    order.epcs.length,
                    order.epcs_impresso.length,
                ])
            })
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
        return [...body2]
    }

    function csvRefazer() {
        let body = []
        printOrderList.map((order) => {
            order.epcs_refazer.map((epc) => {
                body.push([
                    epc,
                    epcToSscc(epc),
                    '1',
                ])
            })
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
        if(body2.length){
            return [...body2]
        }else{
            return [['']]
        }
    }

    function csvRefazerKey(key) {
        let body = []
        printOrderList[key].epcs_refazer.map((epc) => {
            body.push([
                epc,
                epcToSscc(epc),
                '1',
            ])
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
        if(body2.length){
            return [...body2]
        }else{
            return [['']]
        }
    }

    function scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    function listarEPCs(indexOrder) {
        setPageEpcs(0)
        setSelectedOrder(indexOrder)
        setDivision(2)
        scrollToTop()
    }
    
    function listarObeservacoes(indexOrder) {
        setPageObservacoes(0)
        setSelectedOrder(indexOrder)
        setDivision(5)
        scrollToTop()
    }

    function clearList() {
        setSearch('')
        setPageObservacoes(0)
        setPageEpcs(0)
        setSelectedOrder('')
        setDivision(1)
    }

    function formatDate(data) {
        if(![null, ''].includes(data)) {
            let dt = new Date(data)
            let dt_reg = ("0" + dt.getDate()).slice(-2) + "/" + ("0" + (dt.getMonth() + 1)).slice(-2) + "/" + dt.getFullYear()
            dt_reg += " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" +dt.getMinutes()).slice(-2) + ":" + ("0" +dt.getSeconds()).slice(-2)
            return dt_reg
        }else{
            return ''
        }
    }

    function dates(days, direction) {
        let dt   = new Date()
        if(direction == '+') {
            dt = new Date(dt.getTime() + (days * 24 * 60 * 60 * 1000))
        }
        else if(direction == '-') {
            dt = new Date(dt.getTime() - (days * 24 * 60 * 60 * 1000))
        }
        return (dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2))
    }

    function selectEdit(key) {
        setEditIndex(key)
        setEditVoidReal(printOrderList[key].qtd_void_real)
        setEditPerdasContabilizadas(printOrderList[key].qtd_perdas_contabilizadas)
        setEditNLote(printOrderList[key].num_lote)
        setEditNLoteInterno(printOrderList[key].lote_interno)
        setEditNLoteCliente(printOrderList[key].lote_correios)
        setEditQtdLote(printOrderList[key].qtd_por_lote)
        setEditVoidEstimado(printOrderList[key].qtd_void_esperada)
    }

    function cancelEdit() {
        setEditIndex(-1)
        setEditVoidReal('')
        setEditPerdasContabilizadas('')
        setEditNLote('')
        setEditNLoteInterno('')
        setEditNLoteCliente('')
        setEditQtdLote('')
        setEditVoidEstimado('')
    }

    function saveEdit(key) {
        Swal.fire({
            title: "Atenção !",
            html: `Deseja realmente editar esta ordem de impressão?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            async result => {
                if(result.isConfirmed) {
                    let printerLista = printOrderList

                    printerLista[key].qtd_void_real = editVoidReal
                    printerLista[key].qtd_perdas_contabilizadas = editPerdasContabilizadas
                    printerLista[key].num_lote = editNLote
                    printerLista[key].lote_interno = editNLoteInterno
                    printerLista[key].lote_correios = editNLoteCliente
                    printerLista[key].qtd_por_lote = editQtdLote
                    printerLista[key].qtd_void_esperada = editVoidEstimado

                    let reg = {
                        type: 'editar',
                        info: {
                            search: {
                                _id: printerLista[key]._id,
                                id_conta: sessionStorage.getItem('idConta')
                            },
                            update:{
                                qtd_void_real: printerLista[key].qtd_void_real,
                                qtd_perdas_contabilizadas: printerLista[key].qtd_perdas_contabilizadas,
                                num_lote: printerLista[key].num_lote,
                                lote_interno: printerLista[key].lote_interno,
                                lote_correios: printerLista[key].lote_correios,
                                qtd_por_lote: printerLista[key].qtd_por_lote,
                                qtd_void_esperada: printerLista[key].qtd_void_esperada
                            }
                        }
                    }

                    socket.sendJsonMessage(reg)
                    
                    Swal.fire({
                        title: "Sucesso !",
                        text: 'Informações atualizadas',
                        icon: 'success',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                    cancelEdit()

                    await setPrintOrderList(printerLista)
                    await forceToUpdate()
                }
            }
        )
    }

    function askTheReason(key) {
        if(printOrderList[key].concluido){
            Swal.fire({
                title: "Ops... !",
                text: 'Está ordem já foi concluida',
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })
        }else{
            Swal.fire({
                title: 'Observação',
                text: "Razão para concluir previamente ao termino da leitura",
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                preConfirm: (reason) => {
                    return reason
                },
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Concluir Leitura",
                cancelButtonText: "Cancelar"
            }).then(
                result => {
                    if(result.isConfirmed) {
                        conclude(key, result.value)
                    }
                }
            )
        }
    }
    
    function conclude(key, reason) {
        Swal.fire({
            title: "Atenção !",
            html: `Deseja realmente concluir previamente esta ordem de impressão?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    let printerLista = printOrderList
                    
                    let reg = {
                        type: 'concluir',
                        info: {
                            search: {
                                _id: printerLista[key]._id,
                                id_conta: sessionStorage.getItem('idConta')
                            },
                            update: {
                                concluido: printerLista[key].concluido = true,
                                $push: {
                                    observacao: {
                                        obs: 'Ordem concluida: ' + reason.trim()
                                    }
                                }
                            }
                        }
                    }

                    socket.sendJsonMessage(reg)

                    Swal.fire({
                        title: "Sucesso !",
                        text: 'Ordem finalizada',
                        icon: 'success',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            }
        )
    }

    /*function concludenotask(key, reason) {
        let printerLista = printOrderList

        let options = {
            headers: { 'Content-Type': ['application/json'], "Access-Control-Allow-Origin": "*" }
        }

        
        printerLista[key].concluido = true
        printerLista[key].dt_termino_ordem = new Date()
        printerLista[key].qtd_perdas_lidas = (printerLista[key].epcs.length - printerLista[key].epcs_impresso.length).toString()
        if(!printerLista[key].observacao){
            printerLista[key]['observacao'] = []
        }
        printerLista[key].observacao.push({
            obs: 'Ordem concluida: ' + reason.trim(),
            dt_obs: new Date()
        })
        
        let reg = [{
            _id: printerLista[key]._id,
            concluido: printerLista[key].concluido = true,
            dt_termino_ordem: printerLista[key].dt_termino_ordem,
            observacao: printerLista[key].observacao
        }]

        api.post(`/ordem_impressao`, reg, options).then(
            response => {
                Swal.fire({
                    title: "Sucesso !",
                    text: 'Ordem finalizada',
                    icon: 'success',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            },
            response => {
                Swal.fire({
                    title: "Ops... !",
                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }
        ).finally(
            async response => {
                await setPrintOrderList(printerLista)
                await forceToUpdate()
            }
        )
    }*/

    function ssccToEpc(sscc) {
        let epcHeader = '00110001'
        let filter = Number(6).toString(2)
        let partition = Number(5).toString(2)
        let reserved = '000000000000000000000000'
        let value = sscc.replace('(', '')
        value = value.replace(')', '')
        value = value.slice(2)
        let clearPrefix = '000000000000000000000000'
        let prefix = Number(value.slice(1,8)).toString(2)
        prefix = clearPrefix.substring(0, clearPrefix.length - prefix.length) + prefix
        let clearSerial = '0000000000000000000000000000000000'
        let serial = value.replace(value.slice(1,8), '')
        serial = serial.slice(0, serial.length - 1)
        serial = Number(serial).toString(2) //erro?
        serial = clearSerial.substring(0, clearSerial.length - serial.length) + serial
        let finalBin = `${epcHeader}${filter}${partition}${prefix}${serial}${reserved}`
        let finalHex = ''
        for(let i=0;i<finalBin.length; i+=4){
            finalHex += parseInt(finalBin.slice(i, i+4), 2).toString(16)
    
        }
        finalHex = finalHex.toUpperCase()
        return finalHex
    }

    function epcToSscc(epc) {
        let binary = ''
        for(let i=0;i<epc.length; i++){
            let val = parseInt(epc[i], 16).toString(2)
            binary += ('0000').substring(0, 4 - val.length) + val
        }
        let epcHeader = '00110001'
        let filter = Number(6).toString(2)
        let partition = Number(5).toString(2)
        binary = binary.slice(epcHeader.length + filter.length + partition.length)
        let reserved = '000000000000000000000000'
        binary = binary.slice(0, binary.length - reserved.length)
        let prefix = binary.slice(0, 24)
        prefix = parseInt(prefix, 2).toString()
        prefix = ('0000000').substring(0, 7 - prefix.length) + prefix
        let serial = binary.slice(24)
        serial = parseInt(serial, 2).toString()
        serial = ('0000000000').substring(0, 10 - serial.length) + serial
        let finalDecimal = prefix + serial
        finalDecimal = finalDecimal[7] + finalDecimal.slice(0,7) + finalDecimal.slice(8)
        let checkDigit = 0
        for(let i=0; i<finalDecimal.length; i++) {
            if(i%2 == 0) {
                checkDigit += parseInt(finalDecimal[i]) * 3
            }else{
                checkDigit += parseInt(finalDecimal[i])
            }
        }
        if(checkDigit%10 == 0) {
            checkDigit = 0
        }else{
            checkDigit = 10 - checkDigit%10
        }
        finalDecimal = '(00)' + finalDecimal + checkDigit.toString()
        return finalDecimal
    }

    function ssccToEpc(sscc) {
        let epcHeader = '00110001'
        let filter = Number(6).toString(2)
        let partition = Number(5).toString(2)
        let reserved = '000000000000000000000000'
        let value = sscc.replace('(', '')
        value = value.replace(')', '')
        value = value.slice(2)
        let clearPrefix = '000000000000000000000000'
        let serial = value.slice(0,1)
        let prefix = Number(value.slice(1,8)).toString(2)
        prefix = clearPrefix.substring(0, clearPrefix.length - prefix.length) + prefix
        let clearSerial = '0000000000000000000000000000000000'
        serial += value.replace(value.slice(1,8), '')
        serial = serial.slice(0, serial.length - 1)
        serial = Number(serial).toString(2) //erro?
        serial = clearSerial.substring(0, clearSerial.length - serial.length) + serial
        let finalBin = `${epcHeader}${filter}${partition}${prefix}${serial}${reserved}`
        let finalHex = ''
        for(let i=0;i<finalBin.length; i+=4){
            finalHex += parseInt(finalBin.slice(i, i+4), 2).toString(16)
    
        }
        finalHex = finalHex.toUpperCase()
        return finalHex
    }
    
    function ssccSerialized(prefixo,serialImp) {
        let epcHeader = '00110001'
        let filter = Number(6).toString(2)
        let partition = Number(5).toString(2)
        let reserved = '000000000000000000000000'
        let serialFormated = ('0').repeat(7).substring(0, 7 - serialImp.toString().length) + serialImp.toString()
        let value = prefixo + serialFormated//.replace('(', '')
        //value = value.replace(')', '')
        //value = value.slice(2)
        let clearPrefix = '000000000000000000000000'
        let serial = value.slice(0,1)
        let prefix = Number(value.slice(1,8)).toString(2)
        prefix = clearPrefix.substring(0, clearPrefix.length - prefix.length) + prefix
        let clearSerial = '0000000000000000000000000000000000'
        serial += value.replace(value.slice(1,8), '')
        //serial = serial.slice(0, serial.length - 1)
        serial = Number(serial).toString(2) //erro?
        serial = clearSerial.substring(0, clearSerial.length - serial.length) + serial
        let finalBin = `${epcHeader}${filter}${partition}${prefix}${serial}${reserved}`
        let finalHex = ''
        for(let i=0;i<finalBin.length; i+=4){
            finalHex += parseInt(finalBin.slice(i, i+4), 2).toString(16)
        }
        finalHex = finalHex.toUpperCase()
        return finalHex
    }
    async function verifyCodeBar(sscc) {
        if(popUpsList.length){
            let printerLista = printOrderList
            let ppsList = popUpsList
            let epcbysscc = ssccToEpc(sscc)
            let orderIndex = await printerLista.findIndex((order) => (order.epcs.includes(ppsList[0].epc)))
            let obss = ''
            if(sscc !='00000000000000000000' && ppsList[0].epc == epcbysscc) {
                obss = `Conferência de leitura: Epc ${ppsList[0].epc} e Sscc ${epcToSscc(ppsList[0].epc)} conferência validada`
                Swal.fire({
                    title: "Sucesso !",
                    text: 'Conferência validada',
                    icon: 'success',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }else{
                obss = `Conferência de leitura: Epc ${ppsList[0].epc} e Sscc ${epcToSscc(ppsList[0].epc)} leitura não confere`
                Swal.fire({
                    title: "Ops... !",
                    text: 'Conferência invalidada',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }
            ppsList = ppsList.slice(1)

            let reg = {
                type: 'obs',
                info: {
                    search: {
                        _id:printerLista[orderIndex]._id,
                        id_conta: sessionStorage.getItem('idConta')
                    },
                    update: {
                        $push: {
                            observacao: {
                                obs: obss}
                        }
                    }
                }
            }

            socket.sendJsonMessage(reg)
            await setPopUpsList(ppsList)
            await forceToUpdate()
        }
        setValidInput('')
    }

    function showPopUps() {
        if(popUpsList.length){
            let pps = popUpsList[0]
            return (
                <div className='extra'>
                    <div className='extracontainer' style={{width: '50vw', minHeight: '100px', margin: 'auto', marginTop: '40vh'}}>
                        <div className="title">
                            <h1>{pps.title}</h1>
                        </div>
                        <div style={{textAlign: 'center'}}>{pps.text}</div>
                        <div style={{textAlign: 'center'}}>ID: {pps.identificador}</div>
                        <div style={{textAlign: 'center'}}>EPC: {pps.epc}</div>
                        <div style={{textAlign: 'center'}}>SSCC:{epcToSscc(pps.epc)}</div>
                        <div>
                            <div style={{padding: '10px', background:'#E3AE1B', width: '160px', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold', margin: '10px auto'}}>
                                <div style={{margin: 'auto'}}>Aguardando leitura</div>
                            </div>
                        </div>
                        <form onSubmit={e=>{verifyCodeBar(epcToSscc(pps.epc))}}>
                            <input
                                autoFocus
                                value={validInput}
                                onChange={e=>{setValidInput(e.target.value)}}
                            />
                        </form>
                    </div>
                </div>
            )
        }else{
            return ''
        }
    }

    async function refazerLeitura(key, epc, contador=1) {
        let printerLista = printOrderList

        let reg = {
            type: 'refazer',
            info: {
                search: {
                    _id: printerLista[key]._id,
                    id_conta: sessionStorage.getItem('idConta'),
                    epcs: epc
                },
                update: {
                    contador: contador,
                    $push: {
                        observacao: {
                            obs: `Reimpressão: Gerando reimpressão de ${contador} etiquetas apartir do EPC: ${epc}`
                        }
                    }
                }
            }
        }
        
        socket.sendJsonMessage(reg)
        Swal.fire({
            title: "Sucesso !",
            text: 'item enviado a lista de reimpressão',
            icon: 'success',
            showConfirmButton: false,
            timerProgressBar: true,
            timer: '2500'
        })
    }

    async function conferirManual(epc) {
        let ppsList = popUpsList
        ppsList.push({
            epc,
            identificador: printOrderList[selectedOrder].identificador,
            _id: printOrderList[selectedOrder]._id,
            type: 'conf',
            title: 'Verificar leitura',
            text: `Faça a leitura do código de barra ${epcToSscc(epc)} etiqueta impressa`
        })
        await setPopUpsList(ppsList)
        await forceToUpdate()
    }

    async function trocarRibbon(key) {    
        Swal.fire({
            title: 'Observação',
            text: "Razão para realizar troca de ribbon",
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            preConfirm: (reason) => {
                return reason
            },
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Confirmar Troca",
            cancelButtonText: "Cancelar"
        }).then(
            result => {
                if(result.isConfirmed) {
                    let printerLista = printOrderList
                    
                    printerLista[key].qtd_ribbon = (parseInt(printerLista[key].qtd_ribbon) + 1).toString()
                    
                    let reg = {
                        type: 'obs',
                        info: {
                            search: {
                                _id: printerLista[key]._id,
                                id_conta: sessionStorage.getItem('idConta')
                            },
                            update: {
                                qtd_ribbon: printerLista[key].qtd_ribbon,
                                read_by_ribbon: 0,
                                $push: {
                                    observacao: {
                                        obs: `${printerLista[key].read_by_ribbon} etiquetas foram impressas no ultimo ribbon, ${printerLista[key].qtd_ribbon}º Troca de Ribbon: ${result.value.trim()}`
                                    }
                                }
                            }
                        }
                    }

                    socket.sendJsonMessage(reg)
                    Swal.fire({
                        title: "Sucesso !",
                        text: 'Não se esqueça de limpar o cabeçote de impressão',
                        icon: 'success',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            }
        )
    }

    /*async function initnotask(key, reason) {
        let printerLista = printOrderList

        let options = {
            headers: { 'Content-Type': ['application/json'], "Access-Control-Allow-Origin": "*" }
        }

        
        printerLista[key].dt_inicio_ordem = new Date()
        if(!printerLista[key].observacao){
            printerLista[key]['observacao'] = []
        }
        printerLista[key].observacao.push({
            obs: 'Ordem iniciada: ' + reason.trim(),
            dt_obs: new Date()
        })
        
        let reg = [{
            _id: printerLista[key]._id,
            dt_inicio_ordem: printerLista[key].dt_inicio_ordem,
            observacao: printerLista[key].observacao
        }]

        api.post(`/ordem_impressao`, reg, options).then(
            response => {
                Swal.fire({
                    title: "Sucesso !",
                    text: 'Ordem iniciada',
                    icon: 'success',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            },
            response => {
                Swal.fire({
                    title: "Ops... !",
                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }
        ).finally(
            async response => {
                await setPrintOrderList(printerLista)
                await forceToUpdate()
            }
        )
    }*/

    function returnUserName(iduser) {
        let index = userList.findIndex((val) => (val._id == iduser))
        if(index == -1){
            return ''
        }else{
            return userList[index].nome
        }
    }

    function reimprimirApartir(key) {
        Swal.fire({
            title: 'Reimprimir a partir de',
            text: "Informe o primeiro Sscc de onde partira a reimpressão",
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            preConfirm: (reason) => {
                return reason
            },
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Confirmar reimpressão",
            cancelButtonText: "Cancelar"
        }).then(
            result => {
                if(result.isConfirmed) {
                    let printerLista = printOrderList

                    let epcVerify = ssccToEpc(result.value)
                    let indexLast = printerLista[key].epcs.findIndex((val) => (val == epcVerify))

                    if(indexLast != -1) {
                        
                        let reg = {
                            type: 'refazer',
                            info: {
                                search: {
                                    _id: printerLista[key]._id,
                                    id_conta: sessionStorage.getItem('idConta'),
                                    epcs: epcVerify
                                },
                                update: {
                                    contador: 'apartir',
                                    $push: {
                                        observacao: {
                                            obs: 'Reimpressão: ' + 'Gerando reimpressão de etiquetas apartir do EPC: ' + ssccToEpc(result.value)
                                        }
                                    }
                                }
                            }
                        }

                        socket.sendJsonMessage(reg)
                        Swal.fire({
                            title: "Sucesso !",
                            text: 'Não se esqueça de limpar o cabeçote de impressão',
                            icon: 'success',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }else{
                        Swal.fire({
                            title: "Ops... !",
                            text: 'código Sscc não confere com esta ordem',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }
                }
            }
        )
    }

    function addObs(key) {
        Swal.fire({
            title: 'Observação',
            text: "Adicione uma informação sobre a impressão",
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            preConfirm: (reason) => {
                return reason
            },
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        }).then(
            result => {
                if(result.isConfirmed) {
                    let printerLista = printOrderList
                    
                    let reg = {
                        type: 'obs',
                        info: {
                            search: {
                                _id: printerLista[key]._id,
                                id_conta: sessionStorage.getItem('idConta')
                            },
                            update: {
                                $push: {
                                    observacao: {
                                        obs: result.value.trim(),
                                    }
                                }
                            }
                        }
                    }

                    socket.sendJsonMessage(reg)
                    Swal.fire({
                        title: "Sucesso !",
                        text: 'Observação adicionada',
                        icon: 'success',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            }
        )
    }

    function dateFormat(dt = new Date()) {
        return (dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2))
    }

    function diffTime(inicio, fim, qtd) {
        if(inicio && fim) {
            let date1 = new Date(inicio)
            let date2 = new Date(fim)
            let diffTime = Math.abs(date2 - date1)
            let tags = parseInt(Math.floor(qtd/(diffTime/(1000 * 60))))
            let colorBack = '#FFFFFF'
            if(tags < 40) {
                colorBack = '#DB0000'
            }else if(tags > 60) {
                colorBack = '#4EA90B'
            }else {
                colorBack = '#E3AE1B'
            }
            let remain = diffTime
            let diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
            remain -= diffDays * (1000 * 60 * 60 * 24)
            let diffHoursRemain = Math.floor(remain/ (1000 * 60 * 60))
            remain -= diffHoursRemain * (1000 * 60 * 60)
            let diffMinRemain = Math.floor(remain/(1000 * 60))
            remain -= diffMinRemain * (1000 * 60)
            let diffSegRemain = Math.floor(remain/(1000))
            return (
                <div style={{background: colorBack, width: '100%', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold', margin: '0', padding: '0'}}>
                    <div style={{margin: 'auto'}}>{`${diffDays}dias ${diffHoursRemain}h ${diffMinRemain}min ${diffSegRemain}seg`}</div>
                </div>
            )
        }else{
            return 'Sem informação'
        }
    }

    function validarSsccEmulador(e) {
        e.preventDefault()
        let printerLista = printOrderList
        let epcVerify = ssccToEpc(codeBarVerify)
        if(printerLista[selectedOrder].epcs.indexOf(epcVerify) >= 0) {
            if(printerLista[selectedOrder].epcs_impresso.indexOf(epcVerify) == -1) {
                let reg = {
                    type: 'validar',
                    info: {
                        search: {
                            _id: printerLista[selectedOrder]._id,
                            id_conta: sessionStorage.getItem('idConta'),
                            epcs: epcVerify,
                            epcs_impresso: {$nin: [epcVerify]}
                        }
                    }
                }

                socket.sendJsonMessage(reg)
                Swal.fire({
                    title: "Sucesso !",
                    text: 'Validado e Encontrado',
                    icon: 'success',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }else{
                Swal.fire({
                    title: "Sucesso !",
                    text: 'Item já Validado e Encontrado',
                    icon: 'success',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }
        }else{
            Swal.fire({
                title: "Ops... !",
                text: 'Este sscc não pertence a esta ordem',
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })
        }
        setCodeBarVerify('')
    }

    function colorStatus2(status) {
        switch (status) {
            case '':
                return '#E3AE1B'
            case 'AGUARDANDO IMPRESSAO':
                return '#E3AE1B'
            case 'IMPRESSAO ENVIADA':
                return '#ED7D31'
            case 'SENDO IMPRESSA':
                return '#69ABE5'
            case 'FINALIZADA CONFERIDA RFID':
                return '#4EA90B'
            case 'FINALIZADA CONFERIDA MANUAL':
                return '#809373'
            case 'FINALIZADA SEM CONFERENCIA':
                return '#DB0000'
            case 'ORDEM CANCELADA':
                return '#DB0000'
            default:
                return '#000000'
        }
    }

    function colorQtd(qtd, estimado) {
        let colorBack = '#FFFFFF'
        if(qtd == 0 && estimado != 0) {
            colorBack = '#DB0000'
        }else if(qtd == estimado){
            colorBack = '#4EA90B'
        }else if(qtd > 0 && qtd < estimado) {
            colorBack = '#E3AE1B'
        }
        return (
            <div style={{background: colorBack, width: '100%', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold', margin: '0', padding: '0'}}>
                <div style={{margin: 'auto'}}>{qtd}</div>
            </div>
        )
    }

    function confSSCCAndEPC() {
        Swal.fire({
            title: 'Atenção!',
            text: "Deseja conferir ultima tag desta ordem de impressão?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        }).then(
            result => {
                if(result.isConfirmed) {
                    Swal.fire({
                        title: `Ultima leitura: ${epcToSscc(printOrderList[selectedOrder].epcs[printOrderList[selectedOrder].epcs.length - 1])}`,
                        text: "Faça a leitura do código de barras SSCC",
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        preConfirm: (reason) => {
                            return reason.toUpperCase()
                        },
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar"
                    }).then(
                        resultSSCC => {
                            if(resultSSCC.isConfirmed) {
                                if(resultSSCC.value == epcToSscc(printOrderList[selectedOrder].epcs[printOrderList[selectedOrder].epcs.length - 1]).replace('(','').replace(')','')) {
                                    Swal.fire({
                                        title: `Ultima leitura: ${epcToSscc(printOrderList[selectedOrder].epcs[printOrderList[selectedOrder].epcs.length - 1])}`,
                                        text: "Faça a leitura do código RFID",
                                        input: 'text',
                                        inputAttributes: {
                                            autocapitalize: 'off'
                                        },
                                        preConfirm: (reason) => {
                                            return reason.toUpperCase()
                                        },
                                        showCancelButton: true,
                                        confirmButtonColor: "#DD6B55",
                                        confirmButtonText: "Confirmar",
                                        cancelButtonText: "Cancelar"
                                    }).then(
                                        resultEPC => {
                                            if(resultEPC.isConfirmed) {
                                                if(resultEPC.value.toUpperCase() == printOrderList[selectedOrder].epcs[printOrderList[selectedOrder].epcs.length - 1]) {
                                                    if(printOrderList[selectedOrder].epcs_impresso.indexOf(resultEPC.value.toUpperCase()) == -1) {
                                                        let reg = {
                                                            type: 'validar',
                                                            info: {
                                                                search: {
                                                                    _id: printOrderList[selectedOrder]._id,
                                                                    id_conta: sessionStorage.getItem('idConta'),
                                                                    epcs: resultEPC.value.toUpperCase(),
                                                                    epcs_impresso: {$nin: [resultEPC.value.toUpperCase()]}
                                                                }
                                                            }
                                                        }
                                        
                                                        socket.sendJsonMessage(reg)
                                                    }
                    
                                                    let reg = {
                                                        type: 'obs',
                                                        info: {
                                                            search: {
                                                                _id: printOrderList[selectedOrder]._id,
                                                                id_conta: sessionStorage.getItem('idConta')
                                                            },
                                                            update: {
                                                                $push: {
                                                                    observacao: {
                                                                        obs: `Conferência de dois fatores para ultima tag da ordem SSCC: ${epcToSscc(printOrderList[selectedOrder].epcs[printOrderList[selectedOrder].epcs.length - 1])} Realizado com sucesso`,
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    socket.sendJsonMessage(reg)
                                                    Swal.fire({
                                                        title: "Sucesso !",
                                                        text: 'Conferência adicionada na lista de observação',
                                                        icon: 'success',
                                                        showConfirmButton: false,
                                                        timerProgressBar: true,
                                                        timer: '2500'
                                                    })
                                                }else {
                                                    Swal.fire({
                                                        title: "Ops... !",
                                                        text: 'Esta leitura não confere com EPC especificado',
                                                        icon: 'error',
                                                        showConfirmButton: false,
                                                        timerProgressBar: true,
                                                        timer: '2500'
                                                    })
                                                }
                                            }
                                        }
                                    )
                                }else {
                                    Swal.fire({
                                        title: "Ops... !",
                                        text: 'Esta leitura não confere com SSCC especificado',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '2500'
                                    })
                                }
                            }
                        }
                    )
                }
            }
        )
    }

    return (
        <div className="mainContainer"
        style={JSON.parse(sessionStorage.getItem('collors'))}
        >
            <Menu/>
            <div id="background" className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                <Container fluid>
                    <Row>
                        <Col>
                            <h3>Ordem de impressão</h3>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            {[2,3,4,5].includes(division) ? <div>
                                <Button variant="light" onClick={e => {clearList()}}>{t('actions.exit')} </Button>
                            </div>:<div></div>}
                        </Col>
                    </Row>
                    <Col className="bg-white border rounded p-3">
                        {popUpsList.length? showPopUps() : ''}
                        {[1].includes(division) ? 
                            <Row className="bg-primary-light rounded mx-1 p-3">
                                <Form.Group as={Col} lg={4}>
                                    <Form.Label className="fw-bold">Usuário</Form.Label>
                                    <Form.Select
                                        value={userFilter}
                                        onChange={e=>{setUserFilter(e.target.value)}}
                                    >
                                        <option value={'*'}>Todos</option>
                                        {userList.map((user,index) => (
                                            <option key={index} value={user._id}>{user.nome}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} lg={4}>
                                    <Form.Label className="fw-bold">Data</Form.Label>
                                    <div className="date">
                                        <input
                                            type="date"
                                            value={dataInicio}
                                            onChange={e => {setDataInicio(e.target.value)}}
                                        />
                                        <input
                                            type="date"
                                            value={dataFim}
                                            onChange={e => {setDataFim(e.target.value)}}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col} lg={4}>
                                    <Form.Label className="fw-bold">Status de leitura</Form.Label>
                                    <Form.Select
                                        value={concludeFilter}
                                        onChange={e=> {setConcludeFilter(e.target.value)}}
                                    >
                                        <option value={'*'}>Todas</option>
                                        <option value={'AGUARDANDO IMPRESSAO'}>{'AGUARDANDO IMPRESSAO'}</option>
                                        <option value={'IMPRESSAO ENVIADA'}>{'IMPRESSAO ENVIADA'}</option>
                                        <option value={'SENDO IMPRESSA'}>{'SENDO IMPRESSA'}</option>
                                        <option value={'FINALIZADA CONFERIDA RFID'}>{'FINALIZADA CONFERIDA RFID'}</option>
                                        <option value={'FINALIZADA CONFERIDA MANUAL'}>{'FINALIZADA CONFERIDA MANUAL'}</option>
                                        <option value={'FINALIZADA SEM CONFERENCIA'}>{'FINALIZADA SEM CONFERENCIA'}</option>
                                        <option value={'ORDEM CANCELADA'}>{'ORDEM CANCELADA'}</option>
                                    </Form.Select>
                                </Form.Group>
                                <Col lg={2} className='ms-auto my-2'>
                                    <Button className='w-100 text-uppercase' onClick={e=> {
                                        setPageOrder(0)
                                        getPrintOrderList()
                                    }}>Pesquisar</Button>
                                </Col>
                            </Row>
                        : ''}
                    {division == 1 ?
                    <div>
                        {loading ? 
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '90px', minHeight: 'auto', height: '200px'}}>
                                <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                            </div>
                        : 
                        <div>            
                            <Row className="align-items-center justify-content-between my-3">
                                {printOrderList[printOrderList.length - 1] && printOrderList[printOrderList.length - 1].total_ordens > 30 ? 
                                    <Col className="pagesContent" style={{marginTop: '10px'}}>
                                        <div className="numberPages" onClick={e=> {pageOrder > 0 ? setPageOrder(pageOrder - 1) : setPageOrder(pageOrder)}}>Anterior</div>
                                        {orderNumberListGet().map((number, index) => (
                                            <div className="numberPages" key={index} onClick={e => {setPageOrder(number - 1)}}>{number}</div>
                                        ))}
                                        <div className="numberPages" onClick={e=> {pageOrder < Math.ceil(printOrderList[printOrderList.length - 1].total_ordens / 30) - 1 ? setPageOrder(pageOrder + 1) : setPageOrder(pageOrder)}}>Próximo</div>
                                        <div className="numberPages">Ordens de impressão: {printOrderList[printOrderList.length - 1].total_ordens}</div>
                                    </Col>
                                :
                                    <Col className="pagesContent" style={{marginTop: '10px'}}>
                                        <div className="numberPages">Ordens de impressão: {printOrderList[printOrderList.length - 1] ? printOrderList[printOrderList.length - 1].total_ordens : 0}</div>
                                    </Col>
                                }
                                <Col className='container-btn'>
                                    {true?
                                        <div>
                                            <Form onSubmit={e=>{submitTeste(e)}}>
                                                <InputGroup>
                                                    <InputGroup.Text className="bg-white border-end-0"><FiSearch/></InputGroup.Text>
                                                    <Form.Control
                                                        className="border-start-0"
                                                        placeholder="Pesquisar epc ou sscc"
                                                        value={searchByOrder}
                                                        onChange={e=>{setSearchByOrder(e.target.value)}}
                                                    />
                                                </InputGroup>
                                            </Form>
                                        </div>
                                    : ''}
                                        <div className='container-btn'>
                                            <Button variant="light" className="me-2" onClick={e => {setDivision(3)}}><FaFilePdf className="me-2"/>{t('actions.generate')} PDF</Button>
                                            <Button variant="light" onClick={e => {setDivision(4)}}><FaFileCsv className="me-2"/>{t('actions.generate')} CSV</Button>
                                        </div>
                                        {false?<div className="export">
                                            <CSVLink className="csv" data={csvRefazer()} separator={';'}>
                                                <Button>Etiquetas a reimprimir</Button>
                                            </CSVLink>
                                        </div>:''}
                                </Col>
                            </Row>

                            {printOrderList.length ?
                                <div>
                                    {printOrderList.slice(0, printOrderList.length - 1).map((printOrder, index) => {
                                        //let filtered = searchByOrder.replace('(', '').replace(')', '')
                                        if(
                                            true
                                            //searchByOrder == '' ||
                                            //printOrder.epcs.findIndex((val) => (val.search(filtered) >= 0)) >= 0 ||
                                            //printOrder.epcs.findIndex((val) => (epcToSscc(val).replace('(', '').replace(')', '').search(filtered) >= 0)) >= 0
                                        ) {
                                            return (
                                                <div className='printOrderListItem' key={index}>
                                                    <div style={{display: 'flex', flexDirection:'column', margin: 'auto', width:'100%'}}>
                                                        <div className='printOrderItem'>
                                                            <div className='printOrderInfo'>
                                                                {false?<div style={{background: printOrder.concluido ? '#4EA90B': '#E3AE1B', width: '100%', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold', margin: '0', padding: '0'}}>
                                                                    {printOrder.concluido ? <div style={{margin: 'auto'}}>Leitura concluida</div>: <div style={{margin: 'auto'}}>Leitura em andamento</div>}
                                                                </div>:''}
                                                            </div>
                                                            <div className='printOrderInfo'>
                                                                <div style={{background: colorStatus2(printOrder.status_secundario), width: '100%', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold', margin: '0', padding: '0'}}>
                                                                    <div style={{margin: 'auto'}}>{printOrder.status_secundario}</div>
                                                                </div>
                                                            </div>
                                                            <div className='printOrderInfo' style={{cursor: 'pointer', height: '25px', display: 'flex', flexDirection: 'row', justifyContent:'right'}}>
                                                                {editIndex == -1? <div onClick={e=>{selectEdit(index)}}><AiFillEdit className="dIcons" style={{fontSize: '25px'}}/></div> : ''}
                                                                {editIndex == -1? <div onClick={e=>{deleteOrder(printOrder)}}><BiTrash className="dIcons" style={{fontSize: '25px'}}/></div> : ''}
                                                                {editIndex != -1? <div onClick={e=>{saveEdit(index)}}><AiOutlineCheckCircle className="dIcons" style={{fontSize: '25px'}}/></div> : ''}
                                                                {editIndex != -1? <div onClick={e=>{cancelEdit()}}><AiOutlineCloseCircle className="dIcons" style={{fontSize: '25px'}}/></div> : ''}
                                                            </div>
                                                        </div>
                                                        <div className='printOrderItem'>
                                                            <div className='printOrderInfo' id='identificador'>ID: {printOrder.identificador}</div>
                                                            <div className='printOrderInfo'>Data Registro: {formatDate(printOrder.dt_reg_ordem)}</div>
                                                            {(index) == editIndex ?
                                                                <div className='printOrderInfo'>Nº do Lote Fornecedor: <input value={editNLote} onChange={e=>{setEditNLote(e.target.value)}}/></div>
                                                                :
                                                                <div className='printOrderInfo'>Nº do Lote Fornecedor: {printOrder.num_lote}</div>
                                                            }
                                                        </div>
                                                        <div className='printOrderItem'>
                                                            <div className='printOrderInfo'>Quantidade impressa: {colorQtd(printOrder.epcs_impresso.length,printOrder.epcs.length)}</div>
                                                            <div className='printOrderInfo'>Data Inicio: {printOrder.dt_inicio_ordem ? formatDate(printOrder.dt_inicio_ordem) : 'Não iniciado'}</div>
                                                            {(index) == editIndex ?
                                                                <div className='printOrderInfo'>Qtd. Void Estimado: <input value={editVoidEstimado} onChange={e=>{setEditVoidEstimado(e.target.value)}}/></div>
                                                                :
                                                                <div className='printOrderInfo'>Qtd. Void Estimado: {printOrder.qtd_void_esperada}</div>
                                                            }
                                                        </div>
                                                        <div className='printOrderItem'>
                                                            {!printOrder.concluido && (index) == editIndex ?
                                                                <div className='printOrderInfo'>Nº do Lote Interno:  <input value={editNLoteInterno} onChange={e=>{setEditNLoteInterno(e.target.value)}}/></div>
                                                                :
                                                                <div className='printOrderInfo'>Nº do Lote Interno: {printOrder.lote_interno}</div>
                                                            }
                                                            <div className='printOrderInfo'>Data Término: {printOrder.dt_termino_ordem ? formatDate(printOrder.dt_termino_ordem) : 'Não finalizado'}</div>
                                                            {(index) == editIndex ?
                                                                <div className='printOrderInfo'>Qtd. Void Real: <input value={editVoidReal} onChange={e=>{setEditVoidReal(e.target.value)}}/></div>
                                                                :
                                                                <div className='printOrderInfo'>Qtd. Void Real: {printOrder.qtd_void_real}</div>
                                                            }
                                                        </div>
                                                        <div className='printOrderItem'>
                                                            {!printOrder.concluido && (index) == editIndex ?
                                                                <div className='printOrderInfo'>Nº do Lote Cliente: <input value={editNLoteCliente} onChange={e=>{setEditNLoteCliente(e.target.value)}}/></div>
                                                                :
                                                                <div className='printOrderInfo'>Nº do Lote Cliente: {printOrder.lote_correios}</div>
                                                            }
                                                            <div className='printOrderInfo'>Duração: {diffTime(printOrder.dt_inicio_ordem, printOrder.dt_termino_ordem, printOrder.epcs_impresso.length)}</div>
                                                            <div className='printOrderInfo'>Perdas Lidas: {printOrder.qtd_perdas_lidas}</div>
                                                        </div>
                                                        <div className='printOrderItem'>
                                                            <div className='printOrderInfo'>Responsável: {returnUserName(printOrder.id_usuario)}</div>
                                                            {(index) == editIndex ?
                                                                <div className='printOrderInfo'>Qtd. Tags por Lote: <input value={editQtdLote} onChange={e=>{setEditQtdLote(e.target.value)}}/></div>
                                                                :
                                                                <div className='printOrderInfo'>Qtd. Tags por Lote: {printOrder.qtd_por_lote}</div>
                                                            }
                                                            {(index) == editIndex ?
                                                                <div className='printOrderInfo'>Perdas Contabilizadas: <input value={editPerdasContabilizadas} onChange={e=>{setEditPerdasContabilizadas(e.target.value)}}/></div>
                                                                :
                                                                <div className='printOrderInfo'>Perdas Contabilizadas: {printOrder.qtd_perdas_contabilizadas}</div>
                                                            }
                                                        </div>
                                                        <div className='printOrderItem'>
                                                            <div className='printOrderInfo'>Impressora: {printOrder.endereco_impressora_def}</div>
                                                            <div className='printOrderInfo'>Quantidade esperada: {printOrder.epcs.length}</div>
                                                            <div className='printOrderInfo'>Qtd. Trocas de Ribbon: {printOrder.qtd_ribbon}</div>
                                                        </div>
                                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                                            {!printOrder.concluido ? <button style={{margin: '10px 0', width: '100%'}} onClick={e => {askDownloadArchives((index))}}>IMPRIMIR</button>:''}
                                                            {!printOrder.concluido ? <button style={{margin: '10px 0', width: '100%'}} onClick={e => {askTheReason((index))}}>FINALIZAR</button>:''}
                                                            {!printOrder.concluido ? <button style={{margin: '10px 0', width: '100%'}} onClick={e => {listarEPCs((index))}}>RFIDs/CONFERIR</button>:''}
                                                            <button style={{margin: '10px 0', width: '100%'}} onClick={e => {listarObeservacoes((index))}}>OBSERVAÇÕES: {printOrder.observacao.length}</button>
                                                            {!printOrder.concluido ? <button style={{margin: '10px 0', width: '100%'}} onClick={e => {reimprimirApartir((index))}}>REIMPRESSÃO</button>:''}
                                                            {!printOrder.concluido ? <button style={{margin: '10px 0', width: '100%'}} onClick={e => {window.document.getElementById('csv-click').click()}}><FaFileCsv/> CSV</button>:''}
                                                            <CSVLink id='csv-click' style={{display:'none'}} data={csvRefazerKey(index)} separator={';'}></CSVLink>
                                                            {!printOrder.concluido ? <button style={{margin: '10px 0', width: '100%'}} onClick={e => {trocarRibbon((index))}}>TROCAR RIBBON</button>:''}
                                                            {false?<button style={{margin: '10px 0', width: '100%'}} onClick={e => {deleteOrder(printOrder)}}>EXCLUIR</button>:''}
                                                            {!printOrder.concluido ? <button style={{margin: '10px 0', width: '100%'}} onClick={e => {cancelOrder(printOrder)}}>CANCELAR</button>:''}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }).filter((printOrder) => {
                                        if(printOrder){
                                            return printOrder
                                        }
                                    })}
                                </div>
                            :
                                <div className='noOrder'>
                                    <h1>NÃO HÁ ORDENS DE IMPRESSÃO</h1>
                                </div>
                            }
                        </div>
                        }
                    </div>
                    :''}
                    {division == 2 ?
                        <div>
                            <div className='filters'>
                                <div className='filter'>
                                    <Form.Label>Pesquisar epc ou sscc</Form.Label>
                                    <input
                                        value={search}
                                        onChange={e=>{setSearch(e.target.value)}}
                                    />
                                </div>
                            </div>
                            <form onSubmit={e=>{validarSsccEmulador(e)}} autocomplete="off">
                                <div className='filters' onSubmit={e=>{validarSsccEmulador(e)}}>
                                    <div className='filter'>
                                        <Form.Label>Validar por código de barras</Form.Label>
                                        <input
                                            id='validarSscc'
                                            autoFocus
                                            value={codeBarVerify}
                                            onChange={e=>{setCodeBarVerify(e.target.value)}}
                                        />
                                    </div>
                                </div>
                            </form>
                            <div className='export'>
                                <button onClick={e=>{confSSCCAndEPC()}}>Conferência de dois fatores para ultima tag</button>
                            </div>
                            {printOrderList[selectedOrder].epcs.filter((val) => {
                                            let filtered = search.replace('(', '').replace(')', '')
                                            if(
                                                search == '' ||
                                                val.search(filtered) >= 0 ||
                                                epcToSscc(val).replace('(', '').replace(')', '').search(filtered) >= 0
                                            ) {
                                                return val
                                            }
                                        }).length > 1000 ? 
                            <div className="pagesContent" style={{marginTop: '10px'}}>
                                <div className="numberPages" onClick={e=> {pageEpcs > 0 ? setPageEpcs(pageEpcs - 1) : setPageEpcs(pageEpcs)}}>Anterior</div>
                                {epcsNumberListGet().map((number, index) => (
                                    <div className="numberPages" key={index} onClick={e => {setPageEpcs(number - 1)}}>{number}</div>
                                ))}
                                <div className="numberPages" onClick={e=> {pageEpcs < Math.ceil(printOrderList[selectedOrder].epcs.length / 1000) - 1 ? setPageEpcs(pageEpcs + 1) : setPageEpcs(pageEpcs)}}>Próximo</div>
                                <div className="numberPages">EPCs: {printOrderList[selectedOrder].epcs.filter((val) => {
                                            let filtered = search.replace('(', '').replace(')', '')
                                            if(
                                                search == '' ||
                                                val.search(filtered) >= 0 ||
                                                epcToSscc(val).replace('(', '').replace(')', '').search(filtered) >= 0
                                            ) {
                                                return val
                                            }
                                        }).length}</div>
                            </div>
                        :
                            <div className="pagesContent" style={{marginTop: '10px'}}>
                                <div className="numberPages">EPCs: {printOrderList[selectedOrder].epcs.filter((val) => {
                                            let filtered = search.replace('(', '').replace(')', '')
                                            if(
                                                search == '' ||
                                                val.search(filtered) >= 0 ||
                                                epcToSscc(val).replace('(', '').replace(')', '').search(filtered) >= 0
                                            ) {
                                                return val
                                            }
                                        }).length}</div>
                            </div>
                        }
                        {printOrderList.length && printOrderList[selectedOrder].epcs.length?
                            <div className='list'>
                                <table>
                                    <thead>
                                        <tr id="header">
                                            <th style={{width: '30px'}}>#</th>
                                            <th>EPCs</th>
                                            <th>SSCC</th>
                                            <th style={{width: '200px'}}>STATUS</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {printOrderList[selectedOrder].epcs.slice(0).reverse().map((printOrder, index) => {
                                                let filtered = search.replace('(', '').replace(')', '')
                                                if(
                                                    search == '' ||
                                                    printOrder.search(filtered) >= 0 ||
                                                    epcToSscc(printOrder).replace('(', '').replace(')', '').search(filtered) >= 0
                                                ) {
                                                    return (
                                                        <tr key={printOrderList[selectedOrder].epcs.length - index}>
                                                            <th style={{width: '30px'}}>{printOrderList[selectedOrder].epcs.length - (index)}</th>
                                                            <th>{printOrder}</th>
                                                            <th>{epcToSscc(printOrder)}</th>
                                                            <th style={{width: '200px'}}>
                                                                <div style={{padding: '10px', background: printOrderList[selectedOrder].epcs_impresso.indexOf(printOrder) >= 0 ? '#4EA90B': '#E3AE1B', width: '160px', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}>
                                                                    {printOrderList[selectedOrder].epcs_impresso.indexOf(printOrder) >= 0 ? <div style={{margin: 'auto'}}>Encontrado</div>: <div style={{margin: 'auto'}}>Aguardando leitura</div>}
                                                                </div>
                                                            </th>
                                                            <th style={{display:'flex', flexDirection: 'column'}}>
                                                                <button style={{width: '100px'}} onClick={e=>{refazerLeitura(selectedOrder, printOrder)}}>Reimprimir</button>
                                                                <button style={{width: '100px'}} onClick={e=>{conferirManual(printOrder)}}>Conferir</button>
                                                            </th>
                                                        </tr>
                                                    )
                                                }
                                        }).filter((item) => {
                                            if(item){
                                                return item
                                            }
                                        }).filter((item, index) => {
                                            if((index >= (pageEpcs * 1000) && index < ((pageEpcs * 1000) + 1000))){
                                                return item
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        :''}
                    </div>
                    :''}
                    {division == 3 ? <ExportPDF headers={headersCSV} listItens={csv()} modulo={'ordem_impressao_orderList'}/>:''}
                    {division == 4 ? <ExportCSV headers={headersCSV} listItens={csv()} modulo={'ordem_impressao_orderList'}/>:''}
                    {division == 5 ?
                        <div>
                            {printOrderList[selectedOrder].cancelado == '0'?<div className='export'>
                                <button onClick={e=>{addObs(selectedOrder)}}>Adicionar Observação</button>
                            </div>:''}
                            {printOrderList[selectedOrder].observacao.length > 1000 ? 
                            <div className="pagesContent" style={{marginTop: '10px'}}>
                                <div className="numberPages" onClick={e=> {pageObservacoes > 0 ? setPageObservacoes(pageObservacoes - 1) : setPageObservacoes(pageObservacoes)}}>Anterior</div>
                                {obsNumberListGet().map((number, index) => (
                                    <div className="numberPages" key={index} onClick={e => {setPageObservacoes(number - 1)}}>{number}</div>
                                ))}
                                <div className="numberPages" onClick={e=> {pageObservacoes < Math.ceil(printOrderList[selectedOrder].observacao.length / 1000) - 1 ? setPageObservacoes(pageObservacoes + 1) : setPageEpcs(pageEpcs)}}>Próximo</div>
                                <div className="numberPages">OBSERVAÇÕES: {printOrderList[selectedOrder].observacao.length}</div>
                            </div>
                        :
                            <div className="pagesContent" style={{marginTop: '10px'}}>
                                <div className="numberPages">OBSERVAÇÕES: {printOrderList[selectedOrder].observacao.length}</div>
                            </div>
                        }
                        {printOrderList.length && printOrderList[selectedOrder].observacao.length?
                            <div className='list'>
                                <table>
                                    <thead>
                                        <tr id="header">
                                            <th style={{width: '30px'}}>#</th>
                                            <th>OBSERVAÇÃO</th>
                                            <th style={{width: '200px'}}>DATA</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {printOrderList[selectedOrder].observacao.slice(pageObservacoes * 1000, (pageObservacoes * 1000) + 1000).map((printOrder, index) => (
                                            <tr key={index}>
                                                <th style={{width: '30px'}}>{index + 1}</th>
                                                <th>{printOrder.obs}</th>
                                                <th style={{width: '200px'}}>{formatDate(printOrder.dt_obs)}</th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        :''}
                    </div>
                    :''}
                    </Col>
                </Container>
            </div>
        </div>
    )
}

export default PrintOrder