import { useEffect, useState } from "react";

import { useParams } from 'react-router-dom'

import { t } from "i18next";

import { Backdrop, Box, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import Menu from "../../../components/Menu";

import WidgetStatistics from "./widget/WidgetStatistics";

import ItemsHalfPieChart from "./graphs/ItemsHalfPieChart";

import api from "../../../services/api";

import { Button } from "react-bootstrap";

import { FiArrowLeft } from "react-icons/fi";

import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------

export default function Dashboard(){
    const navigate = useNavigate()

    let {id_parceiro} = useParams()

    const [loading, setLoading] = useState(false)

    const [items, setItems] = useState([]) // Itens

    const [details, setDetails] = useState([]) // Informações dos items requisitados (total/total filtrado)

    const [partner, setPartner] = useState([])

    // Filters
    const [categoria, setCategoria] = useState('*')

    const [tag, setTag] = useState('*')

    const [status, setStatus] = useState('*')

    const [nivel1, setNivel1] = useState('*')

    const [nivel2, setNivel2] = useState('*')

    const [nivel3, setNivel3] = useState('*')

    const [nivel4, setNivel4] = useState('*')

    // Pagination
    const [page, setPage] = useState(0)

    const [limitPerPage, setLimitPerPage] = useState(10)

    useEffect(() => {
        itemsBO()
    }, [limitPerPage, page])

    async function itemsBO() {
        setLoading(true)
        await api.get(`/itensPage/${sessionStorage.getItem('idConta')}/*/${categoria}/${tag}/${status}/*/*/*/*/*/${nivel1}/${nivel2}/${nivel3}/${nivel4}/*/0?id_parceiro=${id_parceiro}`, {}
        ).then(response => {
            const result = response.data
            const itemsDetails = [...result].pop()
            setDetails({
                ...itemsDetails,
                pageCount: Math.ceil(itemsDetails.total_reg / itemsDetails.total_filter)
            })
            setItems(result.filter(objeto => !('total_reg' in objeto)));
            result.forEach((item)=>{
                if(item?.id_parceiro?.nome){
                    setPartner(item.id_parceiro)
                    return
                }
            })
        }).finally(
            () => {
                setLoading(false)
            }
        )
    }

    return (
        <div className="mainContainer" 

        style={JSON.parse(sessionStorage.getItem('collors'))}
        >
            <Menu/>
            <div id="background">
                <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>

                    

                    <Container sx={{my:3}}>

                        <Stack spacing={5}>

                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="subtitle2">Parceiro:</Typography>
                                    <Typography variant="h4">{partner?.nome}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button variant="light" className="ms-2" onClick={e=>{navigate.push('/partners')}}>
                                        <FiArrowLeft className="me-2"/>
                                        {t('actions.exit')}
                                    </Button>
                                </Grid>
                            </Grid>
                            
                            { items.length > 0 ? <WidgetStatistics items={items} partner={partner} details={details}/> : '' }


                            <Box sx={{py: 3, borderRadius: 5}} className="bg-white shadow-sm">
                                
                                <Grid container sx={{px: 3}}>
                                    <Typography variant="h5">{t('common.items')}</Typography>
                                </Grid>

                                <TableContainer sx={{mt: 3}}>
                                    <Table aria-label="simple table" size="small">
                                        <TableHead sx={{backgroundColor: 'grey.100'}}>
                                            <TableRow>
                                                <TableCell sx={{ color: 'grey.A700'}}>#</TableCell>
                                                <TableCell sx={{ color: 'grey.A700'}}>{t('common.items')}</TableCell>
                                                <TableCell sx={{ color: 'grey.A700'}}>{t('common.info_label')} [1-5]</TableCell>
                                                <TableCell sx={{ color: 'grey.A700', whiteSpace: 'nowrap'}}>{t('common.info_label')} [6-10]</TableCell>
                                                <TableCell sx={{ color: 'grey.A700'}}>{t('common.locate')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {items.map((tag, index) => (
                                                <TableRow
                                                    key={tag.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                                                >                                                    
                                                    <TableCell sx={{width: '8px'}}>{index + 1}</TableCell>
                                                    <TableCell component="th" scope="row" sx={{fontWeight: '500'}}>
                                                        {tag?.id_categoria?.descricao}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{tag?.inf_compl_1}</Typography>
                                                        <Typography>{tag?.inf_compl_2}</Typography>
                                                        <Typography>{tag?.inf_compl_3}</Typography>
                                                        <Typography>{tag?.inf_compl_4}</Typography>
                                                        <Typography>{tag?.inf_compl_5}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{tag?.inf_compl_6}</Typography>
                                                        <Typography>{tag?.inf_compl_7}</Typography>
                                                        <Typography>{tag?.inf_compl_8}</Typography>
                                                        <Typography>{tag?.inf_compl_9}</Typography>
                                                        <Typography>{tag?.inf_compl_10}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{tag?.id_nivel_loc_1?.descricao}</Typography>
                                                        <Typography>{tag?.id_nivel_loc_2?.descricao}</Typography>
                                                        <Typography>{tag?.id_nivel_loc_3?.descricao}</Typography>
                                                        <Typography>{tag?.id_nivel_loc_4?.descricao}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid container justifyContent='space-between' alignItems="center" spacing={3} sx={{px: 3, pt: 2}}>
                                    <Grid container item xs>
                                        <Typography>Total</Typography>
                                        <Typography sx={{fontWeight: 600, ml: 1}}>{details?.total_reg || 0}</Typography>
                                    </Grid>
                                    <Grid container item justifyContent='flex-end' alignItems="center" xs spacing={3}>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth size="small">
                                                <InputLabel>{t('common.limit_per_page')}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={limitPerPage}
                                                    label={t('common.limit_per_page')}
                                                    onChange={e=>{setLimitPerPage(e.target.value)}}
                                                    type="number"
                                                >
                                                    <MenuItem value={10}>10</MenuItem>
                                                    <MenuItem value={100}>100</MenuItem>
                                                    <MenuItem value={500}>500</MenuItem>
                                                    <MenuItem value={1000}>1000</MenuItem>
                                                    <MenuItem value={null}>Todos</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            {details?.pageCount > 1 ? 
                                                <Pagination 
                                                    count={details.pageCount} 
                                                    page={page} 
                                                    onChange={(event, value) => setPage(value)} 
                                                    showFirstButton showLastButton 
                                                    shape="rounded" 
                                                />
                                            : ''}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        
                    </Container>
                </div>
            </div>
        </div>
    )
}