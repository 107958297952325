import { useState, useEffect } from "react";

import { useParams } from "react-router-dom"; // Import useParams

import { Container } from "react-bootstrap";

import Menu from "../../components/Menu";

import ComumDashboard from "./ComumDashboard";

import CBA from "./Custom/CBA";

// ----------------------------------------------------------------

const _id = sessionStorage.getItem('idConta');

export default function Bi() {

    function renderBIScreen(id) {
        switch (id) {
            case "Mrz2ue7F2":
                return <CBA />; //CBA
            case "_Y12sHQ61":
                return <CBA />; //CBA
            case "R1re7C0C_":
                return <CBA />; //CBA
            default:
                return <ComumDashboard />;
        }
    }

    return (
        <>
            <div className="mainContainer" style={JSON.parse(sessionStorage.getItem('collors') || "{}")}>
                <Menu />
                <div id="background" className={sessionStorage.getItem('show-menu') === 'true' ? "contentContainer" : "m-5"}>
                    <Container className="p-3" fluid>
                        {renderBIScreen(_id)}
                    </Container>
                </div>
            </div> {/* Corrected the closing div */}
        </>
    );
}
