import React, {useState, useEffect} from 'react'

import Menu from '../../components/Menu'
import {FaSearch} from 'react-icons/fa'
import { Container, Row, Col, Table, Button, Dropdown, Form, Modal, CloseButton, InputGroup  } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, RadialBarChart, RadialBar, Tooltip, Legend  } from 'recharts';

import api from '../../services/api'

function Dashboard() {
    const [change, setChange] = useState(0)    
    const { t } = useTranslation();

    const [userList, setUserList] = useState([])

    useEffect(() => {
        getUserList()
    },[])
    
    async function getUserList(){
        await api.get('https://api.inovacode.app.br/usuario/*/*/*/*/*?key=icdeva%4023!')
          .then(response => {
            const userList = response.data;
            userList.sort((a, b) => new Date(b.dt_acesso) - new Date(a.dt_acesso));
            setUserList(userList);
            console.log(userList);
        });
    }  

    const data = [
        {
          "name": "Janeiro",
          "uv": 40,
          "pv": 2400,
          "amt": 240
        },
        {
          "name": "Fevereiro",
          "uv": 30,
          "pv": 1398,
          "amt": 221
        },
        {
          "name": "Março",
          "uv": 200,
          "pv": 9800,
          "amt": 229
        },
        {
          "name": "Abril",
          "uv": 203,
          "pv": 3908,
          "amt": 200
        }
      ]
      const databar = [
        {
          "name": "Items",
          "acessos": 100,
          "pv": 2400,
          "fill": "#8884d8"
        },
        {
          "name": "Inventário",
          "acessos": 123,
          "pv": 4567,
          "fill": "#83a6ed"
        },
        {
          "name": "Coletas",
          "acessos": 199,
          "pv": 1398,
          "fill": "#8dd1e1"
        }
    ]

    function changeFunction(key){
        switch (key) {
            case 0:
                return(
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <Container fluid>
                            <Row>
                                <Col md={7} className="bg-white border rounded m-1 p-3">
                                    <Row>
                                        <Col>
                                            <h4 className='fw-bold'>Dashboard</h4>
                                            <p className='text-secondary' style={{marginTop: '-15px'}}>Ver dados</p>
                                            <div className='mt-3'>
                                                <h3 className='fw-bold'>{userList.length}</h3>
                                                <p className='text-secondary' style={{marginTop: '-15px'}}>Usuários</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <LineChart width={600} height={300} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                                <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                            </LineChart>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="bg-white border rounded m-1 p-3">
                                    <Row>
                                        <Col lg={3}>
                                            <h6 className='fw-bold'>Tráfego</h6>
                                            <p className='text-secondary' style={{marginTop: '-15px'}}>Ver dados</p>
                                        </Col>
                                        <Col>
                                            <RadialBarChart 
                                                width={600} 
                                                height={250} 
                                                innerRadius="10%" 
                                                outerRadius="80%" 
                                                data={databar} 
                                                startAngle={180} 
                                                endAngle={0}
                                            >
                                                <RadialBar minAngle={15} label={{ fill: '#666', position: 'insideStart' }} background clockWise={true} dataKey='acessos' />
                                                <Legend iconSize={10} width={120} height={140} layout='vertical' verticalAlign='middle' align="right" />
                                                <Tooltip />
                                            </RadialBarChart>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8} className="bg-white border rounded m-1 p-3">
                                    <Table className="rounded">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>EMAIL</th>
                                                <th>Ultimo acesso</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userList.map((user)=>(
                                                <tr>
                                                    <th className="fw-bold col-1">{user.nome}</th>
                                                    <th><a href="mailto:gabrielleso125@gmail.com">{user.login}</a></th>
                                                    {isNaN(user.dt_acesso) ?
                                                        <th className='text-end'>{new Date(user.dt_acesso).toLocaleString("default", { day: "numeric", month: "long"})}, {new Date(user.dt_acesso).toLocaleString("default", { year: "numeric" })} <span className="border-start ps-3 ms-3">{new Date(user.dt_acesso).getHours().toString().padStart(2, "0")}:{new Date(user.dt_acesso).getMinutes().toString().padStart(2, "0")}</span> </th>
                                                        :
                                                        <th></th>
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col className="bg-white border rounded m-1 p-3">
                                    <h6 className='fw-bold'>Ranking</h6>
                                    <p className='text-secondary' style={{marginTop: '-15px'}}>Usuários mais ativos</p>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Nome</th>
                                                <th></th>
                                                <th>Acessos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>#1</th>
                                                <th>Gabrielle</th>
                                                <th></th>
                                                <th>123</th>
                                            </tr>    
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                )
                break
            default:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>Error</div>
                )
                break
        }
    }

    return(
        <div className='mainContainer'>
            <Menu/>
            {changeFunction(change)}
        </div>
    )
}

export default Dashboard