import React from 'react';
import { BrowserRouter, Navigate, useNavigate, useRoutes } from 'react-router-dom';

import Login from './pages/Auth/LoginPage';
import Register from './pages/Auth/RegisterPage';
import PBC_Login from './pages/Auth/PBC/LoginPage';
import PBC_Register from './pages/Auth/PBC/RegisterPage';
import Logs from './pages/Logs';
import BalanceSheet from './pages/BalanceSheet';
import Collects from './pages/Collects';
import Inventories from './pages/Inventories';
import CreateInventory from './pages/Inventories/CreateInventory';
import OrderInventory from './pages/Inventories/CreateInventory/Monitoring/Checagem';
import MonitoringInventory from './pages/Inventories/CreateInventory/Monitoring/TagPicking';
import Almoxarifado from './pages/Inventories/CreateInventory/Monitoring/Almoxarifado';
import ExternInventories from './pages/ExternInventories';
import RegistroRotas from './pages/RegistroRotas';
import EILink from './pages/EILink';
import Monitoring from './pages/Monitoring';
import Maps from './pages/Maps';
import MapsC from './pages/MapsContelurb';
import BI from './pages/BI';
import Items from './pages/Items';
import ItemsCopy from './pages/Items/ItemsCopy';
import Partners from './pages/Partners';
import PartnerDetails from './pages/Partners/details';
import Readers from './pages/Readers';
import Categories from './pages/Categories';
import Locations from './pages/Locations';

import Types from './pages/Types';

import Print from './pages/Print';

import Account from './pages/Account';

import Redirect from './components/Redirect';

import PrintOrder from './pages/PrintOrder';

import PrintAdress from './pages/PrintAdress';

import Dashboard from './pages/Dashboard';

import PrivacyPolicy from './pages/Terms/privacyPolicy';

import TermsAndConditions from './pages/Terms/termsConditions';

import UserGuide from './pages/Guide';

import Home from './pages/Home';

import LogActivity from './pages/Activities/index';

import ExportActivity from './pages/Activities/export-page';

import TransportLogin from './pages/Transport/TransportLogin';

import Transport from './pages/Transport';

import ScanQRCode from './pages/Transport/ScanQRCode';

import UploadPhoto from './pages/Transport/UploadPhoto';

import SendData from './pages/Transport/SendData';

import Layout from './layout'

// ----------------------------------------------------------------------

export default function Router() {
    const navigate = useNavigate()
  
    const routes = useRoutes([
        { path: '/login/:id_conta?/:token?', element: <Login /> },
        { path: '/register', element: <Register /> },
        { path: '/PBC/login', element: <PBC_Login /> },
        { path: '/PBC/register', element: <PBC_Register /> },
        { path: '/logs', element: <Logs /> },
        { path: '/balance-sheet', element: <BalanceSheet /> },
        { path: '/collections/collects', element: <Collects /> },
        { path: '/collections/inventories', element: <Inventories /> },
        { path: '/collections/create/checagem/:id', element: <OrderInventory /> },
        { path: '/collections/create/tag_picking/:id', element: <MonitoringInventory /> },
        { path: '/collections/create/almoxarifado/:id', element: <Almoxarifado /> },
        { path: '/collections/create', element: <CreateInventory /> },
        { path: '/collections/extern-inventories/:identificador', element: <EILink /> },
        { path: '/collections/extern-inventories', element: <ExternInventories /> },
        { path: '/collections/registers', element: <RegistroRotas /> },
        { path: '/monitoring', element: <Monitoring /> },
        { path: '/maps', element: <Maps /> },
        { path: '/mapsc/:conta', element: <MapsC /> },
        { path: '/BI', element: <BI /> },
        { path: '/items', element: <Items /> },
        { path: '/items/V2', element: <ItemsCopy /> },
        { path: '/partners/details/:id_parceiro', element: <PartnerDetails /> },
        { path: '/partners', element: <Partners /> },
        { path: '/readers', element: <Readers /> },
        { path: '/categories', element: <Categories /> },
        { path: '/locations', element: <Locations /> },
        { path: '/types', element: <Types /> },
        { path: '/print', element: <Print /> },
        { path: '/print-adress', element: <PrintAdress /> },
        { path: '/print-order', element: <PrintOrder /> },
        { path: '/account/:page?', element: <Account /> },
        { path: '/user_guide', element: <UserGuide /> },
        { path: '/dashboard', element: <Dashboard /> },
        { path: '/privacy_policy', element: <PrivacyPolicy /> },
        { path: '/home', element: <Home /> },
        { path: '/activity', element: <LogActivity /> },
        { path: '/activityexport', element: <ExportActivity /> },
        { path: '/terms-and-conditions', element: <TermsAndConditions /> },
        { path: '/transport/login', element: <TransportLogin /> },
        { path: '*', element: <Redirect /> },
        {
            path: '/transport',
            element: <Transport />,
            children: [
              { element: <Navigate to="qrcode" />, index: true },
              { path: 'qrcode/:id_conta?/:tag?', element: <ScanQRCode />},
              { path: 'upload-foto', element: <UploadPhoto />},
              { path: 'confirm-data', element: <SendData />}
            ],
        }
    ]);
  
    return routes;
  }