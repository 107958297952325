import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import api from '../../services/api'
import Menu from '../../components/Menu'

import './activities.css'

import { Button, Form, Row, Col, Table, OverlayTrigger, Popover } from 'react-bootstrap';
import { BsCircleFill } from 'react-icons/bs';
import { FiHelpCircle } from 'react-icons/fi';
import { FaFileExport } from 'react-icons/fa';
import { TbActivityHeartbeat } from "react-icons/tb"; 
import { BsTable } from "react-icons/bs"; 

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CircularProgress } from "@mui/material";
import ListActivities from "./ListActivities";
import CalendarActivities from './CalendarActivities'


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}


function ActivityLog(){
    const { t } = useTranslation();
    const [activities, setActivities] = useState([])
    const [actList, setActList] = useState([])
    const [nivel1List, setNivel1List] = useState([])
    const [nivel2List, setNivel2List] = useState([])
    const [nivel3List, setNivel3List] = useState([])
    const [nivel4List, setNivel4List] = useState([])

    const [dt_inital, setDt_inital] = useState()
    const [dt_final, setDt_final] = useState()

    const [userList, setUserList] = useState([])
    const [filterBy, setFilterbyUser] = useState('*')
    const [catList, setListbyCategory] = useState([])
    const [value, setValue] = React.useState(0);
    const [isLoading, setLoading] = useState()
    const [year, setYear] = useState(new Date().getFullYear())
    const [month, setMonth] = useState(new Date().getMonth())

    const [activityPerUser, setActivitiesPerUser] =  useState([]) 
    const [historic, setHistoric] = useState([])
    const[media, setMedia] = useState()

    useEffect(() => {
        async function getHistory() {
          setLoading(true);
          await api
            .get(`/conta_historico/${sessionStorage.getItem('idConta')}/${filterBy}`, {
              headers: { key: 'icdeva@23!' },
            })
            .then((response) => {
              let acoes = [];
      
              for (let i = 0; i < response.data.length; i++) {
                for (let j = 0; j < response.data[i].acoes.length; j++) {
                  acoes.push(response.data[i].acoes[j]);
                }
              }
      
              // Ordenar o array pelo campo dt_acao
              acoes.sort((a, b) => {
                return new Date(b.dt_acao) - new Date(a.dt_acao);
              });
      
              setActivities(acoes);
              setHistoric(response.data)
              setActList(acoes.slice().reverse()); // Reverter a ordem se necessário
              setDt_inital(acoes[0].dt_acao.substring(0, 10));
              setLoading(false);
            });
        }
      
        getHistory();
      }, [filterBy]);      

    useEffect(()=>{
        async function getUserList() {
            api.get(`/usuario/${sessionStorage.getItem('idConta')}/*/*/*/*`, {})
            .then(response => {        
                setUserList(response.data)
            })
        }
        getUserList()
    },[])

    useEffect(()=>{
        async function getCategorias() {
            api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/1`, {})
            .then(response => {                
                setListbyCategory(response.data)
            })
        }
        getCategorias()
    },[])

    useEffect(()=>{
        async function getNivel1(){
            api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
            ).then(
                response=>{
                    setNivel1List(response.data);
                }
            )
        }
        async function getNivel2(){
            api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/*/*/*/*/*`, {}
            ).then(
                response=>{
                    setNivel2List(response.data);
                }
            )
        }
        async function getNivel3(){
            api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/*/*/*/*/*`, {}
            ).then(
                response=>{
                    setNivel3List(response.data);
                }
            )
        }
        async function getNivel4(){
            api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/*/*/*/*/*`, {}
            ).then(
                response=>{
                    setNivel4List(response.data);
                }
            )
        }
        getNivel1()
        getNivel2()
        getNivel3()
        getNivel4()
    },[])

    function getUsername(id){
        let username = userList.filter(item => item._id == id)
        if(!username){
            return 'Usuário não identificado'
        }

        return username[0]
    }

    function getCategoria(id) {
        let username = catList.filter(item => item._id == id)
        return username[0]?.descricao
    }

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    function calcularMediaAtividadesPorUsuario(activityPerUser) {
        let totalActivities = 0;
    
        if (activityPerUser.length === 0) {
            return 0; // Avoid division by zero
        }
    
        // Initialize a variable for the sum of activities
        let sumActivities = 0;
    
        // Iterate over the list of activities per user and sum the number of activities
        activityPerUser.forEach((a) => {
            // Check if 'a' has the expected structure
            if (a && a.years) {
                for (let year in a.years) {
                    for (let month in a.years[year]) {
                        if (a.years[year].hasOwnProperty(month)) {
                            for (let day = 1; day <= 31; day++) {
                                if (
                                    a.years[year][month][day] &&
                                    a.years[year][month][day].length > 0
                                ) {
                                    sumActivities += a.years[year][month][day].length;
                                    totalActivities++;
                                }
                            }
                        }
                    }
                }
            }
        });
    
        // Calculate the average by dividing the sum by the total number of activities
        const averageActivities = totalActivities > 0 ? sumActivities / totalActivities : 0;    
        return averageActivities;
    }
    
    useEffect(() => {
        setLoading(true)
        function getPerUserActivity() {
            let teste = []; // Inicializar o array teste como um array vazio
        
            for (const a of activities) {
                const userID = a.valor.id_usuario ?? a.valor.id_conta;
                const year = new Date(a.dt_acao).getFullYear();
                const month = new Date(a.dt_acao).getMonth();
                const day = new Date(a.dt_acao).getDate();
        
                let userObj = teste.find(item => item.userID === userID);
        
                if (!userObj) {
                    userObj = { userID, years: {} };
                    teste.push(userObj);
                }
        
                if (!userObj.years[year]) {
                    userObj.years[year] = Array(12).fill([]).map(() => Array(32).fill([])); // Inicializa uma matriz com 12 meses e 31 dias vazios
                }
        
                if (!userObj.years[year][month]) {
                    userObj.years[year][month] = Array(32).fill([]); // Inicializa um array com 31 dias vazios
                }
        
                userObj.years[year][month][day] = userObj.years[year][month][day].concat(a.acao);
            }
            const mediaPerUser = calcularMediaAtividadesPorUsuario(teste)
            setMedia(mediaPerUser)
            setActivitiesPerUser(teste);
            setLoading(false)
        }
        
        if (activities && activities.length > 0) {
            getPerUserActivity();
        }
    }, [activities]);    
        
    useEffect(()=>{
        const dataAtual = new Date();

        const ano = dataAtual.getFullYear();
        const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
        const dia = String(dataAtual.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário

        // Formata a data no formato "yyyy/mm/ano"
        const dataFormatada = `${ano}-${mes}-${dia}`;
        setDt_final(dataFormatada);
    },[])

    function dateFilter(){
        setLoading(true);
        let data_inicio = new Date(dt_inital);
        let data_final = new Date(dt_final);

        const filteredActions = actList.filter((action) => {
            const dt_acao = new Date(action.dt_acao.substring(0, 10));
            return dt_acao >= data_inicio && dt_acao <= data_final;
        });

        setActivities(filteredActions ?? []);
        setLoading(false);
    }

    function getTableColor(activityCount){
        if(activityCount == 0){
            return ''
        }
        if(activityCount < media - (media*0.25)){
            return 'text-white bg-success bg-opacity-25'
        }else if(activityCount > media - (media*0.25) && activityCount < media + (media*0.25)){
            return 'text-white bg-success'
        }else if(activityCount > media + (media*0.25)){
            return 'text-white bg-success-dark'
        }
    }

    return(
        <div className="mainContainer">
            <Menu/>
            <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                <h2 className="fw-bold m-0 p-0">{t('activity_log.activity')}</h2>
                <h6 className="text-secondary">{t('activity_log.history')}</h6>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab icon={<TbActivityHeartbeat />} iconPosition="start" label="Log de Atividade" {...a11yProps(0)} />
                            <Tab icon={<BsTable />} iconPosition="start" label="Por Usuário" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <div>
                            <Row className='my-4 justify-content-between me-3 g-2'>
                                <Col sm={12} lg={5} className="d-flex">
                                    <Form.Group>
                                        <Form.Label>Filtrar por usuário</Form.Label>
                                        <Form.Select size="sm" className="text-secondary" value={filterBy} onChange={e=>{setFilterbyUser(e.target.value)}}>
                                            <option value="*">{t('activity_log.filter_by')}</option>
                                            {userList.map((item)=>(
                                                <option value={item._id}>{item?.nome}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="ms-3">
                                        <Form.Label>Data inicial</Form.Label>
                                        <Form.Control id="date" type="date"  value={dt_inital} onChange={e=>{setDt_inital(e.target.value); dateFilter()}}/>
                                    </Form.Group>
                                    <Form.Group className="ms-3">
                                        <Form.Label>Data final</Form.Label>
                                        <Form.Control id="date" type="date" value={dt_final} onChange={e=>{setDt_final(e.target.value); dateFilter()}}/>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} lg={1} >
                                    <a href="/activityexport" className='btn btn-primary rounded-pill w-100'><FaFileExport className='me-2'/> {t('actions.export')}</a>
                                </Col>
                            </Row>
                            {
                                isLoading ? 
                                    <>
                                        <CircularProgress />
                                    </>
                                :
                                <>
                                    <ListActivities header={['Data', 'Ações', 'Responsável', 'Detalhes']} body={activities} users={userList} categoria={catList} nivel1={nivel1List} nivel2={nivel2List} nivel3={nivel3List} nivel4={nivel4List}/>
                                </>   
                            }
                            
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <CalendarActivities activity={historic} users={userList}/>
                    </CustomTabPanel>
                </Box>
                
            </div>
        </div>
    )
}

export default ActivityLog