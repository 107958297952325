import { useEffect, useState } from "react";

import {useNavigate } from 'react-router-dom'

import Menu from "../../../components/Menu";

import { Box, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Paper, Select, Stack, Switch, TextField, Typography } from "@mui/material";

import api from "../../../services/api";

import { Button } from "react-bootstrap";

import secureLocalStorage from "react-secure-storage";

import { Icon } from "@iconify/react/dist/iconify.js";

import FormChecagem from '../CreateInventory/Forms/FormChecagem'

import FormTagPicking from "./Forms/FormTagPicking";

import FormAlmoxarifado from "./Forms/FormAlmoxarifado";


//----------------------------------------------------------------

export default function CreateInventory(){
    const history = useNavigate()

    const [loading, setLoading] = useState()

    const [mode, setMode] = useState()

    const [decoderCodes, setDecoderCodes] = useState([{}])

    const [filter, setFilter] = useState()

    const [nivel1, setNivel1] = useState()

    const [nivel2, setNivel2] = useState()

    const [nivel3, setNivel3] = useState()

    const [nivel4, setNivel4] = useState()

    const [nivel1List, setNivel1List] = useState([])

    const [nivel2List, setNivel2List] = useState([])

    const [nivel3List, setNivel3List] = useState([])

    const [nivel4List, setNivel4List] = useState([])

    const [categoria, setCategoria] = useState()

    const [categoriaList, setCategoriaList] = useState([])

    const [marca, setMarca] = useState()

    const [marcaList, setMarcaList] = useState([])

    const [model, setModel] = useState()

    const [modelList, setModelList] = useState([])

    const [reader, setReader] = useState()

    const [readerError, setReaderError] = useState(false)

    const [readerList, setReaderList] = useState([])

    const [ean, setEan] = useState()
    
    const [tagCount, setTagCount] = useState()

    const [OF, setOF] = useState()

    const [system, setSystem] = useState("")

    useEffect(() => {
        nivel2Get(nivel1)
    }, [nivel1])

    useEffect(() => {
        setNivel3()
        setNivel4()
        nivel3Get(nivel2)
    }, [nivel2])
    
    useEffect(() => {
        nivel4Get(nivel3)
    }, [nivel3])

    function nivel1Get() {
        setLoading(true)
        api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setNivel1List(response.data)})
    }

    function nivel2Get(nivel1) {
        setLoading(true)
        if(nivel1) {
            api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/${nivel1 != '' ? nivel1._id : '*'}/*/*/*/*`, {}
            ).then(
                response => {
                    setNivel2List(response.data)
                }, err => {
                    setLoading(false)
                }
            )
        }else {
            setNivel2List([])
        }
    }

    function nivel3Get(nivel2) {
        if(nivel2) {
            setLoading(true)
            api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/${nivel2 != '' ? nivel2._id : '*'}/*/*/*/*`, {}
            ).then(
                response => {
                    setNivel3List(response.data)
                }, err => {
                    setLoading(false)
                }
            )
        }else {
            setNivel3List([])
        }
    }

    function nivel4Get(nivel3) {
        if(nivel3) {
            setLoading(true)
            api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/${nivel3 != '' ? nivel3._id : '*'}/*/*/*/*`, {}
            ).then(
                response => {
                    setNivel4List(response.data)
                }, err => {
                    setLoading(false)
                }
            )
        }else {
            setNivel4List([])
        }
    }

    function handleCategoryChange(category){
        setCategoria(category)
        setMarca()
        getInfoBrand(category)
    }

    function handleChangeMarca(mark){
        setMarca(mark._id)
        setModel()
        setModelList(mark.modelo)
    }

    function categoriaGet() {
        setLoading(true)
        api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(
            response => {
                setCategoriaList(response.data)
                setLoading(false)
            }, err => {
                setLoading(false)
            }
        )
    }

    function getInfoBrand(id_categoria){
        setLoading(true)
        api.get(`/infs_compl/${sessionStorage.getItem('idConta')}?id_categoria=${id_categoria}&marca=${marca ? marca : ''}&modelo=`).then(
            response => {
                setMarcaList(response.data)
            }, err => {
                setLoading(false)
            }
        )
    }

    useEffect(() => {
        leitoresGet()
    },[])

    function leitoresGet() {
        api.get(`/leitor/${sessionStorage.getItem('idConta')}/*/*/*/*`
        ).then(
            response => {
                setReaderList(response.data)
            }
        )
    }

    function generateID() {
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var id = '';
        for (var i = 0; i < 6; i++) {
          var randomIndex = Math.floor(Math.random() * characters.length);
          id += characters[randomIndex];
        }
        return id;
    }

    function handleStart(saveConfig){
        if(!reader){
            setReaderError(true)
            return
        }
        const id = generateID()

        let startConfig = {
            ...saveConfig,
            reader: reader,  
            _id: id,
            dt_inicio: new Date()
        }

        for (let key in startConfig) {
            if (!startConfig[key]) {
                delete startConfig[key];
            }
        }

        const running_inventories = secureLocalStorage.getItem('running_inventories') || []
        
        running_inventories.push(id)

        secureLocalStorage.setItem('running_inventories', running_inventories)

        secureLocalStorage.setItem(id, startConfig)

        history(`/collections/create/${mode}/${id}`)
    }
    
    return (
        <>
            <div 
                className="mainContainer" 
                style={JSON.parse(sessionStorage.getItem('collors'))}
            >
                <Menu/>
                <div id="background">
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        
                        <Stack component={Paper} className="shadow" sx={{m: 5, p: 3}} spacing={3}>

                            <Box>
                                <Typography variant="h1">Preparar leitura</Typography>
                                <Typography variant="subtitle">Configure as opções para iniciar inventário</Typography>
                            </Box>

                            <Grid item md={12}>
                                <FormControl fullWidth error={readerError}>
                                    <Typography>Leitor</Typography>
                                    <Select size="small" value={reader} onChange={e=>{setReader(e.target.value)}}>
                                        {readerList.map((option)=>(
                                            <MenuItem key={option.serial} value={option}>
                                                <ListItemText>{option.nm_leitor}</ListItemText>
                                                <Typography variant="body2" color="text.secondary">
                                                    {option.serial}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {readerError ?<FormHelperText sx={{color: 'error.main'}}>Campo obrigatório</FormHelperText>:''}
                                </FormControl>
                            </Grid>
                            <Grid item md>
                                <FormGroup>
                                    <Typography>Modo</Typography>
                                    <Select size="small" value={mode} onChange={e=>{setMode(e.target.value)}}>
                                        <MenuItem value="tag_picking">
                                            <Typography sx={{fontWeight: 500}}>Tag Picking</Typography>
                                        </MenuItem>
                                        <MenuItem value="checagem">
                                            <Typography sx={{fontWeight: 500}}>Checagem</Typography>
                                        </MenuItem>
                                        <MenuItem value="almoxarifado">
                                            <Typography sx={{fontWeight: 500}}>Almoxarifado</Typography>
                                        </MenuItem>
                                    </Select>
                                </FormGroup>
                            </Grid>
                            {mode === 'tag_picking' ? 
                                <FormTagPicking reader={reader} onSave={e=>{handleStart(e)}}/>
                            : mode === 'checagem' ?
                                <FormChecagem reader={reader} onSave={e=>{handleStart(e)}}/>
                            : mode === 'almoxarifado' ?
                                <FormAlmoxarifado onSave={e=>{handleStart(e)}}/>
                            : ""}
                            
                        </Stack>
                    </div>        
                </div>
            </div> 
        </>
    )
}