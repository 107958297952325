import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Input(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: theme.palette.secondary.main,
          },
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
          //backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: alpha(theme.palette.grey[500], 0.56),
          },
        },
        root: {
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey[300],
              backgroundColor: theme.palette.grey[200],
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey[300],
              backgroundColor: theme.palette.grey[200],
            },
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottom: 'none',  // Remove the underline before focus
          },
          '&:after': {
            borderBottom: 'none',  // Remove the underline after focus
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',  // Remove the underline on hover
          },
        },
        input: {
          //backgroundColor: theme.palette.grey[200],
        },
      },
    },
  };
}
