import { BiFile } from "react-icons/bi"; 
import { FiSettings } from "react-icons/fi"; 
import { CgClose } from "react-icons/cg"; 
import React, {useState, useEffect} from 'react'
import InputMask from 'react-input-mask'
import ReactLoading from 'react-loading'

import Swal from 'sweetalert2'

import api from '../../services/api'
import Menu from '../../components/Menu'
import imgIcon from '../../assets/img-menu.png'
import './style.css'
import { Container, Row, Col, Table, Button, Dropdown, Form, Modal, FloatingForm, CloseButton, OverlayTrigger, Popover } from 'react-bootstrap'
import {FiRefreshCcw, FiSave} from 'react-icons/fi'
import {BiRfid, BiUser} from 'react-icons/bi'
import {BiBarcodeReader, BiPrinter, BiBook, BiPurchaseTag} from 'react-icons/bi'

import { useTranslation } from 'react-i18next'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import Typography from '@mui/material/Typography';
import { BsFillPersonFill } from 'react-icons/bs'
import {GrDocumentUser} from 'react-icons/gr'

import { useParams } from 'react-router-dom';
import { FormLabel } from "@mui/material";

function Account() {
    const { t } = useTranslation();
    const { page  } = useParams()
    const [usuario, setUsuario] = useState([])
    const [nomeC, setNomeC] = useState('')
    const [uuidC, setUuidC] = useState('')
    const [ativoC, setAtivoC] = useState('1')
    const [telefoneC, setTelefoneC] = useState('')
    const [eMailC, setEMailC] = useState('')
    const [cnpjC, setCnpjC] = useState('')
    const [enderecoC, setEnderecoC] = useState('')
    const [observacaoC, setObservacaoC] = useState('')
    const [dtLicencaC, setDtLicencaC] = useState('')
    const [dtRegistroC, setDtRegistroC] = useState('')

    const [showEditUser, setShowEditUser] = useState(false)
    const [idU, setIdU] = useState('0')
    const [nomeU, setNomeU] = useState('')
    const [ativoU, setAtivoU] = useState('1')
    const [perfilU, setPerfilU] = useState('admin')
    const [loginU, setLoginU] = useState('')
    const [senhaU, setSenhaU] = useState('')
    const [tagU, setTagU] = useState('')
    const [adressU, setAdressU] = useState('')


    const [idCC, setIdCC] = useState('0')
    const [idConfig, setIdConfig] = useState('')
    const [modeloL, setModeloL] = useState('')
    const [serialL, setSerialL] = useState('')
    const [potenciaL, setPotenciaL] = useState('')
    const [seccaoL, setSeccaoL] = useState('')
    const [estadoL, setEstadoL] = useState('')
    const [impressoraL, setImpressoraL] = useState('')

    const [limiteRssi, setLimiteRssi] = useState(0)
    const [quantidadeFantasma, setQuantidadeFantasma] = useState(0)

    const [padraoI, setPadraoI] = useState('')
    const [verificadorInicialI, setVerificadorInicialI] = useState('')
    const [posicaoI, setPosicaoI] = useState('')
    const [comprimentoI, setComprimentoI] = useState('')
    const [corteTag, setCorteTag] = useState('0')
    const [infCompl1I, setInfCompl1I] = useState('')
    const [infCompl2I, setInfCompl2I] = useState('')
    const [infCompl3I, setInfCompl3I] = useState('')
    const [infCompl4I, setInfCompl4I] = useState('')
    const [infCompl5I, setInfCompl5I] = useState('')
    const [infCompl6I, setInfCompl6I] = useState('')
    const [infCompl7I, setInfCompl7I] = useState('')
    const [infCompl8I, setInfCompl8I] = useState('')
    const [infCompl9I, setInfCompl9I] = useState('')
    const [infCompl10I, setInfCompl10I] = useState('')

    const [prefixType, setPrefixType] = useState('')
    const [prefixText, setPrefixText] = useState('')

    const [modoA, setModoA] = useState('')
    const [enderecoA, setEnderecoA] = useState('')
    //const [tokenA, setTokenA] = useState('')

    const [division, setDivision] = useState(0)
    
    const [loading, setLoading] = useState(false)

    const [theme, setTheme] = useState('light')
    const [pallet_1, setPallet_1] = useState('#2488f7')
    const [pallet_2, setPallet_2] = useState('#730930')
    const [pallet_3, setPallet_3] = useState('#FFFFFF')
    const [pallet_4, setPallet_4] = useState('#919191')
    const [renderUpdate, setRenderUpdate] = useState(false)
    const [receberAlerta, setReceberAlerta] = useState(false)

    const [nivelLoc1, setNivelLoc1] = useState('*')
    const [nivelLoc2, setNivelLoc2] = useState('*')
    const [nivelLoc3, setNivelLoc3] = useState('*')
    const [nivelLoc4, setNivelLoc4] = useState('*')
    const [nivelLoc1List, setNivelLoc1List] = useState([])
    const [nivelLoc2List, setNivelLoc2List] = useState([])
    const [nivelLoc3List, setNivelLoc3List] = useState([])
    const [nivelLoc4List, setNivelLoc4List] = useState([])
    const [inputNew, setInputNew] = useState('')

    const [unsave, setUnsave] = useState(false)

    const [historic, setHistoric] = useState([])
    const [customizeMenu, setCustomizeMenu] = useState(false)
    const [userMenu, setUserMenu] = useState([])
    const [menuItens, setMenuItens] = useState([
        'inventarios',
        'inventarios_externo',
        'coletas',
        'itens',
        'categorias',
        'localizacoes',
        'parceiros',
        'impressoes',
        'ordem_impressao',
        'leitores',
        'impressoras',
        'tipos_registros',
        'balancete',
        'mapas',
        'monitoramento',
        'logs'
    ])
    const [obrigatorio, setobrigatorio] = React.useState([]);

    const [sincronizarRotas, setSincronizarRotas] = useState()

    const handleChange = (event) => {
        const {
        target: { value },
        } = event;
        setobrigatorio(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
    };

    const names = [
        'info_compl_1',
        'info_compl_2',
        'info_compl_3',
        'info_compl_4',
        'info_compl_5',
        'info_compl_6',
        'info_compl_7',
        'info_compl_8',
        'info_compl_9',
        'info_compl_10',
        'marca',
        'modelo',
        'parceiro',
        'status',
        'valor',
        'observacao',
        'nivel_1',
        'nivel_2',
        'nivel_3',
        'nivel_4',
        'latitude',
        'longitude'
    ];

    const [modalPermissions, setModalPermissions] = useState(false);

    const handleCloseModalPermissions = () => setModalPermissions(false);
    const handleShowModalPermissions = () => setModalPermissions(true);

    const [canCreateItem, setCanCreateItem] = useState(false);
    const [canEditItem, setCanEditItem] = useState(false);
    const [canViewItem, setCanViewItem] = useState(false);
    const [canExcludeItem, setCanExcludeItem] = useState(false);

    useEffect(() => {
        if(page == 'guides'){
            setDivision(6)
        }
    },[])

    useEffect(() => {
        getTimeline()
    },[])    

    function getUser(testeteste) {
        return new Promise((resolve, reject) => {
            api.get(`/usuario/${sessionStorage.getItem('idConta')}/${testeteste}/*/*/*`, {})
                .then(response => {
                    resolve(response.data[0].nome);
                })
                .catch(error => {
                    reject(error);
                }
            );
        });
    }

    useEffect(() => {
        contaGet()
        usuarioGet()
        contaConfigGet()
    }, [])

    useEffect(() => {
        nivel1Get()
    }, [])

    useEffect(() => {
        nivel2Get(nivelLoc1)
    }, [nivelLoc1])

    useEffect(() => {
        nivel3Get(nivelLoc2)
    }, [nivelLoc2])
    
    useEffect(() => {
        nivel4Get(nivelLoc3)
    }, [nivelLoc3])

    function nivel1Get() {
        api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setNivelLoc1List(response.data)})
    }

    function nivel2Get(nivel1) {
        if(nivel1 != "*" && nivel1 != '' && nivel1 != null && nivel1 != undefined) {
            api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/${nivel1 != '' ? nivel1 : '*'}/*/*/*/*`, {}
            ).then(response => {setNivelLoc2List(response.data)})
        }else {
            setNivelLoc2List([])
            setNivelLoc2("")
        }
    }

    function nivel3Get(nivel2) {
        if(nivel2 != "*" && nivel2 != '' && nivel2 != null && nivel2 != undefined) {
            api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/${nivel2 != '' ? nivel2 : '*'}/*/*/*/*`, {}
            ).then(response => {setNivelLoc3List(response.data)})
        }else {
            setNivelLoc3List([])
            setNivelLoc3("")
        }
    }

    function nivel4Get(nivel3) {
        if(nivel3 != "*" && nivel3 != '' && nivel3 != null && nivel3 != undefined) {
            api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/${nivel3 != '' ? nivel3 : '*'}/*/*/*/*`, {}
            ).then(response => {setNivelLoc4List(response.data)})
        }else {
            setNivelLoc4List([])
            setNivelLoc4("")
        }
    }

    function novoNivel1(fechar) {
        if(fechar == 'fechar') {
            setInputNew('')
            setNivelLoc1('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc1: "0",
                observacao: "",
                tag: "",
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post('/nivel_loc1', reg, option).then(
                response=> {
                    setInputNew('')
                    nivel1Get()
                    setNivelLoc1(response.data[0]._id)

                },
                response=> {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Algo deu errado, por favor tente novamente mais tarde',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
        }
    }

    function novoNivel2(fechar) {
        if(fechar == 'fechar') {
            setInputNew('')
            setNivelLoc2('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc1: nivelLoc1,
                id_nivel_loc2: "0",
                observacao: "",
                tag: "",
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post('/nivel_loc2', reg, option).then(
                response=> {
                    setInputNew('')
                    nivel2Get(nivelLoc1)
                    setNivelLoc2(response.data[0]._id)

                },
                response=> {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Algo deu errado, por favor tente novamente mais tarde',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
        }
    }

    function novoNivel3(fechar) {
        if(fechar == 'fechar') {
            setInputNew('')
            setNivelLoc3('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc2: nivelLoc2,
                id_nivel_loc3: "0",
                observacao: "",
                tag: ""
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post('/nivel_loc3', reg, option).then(
                response=> {
                    setInputNew('')
                    nivel3Get(nivelLoc2)
                    setNivelLoc3(response.data[0]._id)

                },
                response=> {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Algo deu errado, por favor tente novamente mais tarde',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
        }
    }

    function novoNivel4(fechar) {
        if(fechar == 'fechar') {
            setInputNew('')
            setNivelLoc4('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc3: nivelLoc3,
                id_nivel_loc4: "0",
                observacao: "",
                tag: ""
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post('/nivel_loc4', reg, option).then(
                response=> {
                    setInputNew('')
                    nivel4Get(nivelLoc3)
                    setNivelLoc4(response.data[0]._id)

                },
                response=> {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Algo deu errado, por favor tente novamente mais tarde',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
        }
    }

    async function getTimeline() {
        let option = {
          headers: { key: 'icdeva@23!' }
        };
      
        try {
            const response = await api.get(`/conta_historico/${sessionStorage.getItem('idConta')}/*`, option);
            let lista = [];

            for (let item of response.data) {
                let usuario = await getUser(item.id_usuario);
                for (let i = 0; i < item.acoes.length; i++) {
                    const data = new Date(item.acoes[i].dt_acao);
                    const dataFormatado = data.toLocaleDateString('pt-BR', {
                    day: '2-digit',
                    month: 'short'
                    })
                    lista.push({
                        'data': dataFormatado,
                        'acao': item.acoes[i].acao,
                        'hour': item.acoes[i].dt_acao.substring(11,16),
                        'usuario': usuario
                    });
                }
            }
            setHistoric(lista);
        } catch (error) {
            console.error(error);
        }
    }  

    function handleCheckboxClick(event){
        const { value, checked } = event.target;
        if (checked) {
            setMenuItens([...menuItens, value]);
        } else {
            setMenuItens(menuItens.filter((item) => item !== value));
        }
    }

    function saveMenu(){
        let obj = {};

        for (var i = 0; i < menuItens.length; i++) {
            obj[menuItens[i]] = true;
        }
        setCustomizeMenu(false)
        let option = {
            headers: { 'Content-Type': ['application/json'] }
        }
        let dt   = new Date();
        let dt_reg  = dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2)
        dt_reg     += " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" +dt.getMinutes()).slice(-2) + ":" + ("0" +dt.getSeconds()).slice(-2)
        let reg = []
        
        reg.push({
            ativo: usuario[0].ativo,
            dt_acesso: usuario[0].dt_acesso,
            foto: "",
            id_conta: sessionStorage.getItem('idConta'),
            id_usuario: usuario[0]._id,
            login: usuario[0].login,
            nome: usuario[0].nome,
            perfil: usuario[0].perfil,
            senha: usuario[0].senha,
            tag: usuario[0].tag,
            endereco_impressora: usuario[0].endereco_impressora,
            niveis_acesso: usuario[0].niveis_acesso,
            modulos_acesso: [obj]
        })
        api.post('/usuario', reg, option).then(
            response => {
                sessionStorage.setItem('menu', JSON.stringify([{custom: Object.keys(response.data[0].modulos_acesso[0]).filter(item => item != '_id')}]))
                window.location.reload()
            }
        )
    }
    /*
    useEffect(() => {
        let cor = {
            '--menu-text': theme == 'light' ? '#FFFFFF' : '#DDDDDD', //okay
            '--main-back-1': theme == 'light' ? '#C4C4C4' : '#2A2A2A', //okay
            '--main-back-1-1': theme == 'light' ? '#C4C4C4' : '#3e3e3e', //okay
            '--main-back-2': theme == 'light' ? '#DBDBDB' : '#575757', //okay
            '--main-back-3': theme == 'light' ? '#3B3C40' : '#777777',
            '--main-back-4': theme == 'light' ? '#575757' : '#575757',
            '--main-back-5': theme == 'light' ? '#f8f8f8' : '#f8f8f8',
            '--main-back-6': theme == 'light' ? '#cacaca' : '#cacaca',
            '--main-back-7': theme == 'light' ? '#F1F1F1' : '#F1F1F1',
            '--main-back-8': theme == 'light' ? 'rgb(221, 221, 221)' : 'rgb(221, 221, 221)',
            '--main-back-9': theme == 'light' ? 'rgb(250, 250, 250)' : 'rgb(250, 250, 250)',
            '--main-back-10': theme == 'light' ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.3)',
            '--main-back-11': theme == 'light' ? 'rgb(114, 114, 114)' : 'rgb(114, 114, 114)',
            '--main-back-12': theme == 'light' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.5)',
            '--main-back-13': theme == 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.87)',
            '--main-back-14': theme == 'light' ? '#A8A8A8' : '#A8A8A8',
            '--main-back-15': theme == 'light' ? '#8A8A8A' : '#8A8A8A',
            '--pallet-1': pallet_1,
            '--pallet-2': pallet_2,
            '--pallet-3': pallet_3,
            '--pallet-4': pallet_4
        }
        sessionStorage.setItem('collors', JSON.stringify(cor))
        setRenderUpdate(!renderUpdate)
    }, [theme, pallet_1, pallet_2, pallet_3, pallet_4])
    */

    function unSaveChanges(){
        Swal.fire({
            title: "Atenção !",
            text: "Há alterações que não foram salvas! Deseja sair sem salvar?",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Não",
            confirmButtonColor: "#dc3545",
            confirmButtonText: "Sim!"
            
        }).then(
            result => {
                if(result.isConfirmed) {
                    setUnsave(false)
                }else{
                    saveContaConfig()
                    setUnsave(false)
                }
            }
        )
    }

    function getCollorBase() {
        setPallet_1('#2488f7')
        setPallet_2('#730930')
        setPallet_3('#FFFFFF')
        setPallet_4('#919191')
    }

    function contaGet() {
        api.get(`/conta/${sessionStorage.getItem('idConta')}/*/*/*`, {}
        ).then(response => {
            if(response.data[0]){
                setAtivoC(response.data[0].ativo ? response.data[0].ativo : '1')
                setCnpjC(response.data[0].cnpj ? response.data[0].cnpj : '')
                setDtLicencaC(response.data[0].dt_licenca ? response.data[0].dt_licenca : '')
                setDtRegistroC(response.data[0].dt_registro ? response.data[0].dt_registro : '')
                setEMailC(response.data[0].email ? response.data[0].email : '')
                setEnderecoC(response.data[0].endereco ? response.data[0].endereco : '')
                setNomeC(response.data[0].nome ? response.data[0].nome : '')
                setObservacaoC(response.data[0].observacao ? response.data[0].observacao : '')
                setTelefoneC(response.data[0].telefone ? response.data[0].telefone : '')
                setUuidC(response.data[0].uuid ? response.data[0].uuid : '')
            }
        })
    }
    function usuarioGet() {
        api.get(`/usuario/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {
            setUsuario(response.data)
            setUserMenu(response.data[0].modulos_acesso[0])
            let lista 
            lista = Object.keys(response.data[0].modulos_acesso[0])
            setMenuItens(lista.filter(item => item != '_id'))
        })
    }
    function contaConfigGet() {
        api.get(`/conta_config/${sessionStorage.getItem('idConta')}`
        ).then(response => {
            if(response.data[0]){
                setIdCC(response.data[0]._id)
                setEnderecoA(response.data[0].api_endereco)
                setModoA(response.data[0].api_modo)
                //setTokenA(response.data[0].api_token)
                setIdConfig(response.data[0].id_conta_config)
                setImpressoraL(response.data[0].impressora_serial)
                setInfCompl1I(response.data[0].info_compl_1)
                setInfCompl2I(response.data[0].info_compl_2)
                setInfCompl3I(response.data[0].info_compl_3)
                setInfCompl4I(response.data[0].info_compl_4)
                setInfCompl5I(response.data[0].info_compl_5)
                setInfCompl6I(response.data[0].info_compl_6 ? response.data[0].info_compl_6 : '')
                setInfCompl7I(response.data[0].info_compl_7 ? response.data[0].info_compl_7 : '')
                setInfCompl8I(response.data[0].info_compl_8 ? response.data[0].info_compl_8 : '')
                setInfCompl9I(response.data[0].info_compl_9 ? response.data[0].info_compl_9 : '')
                setInfCompl10I(response.data[0].info_compl_10 ? response.data[0].info_compl_10 : '')
                setobrigatorio(JSON.parse(response.data[0].obrigatorio) ?? [])
                setSincronizarRotas(response?.data[0]?.token_id_rotas)
                setPrefixType(response.data[0].prefix_type ? response.data[0].prefix_type : '')
                setPrefixText(response.data[0].prefix_text ? response.data[0].prefix_text : '')

                setComprimentoI(response.data[0].item_comprimento)
                setPadraoI(response.data[0].item_padrao)
                setPosicaoI(response.data[0].item_posicao)
                setVerificadorInicialI(response.data[0].item_verif_inicial)
                setCorteTag(response.data[0].corte_tag ? response.data[0].corte_tag : '0')
                setEstadoL(response.data[0].leitor_estado)
                setModeloL(response.data[0].leitor_modelo)
                setPotenciaL(response.data[0].leitor_potencia)
                setSeccaoL(response.data[0].leitor_secao)
                setSerialL(response.data[0].leitor_serial)
                setLimiteRssi(response.data[0].limite_rssi ? response.data[0].limite_rssi : 0)
                setQuantidadeFantasma(response.data[0].quantidade_fantasma ? response.data[0].quantidade_fantasma : 0)
                setReceberAlerta(response.data[0].receber_alerta ? '1' : '0')
            }
        })
    }

    function renderSwitch(key) {
        switch (key) {
            case 0:
                return (
                    <Row>
                        <Col className="mt-4">
                            <h3>Dados</h3>
                            <p className="text-secondary" style={{marginTop:'-10px'}}>Atualize seus dados aqui.</p>
                        </Col>
                        <Row className="g-3 mt-0">
                            <Form.Group as={Col} md={4}>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control type="text" value={nomeC} onChange={e=> {setNomeC(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>UUID</Form.Label>
                                <Form.Control className="blocked" type="text" value={uuidC} onChange={e=> {setUuidC(e.target.value)}} disabled/>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>Ativo</Form.Label>
                                <Form.Select className="blocked" value={ativoC} onChange={e=> {setAtivoC(e.target.value)}} disabled>
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={3} className="d-flex flex-column">
                                <Form.Label>Telefone</Form.Label>
                                <InputMask className="form-control" mask='+99 (99) 99999-9999' value={telefoneC} onChange={e=> {setTelefoneC(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col} md={3}>
                                <Form.Label>Registro Externo por Whatsapp</Form.Label>
                                <Form.Select
                                    value={receberAlerta}
                                    onChange={e=>{setReceberAlerta(e.target.value)}}
                                >
                                    <option value={'1'}>SIM</option>
                                    <option value={'0'}>NÃO</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={3}>
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control type="text" value={eMailC} onChange={e=> {setEMailC(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col} md={3}>
                                <Form.Label>CNPJ</Form.Label>
                                <InputMask className="form-control" mask='99.999.999/9999-99' value={cnpjC} onChange={e=> {setCnpjC(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Endereço</Form.Label>
                                <Form.Control type="text" value={enderecoC} onChange={e=> {setEnderecoC(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Observação</Form.Label>
                                <Form.Control id="text_area" as="textarea" rows={3} value={observacaoC} onChange={e=> {setObservacaoC(e.target.value)}}/>
                            </Form.Group>
                        </Row>
                    </Row>
                )
                break
            case 1:
                return (
                    <Row className="p-3 flex-wrap className='mx-2'">
                        <Col className="bg-white p-1 border rounded" sm={12} md={6}>
                            <Table id="Users">
                                <thead>
                                    <tr id="header">
                                        <th>#</th>
                                        <th>USUARIO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {usuario.map((item, index) => (
                                        <tr key={index} onClick={e=> {editUser(item)}}>
                                            <th>{index + 1}</th>
                                            <th>{item.nome}</th>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                        {showEditUser?
                            <Col className="p-3">
                                
                                <Row className="g-2">
                                    <Form.Group>
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control type="text" value={nomeU} onChange={e=> {setNomeU(e.target.value)}}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Ativo</Form.Label>
                                        <Form.Select value={ativoU} onChange={e=> {setAtivoU(e.target.value)}}>
                                            <option value="0">Não</option>
                                            <option value="1">Sim</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Perfil</Form.Label>
                                        {sessionStorage.getItem('idConta') === 'c68AeC-CZ' ?
                                            <Form.Select value={perfilU} onChange={e=> {setPerfilU(e.target.value)}}>
                                                <option value="admin">Administrador master</option>
                                                <option value="restricted-admin">Administrador</option>
                                                <option value="usu">Usuário</option>
                                            </Form.Select>
                                        :
                                        <Form.Select value={perfilU} onChange={e=> {setPerfilU(e.target.value)}}>
                                            <option value="admin">Administrador</option>
                                            <option value="usu">Usuário</option>
                                            <option value="ivc">Inventário Cego</option>
                                            <option value="consumidor">Consumidor</option>
                                        </Form.Select>
                                        }
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Login</Form.Label>
                                        <Form.Control type="text" value={loginU} onChange={e=> {setLoginU(e.target.value)}} autoComplete="off"/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Senha</Form.Label>
                                        <Form.Control type="password" value={senhaU} onChange={e=> {setSenhaU(e.target.value)}} autoComplete="new-password"/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Tag</Form.Label>
                                        <Form.Control type="text" value={tagU} onChange={e=> {setTagU(e.target.value)}}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Endereço Impressora</Form.Label>
                                        <Form.Control type="text" value={adressU} onChange={e=> {setAdressU(e.target.value)}}/>
                                    </Form.Group>
                                    <Row className="mt-3">
                                        <Col className="p-1 fw-bold"><h4>Filtragem por níveis</h4></Col>
                                        <Col className="container-btn">
                                            <Button variant="light" style={{height: '32px'}} onClick={handleShowModalPermissions}><FiSettings className="me-1"/> Permissões</Button>
                                        </Col>
                                        <Modal show={modalPermissions} onHide={handleCloseModalPermissions} centered size="lg">
                                            <Modal.Header closeButton>
                                            <Modal.Title>Customizar filtragem</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <p className="mb-4">Defina o que a filtragem por níveis deve limitar</p>
                                                <Row className="bg-primary bg-opacity-10 border border-primary border-opacity-25 rounded p-1 py-3 m-2 align-items-center">
                                                    <Col><h5>Itens</h5></Col>
                                                    <Col>
                                                        <p className="m-0">Criar</p>
                                                        <Form.Check
                                                            type="switch"
                                                            checked={canCreateItem}
                                                            onChange={e=>{setCanCreateItem(!canCreateItem)}}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <p className="m-0">Editar</p>
                                                        <Form.Check
                                                            type="switch"
                                                            checked={canEditItem}
                                                            onChange={e=>{setCanEditItem(!canEditItem)}}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <p className="m-0">Visualizar</p>
                                                        <Form.Check
                                                            type="switch"
                                                            checked={canViewItem}
                                                            onChange={e=>{setCanViewItem(!canViewItem)}}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <p className="m-0">Excluir</p>
                                                        <Form.Check
                                                            type="switch"
                                                            checked={canExcludeItem}
                                                            onChange={e=>{setCanExcludeItem(!canExcludeItem)}}
                                                            disabled={perfilU === 'usu' ? true : false}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Modal.Body>
                                            <Modal.Footer>
                                            <Button variant="light" onClick={handleCloseModalPermissions}>
                                                Fechar
                                            </Button>
                                            <Button variant="primary" onClick={handleCloseModalPermissions}>
                                                Salvar
                                            </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </Row>
                                    {nivelLoc1 == 'new' ? (
                                        <>
                                            <Modal show={true} centered>
                                                <Modal.Header closeButton>
                                                <Modal.Title>{t('items.add_nivel')}</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form.Group>
                                                        <Form.Label>{t('items.new_nivel')} 1</Form.Label>
                                                        <Form.Control value={inputNew} onChange={e=> {setInputNew(e.target.value)}}/>
                                                    </Form.Group>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                <Button variant="secondary" onClick={e=> {novoNivel1('fechar')}}>{t('actions.close')}</Button>
                                                <Button variant="danger" onClick={e=> {novoNivel1()}}>{t('actions.save')}</Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </>
                                    ) : ''}
                                    {nivelLoc2 == 'new' ? (
                                        <>
                                            <Modal show={true} centered>
                                                <Modal.Header closeButton>
                                                <Modal.Title>{t('items.add_nivel')}</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form.Group>
                                                        <Form.Label>{t('items.new_nivel')} 2</Form.Label>
                                                        <Form.Control value={inputNew} onChange={e=> {setInputNew(e.target.value)}}/>
                                                    </Form.Group>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                <Button variant="secondary" onClick={e=> {novoNivel2('fechar')}}>{t('actions.close')}</Button>
                                                <Button variant="danger" onClick={e=> {novoNivel2()}}>{t('actions.save')}</Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </>
                                    ) : ''}
                                    {nivelLoc3 == 'new' ? (
                                        <>
                                            <Modal show={true} centered>
                                                <Modal.Header closeButton>
                                                <Modal.Title>{t('items.add_nivel')}</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form.Group>
                                                        <Form.Label>{t('items.new_nivel')} 3</Form.Label>
                                                        <Form.Control value={inputNew} onChange={e=> {setInputNew(e.target.value)}}/>
                                                    </Form.Group>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                <Button variant="secondary" onClick={e=> {novoNivel3('fechar')}}>{t('actions.close')}</Button>
                                                <Button variant="danger" onClick={e=> {novoNivel3()}}>{t('actions.save')}</Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </>
                                    ) : ''}
                                    {nivelLoc4 == 'new' ? (
                                        <>
                                            <Modal show={true} centered>
                                                <Modal.Header closeButton>
                                                <Modal.Title>{t('items.add_nivel')}</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form.Group>
                                                        <Form.Label>{t('items.new_nivel')} 4</Form.Label>
                                                        <Form.Control value={inputNew} onChange={e=> {setInputNew(e.target.value)}}/>
                                                    </Form.Group>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                <Button variant="secondary" onClick={e=> {novoNivel4('fechar')}}>{t('actions.close')}</Button>
                                                <Button variant="danger" onClick={e=> {novoNivel4()}}>{t('actions.save')}</Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </>
                                    ) : ''}
                                    <Form.Group as={Col} sm={12} md={6}>
                                        <Form.Label>Nivel 1</Form.Label>
                                        <Form.Select value={nivelLoc1} onChange={e=> {setNivelLoc1(e.target.value)}}>
                                            <option value={''}></option>
                                            <option className='fw-bold' value={'new'}>Novo Nivel</option>
                                            {nivelLoc1List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={12} md={6}>
                                        <Form.Label>Nivel 2</Form.Label>
                                        <Form.Select value={nivelLoc2} onChange={e=> {setNivelLoc2(e.target.value)}}>
                                            <option value={''}></option>
                                            <option className='fw-bold' value={'new'}>Novo Nivel</option>
                                            {nivelLoc2List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={12} md={6}>
                                        <Form.Label>Nivel 3</Form.Label>
                                        <Form.Select value={nivelLoc3} onChange={e=> {setNivelLoc3(e.target.value)}}>
                                            <option value={''}></option>
                                            <option className='fw-bold' value={'new'}>Novo Nivel</option>
                                            {nivelLoc3List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={12} md={6}>
                                        <Form.Label>Nivel 4</Form.Label>
                                        <Form.Select value={nivelLoc4} onChange={e=> {setNivelLoc4(e.target.value)}}>
                                            <option value={''}></option>
                                            <option className='fw-bold' value={'new'}>Novo Nivel</option>
                                            {nivelLoc4List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className="justify-content-end m-2 me-0">
                                    <Button as={Col} lg={2} variant="light" className="m-1" onClick={e=> {cleanUser()}}>CANCELAR</Button>
                                    {idU != '0' ? <Button as={Col} lg={2} variant="dark" className="m-1" onClick={e=> {deleteUser()}}>EXCLUIR</Button> : ''}
                                    <Button as={Col} lg={2} variant="custom-danger" className="m-1" onClick={e=> {saveUser()}}>SALVAR</Button>
                                </Row>
                            </Col>
                        :
                            <Col className="d-flex align-items-center justify-content-center flex-column">
                                <div>Clique em "Criar Novo Usuário" ou selecione um para editar</div>
                                <Button
                                    variant="dark"
                                    onClick={e=>{setShowEditUser(true)}}
                                >Criar Novo Usuário</Button>
                            </Col>
                        }
                    </Row>
                )
                break
            case 2:
                return (
                    <Col className="m-3">
                        <Row className="g-3">
                            <Form.Group as={Col} md={6}>
                                <Form.Label>Modelo</Form.Label>
                                <Form.Select value={modeloL} onChange={e=> {setModeloL(e.target.value); setUnsave(true)}}>
                                    <option value=""></option>
                                    <option value="blu">Bluebird</option>
                                    <option value="dot">Dot 900</option>
                                    <option value="301">i300</option>
                                    <option value="bu1">Clock B1</option>
                                    <option value="tsl">Tsl 1128</option>
                                    <option value="imp">Impinj</option>
                                    <option value="inn">InovaCode</option>
                                    <option value="mdl">Midleware</option>
                                    <option value="nfc">NFC</option>  
                                    <option value="ebc">eBeacon</option> 
                                    <option value="ter">Terminal</option> 
                                    <option value="csv">CSV</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={6}>
                                <Form.Label>Serial</Form.Label>
                                <Form.Control type="text" value={serialL} onChange={e=> {setSerialL(e.target.value); setUnsave(true)}}/>
                            </Form.Group>
                            <Form.Group as={Col} md={6}>
                                <Form.Label>Limite RSSI</Form.Label>
                                <Form.Control type="number" min={0} value={limiteRssi} onChange={e=> {setLimiteRssi(e.target.value); setUnsave(true)}}/>
                            </Form.Group>
                            <Form.Group as={Col} md={6}>
                                <Form.Label>Quantidade Fantasma</Form.Label>
                                <Form.Control type="number" min={0} value={quantidadeFantasma} onChange={e=> {setQuantidadeFantasma(e.target.value); setUnsave(true)}}/>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>Potência</Form.Label>
                                <Form.Select value={potenciaL} onChange={e=> {setPotenciaL(e.target.value); setUnsave(true)}}>
                                    <option value=""></option>
                                    <option value="5">Baixa</option>
                                    <option value="20">Média</option>
                                    <option value="30">Alta</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>Seção</Form.Label>
                                <Form.Select value={seccaoL} onChange={e=> {setSeccaoL(e.target.value); setUnsave(true)}}>
                                    <option value=""></option>
                                    <option value="0">Seção 0</option>
                                    <option value="1">Seção 1</option>
                                    <option value="2">Seção 2</option>
                                    <option value="3">Seção 3</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>Estado</Form.Label>
                                <Form.Select value={estadoL} onChange={e=> {setEstadoL(e.target.value); setUnsave(true)}}>
                                    <option value=""></option>
                                    <option value="0">A</option>
									<option value="1">A~B</option>
									<option value="2">B</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Impressora</Form.Label>
                                <Form.Control type="text" value={impressoraL} onChange={e=> {setImpressoraL(e.target.value); setUnsave(true)}}/>
                            </Form.Group>
                        </Row>
                    </Col>
                )
                break
            case 3:
                return (
                    <Col className="m-3">
                        <Row className="g-3">
                            <Form.Group as={Col} md={6}>
                                <Form.Label>Conversão de Prefixo</Form.Label>
                                <Form.Select value={prefixType} onChange={e=> {setPrefixType(e.target.value)}}>
                                    <option value="">Sem conversão</option>
                                    <option value="hexa">Hexadecimal</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={6}>
                                <Form.Label>Prefixo</Form.Label>
                                <Form.Control value={prefixText} onChange={e=>{setPrefixText(e.target.value)}}/>
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group as={Col} sm={12} md={2}>
                                <Form.Label>Padrão</Form.Label>
                                <Form.Select value={padraoI} onChange={e=> {setPadraoI(e.target.value)}}>
                                    <option value="0">Nenhuma</option>
                                    <option value="manual">Manual</option>
                                    <option value="gtin">GTIN</option>
                                    <option value="sgtin">sGTIN</option>
                                    <option value="one">InovaOne</option>
                                    <option value="epc">EPC API</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Verificador Inicial</Form.Label>
                                <Form.Control type="text" value={verificadorInicialI} onChange={e=> {setVerificadorInicialI(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Posição</Form.Label>
                                <Form.Control type="text" value={posicaoI} onChange={e=> {setPosicaoI(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Comprimento</Form.Label>
                                <Form.Control type="text" value={comprimentoI} onChange={e=> {setComprimentoI(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Corte Tag</Form.Label>
                                <Form.Control type="text" value={corteTag} onChange={e=> {setCorteTag(e.target.value)}}/>
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group as={Col}>
                                <Form.Label>Inf. Complementar 1</Form.Label>
                                <Form.Control type="text" value={infCompl1I} onChange={e=> {setInfCompl1I(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Inf. Complementar 2</Form.Label>
                                <Form.Control type="text" value={infCompl2I} onChange={e=> {setInfCompl2I(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Inf. Complementar 3</Form.Label>
                                <Form.Control type="text" value={infCompl3I} onChange={e=> {setInfCompl3I(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Inf. Complementar 4</Form.Label>
                                <Form.Control type="text" value={infCompl4I} onChange={e=> {setInfCompl4I(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Inf. Complementar 5</Form.Label>
                                <Form.Control type="text" value={infCompl5I} onChange={e=> {setInfCompl5I(e.target.value)}}/>
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group as={Col}>
                                <Form.Label>Inf. Complementar 6</Form.Label>
                                <Form.Control type="text" value={infCompl6I} onChange={e=> {setInfCompl6I(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Inf. Complementar 7</Form.Label>
                                <Form.Control type="text" value={infCompl7I} onChange={e=> {setInfCompl7I(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Inf. Complementar 8</Form.Label>
                                <Form.Control type="text" value={infCompl8I} onChange={e=> {setInfCompl8I(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Inf. Complementar 9</Form.Label>
                                <Form.Control type="text" value={infCompl9I} onChange={e=> {setInfCompl9I(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Inf. Complementar 10</Form.Label>
                                <Form.Control type="text" value={infCompl10I} onChange={e=> {setInfCompl10I(e.target.value)}}/>
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Form.Group as={Col}>
                                <Form.Label>Chave de Acesso ROTAS</Form.Label>
                                <Form.Control type="text" value={sincronizarRotas} onChange={e=> {setSincronizarRotas(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Campos Obrigatórios</Form.Label>
                                <FormControl sx={{width: '100%' }}>
                                    <InputLabel id="demo-multiple-checkbox-label">Selecione os campos</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        className="text-capitalize bg-white"
                                        size="small"
                                        multiple
                                        value={obrigatorio}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Selecione os campos" />}
                                        renderValue={(selected) => selected.join(', ').replace(/_/g, ". ")}
                                        MenuProps={MenuProps}
                                    >
                                        {names.map((name, index) => (
                                            <MenuItem key={name} value={name}>
                                                <Checkbox checked={obrigatorio.indexOf(name) > -1} />
                                                <ListItemText className="text-capitalize" primary={name.replace(/_/g, ". ")} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Form.Group>
                        </Row>
                    </Col>
                )
                break
            case 4:
                return (
                    <div className="list_account">
                        <div>
                            <div className="export" style={{display: 'flex', flexDirection:'row'}}>
                                <button onClick={e => {setTheme('light')}}>Light Mode</button>
                                <button onClick={e => {setTheme('dark')}}>Dark Mode</button>
                            </div>
                        </div>
                        <div>
                            <div className="export">
                                <button onClick={e => {getCollorBase()}}>Cores Padrão</button>
                            </div>
                        </div>
                        <div>
                            <div>
                                <Form.Label>Paleta 1</Form.Label>
                                <input type="color" value={pallet_1} style={{height: '200px'}} onChange={e=> {setPallet_1(e.target.value)}}/>
                            </div>
                            <div>
                                <Form.Label>Paleta 2</Form.Label>
                                <input type="color" value={pallet_2} style={{height: '200px'}} onChange={e=> {setPallet_2(e.target.value)}}/>
                            </div>
                            <div>
                                <Form.Label>Paleta 3</Form.Label>
                                <input type="color" value={pallet_3} style={{height: '200px'}} onChange={e=> {setPallet_3(e.target.value)}}/>
                            </div>
                            <div>
                                <Form.Label>Paleta 4</Form.Label>
                                <input type="color" value={pallet_4} style={{height: '200px'}} onChange={e=> {setPallet_4(e.target.value)}}/>
                            </div>
                        </div>
                    </div>
                )
                break
            case 5:
                return (
                    <Col className="m-3">
                        <Row className="g-3">
                            <Form.Group>
                                <Form.Label>Modo</Form.Label>
                                <Form.Select value={modoA} onChange={e=> {setModoA(e.target.value)}}>
                                    <option value=""></option>
                                    <option value="local">Local</option>
                                    <option value="one">InovaOne</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Endereço</Form.Label>
                                <Form.Control type="text" value={enderecoA} onChange={e=> {setEnderecoA(e.target.value)}}/>
                            </Form.Group>
                            {false?<Form.Group>
                                <Form.Label>Token</Form.Label>
                                <Form.Control type="text" value={'tokenA'} onChange={e=> {'setTokenA(e.target.value)'}}/>
                            </Form.Group>:''}
                        </Row>
                    </Col>
                )
                break
            case 6:
                return (
                    <Col className="mx-3">
                        <Row className="mt-5 justify-content-center fw-bold">
                            <Col lg={3} className="bg-white rounded m-2 guideblock shadow-sm" onClick={e=>{window.open('https://drive.google.com/drive/folders/1RJvB3bs5hF4M1WRsU8DHNb8PTlO6Yu7B?usp=share_link')}}>
                                <BiBarcodeReader fontSize={64} className="mb-3"/>
                                Guia Leitores
                            </Col>
                            <Col lg={3} className="bg-white rounded m-2 guideblock shadow-sm" onClick={e=>{window.open('https://docs.google.com/spreadsheets/d/1JmwKcYbssqSlgrTw5D7FpyR_Av9LFLUN/edit#gid=1591511872')}}>
                                <BiFile fontSize={64} className="mb-3"/>
                                Guia Layout de Importação
                            </Col>
                            
                            <Col lg={3} className="bg-white rounded m-2 guideblock shadow-sm" onClick={e=>{window.open('https://drive.google.com/drive/u/0/folders/1zhNsNm4vRlxmVgGr0YVLwzXO5gCRliRG')}}>
                                <BiPrinter fontSize={64} className="mb-3"/>
                                Guia de Impressão
                            </Col>
                            <Col lg={3} className="bg-white rounded m-2 guideblock shadow-sm" onClick={e=>{window.open('https://drive.google.com/drive/folders/1mAop5WfB1GgU6YnwfY9haCF1JYKV7QQK?usp=share_link')}}>
                                <BiBook fontSize={64} className="mb-3"/>
                                Guia InovaOne
                            </Col>
                            <Col lg={3} className="bg-white rounded m-2 guideblock shadow-sm" onClick={e=>{window.open('https://drive.google.com/drive/folders/1IxVEuThtv_xaZYu0AzbpIcuhS0ANZZ_k?usp=share_link')}}>
                                <BiPurchaseTag fontSize={64} className="mb-3"/>
                                Guia Aplicação de Etiquetas
                            </Col>
                            <Col lg={3} className="bg-white rounded m-2 guideblock shadow-sm" onClick={e=>{window.open('/user_guide')}}>
                                <BiUser fontSize={64} className="mb-3"/>
                                Guia de Usuários
                            </Col>
                        </Row>
                    </Col>
                )
                break
            case 8:
                return(
                    <Col className='m-3 p-5 border bg-white rounded'>
                        <h3 className='fw-bold m-0 p-0'>Workspace</h3>
                        <p className='text-secondary'>Customizar area de trabalho</p>
                        <Row className='mt-5 align-items-center'>
                            <Col sm={2}>   
                                <h5 className="fw-bold p-0 m-0">Menu lateral</h5>
                                <p className="text-secondary">Alterar itens do menu</p>
                                <Button variant="outline-primary" onClick={e=>setCustomizeMenu(true)}>Personalizar</Button>
                                <Modal show={customizeMenu} onHide={e=>{setCustomizeMenu(true)}} size="lg" centered>
                                    <Modal.Header closeButton>
                                    <Modal.Title>Customizar menu lateral</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row>
                                            <Col className='border-end'>
                                                <h6 className='fw-semibold'>Módulos disponíveis</h6>
                                                <Form.Check className="mb-2" value="inventarios" label="Inventários" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'inventarios')}/>
                                                <Form.Check className="mb-2" value="inventarios_externo" label="Inventários Externos" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'inventarios_externo')}/>
                                                <Form.Check className="mb-2" value="coletas" label="Coletas" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'coletas')}/>
                                                <Form.Check className="mb-2" value="itens" label="Itens" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'itens')}/>
                                                <Form.Check className="mb-2" value="categorias" label="Categorias" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'categorias')}/>
                                                <Form.Check className="mb-2" value="localizacoes" label="Localizações" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'localizacoes')}/>
                                                <Form.Check className="mb-2" value="parceiros" label="Parceiros" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'parceiros')}/>
                                                <Form.Check className="mb-2" value="impressoes" label="Impressões" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'impressoes')}/>
                                                <Form.Check className="mb-2" value="ordem_impressao" label="Ordem de impressão" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'ordem_impressao')}/>
                                                <Form.Check className="mb-2" value="leitores" label="Leitores" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'leitores')}/>
                                                <Form.Check className="mb-2" value="impressoras" label="Impressoras" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'impressoras')}/>
                                                <Form.Check className="mb-2" value="tipos_registros" label="Tipos de Registros" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'tipos_registros')}/>
                                                <Form.Check className="mb-2" value="balancete" label="Balancete" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'balancete')}/>
                                                <Form.Check className="mb-2" value="mapas" label="Mapas" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'mapas')}/>
                                                <Form.Check className="mb-2" value="monitoramento" label="Monitoramento" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'monitoramento')}/>
                                                <Form.Check className="mb-2" value="logs" label="Logs" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'logs')}/>
                                                <Form.Check className="mb-2" value="bi" label="BI" onChange={handleCheckboxClick} checked={menuItens.find(item =>  item == 'bi')}/>
                                            </Col>
                                            <Col>
                                                <h6 className='fw-semibold py-3'>Módulos selecionados</h6>
                                                {menuItens.map((menu)=>(
                                                    <div className="p-3 shadow-sm border d-flex justify-content-between rounded mb-3">
                                                        <p className="m-0 p-0 text-capitalize">{menu}</p>
                                                        <button onClick={()=> setMenuItens(menuItens.filter(menuItens => menuItens !== menu))} className='btn rounded-circle d-flex justify-content-center align-items-center bg-secondary bg-opacity-10 p-0' style={{width: '24px', height:'24px'}}><CgClose className="text-secondary"/></button>
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="dark" onClick={e=>{setCustomizeMenu(false)}}>
                                            Fechar
                                        </Button>
                                        <Button variant="outline-primary px-3" onClick={e=>{saveMenu()}}>
                                            Salvar
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </Col>
                            <Col sm={2}>
                                <img src={imgIcon} width="150px"></img>
                            </Col>
                        </Row>
                    </Col>
                )
                break
            default:
                return(
                    <div>Error</div>
                )
                break
        }
    }

    function buttonHidden(key) {
        if(division != 1){
            return (
                <Col className="d-flex flex-wrap justify-content-end">
                    <Button variant="light" id="clean" onClick={e=> {cleanBase()}}>LIMPAR BASE</Button>
                    <Button variant="custom-danger" className="ms-1" onClick={e=> {saveContaConfig()}}><FiSave className='me-2'/>SALVAR</Button>
                </Col>
            )
        }
    }

    function editUser(item) {
        cleanUser()
        setIdU(item._id)
        setNomeU(item.nome)
        setAtivoU(item.ativo)
        setPerfilU(item.perfil)
        setLoginU(item.login)
        setSenhaU(item.senha)
        setTagU(item.tag ? item.tag : '')
        setAdressU(item.endereco_impressora ? item.endereco_impressora : '')
        setShowEditUser(true)
        setNivelLoc1(item.niveis_acesso[0].niveis1[0].id_nivel_loc1)
        setNivelLoc2(item.niveis_acesso[0].niveis2[0].id_nivel_loc2)
        setNivelLoc3(item.niveis_acesso[0].niveis3[0].id_nivel_loc3)
        setNivelLoc4(item.niveis_acesso[0].niveis4[0].id_nivel_loc4)
        setCanCreateItem(item.perfil_personalizavel[0]?.permissoes[0]?.itens.criar)
        setCanExcludeItem(item.perfil_personalizavel[0]?.permissoes[0]?.itens.excluir)
        setCanEditItem(item.perfil_personalizavel[0]?.permissoes[0]?.itens.editar)
        setCanViewItem(item.perfil_personalizavel[0]?.permissoes[0]?.itens.visualizar)

    }

    function cleanUser() {
        setShowEditUser(false)
        setIdU('0')
        setNomeU('')
        setAtivoU('1')
        setPerfilU('admin')
        setLoginU('')
        setSenhaU('')
        setTagU('')
        setAdressU('')
        setNivelLoc1('')
        setNivelLoc2('')
        setNivelLoc3('')
        setNivelLoc4('')
    }

    function saveUser() {
        Swal.fire({
            title: "Atenção !",
            text: idU == '0' ? "Deseja realmente criar este usuário ?" : "Deseja realmente editar este usuário ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    if(nomeU==''){
                        Swal.fire({
                            title: "Ops... !",
                            text: 'Informe o nome do usuário',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    } else if(loginU==''){
                        Swal.fire({
                            title: "Ops... !",
                            text: 'Informe o login do usuário',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    } else {
                        let dt   = new Date();
                        let dt_reg  = dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2)
                        dt_reg     += " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" +dt.getMinutes()).slice(-2) + ":" + ("0" +dt.getSeconds()).slice(-2)
                        let reg = []
                        reg.push({
                            ativo: ativoU,
                            dt_acesso: dt_reg,
                            foto: "",
                            id_conta: sessionStorage.getItem('idConta'),
                            id_usuario: idU,
                            login: loginU,
                            nome: nomeU,
                            perfil: perfilU,
                            senha: senhaU,
                            tag: tagU,
                            endereco_impressora: adressU,
                            niveis_acesso: [{
                                niveis1:[{
                                    id_nivel_loc1: nivelLoc1
                                }],
                                niveis2:[{
                                    id_nivel_loc2: nivelLoc2
                                }],
                                niveis3:[{
                                    id_nivel_loc3: nivelLoc3
                                }],
                                niveis4:[{
                                    id_nivel_loc4: nivelLoc4
                                }],
                            }],
                            modulos_acesso: [userMenu],
                            perfil_personalizavel:[{
                                permissoes:[{
                                    itens:{
                                        criar: canCreateItem,
                                        editar: canEditItem,
                                        excluir: canExcludeItem,
                                        visualizar: canViewItem
                                    }
                                }]
                            }]
                        })
                        if(idU == sessionStorage.getItem('idUser')){
                            sessionStorage.setItem('perfil', perfilU)
                            sessionStorage.setItem('nivel1', nivelLoc1 ? nivelLoc1 : '')
                            sessionStorage.setItem('nivel2', nivelLoc2 ? nivelLoc2 : '')
                            sessionStorage.setItem('nivel3', nivelLoc3 ? nivelLoc3 : '')
                            sessionStorage.setItem('nivel4', nivelLoc4 ? nivelLoc4 : '')
                            sessionStorage.setItem('filterCreate', canCreateItem)
                            sessionStorage.setItem('filterEdit', canEditItem)
                            sessionStorage.setItem('filterExclude', canExcludeItem)
                            sessionStorage.setItem('filterView', canViewItem)
                        }
                        
                        
                        let option = {
                            headers: { 'Content-Type': ['application/json'] }
                        }
            
                        api.post('/usuario', reg, option
                        ).then(
                            response => {
                                usuarioGet()
                                Swal.fire({
                                    title: "Sucesso !",
                                    text: idU == '0' ? 'Usuário criado com sucesso' : 'Usuário editado com sucesso',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                                cleanUser()
                            },
                            response => {
                                Swal.fire({
                                    title: "Ops... !",
                                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                            }
                        )
                    }
                }
            }
        )
    }

    function deleteUser() {
        Swal.fire({
            title: "Atenção !",
            text: "Deseja realmente excluir este usuário ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
            
                    api.delete(`/usuario/${idU}`, option
                    ).then(
                        response => {
                            usuarioGet()
                            Swal.fire({
                                title: "Sucesso !",
                                text: 'Usuário foi excluido de sua base',
                                icon: 'success',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                            cleanUser()
                        },
                        response => {
                            Swal.fire({
                                title: "Ops... !",
                                text: 'Algo deu errado, por favor tente novamente mais tarde',
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }
                    )
                }
            }
        )
    }

    function saveContaConfig() {
        Swal.fire({
            title: "Atenção !",
            text: "Deseja atualizar informações de conta ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {

                    if(receberAlerta == false || (receberAlerta == true && telefoneC.indexOf('_') == -1)) {
                        let contaData = []
                        let contaConfigData = []
    
                        contaData.push({
                            ativo: ativoC,
                            cnpj: cnpjC,
                            dt_licenca: dtLicencaC,
                            dt_registro: dtRegistroC,
                            email: eMailC,
                            endereco: enderecoC,
                            foto: "",
                            id_conta: sessionStorage.getItem('idConta'),
                            nome: nomeC,
                            observacao: observacaoC,
                            telefone: telefoneC,
                            uuid: uuidC
                        })
                        contaConfigData.push({
                            _id: idCC,
                            api_endereco: enderecoA,
                            api_modo: modoA,
                            //api_token: tokenA,
                            id_conta: sessionStorage.getItem('idConta'),
                            id_conta_config: idConfig,
                            impressora_serial:impressoraL,
                            info_compl_1: infCompl1I,
                            info_compl_2: infCompl2I,
                            info_compl_3: infCompl3I,
                            info_compl_4: infCompl4I,
                            info_compl_5: infCompl5I,
                            info_compl_6: infCompl6I,
                            info_compl_7: infCompl7I,
                            info_compl_8: infCompl8I,
                            info_compl_9: infCompl9I,
                            info_compl_10: infCompl10I,
                            prefix_type: prefixType,
                            prefix_text: prefixText,
                            item_comprimento: comprimentoI,
                            item_padrao: padraoI,
                            item_posicao: posicaoI,
                            item_verif_inicial: verificadorInicialI,
                            corte_tag: corteTag,
                            leitor_estado: estadoL,
                            leitor_modelo: modeloL,
                            leitor_potencia: potenciaL,
                            leitor_secao: seccaoL,
                            leitor_serial: serialL,
                            limite_rssi: limiteRssi,
                            quantidade_fantasma: quantidadeFantasma,
                            receber_alerta: receberAlerta == '1' ? true : false,
                            obrigatorio: JSON.stringify(obrigatorio),
                            token_id_rotas: sincronizarRotas
                        })
    
                        let option = {
                            headers: { 'Content-Type': ['application/json'] }
                        }
    
                        api.post('/conta', contaData, option
                        ).then(
                            response => {
                                api.post('/conta_config', contaConfigData, option
                                ).then(
                                    response => {
                                        Swal.fire({
                                            title: "Sucesso !",
                                            text: 'Os dados foram atualizados',
                                            icon: 'success',
                                            showConfirmButton: false,
                                            timerProgressBar: true,
                                            timer: '2500'
                                        })
                                    },
                                    response => {
                                        Swal.fire({
                                            title: "Ops... !",
                                            text: 'Algo deu errado, por favor tente novamente mais tarde',
                                            icon: 'error',
                                            showConfirmButton: false,
                                            timerProgressBar: true,
                                            timer: '2500'
                                        })
                                    }
                                )
                            },
                            response => {
                                Swal.fire({
                                    title: "Ops... !",
                                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                            }
                        )
                    }else{
                        Swal.fire({
                            title: "Ops... !",
                            html: 'Para ativar o Registro Externo por Whatsapp,</br>Preencha corretamente o numero de telefone.',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }
                }
            }
        )
    }

    function cleanBase() {
        Swal.fire({
            title: "Atenção !",
            text: "Deseja realmente limpar a base ?",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: "#595c5f",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
                    api.post(`/create2fa/${sessionStorage.getItem('idConta')}?emailSend=${eMailC}`, option
                    ).then(
                        response => {
                            console.log(response)
                        }
                    )
                    Swal.fire({
                        title: 'Código de Verificação',
                        html: 'Enviamos um código de verificação por Email, por favor insira o código',
                        input: 'text',
                        icon: "info",
                        inputAttributes: {
                        autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: "#595c5f",
                        cancelButtonText: "Fechar",
                        confirmButtonText: "Enviar",
                        preConfirm: (code) =>{
                            let option = {
                                headers: { 'TOKENKEY': [code] }
                            }
                            api.delete(`conta/${sessionStorage.getItem('idConta')}`, option
                            ).then(
                                response => {
                                    Swal.fire({
                                        title: "Sucesso !",
                                        text: 'Base limpa com sucesso',
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '2500'
                                    })
                                    setLoading(false)
                                },
                                response => {
                                    Swal.fire({
                                        title: "Ops... !",
                                        text: 'Código incorreto',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '2500'
                                    })
                                    setLoading(false)
                                }
                            )
                        }
                    })
                }
            }
        )
    }

    return (
        <div className="mainContainer"
            style={JSON.parse(sessionStorage.getItem('collors'))}
        >
            <Menu/>
            <div id="background" className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                <Container fluid className="m-0 p-0">
                    <Row className="my-3">
                        <Col><h3>Minha Conta</h3></Col>
                        {buttonHidden(division)}
                    </Row>
                    {loading ?
                        <div className='extra'>
                            <div style={{marginLeft: 'calc(50vw - 75px)', marginTop: 'calc(50vh - 75px)', minHeight: 'auto', height: '200px'}}>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                </div>
                            </div>
                        </div>
                    : ''}
                    <ul className="nav custom-nav text-secondary">
                        <Button variant="nav" onClick={e=>setDivision(0)}>Cadastro</Button>
                        <Button variant="nav" onClick={e=>setDivision(8)}>Workspace</Button>
                        <Button variant="nav" onClick={e=>setDivision(1)}>Usuários</Button>
                        <Button variant="nav" onClick={e=>setDivision(2)}>Leitor</Button>
                        <Button variant="nav" onClick={e=>setDivision(3)}>Item</Button>
                        {false ? <Button variant="nav" onClick={e=>setDivision(4)}>Estilo</Button>:null}
                        <Button variant="nav" onClick={e=>setDivision(5)}>API</Button>
                        {true?<Button variant="nav" onClick={e=>setDivision(6)}>Guia</Button>:null}
                    </ul>

                    { division != 2 && unsave ? 
                        unSaveChanges()
                    :''}

                    {renderSwitch(division)}
                </Container>
            </div>
        </div>
    )
}

export default Account