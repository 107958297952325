import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import Menu from '../../components/Menu'
import api from '../../services/api'

import {ImCompass2} from 'react-icons/im'
import {GoLocation, GoStop} from 'react-icons/go'

import { Container, Row, Col, Button, Table, Form } from 'react-bootstrap';

import { useTranslation } from 'react-i18next'

import {GoogleMap, useLoadScript, Marker, InfoWindow} from '@react-google-maps/api'
import mapStyle from '../../mapstyle'
const libraries = ['places']
const mapContainerStyle = {
    width: '100%',
    height: '600px'
}
const optionsMap = {
    //styles: mapStyle
}

function Maps() {
    const { t } = useTranslation();

    const [leitorList, setLeitorList] = useState([])
    const [intervaloList, setIntervaloList] = useState([])
    const [leitorFilter, setLeitorFilter] = useState('*')
    const [dataInicioFilter, setDataInicioFilter] = useState(dates(31, '-'))
    const [dataFimFilter, setDataFimFilter] = useState(dates(1, '+'))
    const [intervaloFilter, setIntervaloFilter] = useState('*')
    const [tagTableList, setTagTableList] = useState([])

    const [showMap, setShowMap] = useState(false)
    const [markList, setMarkList] = useState([])
    const [selectedMark, setSelectedMark] = useState(null)
    const [selectedMark2, setSelectedMark2] = useState(null)

    const history = useNavigate()

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_API_G_KEY,
        libraries,
    })

    useEffect(() => {
        getLeitor()
    }, [])

    useEffect(() => {
        getItems()
    }, [dataInicioFilter, dataFimFilter, leitorFilter, intervaloFilter])

    if(loadError) return (
        <div className="mainContainer"
        style={JSON.parse(sessionStorage.getItem('collors'))}
        >
            <Menu/>
            <div id="background">
                Error loading maps
            </div>
        </div>
    )
    if(!isLoaded) return (
        <div className="mainContainer"
        style={JSON.parse(sessionStorage.getItem('collors'))}
        >
            <Menu/>
            <div id="background">
            </div>
        </div>
    )

    function dates(days, direction) {
        let dt   = new Date()
        if(direction == '+') {
            dt = new Date(dt.getTime() + (days * 24 * 60 * 60 * 1000))
        }
        else if(direction == '-') {
            dt = new Date(dt.getTime() - (days * 24 * 60 * 60 * 1000))
        }
        return (dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2))
    }

    function getItems() {
        api.get(`/registro/${sessionStorage.getItem('idConta')}/*/*/${dataInicioFilter}/${dataFimFilter}/*/*/*`
        ).then(
            response => {
                let regList = response.data.filter((item) => {
                    if(item.modo == 'p' || item.modo == 'l') return item
                })
                let regIds = regList.map((item) => {
                    return item._id
                })

                api.get(`/registro_tag_date/${sessionStorage.getItem('idConta')}/${dataInicioFilter}/${dataFimFilter}`).then(
                    async response2 => {
                        let lastRead = {}
                        let lastReadByTag = {}
                        let tagList = []
                        await response2.data.map((item) => {
                            if(regIds.includes(item.id_registro) && item.latitude && item.latitude != '') {
                                let itemFiltered = item

                                itemFiltered['regInfo'] = regList[regIds.indexOf(item.id_registro)]

                                if(item.regInfo.id_leitor && (leitorFilter == '*' || leitorFilter == itemFiltered.regInfo.id_leitor._id) && ((intervaloFilter == '*' || intervaloFilter == itemFiltered.regInfo.id_leitor.periodo))) {
                                    if(!(lastRead[item.regInfo.id_leitor._id])) {
                                        lastRead[item.regInfo.id_leitor._id] = [item.tag, item.dt_leitura]
                                    }else {
                                        if(new Date(item.dt_leitura) > new Date(lastRead[item.regInfo.id_leitor._id][1])) {
                                            lastRead[item.regInfo.id_leitor._id] = [item.tag, item.dt_leitura]
                                        }
                                    }

                                    if(!(lastReadByTag[item.tag])) {
                                        lastReadByTag[item.tag] = [item.dt_leitura]
                                    }else {
                                        if(new Date(item.dt_leitura) > new Date(lastReadByTag[item.tag][0])) {
                                            lastReadByTag[item.tag] = [item.dt_leitura]
                                        }
                                    }

                                    itemFiltered['status'] = {}
                                    let info = ""
                                    if(item.excedente == 1) {
                                        info = "Excedente"
                                    }
                                    if(item.auditado == 1) {
                                        info = "Auditado"
                                    }
                                    if(item.encontrado == 1 && item.excedente == 0) {
                                        info = "Encontrado"
                                    }
                                    if(item.encontrado == 0 && item.excedente == 0) {
                                        info = "Ausente"
                                    }
                                    if(item.incorporado == 1) {
                                        info = 'Incorporado'
                                    }
                                    itemFiltered.status['read'] = info
                                    itemFiltered.status['period'] = ''
                                    itemFiltered.status['last'] = false
                                    tagList.push(itemFiltered)
                                }
                            }
                        })
                        setTagTableList(tagList.map((item => {
                            let finalItem = item
                            if(
                                lastRead[item.regInfo.id_leitor._id][0] == item.tag
                                && lastRead[item.regInfo.id_leitor._id][1] == item.dt_leitura
                            ) {
                                finalItem.status.last = true
                            }

                            if(lastReadByTag[item.tag][0] == item.dt_leitura) {
                                if(Number(item.regInfo.encontrado) + Number(item.regInfo.excedente) >= 1) {
                                    let date1 = new Date(item.dt_leitura)
                                    let date2 = new Date()
                                    let diffTime = Math.abs(date2 - date1)
                                    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) 
                                    finalItem.status.period = diffDays
                                }else{
                                    finalItem.status.period = -1
                                }
                            }

                            return finalItem
                        })).reverse())
                    }
                )
            }
        )
    }

    function getLeitor() {
        api.get(`/leitor/${sessionStorage.getItem('idConta')}/*/*/*/1`).then(
            response => {
                let periodoLista = []
                response.data.filter(item => {
                    if((item.periodo) && (item.periodo > 0)) {return item}
                }).map(item => {
                    if(!periodoLista.includes(item.periodo)) {
                        periodoLista.push(item.periodo)
                    }
                })
                setIntervaloList(periodoLista)
                setLeitorList(response.data)
            }
        )
    }

    function colorGet(stat) {
        let color = ''
        if(stat == "Excedente") {
            color = '#A33BBF'
        }else if(stat == "Auditado") {
            color = '#08B08C'
        }else if(stat == "Encontrado") {
            color = '#4EA90B'
        }else if(stat == "Ausente") {
            color = '#B40C0C'
        }else if(stat == "Incorporado") {
            color = '#26C6DA'
        }

        return color
    }
    
    function colorReaderGet(days, period) {
        let color = ''
        if(days == -1) {
            color = '#B40C0C'
        }else{
            if ((period > 0) && (days > period)) {
                color = 'gray'
            }else if(period == 0 || period == null || ((period > 0) && (days < period))) {
                color = '#4EA90B'
            }
        }

        return color
    }

    function markersLoader(itensList) {
        window.scroll(0, 0)
        let preList = itensList.filter((item) => {
            if(item.longitude) {
                return item
            }
        })
        if(preList.length == 0) {
            window.alert('Itens não possuem localização')
            
        }else {
            setMarkList(preList)
            setShowMap(true)
        }
    }

    function markersUnloader() {
        setMarkList([])
        setShowMap(false)
        setSelectedMark(null)
    }

    function textReadReturn(period) {
        let textRead = ''
        if (period == 0 || period == null || period == '') {
            textRead = 'Não há periodo'
        }else {
            textRead = `Perido de ${period} dias`
        }

        return textRead
    }
    
    function textStatusReturn(days) {
        let textStatus = ''
        if(days == -1) {
            textStatus = 'Leitura ausente'
        }else{
            if(days == 0) {
                textStatus = `Lido hoje`
            }else if(days == 1){
                textStatus = `Lido há ${days} dia`
            }else{
                textStatus = `Lido há ${days} dias`
            }
        }

        return textStatus
    }

    return (
        <div className="mainContainer"
        style={JSON.parse(sessionStorage.getItem('collors'))}
        >
            <Menu/>
            <div id="background">
                <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                    <Container fluid>
                        {showMap ? (
                            <div className='extra'>
                                <div
                                    className='extracontainer'
                                    style={{
                                        marginLeft: 'calc(50% - 40%)',
                                        minHeight: 'auto',
                                        height: '660px',
                                        width: '80%'
                                    }}
                                >
                                    <div className="title">
                                        <h1>Mapa</h1>
                                        <div>
                                            <button onClick={e=> {markersUnloader()}}>FECHAR</button>
                                        </div>
                                    </div>
                                    <div style={{marginTop: '20px'}}>
                                        <GoogleMap
                                            className='mapStyles'
                                            mapContainerStyle={mapContainerStyle}
                                            zoom={18}
                                            center={{lat: Number.parseFloat(markList[0].latitude), lng: Number.parseFloat(markList[0].longitude)}}
                                        >
                                            {markList.map((item, index) => {
                                                if(item.longitude) {
                                                    return (
                                                        <Marker
                                                            key = {index}
                                                            position={{
                                                                lat: Number.parseFloat(item.latitude),
                                                                lng: Number.parseFloat(item.longitude)
                                                            }}
                                                            icon={{
                                                                url: `/iconMap_${item.status.read}.png`,
                                                                scaledsize: new window.google.maps.Size(32, 30)
                                                            }}
                                                            onClick= {e => {setSelectedMark(item)}}
                                                        />
                                                    )
                                                }
                                            })}

                                            {selectedMark && (
                                                <InfoWindow
                                                    position={{
                                                        lat: Number.parseFloat(selectedMark.latitude),
                                                        lng: Number.parseFloat(selectedMark.longitude)
                                                    }}
                                                    onCloseClick={e => {setSelectedMark(null)}}
                                                >
                                                    <div style={{textAlign: 'center'}}>
                                                        <h2>{selectedMark.descricao}</h2>
                                                        <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark.tag}</p>
                                                        <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark.latitude} {selectedMark.longitude}</p>
                                                    </div>
                                                </InfoWindow>
                                            )}
                                        </GoogleMap>
                                    </div>
                                </div>
                            </div>
                        ) : ''}
                        <Col className="my-3">
                            <h1>Mapas</h1>
                        </Col>
                        <Row className='flex-wrap'>
                            <Form.Group as={Col} sm={12} lg={4}>
                                <Form.Label>Leitor</Form.Label>
                                <Form.Select
                                    value={leitorFilter}
                                    onChange={e => {setLeitorFilter(e.target.value)}}
                                >
                                    <option value='*'>Todos</option>
                                    {leitorList.map((item, index) => (
                                        <option key={index} value={item._id}>{item.nm_leitor}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} sm={12} lg={4}>
                                <Form.Label>Data</Form.Label>
                                <div className="date">
                                    <Form.Control
                                        className='dinput m-1'
                                        type='date'
                                        value={dataInicioFilter}
                                        onChange={e => {setDataInicioFilter(e.target.value)}}
                                    />
                                    <Form.Control
                                        className='dinput m-1'
                                        type='date'
                                        value={dataFimFilter}
                                        onChange={e => {setDataFimFilter(e.target.value)}}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} sm={12} lg={4}>
                                <Form.Label>Periodo</Form.Label>
                                <Form.Select
                                    value={intervaloFilter}
                                    onChange={e => {setIntervaloFilter(e.target.value)}}
                                >
                                    <option value='*'>Todos</option>
                                    {intervaloList.map((item, index) => (
                                        <option key={index} value={item}>{`${item} dias`}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        {!showMap?<div style={{marginTop: '20px'}}>
                            <GoogleMap
                                className='mapStyles h-100'
                                mapContainerStyle={mapContainerStyle}
                                zoom={18}
                                center={{lat: Number.parseFloat(tagTableList[0] ? tagTableList[0].latitude: 0), lng: Number.parseFloat(tagTableList[0] ? tagTableList[0].longitude : 0)}}
                            >
                                {tagTableList.map((item, index) => {
                                    if(item.longitude) {
                                        return (
                                            <Marker
                                                key = {index}
                                                position={{
                                                    lat: Number.parseFloat(item.latitude),
                                                    lng: Number.parseFloat(item.longitude)
                                                }}
                                                icon={{
                                                    url: `/iconMap.png`,
                                                    scaledsize: new window.google.maps.Size(100, 100)
                                                }}
                                                onClick= {e => {setSelectedMark2(item)}}
                                            />
                                        )
                                    }
                                })}

                                {selectedMark2 && (
                                    <InfoWindow
                                        position={{
                                            lat: Number.parseFloat(selectedMark2.latitude),
                                            lng: Number.parseFloat(selectedMark2.longitude)
                                        }}
                                        onCloseClick={e => {setSelectedMark2(null)}}
                                    >
                                        <div style={{textAlign: 'center'}}>
                                            <h2>{selectedMark2.descricao}</h2>
                                            <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark2.tag}</p>
                                            <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark2.latitude} {selectedMark2.longitude}</p>
                                            <div style={{padding: '10px', background: colorGet(selectedMark2.status.read), width: 'auto', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}>{selectedMark2.status.read}</div>
                                            <div>{selectedMark2.dt_leitura}</div>
                                            {selectedMark2.status.period != '' ?
                                            <div
                                                style={{padding: '10px', background: colorReaderGet(selectedMark2.status.period, selectedMark2.regInfo.id_leitor.periodo), width: 'auto', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}
                                            >{textStatusReturn(selectedMark2.status.period)}</div>:''}
                                            <div style={{textAlign:'center'}}>{textReadReturn(selectedMark2.regInfo.id_leitor.periodo)}</div>
                                            {selectedMark2.status.last ?   
                                            <div
                                                style={{
                                                    padding: '10px', background: '#f7a22b', width: 'auto', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'
                                                }}
                                            ><GoStop/> Ultima Leitura</div>: ''}
                                            {selectedMark2.status.last ?<div style={{textAlign: 'center'}}>{`Leitor: ${selectedMark2.regInfo.id_leitor.nm_leitor}`}</div>:''}
                                        </div>
                                    </InfoWindow>
                                )}
                            </GoogleMap>
                        </div>:''}
                        <Table>
                            <thead>
                                <tr id="header">
                                    <th>#</th>
                                    <th>ITEM</th>
                                    <th>LOCAL</th>
                                    <th>STATUS DE LEITURA</th>
                                    <th>STATUS DO ITEM</th>
                                    <th>ULTIMA LEITURA</th>
                                    <th><ImCompass2 className="table_icons"/></th>
                                </tr>
                            </thead>
                            <tbody>
                                {tagTableList.map((item, index) => (
                                    <tr key={index} onClick={e=>{console.log(tagTableList)}}>
                                        <th>{index + 1}</th>
                                        <th>
                                            <div>{item.descricao}</div>
                                            <div>{item.tag}</div>
                                        </th>
                                        <th>
                                            <div>{item.id_item && item.id_item.id_nivel_loc_1 ? item.id_item.id_nivel_loc_1.descricao : 'N / A'}</div>
                                            {item.id_item && item.id_item.id_nivel_loc_1 ? <div>{item.id_item.id_nivel_loc_2 ? item.id_item.id_nivel_loc_2.descricao : 'N / A'}</div> : ''}
                                            {item.id_item && item.id_item.id_nivel_loc_2 ? <div>{item.id_item.id_nivel_loc_3 ? item.id_item.id_nivel_loc_3.descricao : 'N / A'}</div> : ''}
                                            {item.id_item && item.id_item.id_nivel_loc_3 ? <div>{item.id_item.id_nivel_loc_4 ? item.id_item.id_nivel_loc_4.descricao : 'N / A'}</div> : ''}
                                        </th>
                                        <th>
                                            <div style={{padding: '10px', background: colorGet(item.status.read), width: '120px', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}>{item.status.read}</div>
                                            <div>{item.dt_leitura}</div>
                                        </th>
                                        {item.status.period != '' ? <th>
                                            <div
                                                style={{
                                                    padding: '10px',
                                                    background: colorReaderGet(item.status.period, item.regInfo.id_leitor.periodo),
                                                    width: 'auto',
                                                    textAlign: 'center',
                                                    borderRadius: '10px',
                                                    color: '#F8F8F8',
                                                    fontWeight: 'bold'
                                                }}
                                            >{textStatusReturn(item.status.period)}</div>
                                            <div style={{textAlign:'center'}}>{textReadReturn(item.regInfo.id_leitor.periodo)}</div>
                                        </th> : <th></th>}
                                        {item.status.last ?
                                            <th>        
                                                <div
                                                    style={{
                                                        padding: '10px',
                                                        background: '#f7a22b',
                                                        width: 'auto',
                                                        textAlign: 'center',
                                                        borderRadius: '10px',
                                                        color: '#F8F8F8',
                                                        fontWeight: 'bold'
                                                    }}
                                                ><GoStop/> Ultima Leitura</div>
                                                <div style={{textAlign: 'center'}}>{`Leitor: ${item.regInfo.id_leitor.nm_leitor}`}</div>
                                            </th>
                                        : <th></th>}
                                        <th>{item.longitude ? <GoLocation className="table_icons show_map" onClick={e => {markersLoader([item])}}/> : ''}</th>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default Maps