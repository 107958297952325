import * as React from 'react';

import Button from '@mui/material/Button'

import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';

import DialogTitle from '@mui/material/DialogTitle';

import DialogContent from '@mui/material/DialogContent';

import DialogActions from '@mui/material/DialogActions';

import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';

import { Icon } from '@iconify/react';

import { Box, Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: 500
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function CustomModal({ title, message, type, icon, children, onClose, onConfirm, onSend, onDeny }) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose(null)
  };

  const handleConfirm = () => {
    setOpen(false);
    onConfirm(null)
  };

  const handleSend = () => {
    setOpen(false);
    onSend(null)
  };

  const handleDeny = () => {
    setOpen(false);
    onDeny(null)
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        open={open}
      >
        {title ? 
            <DialogTitle>
                <Icon icon={icon ?? "ic:round-warning"} style={{marginRight: 8}}/>
                {title}
            </DialogTitle>
        : ''}

        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Icon icon='line-md:close' />
        </IconButton>

        <DialogContent dividers={true}>
            {children}
        </DialogContent>

        <DialogActions>
            { onDeny ? <Button variant="outlined" color="dark" onClick={handleDeny}>{t('actions.cancel')}</Button> : ''}
            { onConfirm ? <Button variant="contained" color="error" onClick={handleConfirm}>{t('actions.continue')}</Button> : ''}
            { onSend ? <Button startIcon={<Icon icon='tabler:send'/>} variant="contained" onClick={handleSend}>{t('actions.send')}</Button> : ''}
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
