import React, {useState, useEffect} from 'react'
import ReactLoading from 'react-loading'

import Swal from 'sweetalert2'

import {FaFileCsv, FaFilePdf} from 'react-icons/fa'
import {parse} from 'papaparse'

import api from '../../services/api'
import Menu from '../../components/Menu'

import './style.css'
import ExportCSV from '../../components/exportCSV'
import ExportPDF from '../../components/exportPDF'
import { Container, Row, Col, Button, Table, Form } from 'react-bootstrap';

import { useTranslation } from 'react-i18next'

function Types() {
    const { t } = useTranslation();

    const [items, setItems] = useState([])
    const [descricao, setDescricao] = useState('*')
    const [sigla, setSigla] = useState('*')
    const [ativo, setAtivo] = useState('1')
    const [idTipoReg, setIdTipoReg] = useState('0')
    const [idExterno, setIdExterno] = useState('')
    const [descricaoNew, setDescricaoNew] = useState('')
    const [siglaNew, setSiglaNew] = useState('')
    const [ativoNew, setAtivoNew] = useState('1')
    const [change, setChange] = useState(0)
    const [editing, setEditing] = useState(0)
    const [importList, setImportList] = useState([])
    const [uploaded, setUploaded] = useState(0)
    const [itemsLoaded, setItemsLoaded] = useState(true)

    const fileTypes = ["text/csv", "application/vnd.ms-excel", "text/plain"]
    const headersCSV = [
        "COD_EXTERNO",
        "ATIVO",
        "DESCRIÇÃO",
        "SIGLA"
    ]

    useEffect(() => {
        tipos()
    }, [descricao, sigla, ativo])

    function tipos() {
        setItemsLoaded(false)
        api.get(`/tipo_registro/${sessionStorage.getItem('idConta')}/*/${descricao}/${sigla}/${ativo}`, {}
        ).then(response => {setItems(response.data)}).finally(
            () => {setItemsLoaded(true)}
        )
    }

    function csv() {
        let body = items.map((item, index) => {
            return [
                item.id_externo ? item.id_externo : '',
                item.ativo ? item.ativo : '',
                item.descricao ? item.descricao : '',
                item.sigla ? item.sigla : ''
            ]
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
        return [...body2]
    }
    //

    async function chooseFileImport(file) {
        const text = await file.text()
        const result = parse(text)
        const filter = result.data.filter(item => {
            if(!(item.length <= 1)) {return item}
        })
        setImportList(filter)
    }

    function changeFunction(key) {
        switch (key) {
            case 0:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <h3>Tipos de Registros</h3>
                                </Col>
                            </Row>
                            <Col className="border rounded bg-white p-3">
                                <Row>
                                    <Col>
                                        <h5>Registros</h5>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <Button variant="light" className="text-uppercase" onClick={e=> {setChange(6)}}><FaFilePdf className="me-2"/>{t('actions.generate')} PDF</Button>
                                        <Button variant="light" className="ms-2 text-uppercase" onClick={e=> {setChange(5)}}><FaFileCsv className="me-2"/>{t('actions.generate')} CSV</Button>
                                        <Button variant="outline-custom-primary" className="ms-2 text-uppercase" onClick={e => setChange(1)}>{t('actions.import')}</Button>
                                        <Button variant="custom-primary" className=" ms-2 text-uppercase" onClick={e => setChange(2)}>+ {t('actions.create')}</Button>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col}>
                                        <Form.Label>Ativo</Form.Label>
                                        <Form.Select
                                            value={ativo}
                                            onChange={e=> {setAtivo(e.target.value)}}
                                        >
                                            <option value={'*'}>Todos</option>
                                            <option value={'1'}>Sim</option>
                                            <option value={'0'}>Não</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Descrição</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={e=> {setDescricao(e.target.value == '' ? '*' : e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Sigla</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={e=> {setSigla(e.target.value == '' ? '*' : e.target.value)}}
                                        />
                                    </Form.Group>
                                </Row>
                                {!itemsLoaded ?
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                    </div>
                                :
                                    <>
                                        <Table>
                                            <thead>
                                                <tr id="header">
                                                    <th>#</th>
                                                    <th>TIPO</th>
                                                    <th>SIGLA</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {items.map((item, index) => (
                                                    <tr className='edit' key={index} onClick={e => {editItem(item)}}>
                                                        <th>{index + 1}</th>
                                                        <th>{item.descricao}</th>
                                                        <th>{item.sigla}</th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </>
                                }
                            </Col>
                        </Container>
                    </div>
                )
                break
            case 1:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <h5>Registros</h5>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <Button variant="light" className="me-2" onClick={e=> {setChange(6)}}><FaFilePdf className="ms-2"/>{t('actions.generate')} PDF</Button>
                                    <Button variant="custom-primary" onClick={e=> {setChange(5)}}><FaFileCsv className="ms-2"/>{t('actions.create')} CSV</Button>
                                </Col>
                            </Row>
                        </Container>
                        <div className="title" id="colection">
                            <h1>Tipos de Registros</h1>
                            <div>
                                <button onClick={e=> {importSave()}}>IMPORTAR</button>
                                <button onClick={e => {
                                    tipos()
                                    cleaner()
                                }}>TIPOS</button>
                            </div>
                        </div>
                        <div className="filters">
                            <Form.Group as={Col}>
                                <Form.Label>Arquivo</Form.Label>
                                <input id='dropFile' style={{display: 'none'}} type='file' accept={fileTypes} onChange={e=> {
                                    e.preventDefault()
                                    if(e.target.files[0]) {
                                        chooseFileImport(e.target.files[0])
                                    }
                                }}/>
                                <div
                                    className="importacao"
                                    onDragOver={e=> {e.preventDefault()}}
                                    onDrop={e=> {
                                        e.preventDefault()
                                        console.log(e.dataTransfer.files[0])
                                        if(fileTypes.includes(e.dataTransfer.files[0].type))
                                        chooseFileImport(e.dataTransfer.files[0])
                                    }}
                                    onClick={e=>{
                                        let getFile = window.document.getElementById('dropFile')
                                        getFile.click()
                                    }}
                                >
                                    Arraste ou selecione um arquivo .CSV ou .TXT aqui
                                </div>
                            </Form.Group>
                        </div>
                        <div className="pagesContent" style={{marginTop: '10px'}}>
                            <div className="numberPages">Itens: {importList.length}</div>
                            <div className="numberPages">Importados: {uploaded}</div>
                        </div>
                        <div className="list">
                            <table>
                                <thead>
                                    <tr id="header">
                                        <th>#</th>
                                        <th>CÓD. EXTERNO</th>
                                        <th>ATIVO</th>
                                        <th>DESCRIÇÃO</th>
                                        <th>SIGLA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {importList.map((item, index) => (
                                        <tr key={index} style={index + 1 <= uploaded ? {background: '#919191', color: '#F8F8F8'} : {}}>
                                            <th>{index + 1}</th>
                                            <th>{item[0]}</th>
                                            <th>{item[1]}</th>
                                            <th>{item[2]}</th>
                                            <th>{item[3]}</th>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
                break
            case 2:
                return(
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <div className="title" id="colection">
                            <h1>Tipos de Registros</h1>
                            <div>
                                {editing == 1 ? <button onClick={e=> {remove()}}>EXCLUIR</button> : ''}
                                <button onClick={e=> {save()}}>SALVAR</button>
                                <button onClick={e => {cleaner()}}>VOLTAR</button>
                            </div>
                        </div>
                        <div className="list_account">
                            <div>
                                <div>
                                    <Form.Label>Descrição</Form.Label>
                                    <input
                                        type="text"
                                        value={descricaoNew}
                                        onChange={e=> {setDescricaoNew(e.target.value)}}
                                    />
                                </div>
                                <div>
                                    <Form.Label>Sigla</Form.Label>
                                    <input
                                        type="text"
                                        value={siglaNew}
                                        onChange={e=> {setSiglaNew(e.target.value)}}
                                    />
                                </div>
                            </div>
                            <div>
                            <div>
                                    <Form.Label>ID Externo</Form.Label>
                                    <input
                                        type="text"
                                        value={idExterno}
                                        onChange={e=> {setIdExterno(e.target.value)}}
                                    />
                                </div>
                                <div>
                                    <Form.Label>Ativo</Form.Label>
                                    <Form.Select
                                        value={ativoNew}
                                        onChange={e=> {setAtivoNew(e.target.value)}}
                                    >
                                        <option value="1">Sim</option>
                                        <option value="0">Não</option>
                                    </Form.Select>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break
            case 5:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>                  
                        <div className="title" id="colection">
                            <h1>Tipos de Registros - Gerar CSV</h1>
                            <div>
                                <button onClick={e => {setChange(0)}}>VOLTAR</button>
                            </div>
                        </div>
                        <ExportCSV headers={headersCSV} listItens={csv()} modulo={'tipos_de_registro'}/>
                    </div>
                )
                break
            case 6:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>                  
                        <div className="title" id="colection">
                            <h1>Tipos de Registros - Gerar PDF</h1>
                            <div>
                                <button onClick={e => {setChange(0)}}>VOLTAR</button>
                            </div>
                        </div>
                        <ExportPDF headers={headersCSV} listItens={csv()} modulo={'tipos_de_registro'}/>
                    </div>
                )
                break
            default:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>Error</div>
                )
                break
        }
    }

    function editItem(item) {
        setChange(2)
        setEditing(1)
        setIdTipoReg(item._id)
        setIdExterno(item.id_externo ? item.id_externo : '')
        setDescricaoNew(item.descricao ? item.descricao : '')
        setSiglaNew(item.sigla ? item.sigla : '')
        setAtivoNew(item.ativo)
    }

    function cleaner() {
        setChange(0)
        setEditing(0)
        setUploaded(0)
        setIdTipoReg('0')
        setIdExterno('')
        setDescricaoNew('')
        setSiglaNew('')
        setAtivoNew('1')
        setImportList([])
    }

    function save() {
        Swal.fire({
            title: "Atenção !",
            text: idTipoReg == '0' ? "Deseja realmente criar este tipo ?" : "Deseja realmente editar este tipo ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    if(descricaoNew == ''){
                        Swal.fire({
                            title: "Ops... !",
                            text: 'Informe a descrição do Tipo',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }else{
                        let reg = []
                        reg.push({
                            ativo: ativoNew,
                            descricao: descricaoNew,
                            id_conta: sessionStorage.getItem('idConta'),
                            id_externo: idExterno,
                            id_tipo_registro: idTipoReg,
                            sigla: siglaNew,
                        })
            
                        let option = {
                            headers: { 'Content-Type': ['application/json'] }
                        }
            
                        api.post('/tipo_registro', reg, option
                        ).then(
                            response=> {
                                tipos()
                                Swal.fire({
                                    title: "Sucesso !",
                                    text: idTipoReg == '0' ? 'Tipo criado com sucesso' : 'Tipo editado com sucesso',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                                cleaner()
                            },
                            response=> {
                                Swal.fire({
                                    title: "Ops... !",
                                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                            }
                        )
                    }
                }
            }
        )
    }

    function importSave() {
        if(importList.length == uploaded) {
            window.alert('Não há nada a ser importado!')
        }else {
            let ok = true
            let importNumber = 0
            importList.map((item, index) => {
                if(ok) {
                    if(item[4] == '') {
                        window.alert(`Ops...\nInforme a descrição do tipo na linha ${index + 1}.`)
                    }else {
                        let reg = []
                        reg.push({
                            "id_conta": sessionStorage.getItem('idConta'),
                            "id_tipo_registro": "0",
                            "id_externo": item[0],
                            "ativo": item[1],
                            "descricao": item[2],
                            "sigla": item[3],
                        })
            
                        let option = {
                            headers: { 'Content-Type': ['application/json'] }
                        }
            
                        api.post('/tipo_registro', reg, option
                        ).then(
                            response => {
                                importNumber++
                                setUploaded(importNumber)
                                if(item == importList[importList.length - 1]) {
                                    window.alert("Importado !\nLista de tipos salvo com sucesso")
                                }
                            },
                            response => {
                                ok = false
                                window.alert(`Temos um problema na linha ${index + 1}...\n${response.data.error}`)
                            }
                        )
                    }
                }
            })
        }
    }

    function remove() {
        Swal.fire({
            title: "Atenção !",
            text: "Tem certeza que deseja excluir este tipo ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
                    api.delete(`/tipo_registro/${idTipoReg}`, option
                    ).then(
                        response=> {
                            tipos()
                            Swal.fire({
                                title: "Sucesso !",
                                text: 'Tipo foi excluido de sua base',
                                icon: 'success',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                            cleaner()
                        },
                        response=> {
                            Swal.fire({
                                title: "Ops... !",
                                text: 'Algo deu errado, por favor tente novamente mais tarde',
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }
                    )
                }
            }
        )
    }
    
    return (
        <div className="mainContainer"
        style={JSON.parse(sessionStorage.getItem('collors'))}
        >
            <Menu/>
            <div id="background">
                {changeFunction(change)}
            </div>
        </div>
    )
}

export default Types