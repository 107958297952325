import React, {useState, useEffect} from 'react'
import ReactLoading from 'react-loading'

import Swal from 'sweetalert2'

import {GoLocation} from 'react-icons/go'
import {FaFileCsv, FaFilePdf, FaCheck} from 'react-icons/fa'
import {FiTrash2, FiFilter, FiChevronsLeft, FiChevronsRight, FiRefreshCcw, FiArrowLeft, FiPlus, FiSave, FiX, FiUpload, FiMap, FiSearch} from 'react-icons/fi'
import { MdInsertChartOutlined, MdOutlineFindReplace} from 'react-icons/md'
import { IoIosArrowUp } from "react-icons/io"; 
import { IoIosArrowDown } from "react-icons/io"; 
import {parse} from 'papaparse'
import { GoSettings } from "react-icons/go"; 
import { GiSettingsKnobs } from "react-icons/gi"; 

import api from '../../services/api'
import b64Resize from '../../services/b64Resize'
import Menu from '../../components/Menu'

//
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import {Impressao} from '../../services/customPDF'
import {CSVLink} from 'react-csv'
//

import './style.css'

import ItemDetails from './ItemsDetails'

import { Container, Row, Col, Table, Button, Dropdown, Form, Modal, CloseButton, ButtonGroup, Tab, Nav, NavDropdown, OverlayTrigger, Tooltip, Collapse } from 'react-bootstrap'

import NoImg from "../../assets/sem_foto.png"
import ExportCSV from '../../components/exportCSV'
import ExportPDF from '../../components/exportPDF'
import SearchSelect from '../../components/searchSelect'
import DashItems from '../../components/dashItems'

import { useTranslation } from 'react-i18next'

import {GoogleMap, useLoadScript, Marker, InfoWindow, Circle} from '@react-google-maps/api'
import { AiFillEdit, AiOutlineDelete, AiOutlineUnorderedList } from 'react-icons/ai'
import { BiEditAlt } from 'react-icons/bi'
import {TbChartBar, TbLetterCase, TbSelect, TbTextPlus} from 'react-icons/tb'
import {VscWholeWord} from 'react-icons/vsc'
import WidgetStatistics from './bi'

const libraries = ['places']
const mapContainerStyle = {
    width: '100%',
    height: 'calc(100vh - 200px)'
}

function Items() {
    const { t } = useTranslation();

    const [pageNumber, setPageNumber] = useState(0)
    const [pageLimit, setPageLimit] = useState(10)
    const [filterByPartner, setFilterByPartner] = useState()
    const [items, setItems] = useState([])
    const [status, setStatus] = useState("*")
    const [tag, setTag] = useState('*')
    const [observacao, setObservacao] = useState('')
    const [idExternoFilter, setIdExternoFilter] = useState('*')
    const [categoria, setCategoria] = useState("*")
    const [categoriaList, setCategoriaList] = useState([])
    const [nivelLoc1, setNivelLoc1] = useState(sessionStorage.getItem('filterView') == 'true' && sessionStorage.getItem('nivel1') && sessionStorage.getItem('nivel1') != '' ? sessionStorage.getItem('nivel1') : '*')
    const [nivelLoc2, setNivelLoc2] = useState(sessionStorage.getItem('filterView') == 'true' && sessionStorage.getItem('nivel2') && sessionStorage.getItem('nivel2') != '' ? sessionStorage.getItem('nivel2') : '*')
    const [nivelLoc3, setNivelLoc3] = useState(sessionStorage.getItem('filterView') == 'true' && sessionStorage.getItem('nivel3') && sessionStorage.getItem('nivel3') != '' ? sessionStorage.getItem('nivel3') : '*')
    const [nivelLoc4, setNivelLoc4] = useState(sessionStorage.getItem('filterView') == 'true' && sessionStorage.getItem('nivel4') && sessionStorage.getItem('nivel4') != '' ? sessionStorage.getItem('nivel4') : '*')
    const [niv1, setNiv1] = useState()
    const [niv2, setNiv2] = useState()
    const [niv3, setNiv3] = useState()
    const [niv4, setNiv4] = useState()
    const [nivelLoc1List, setNivelLoc1List] = useState([])
    const [nivelLoc2List, setNivelLoc2List] = useState([])
    const [nivelLoc3List, setNivelLoc3List] = useState([])
    const [nivelLoc4List, setNivelLoc4List] = useState([])
    const [idItens, setIdItens] = useState('0')
    const [categoriaNew, setCategoriaNew] = useState('')
    const [tagNew, setTagNew] = useState('')
    const [ativoNew, setAtivoNew] = useState('1')
    const [inf1New, setInf1New] = useState('')
    const [inf2New, setInf2New] = useState('')
    const [inf3New, setInf3New] = useState('')
    const [inf4New, setInf4New] = useState('')
    const [inf5New, setInf5New] = useState('')
    const [inf6New, setInf6New] = useState('')
    const [inf7New, setInf7New] = useState('')
    const [inf8New, setInf8New] = useState('')
    const [inf9New, setInf9New] = useState('')
    const [inf10New, setInf10New] = useState('')
    const [latitudeNew, setLatitudeNew] = useState('')
    const [longitudeNew, setLongitudeNew] = useState('')
    const [raioNew, setRaioNew] = useState('')
    const [up1New, setUp1New] = useState('')
    const [up2New, setUp2New] = useState('')
    const [up3New, setUp3New] = useState('')
    const [up4New, setUp4New] = useState('')
    const [up5New, setUp5New] = useState('')
    const [regNew, setRegNew] = useState({})
    const [idRegNew, setIdRegNew] = useState('')
    const [dtRegNew, setDtRegNew] = useState('')
    const [altRegNew, setAltRegNew] = useState('')
    const [dtCadNew, setDtCadNew] = useState('')
    const [altCadNew, setAltCadNew] = useState('')
    const [userCadNew, setUserCadNew] = useState('')
    const [lidoReg, setLidoReg] = useState('1')
    const [itemVinculadoNew, setItemVinculadoNew] = useState('')
    const [codExternoNew, setCodExternoNew] = useState('')
    const [nivelLoc1New, setNivelLoc1New] = useState('')
    const [nivelLoc2New, setNivelLoc2New] = useState('')
    const [nivelLoc3New, setNivelLoc3New] = useState('')
    const [nivelLoc4New, setNivelLoc4New] = useState('')
    const [parceiroList, setParceiroList] = useState([])
    const [parceiroNew, setParceiroNew] = useState('')
    const [registroNew, setRegistroNew] = useState('')
    const [observacaoNew, setObservacaoNew] = useState('')
    const [statusEstoque, setSatusEstoque] = useState('')
    const [fotosCompl, setFotosCompl] = useState([])
    const [valorNew, setValorNew] = useState(0)

    const [dashLoaded, setDashLoaded] = useState(true)
    const [dashDataList, setDashDataList] = useState([])
    const [dashTotalQtd, setDashTotalQtd] = useState(0)
    const [dashTotalValue, setDashTotalValue] = useState(0)

    const [change, setChange] = useState(0)
    const [editing, setEditing] = useState(0)
    const [displayExtra, setDisplayExtra] = useState(false)
    const [regValor, setRegValor] = useState([])
    const [inputNew, setInputNew] = useState('')
    const [foto, setFoto] = useState(NoImg)
    const [fotoUp, setFotoUp] = useState('')
    const [importList, setImportList] = useState([])
    const [exportList, setExportList] = useState([])
    const [exportListSelected, setExportListSelected] = useState([])
    const [itemsLoaded, setItemsLoaded] = useState(true)
    const [uploaded, setUploaded] = useState([])
    const [importLoading, setImportLoading] = useState(false)
    const [uppingImport, setUppingImport] = useState(false)
    const [importOkay, setImportOkay] = useState(0)
    const [importErro, setImportErro] = useState(0)
    const [importRepetido, setImportRepetido] = useState(0)
    const [importRegistrado, setImportRegistrado] = useState(0)
    const [importPage, setImportPage] = useState(0)
    const [exportPage, setExportPage] = useState(0)
    const [inBaseProps, setInBaseProps] = useState({})
    const [importView0, setImportView0] = useState(true)
    const [importView1, setImportView1] = useState(true)
    const [importView2, setImportView2] = useState(true)
    const [importView3, setImportView3] = useState(true)
    
    const [upsName, setUpsName] = useState([])
    
    const [infoNamesList, setInfoNamesList] = useState([])
    const [contaConfig, setContaConfig] = useState()
    const [infoComplList, setinfoComplList] = useState('*')
    const [listMode, setListMode] = useState(false)

    const [exportCounterList, setExportCounterList] = useState([])
    const [exportListLoading, setExportListLoading] = useState(false)
    const [exportCounterListLoading, setExportCounterListLoading] = useState(false)
    const [exportCounterListInfo, setExportCounterListInfo] = useState(['Todos','Todos','Todos','Todos','Todos'])
    const [ordenationExport, setOrdenationExport] = useState('');
    const [exportListFiltered, setExportListFiltered] = useState([]);
    const [exportListSelectedCount, setExportListSelectedCount] = useState([])

    const [latitude, setLatitude] = useState('0')
    const [longitude, setLongitude] = useState('0')

    const fileTypes = ["text/csv", "application/vnd.ms-excel", "text/plain"]
    const fotoNone = ['', '/static/media/sem_foto.b83884cf.png', 'assets/imgs/sem_foto.png']

    const [matchcase, setMatchcase] = useState(false)
    const [wholeWord, setWholeWord] = useState(false)
    const [find, setFind] = useState('')
    const [replace, setReplace] = useState('')
    const [findtype, setFindtype] = useState('')
    const [open, setOpen] = useState(false);
    const [modelList, setModelList] = useState([])
    const [brandList, setBrandList] = useState([])
    const [brandNew, setBrandNew] = useState([])
    const [modelNew, setModelNew] = useState([])
    const [modelId, setModelId] = useState('')
    const [showDash, setshowDash] = useState(false)
    const headersCSV = [
        "ID INOVA",
        "TAG_VINC",
        "COD_EXTERNO",
        "STATUS",
        "TAG",
        "CATEGORIA",
        "EAN",
        (infoNamesList[0] && infoNamesList[0] != '' ? infoNamesList[0] : 'Inf. Complentar 1').toUpperCase(),
        (infoNamesList[1] && infoNamesList[1] != '' ? infoNamesList[1] : 'Inf. Complentar 2').toUpperCase(),
        (infoNamesList[2] && infoNamesList[2] != '' ? infoNamesList[2] : 'Inf. Complentar 3').toUpperCase(),
        (infoNamesList[3] && infoNamesList[3] != '' ? infoNamesList[3] : 'Inf. Complentar 4').toUpperCase(),
        (infoNamesList[4] && infoNamesList[4] != '' ? infoNamesList[4] : 'Inf. Complentar 5').toUpperCase(),
        (infoNamesList[5] && infoNamesList[5] != '' ? infoNamesList[5] : 'Inf. Complentar 6').toUpperCase(),
        (infoNamesList[6] && infoNamesList[6] != '' ? infoNamesList[6] : 'Inf. Complentar 7').toUpperCase(),
        (infoNamesList[7] && infoNamesList[7] != '' ? infoNamesList[7] : 'Inf. Complentar 8').toUpperCase(),
        (infoNamesList[8] && infoNamesList[8] != '' ? infoNamesList[8] : 'Inf. Complentar 9').toUpperCase(),
        (infoNamesList[9] && infoNamesList[9] != '' ? infoNamesList[9] : 'Inf. Complentar 10').toUpperCase(),
        "PARCEIRO",
        "NIVEL_1",
        "NIVEL_2",
        "NIVEL_3",
        "NIVEL_4",
        "OBSERVAÇÃO",
        "VALOR",
        "UP_1",
        "UP_2",
        "UP_3",
        "UP_4",
        "UP_5",
        "DT_CRIAÇÃO",
        "DT_ALTERAÇÃO",
        "USUÁRIO",
        //"IO ESTOQUE",
        "FOTO",
        "MARCA", 
        "MODELO"
    ]
    const [infoDetails, setInfoDetails] = useState()
    const [showModalNewInfo, setShowModalNewInfo] = useState(false)
    const [newLabel, setNewLabel] = useState()
    const [isNewFieldSelect, setIsNewFieldSelect] = useState(false)
    const [showAll, setShowAll] = useState(false)
    const [showMap, setShowMap] = useState(true)
    const [selectedItem, setSelectedItem] = useState(null);

    const [colorMap, setColorMap] = useState({})
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_API_G_KEY,
        libraries,
    })
    const [mapData, setMapData] = useState([])
    const [obrigatorio, setObrigatorio] = useState([])

    //Filters
    const [advancedFilter, setAdvancedFilter] = useState(1)
    const [advancedFilterValue, setAdvancedFilterValue] = useState()

    useEffect(() => {
        items[items.length - 1]? setItems([items[items.length - 1]]) : setItems([])
        itemsBO()
    }, [pageNumber, pageLimit])
    
    useEffect(() => {
        categoriaGet()
    }, [])

    useEffect(() => {
        parceiroGet()
    }, [])

    useEffect(() => {
        nivel1Get()
    }, [])

    useEffect(() => {
        nivel2Get(nivelLoc1)
    }, [nivelLoc1])

    useEffect(() => {
        nivel3Get(nivelLoc2)
    }, [nivelLoc2])
    
    useEffect(() => {
        nivel4Get(nivelLoc3)
    }, [nivelLoc3])

    useEffect(() => {
        nivel2Get(nivelLoc1New)
    }, [nivelLoc1New])

    useEffect(() => {
        nivel3Get(nivelLoc2New)
    }, [nivelLoc2New])
    
    useEffect(() => {
        nivel4Get(nivelLoc3New)
    }, [nivelLoc3New])

    useEffect(() => {
        if(change == 1) {
            verififyBase()
        }
    }, [change])

    useEffect(() => {
        getInfoNames()
    },[])

    useEffect(()=>{
        getInfoCompl()
    }, [])

    useEffect(()=>{
        if(change == 2){
            getInfoBrand()
        }
    }, [categoriaNew])

    useEffect(()=>{
        if(modelId || change == 2){
            getInfoModel()
        }
    }, [brandNew, modelId, modelNew])

    useEffect(()=>{
        if(change == 3){
            loadMapItens()
        }
    }, [change])

    useEffect(()=>{
        getInfoDetails()
    }, [change, inf1New, inf2New, inf3New, inf4New, inf5New, inf6New, inf7New, inf8New, inf9New, inf10New, showAll])

    useEffect(() => {
        let ups = []
        categoriaList.map(item => {
            if(item._id == categoriaNew){
                ups = [
                    item.up_1,
                    item.up_2,
                    item.up_3,
                    item.up_4,
                    item.up_5
                ]
                setUpsName(ups)
            }
        })
        
    }, [categoriaNew])

    function handleAdicionalField(){
        let lista = infoDetails
        let index
        for (let i = 0; i < infoDetails.length; i++) {
            if (lista[i].show !== true) {
                lista[i].show = true
                index = i
                break;
            }
        }
        if(isNewFieldSelect){
            let infos = infoComplList[index] ? infoComplList[index].valores : []
            let list = [{
                "_id": infoComplList[index] ? infoComplList[index]._id : '0',
                "id_conta": sessionStorage.getItem('idConta'),
                "label": infoComplList[index] ? infoNamesList[index].label : `Inf. Complementar ${index}`,
                "valores": infos,
                "destino": infoComplList[index] ? infoComplList[index].destino : `inf_compl_${index+1}`,
            }]
            api.post(`/select_box`, list).then(
                lista[index].modo = 'lista'
            )   
        }
        if(newLabel){
            if (index === 0) {
                contaConfig.info_compl_1 = newLabel
            } else if(index === 1){
                contaConfig.info_compl_2 = newLabel
            } else if(index === 2){
                contaConfig.info_compl_3 = newLabel
            } else if(index === 3){
                contaConfig.info_compl_4 = newLabel
            } else if(index === 4){
                contaConfig.info_compl_5 = newLabel
            } else if(index === 5){
                contaConfig.info_compl_6 = newLabel
            } else if(index === 6){
                contaConfig.info_compl_7 = newLabel
            } else if(index === 7){
                contaConfig.info_compl_8 = newLabel
            } else if(index === 8){
                contaConfig.info_compl_9 = newLabel
            } else {
                contaConfig.info_compl_10 = newLabel
            }
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
            api.post('/conta_config', [contaConfig], option).then(
                response => {
                    lista[index].label = newLabel
                    getInfoNames()
                }
            )
            setNewLabel('')
        }
        setInfoDetails(lista)
        setShowModalNewInfo(false)
        setIsNewFieldSelect(false)
    }

    function abortRequest(){
        const controller = new AbortController();
        controller.abort()
    }
    function getDashValues() {
        setDashLoaded(false)
        setshowDash(true)
        api.get(`/item/${sessionStorage.getItem('idConta')}/*/*/*/*/*/*/*/*/*/*/*/*/*`
        ).then(
            async response => {

                let dashData = []
                let dashValue = 0
                await response.data.map((item) => {
                    dashValue += item.valor
                    let status = ''
                    if(item.ativo == 0) {status = 'Inativo'}
                    if(item.ativo == 1) {status = 'Ativo'}
                    if(item.ativo == 2) {status = 'Danificado'}
                    if(item.ativo == 3) {status = 'Obsoleto'}
                    dashData.push({
                        'Categorias': item.id_categoria ? item.id_categoria.descricao : 'N/A',
                        'Valor': item.valor,
                        'Status': status,
                        'Niveis 1': item.id_nivel_loc_1 ? item.id_nivel_loc_1.descricao : 'N/A',
                        'Niveis 2': item.id_nivel_loc_2 ? item.id_nivel_loc_2.descricao : 'N/A',
                        'Niveis 3': item.id_nivel_loc_3 ? item.id_nivel_loc_3.descricao : 'N/A',
                        'Niveis 4': item.id_nivel_loc_4 ? item.id_nivel_loc_4.descricao : 'N/A',
                        'Parceiros': item.id_parceiro ? item.id_parceiro.nome : 'N/A'
                    })
                })
                await setDashTotalQtd(response.data.length)
                await setDashTotalValue(dashValue)
                await setDashDataList(dashData.filter((item) => {return item}))
            }
        ).finally(
            () => {
                setDashLoaded(true)
                abortRequest()
            }
        )
    }

    function getInfoUser(){
        setNivelLoc1New(sessionStorage.getItem('nivel1') && sessionStorage.getItem('nivel1') !='*' ? sessionStorage.getItem('nivel1') : '')
        setNivelLoc2New(sessionStorage.getItem('nivel2') && sessionStorage.getItem('nivel2') !='*' ? sessionStorage.getItem('nivel2') : '')
        setNivelLoc3New(sessionStorage.getItem('nivel3') && sessionStorage.getItem('nivel3') !='*' ? sessionStorage.getItem('nivel3') : '')
        setNivelLoc4New(sessionStorage.getItem('nivel4') && sessionStorage.getItem('nivel4') !='*' ? sessionStorage.getItem('nivel4') : '')
    }

    function getInfoNames() {
        api.get(`/conta_config/${sessionStorage.getItem('idConta')}`).then(
            response => {
                setObrigatorio(JSON.parse(response.data[0].obrigatorio))
                let data = response.data[0] ? response.data[0] : []
                let lista = [
                    data.info_compl_1 && data.info_compl_1 != '' ? data.info_compl_1 : '' ,
                    data.info_compl_2 && data.info_compl_2 != '' ? data.info_compl_2 : '' ,
                    data.info_compl_3 && data.info_compl_3 != '' ? data.info_compl_3 : '' ,
                    data.info_compl_4 && data.info_compl_4 != '' ? data.info_compl_4 : '' ,
                    data.info_compl_5 && data.info_compl_5 != '' ? data.info_compl_5 : '' ,
                    data.info_compl_6 && data.info_compl_6 != '' ? data.info_compl_6 : '' ,
                    data.info_compl_7 && data.info_compl_7 != '' ? data.info_compl_7 : '' ,
                    data.info_compl_8 && data.info_compl_8 != '' ? data.info_compl_8 : '' ,
                    data.info_compl_9 && data.info_compl_9 != '' ? data.info_compl_9 : '' ,
                    data.info_compl_10 && data.info_compl_10 != '' ? data.info_compl_10 : '' 
                ]
                setInfoNamesList(lista)
                setContaConfig(response.data[0])
            }
        )
    }

    function getInfoCompl(){
        api.get(`/select_box/*/${sessionStorage.getItem('idConta')}`).then(
            response => {
                let lista = []
                for(let i = 0; i < 10; i++){
                    let aux = response.data.filter((item) => {return item.destino == `inf_compl_${i+1}`})
                    lista[i] = aux ? aux[0] : null 
                }
                setinfoComplList(lista)
                setListMode(response.data.length > 0 ? true : false)
            }
        )
    }

    function editInfoCompl(index, info){
        Swal.fire({
            title: `${t('messages.warning_edit')} ?`,
            input: 'text',
            inputLabel: `${t('items.insert_name')}`,
            customClass: {
                title: 'swal-title',
                input: 'swal-form text-left',
                confirmButton: 'swal-confirm-button',
                inputLabel: 'swal-input-label text-left',
                confirmButton: 'btn btn-primary',
            },                
            buttonsStyling: false,
            confirmButtonText: 'Salvar',
        }).then((resultado) => {
            if (resultado.isConfirmed) {
                let infos = infoComplList[index].valores
                let newinfo = infos.indexOf(info);
                infos[newinfo] = resultado.value
                let list = [{
                    "_id": infoComplList[index]._id ? infoComplList[index]._id : '0',
                    "id_conta": sessionStorage.getItem('idConta'),
                    "label": infoComplList[index].label ? infoNamesList[index].label : `Inf. Complementar ${index}`,
                    "valores": infos,
                    "destino": infoComplList[index].destino ? infoComplList[index].destino : `inf_compl_${index+1}`,
                }]
                api.post(`/select_box`, list).then(
                    response => {
                        getInfoCompl()
                        inf1New == infos ? setInf1New('') : setInf1New(inf1New)
                        inf2New == infos ? setInf2New('') : setInf1New(inf2New)
                        inf3New == infos ? setInf3New('') : setInf1New(inf3New)
                        inf4New == infos ? setInf4New('') : setInf1New(inf4New)
                        inf5New == infos ? setInf5New('') : setInf1New(inf5New)
                        inf6New == infos ? setInf6New('') : setInf1New(inf6New)
                        inf7New == infos ? setInf7New('') : setInf1New(inf7New)
                        inf8New == infos ? setInf8New('') : setInf1New(inf8New)
                        inf9New == infos ? setInf9New('') : setInf1New(inf9New)
                        inf10New == infos ? setInf10New('') : setInf1New(inf10New)
                        Swal.fire({
                            title: `${t('messages.success')}`,
                            icon: 'success',
                            confirmButtonText: 'OK',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                        })
                    }
                )
            }
        })
    }

    function deleteInfoCompl(index, info){
        Swal.fire({
            title: `${t('messages.warning_delete')} ${info}`,
            confirmButtonText: 'Confirmar',
            icon: 'warning',
            customClass: {
                title: 'swal-title',
                input: 'swal-form text-left',
                confirmButton: 'swal-confirm-button',
                inputLabel: 'swal-input-label text-left',
                confirmButton: 'btn btn-primary',
            },
          }).then((resultado) => {
            if (resultado.isConfirmed) {
                let infos = infoComplList[index].valores
                infos = infos.filter(items => items != info )
                let list = [{
                    "_id": infoComplList[index]._id ? infoComplList[index]._id : '0',
                    "id_conta": sessionStorage.getItem('idConta'),
                    "label": infoComplList[index].label ? infoNamesList[index].label : `Inf. Complementar ${index}`,
                    "valores": infos,
                    "destino": infoComplList[index].destino ? infoComplList[index].destino : `inf_compl_${index+1}`,
                }]
                api.post(`/select_box`, list).then(
                    response => {
                        inf1New == infos ? setInf1New('') : setInf1New(inf1New)
                        inf2New == infos ? setInf2New('') : setInf2New(inf2New)
                        inf3New == infos ? setInf3New('') : setInf3New(inf3New)
                        inf4New == infos ? setInf4New('') : setInf4New(inf4New)
                        inf5New == infos ? setInf5New('') : setInf5New(inf5New)
                        inf6New == infos ? setInf6New('') : setInf6New(inf6New)
                        inf7New == infos ? setInf7New('') : setInf7New(inf7New)
                        inf8New == infos ? setInf8New('') : setInf8New(inf8New)
                        inf9New == infos ? setInf9New('') : setInf9New(inf9New)
                        inf10New == infos ? setInf10New('') : setInf10New(inf10New)
                        getInfoCompl()
                        Swal.fire({
                            title: `${t('messages.success')}`,
                            icon: 'success',
                            confirmButtonText: 'Confirmar',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })

                    }
                )
            }
            getInfoDetails()
        });
    }

    function newInfoCompl(index){
        Swal.fire({
            title: `${t('actions.add')} ${t('common.info_label')} ${index+1}`,
            input: 'text',
            inputLabel: `${t('items.insert_name')}`,
            customClass: {
                title: 'swal-title',
                input: 'swal-form text-left',
                confirmButton: 'swal-confirm-button',
                inputLabel: 'swal-input-label text-left',
                confirmButton: 'btn btn-primary',
            },                
            buttonsStyling: false,
            confirmButtonText: 'Salvar',
          }).then((resultado) => {
            if (resultado.isConfirmed) {
                let infos = infoComplList[index] ? infoComplList[index].valores : []
                infos.push(resultado.value)
                let list = [{
                    "_id": infoComplList[index] ? infoComplList[index]._id : '0',
                    "id_conta": sessionStorage.getItem('idConta'),
                    "label": infoComplList[index] ? infoNamesList[index].label : `Inf. Complementar ${index}`,
                    "valores": infos,
                    "destino": infoComplList[index] ? infoComplList[index].destino : `inf_compl_${index+1}`,
                }]
                api.post(`/select_box`, list).then(
                    response => {
                        getInfoCompl()
                        Swal.fire({
                            title: `${t('messages.success')}`,
                            icon: 'success',
                            confirmButtonText: 'Confirmar',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    },
                    error => {
                        Swal.fire({
                            title: `${t('messages.error')}`,
                            icon: 'error',
                            confirmButtonText: 'Confirmar',
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                        })
                    }
                )
            }
        });
    }

    function getInfoModel(){
        api.get(`/infs_compl/${sessionStorage.getItem('idConta')}?id_categoria=${categoriaNew}&marca=${brandNew.length !== 0 ? brandNew.marca : ''}&modelo=`).then(
            response => {
                let models = response.data.filter((model) =>  {if(model._id === brandNew._id) {return model}})[0].modelo
                setModelList(models) 
                if(modelId){
                    setModelNew(models.filter((brandL) =>  {if(brandL?._id === modelId) {return brandL}})[0])
                    setModelId('')
                }         
            }
        )
    }

    function getInfoBrand(){
        api.get(`/infs_compl/${sessionStorage.getItem('idConta')}?id_categoria=${categoriaNew !== '' ? categoriaNew : '*'}&marca=$&modelo=`).then(
            response => {
                setBrandList(response.data)
            }
        )
    }

    async function getExportBrand(){
        await api.get(`/infs_compl/${sessionStorage.getItem('idConta')}?id_categoria=&marca=&modelo=`).then(
            async response => {
                setBrandList(response.data)
                let lista = []
                for(let i = 0; i < response.data.length; i++){
                    if(response.data[i].modelo){
                        lista = lista.concat(response.data[i].modelo)
                    }
                }
                await setModelList(lista)
            }
        )    
    }


    function newInfoBrand(newinf){
        if(!categoriaNew){
            Swal.fire({
                title: `${t('messages.error_categorie_missing')}`,
                icon: 'error',
                confirmButtonText: 'Confirmar',
                customClass: {
                    confirmButton: 'btn btn-danger',
                },
            })
        }else{
            Swal.fire({
                title: `${t('actions.add')} Marca`,
                input: 'text',
                inputLabel: `${t('items.insert_name')}`,
                customClass: {
                    title: 'swal-title',
                    input: 'swal-form text-left',
                    confirmButton: 'swal-confirm-button',
                    inputLabel: 'swal-input-label text-left',
                    confirmButton: 'btn btn-primary',
                },                
                buttonsStyling: false,
                confirmButtonText: 'Salvar',
            }).then((resultado) => {
                if (resultado.isConfirmed) {
                    let reg = [{
                        "_id": newinf ? newinf : "0",
                        "id_conta": sessionStorage.getItem('idConta'),
                        "id_categoria": categoriaNew,
                        "marca": resultado.value,
                        "modelo": []
                    }]
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
                    api.post('/infs_compl', reg, option).then(
                        response => {
                            getInfoModel()
                            getInfoBrand()
                            if(newinf){
                                setBrandNew([])
                            }
                           
                            Swal.fire({
                                title: `${t('messages.success')}`,
                                icon: 'success',
                                confirmButtonText: 'Confirmar',
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                            })
                        },
                        error => {
                            Swal.fire({
                                title: `${t('messages.error')}`,
                                icon: 'error',
                                confirmButtonText: 'Confirmar',
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                            })
                        }
                    )
                }
            })
        }
    }

    function newInfoModel(){
        Swal.fire({
            title: `${t('actions.add')} Modelo`,
            input: 'text',
            inputLabel: `${t('items.insert_name')}`,
            customClass: {
                title: 'swal-title',
                input: 'swal-form text-left',
                confirmButton: 'swal-confirm-button',
                inputLabel: 'swal-input-label text-left',
                confirmButton: 'btn btn-primary',
            },                
            buttonsStyling: false,
            confirmButtonText: 'Salvar',
        }).then((resultado) => {
            if (resultado.isConfirmed) {
                let infos = modelList
                infos.push({"_id": "0", "descricao": resultado.value})
                let reg = [{
                    "_id": "0",
                    "id_conta": sessionStorage.getItem('idConta'),
                    "id_categoria": categoriaNew,
                    "marca": brandNew.marca,
                    "modelo": infos
                }]
                let option = {
                    headers: { 'Content-Type': ['application/json'] }
                }
                api.post('/infs_compl', reg, option).then(
                    response => {
                        getInfoModel()
                        getInfoBrand()
                        Swal.fire({
                            title: `${t('messages.success')}`,
                            icon: 'success',
                            confirmButtonText: 'Confirmar',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    },
                    error => {
                        Swal.fire({
                            title: `${t('messages.error')}`,
                            icon: 'error',
                            confirmButtonText: 'Confirmar',
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                        })
                    }
                )
            }
        })
    }

    function editModel(model){
        Swal.fire({
            title: `${t('actions.refresh')} Modelo`,
            input: 'text',
            inputLabel: `${t('items.insert_name')}`,
            customClass: {
                title: 'swal-title',
                input: 'swal-form text-left',
                confirmButton: 'swal-confirm-button',
                inputLabel: 'swal-input-label text-left',
                confirmButton: 'btn btn-primary',
            },                
            buttonsStyling: false,
            confirmButtonText: 'Salvar',
        }).then((resultado) => {
            if (resultado.isConfirmed) {
                let infos = modelList
                for (let i = 0; i < infos.length; i++) {
                    if (infos[i]._id === modelNew._id) {
                        infos[i].descricao = resultado.value;
                    }
                }
                let reg = [{
                    "_id": brandNew._id,
                    "id_conta": sessionStorage.getItem('idConta'),
                    "id_categoria": categoriaNew,
                    "marca": brandNew.marca,
                    "modelo": infos
                }]
                let option = {
                    headers: { 'Content-Type': ['application/json'] }
                }
                api.post('/infs_compl', reg, option).then(
                    response => {
                        getInfoModel()
                        getInfoBrand()
                        setModelNew('')
                        Swal.fire({
                            title: `${t('messages.success')}`,
                            icon: 'success',
                            confirmButtonText: 'Confirmar',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    },
                    error => {
                        Swal.fire({
                            title: `${t('messages.error')}`,
                            icon: 'error',
                            confirmButtonText: 'Confirmar',
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                        })
                    }
                )
            }
        })
    }

    function deleteBrand(brand, type) {
        Swal.fire({
            title: `${t('messages.warning_delete')}`,
            confirmButtonText: 'Confirmar',
            icon: 'warning',
            customClass: {
                title: 'swal-title',
                input: 'swal-form text-left',
                confirmButton: 'swal-confirm-button',
                inputLabel: 'swal-input-label text-left',
                confirmButton: 'btn btn-primary',
            },
          }).then((resultado) => {
            if (resultado.isConfirmed) {   
                if(type == 'brand'){
                    api.delete(`/infs_compl/${brand}`).then(
                        response => {
                            getInfoModel()
                            getInfoBrand()
                            setBrandNew('')
                            Swal.fire({
                                title: `${t('messages.success')}`,
                                icon: 'success',
                                confirmButtonText: 'Confirmar',
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                            })
                        },
                        error => {
                            Swal.fire({
                                title: `${t('messages.error')}`,
                                icon: 'error',
                                confirmButtonText: 'Confirmar',
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                            })
                        }
                    )
                } else {
                    api.delete(`/infs_compl/${brandNew._id}?id_model=${brand}`).then(
                        response => {
                            getInfoModel()
                            getInfoBrand()
                            setModelNew('')
                            Swal.fire({
                                title: `${t('messages.success')}`,
                                icon: 'success',
                                confirmButtonText: 'Confirmar',
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                            })
                        },
                        error => {
                            Swal.fire({
                                title: `${t('messages.error')}`,
                                icon: 'error',
                                confirmButtonText: 'Confirmar',
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                            })
                        }
                    )
                }
            }
        })
    }

    function importViewChange(index) {
        setImportPage(0)
        switch (index) {
            case 0:
                setImportView0(!importView0)
                break;
            case 1:
                setImportView1(!importView1)
                break;
            case 2:
                setImportView2(!importView2)
                break;
            case 3:
                setImportView3(!importView3)
                break;
            default:
                break;
        }
    }
    
    /*useEffect(async () => {
        if(change == 1) {
            setImportLoading(true)
            let lista = []
            await api.get(`/itens/${sessionStorage.getItem('idConta')}`).then(
                async response => {
                    lista = await response.data.map((item) => {
                        return (item.tag)
                    })
                }
            ).finally(() => {
                setTagRegistredList(lista)
                setImportLoading(false)
            })
        }else{
            setTagRegistredList([])
        }
    }, [change])*/

    async function loadMapItens() {
        setItemsLoaded(false)
        await api.get(`/item/${sessionStorage.getItem('idConta')}/*/${categoria}/${tag}/${status}/*/*/*/*/*/${nivelLoc1}/${nivelLoc2}/${nivelLoc3}/${nivelLoc4}`, {}
        ).then(response => {
            setMapData(response.data)
            let count = 0
            nivelLoc1List.forEach((i, index) => {
                if (!colorMap[i._id]) {
                    setColorMap((prevState) => ({
                        ...prevState,
                        [i._id]: generateRandomColor(count)
                    }));
                    count++
                }
            })        
            setItemsLoaded(true)
            
        }).finally(
            () => {
                abortRequest()
                setShowMap(true)
            }
        )
    }

    async function itemsBO() {
        setItemsLoaded(false)
        await api.get(`/itensPage/${sessionStorage.getItem('idConta')}/*/${categoria}/${tag}/${status}/*/*/*/*/*/${nivelLoc1}/${nivelLoc2}/${nivelLoc3}/${nivelLoc4}/${idExternoFilter}/${pageNumber*1000}?limit=${pageLimit}${filterByPartner ? `&id_parceiro=${filterByPartner}` : ''}`, {}
        ).then(response => {
            setItems(response.data)
            setItemsLoaded(true)
        }).finally(
            () => {
                setItemsLoaded(true)
                abortRequest()
            }
        )
    }

    async function handleAdvancedFilter() {
        try {
            setItemsLoaded(false);
    
            const token = await getToken();
            const headers = {
                headers: { 'id_conta': sessionStorage.idConta, 'Authorization': `Bearer ${token}` }
            };
    
            const filterParam = `inf_compl_${advancedFilter}`;
            const response = await api.get(`/v1/geral/bd?c=_item&id_conta=${sessionStorage.idConta}${categoria ? `&id_categoria=${categoria}` : ''}${tag ? `&tag=${tag}` : ''}${nivelLoc1 ? `&id_nivel_loc_1=${nivelLoc1}` : ''}${nivelLoc2 ? `&id_nivel_loc_2=${nivelLoc2}` : ''}${nivelLoc3 ? `&id_nivel_loc_3=${nivelLoc3}` : ''}${nivelLoc4 ? `&id_nivel_loc_4=${nivelLoc4}` : ''}&${filterParam}=${advancedFilterValue}&pop=id_categoria&pop=id_nivel_loc_1&pop=id_nivel_loc_2&pop=id_nivel_loc_3&pop=id_nivel_loc_4`, headers);
            response.data.push({
                "tag": "0",
                "total_reg": response.data.length,
                "total_filter": response.data.length
            })
            setItems(response.data);
            setItemsLoaded(true);
        } catch (error) {
            console.error('Erro ao processar o filtro avançado:', error);
        }
    }
    
    async function getToken() {
        const headerToken = { headers: { 'Authentication': sessionStorage.idConta } };
    
        try {
            const response = await api.get(`/getToken/${sessionStorage.idConta}`, headerToken);
            return response.data.token;
        } catch (error) {
            throw error; // Rethrow para que o erro seja capturado pela função principal
        }
    }
    

    const ordenateBy = (e) => {
        const value = e.target.value;
        setOrdenationExport(value);
        let sortedList = exportList;
        if (value === '') {
            sortedList.sort((a, b) => (a.tag > b.tag ? 1 : -1));
        } else if (value === '0') {
            sortedList.sort((a, b) => {
            const dateRegex1 =  /^[0-9]{1,2}[\/|-][0-9]{1,2}[\/|-][0-9]{2,4}$/;

            let vA = a.observacao.trim()
            let vB = b.observacao.trim()

            const isDateA = dateRegex1.test(vA) 
            const isDateB = dateRegex1.test(vB)
            
            if (isDateA && !isDateB) {
              return -1;
            } else if (!isDateA && isDateB) {
              return 1;
            } else if (isDateA && isDateB) {
                if(a.observacao.length === 8 || b.observacao.length === 8){
                    var value1 = vA;
                    var value2 = vB;
                    if(a.observacao.length === 8){
                        vA = a.observacao.substr(0,6)+"20"+a.observacao.substr(6,8)
                    }
                    if(b.observacao.length === 8){
                        vB = b.observacao.substr(0,6) + "20"+ b.observacao.substr(6,8)
                    }
                    const dateA = new Date(vA.split(/[\/-]/).reverse().join('-'));
                    const dateB = new Date(vB.split(/[\/-]/).reverse().join('-'));
                    return dateB - dateA;
                    
                } 
                const dateA = new Date(vA.split(/[\/-]/).reverse().join('-'));
                const dateB = new Date(vB.split(/[\/-]/).reverse().join('-'));
                return dateB - dateA;

            } else {
              if(a.observacao < b.observacao) { return -1; }
              if(a.observacao > b.observacao) { return 1; }
              return 0;
            }
          });
        }
         else if(value === '1'){
            sortedList.sort((a, b) => (new Date(b.dt_criacao) > new Date(a.dt_criacao) ? 1 : -1));
        }

        setExportListFiltered(sortedList);
    }

    function ExportListFilter() {
        if(observacao){
            const filteredItems = exportList.filter(item => item.observacao.includes(observacao));
            setExportList(filteredItems);
        }
        else{
            let lista = []
            api.get(`/item/${sessionStorage.getItem('idConta')}/*/${categoria}/${tag}/${status}/*/*/*/*/*/${nivelLoc1}/${nivelLoc2}/${nivelLoc3}/${nivelLoc4}`
            ).then(
                response => {
                    response.data.filter((item, index) => {
                        if(!(item.total_reg)) {
                            return item
                        }
                    }).map((item, index) => {
                        lista.push(item)
                        if(response.data.length - 1 == index){
                            setExportList(lista)
                        }
                    })
                }
            )
        }
    }

    function categoriaGet() {
        api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setCategoriaList(response.data)})
    }
    
    function nivel1Get() {
        api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setNivelLoc1List(response.data)})
    }

    function nivel2Get(nivel1) {
        if(nivel1 != "*" && nivel1 != '' && nivel1 != null && nivel1 != undefined) {
            api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/${nivel1 != '' ? nivel1 : '*'}/*/*/*/*`, {}
            ).then(response => {
                setNivelLoc2List(response.data)
            })
        }else {
            setNivelLoc2List([])
        }
    }

    function nivel3Get(nivel2) {
        if(nivel2 != "*" && nivel2 != '' && nivel2 != null && nivel2 != undefined) {
            api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/${nivel2 != '' ? nivel2 : '*'}/*/*/*/*`, {}
            ).then(response => {
                setNivelLoc3List(response.data)
            })
        }else {
            setNivelLoc3List([])
        }
    }

    function nivel4Get(nivel3) {
        if(nivel3 != "*" && nivel3 != '' && nivel3 != null && nivel3 != undefined) {
            api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/${nivel3 != '' ? nivel3 : '*'}/*/*/*/*`, {}
            ).then(response => {
                setNivelLoc4List(response.data)
            })
        }else {
            setNivelLoc4List([])
        }
    }

    function parceiroGet() {
        api.get(`/parceiro/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setParceiroList(response.data)})
    }

    async function getExportList() {
        getExportBrand()
        let infos = ['Todos','Todos','Todos','Todos','Todos','Todos']
        if(status != '*') {
            let ops = ['Inativo','Ativo','Danificado / Descarte','Obsoleto - Apto a doação']
            infos[0]= ops[status]
        }
        if(nivelLoc1 != '*') {
            let findItem = await nivelLoc1List.find((nv) => (nv._id == nivelLoc1))
            infos[1]= findItem.descricao
        }
        if(nivelLoc2 != '*') {
            let findItem = await nivelLoc2List.find((nv) => (nv._id == nivelLoc2))
            infos[2]= findItem.descricao
        }
        if(nivelLoc3 != '*') {
            let findItem = await nivelLoc3List.find((nv) => (nv._id == nivelLoc3))
            infos[3]= findItem.descricao
        }
        if(nivelLoc4 != '*') {
            let findItem = await nivelLoc4List.find((nv) => (nv._id == nivelLoc4))
            infos[4]= findItem.descricao
        }
        if(categoria != '*') {
            let findItem = await categoriaList.find((cat) => (cat._id == categoria))
            infos[5]= findItem.descricao
        }
        Swal.fire({
            title: `${t('messages.attention')} !`,
            html: `${t('messages.warning_export')}<br/>Status: ${infos[0]}<br/>${t('common.categories')}: ${infos[5]}<br/>Nivel Loc 1: ${infos[1]}<br/>Nivel Loc 2: ${infos[2]}<br/>Nivel Loc 3: ${infos[3]}<br/>Nivel Loc 4: ${infos[4]}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: `${t('messages.yes')}`,
            cancelButtonText: `${t('messages.no')}`
        }).then(
            result => {
                if(result.isConfirmed) {
                    setExportListLoading(true)
                    setChange(4)
                    
                    let lista = []
                    //api.get(`/item/${sessionStorage.getItem('idConta')}/:id/:id_categoria/:tag/:ativo/:inf1/:inf2/:inf3/:inf4/:inf5/:nivel1/:nivel2/:nivel3/:nivel4`)
                    //api.get(`/itensPage/${sessionStorage.getItem('idConta')}/*/${categoria}/${tag}/${status}/*/*/*/*/*/${nivelLoc1}/${nivelLoc2}/${nivelLoc3}/${nivelLoc4}/${pageNumber*1000}`, {}
                    api.get(`/item/${sessionStorage.getItem('idConta')}/*/${categoria}/${tag}/${status}/*/*/*/*/*/${nivelLoc1}/${nivelLoc2}/${nivelLoc3}/${nivelLoc4}`
                    ).then(
                        response => {
                            response.data.filter((item, index) => {
                                if(!(item.total_reg)) {
                                    return item
                                }
                            }).map((item, index) => {
                                lista.push(item)
                                if(response.data.length - 1 == index){
                                    setExportList(lista)
                                    setExportListLoading(false)
                                }
                            })
                        }
                    )
                }
            }
        )

    }
    
    async function getExportListCount() {
        let infos = ['Todos','Todos','Todos','Todos','Todos']
        if(status != '*') {
            let ops = ['Inativo','Ativo','Danificado / Descarte','Obsoleto - Apto a doação']
            infos[0]= ops[status]
        }
        if(nivelLoc1 != '*') {
            let findItem = await nivelLoc1List.find((nv) => (nv._id == nivelLoc1))
            infos[1]= findItem.descricao
        }
        if(nivelLoc2 != '*') {
            let findItem = await nivelLoc2List.find((nv) => (nv._id == nivelLoc2))
            infos[2]= findItem.descricao
        }
        if(nivelLoc3 != '*') {
            let findItem = await nivelLoc3List.find((nv) => (nv._id == nivelLoc3))
            infos[3]= findItem.descricao
        }
        if(nivelLoc4 != '*') {
            let findItem = await nivelLoc4List.find((nv) => (nv._id == nivelLoc4))
            infos[4]= findItem.descricao
        }
        await setExportCounterListInfo(infos)
        Swal.fire({
            title: `${t('messages.attention')}`,
            html: `${t('messages.warning_contab')}<br/>Status: ${infos[0]}<br/>Nivel Loc 1: ${infos[1]}<br/>Nivel Loc 2: ${infos[2]}<br/>Nivel Loc 3: ${infos[3]}<br/>Nivel Loc 4: ${infos[4]}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: `${t('messages.yes')}`,
            cancelButtonText: `${t('messages.no')}`
        }).then(
            result => {
                if(result.isConfirmed) {
                    setExportCounterListLoading(true)
                    setChange(7)
                    let lista = []
                    //api.get(`/item/${sessionStorage.getItem('idConta')}/:id/:id_categoria/:tag/:ativo/:inf1/:inf2/:inf3/:inf4/:inf5/:nivel1/:nivel2/:nivel3/:nivel4`)
                    //api.get(`/itensPage/${sessionStorage.getItem('idConta')}/*/${categoria}/${tag}/${status}/*/*/*/*/*/${nivelLoc1}/${nivelLoc2}/${nivelLoc3}/${nivelLoc4}/${pageNumber*1000}`, {}
                    api.get(`/item/${sessionStorage.getItem('idConta')}/*/*/*/${status}/*/*/*/*/*/${nivelLoc1}/${nivelLoc2}/${nivelLoc3}/${nivelLoc4}`
                    ).then(
                        response => {
                            response.data.filter((item, index) => {
                                if(!(item.total_reg)) {
                                    return item
                                }
                            }).map((item, index) => {
                                if(item.id_categoria) {
                                    let ind = lista.findIndex((lCount)=>(lCount.descricao == item.id_categoria.descricao))
                                    if(ind > -1) {
                                        lista[ind].contador += 1
                                    }else{
                                        lista.push({
                                            descricao: item.id_categoria.descricao,
                                            inf_compl_1: item.inf_compl_1,
                                            contador: 1
                                        })
                                    }
                                    if(response.data.length - 1 == index){
                                        setExportCounterList(lista)
                                        setExportCounterListLoading(false)
                                    }
                                }else{
                                    let ind = lista.findIndex((lCount)=>(lCount.descricao == 'N/A'))
                                    if(ind > -1) {
                                        lista[ind].contador += 1
                                    }else{
                                        lista.push({
                                            descricao: 'N/A',
                                            contador: 1
                                        })
                                    }
                                }
                            })
                        }
                    )
                }
            }
        )
    }

    async function verififyBase() {
        let inBase = {
            tags_id: [],
            tags: [],
            item: [],
            categories: [],
            nivel1: [],
            nivel2: [],
            nivel3: [],
            nivel4: [],
            parceiro: []
        }

        setImportLoading(true)

        await api.get(`/item/${sessionStorage.getItem('idConta')}/*/*/*/*/*/*/*/*/*/*/*/*/*`).then(
            async response => {
                await response.data.map((item, index) => {
                    if(inBase.tags.indexOf(item.tag) < 0) {
                        inBase.item.push(item)
                        inBase.tags.push(item.tag)
                        inBase.tags_id.push(item._id)
                    }
                })
            }
        )
        await api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/*`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.categories.push({
                        _id: item._id,
                        descricao: item.descricao
                    })
                })
            }
        )
        await api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.nivel1.push({
                        _id: item._id,
                        descricao: item.descricao
                    })
                })
            }
        )
        await api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.nivel2.push({
                        _id: item._id,
                        descricao: item.descricao,
                        id_nivel_loc1: item.id_nivel_loc1
                    })
                })
            }
        )
        await api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.nivel3.push({
                        _id: item._id,
                        descricao: item.descricao,
                        id_nivel_loc2: item.id_nivel_loc2
                    })
                })
            }
        )
        await api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.nivel4.push({
                        _id: item._id,
                        descricao: item.descricao,
                        id_nivel_loc3: item.id_nivel_loc3
                    })
                })
            }
        )

        await api.get(`/parceiro/${sessionStorage.getItem('idConta')}/*/*/*/1`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.parceiro.push({
                        _id: item._id,
                        nome: item.nome
                    })
                })
            }
        )

        setInBaseProps(inBase)
        setImportLoading(false)
    }

    function addOrRemoveListImport(index) {
        if(exportListSelected.indexOf(index) == -1) {
            setExportListSelected([...exportListSelected, index].sort())
        }else{
            let lindex = exportListSelected.indexOf(index)
            setExportListSelected([...(exportListSelected.slice(0, lindex)), ...(exportListSelected.slice(lindex + 1, exportListSelected.length))])
        }
    }

    function addAllToList() {
        setExportListSelected(exportList.map((item, index) => {
            return index
        }))
    }

    function selectedIndex(index) {
        return exportListSelected.includes(index)
    }

    function csv() {
        let body = exportList.filter((item, index) => {if(exportListSelected.includes(index)) {return item}}).map((item, index) => {
            const noFoto = ['', '/static/media/sem_foto.b83884cf.png', 'assets/imgs/sem_foto.png']
            const descAtivo = ['Inativo','Ativo', 'Descarte', 'Obsoleto']
            return [
                item._id,
                item.tag_vinculado ? item.tag_vinculado : 'NA',
                item.id_externo ? item.id_externo : '',
                [0,1,2,3].includes(item.ativo) ? descAtivo[item.ativo] : '',
                item.tag ? item.tag : '',
                item.id_categoria ? item.id_categoria.descricao : '',
                item.ean ? item.ean : '',
                item.inf_compl_1 ? item.inf_compl_1 : '',
                item.inf_compl_2 ? item.inf_compl_2 : '',
                item.inf_compl_3 ? item.inf_compl_3 : '',
                item.inf_compl_4 ? item.inf_compl_4 : '',
                item.inf_compl_5 ? item.inf_compl_5 : '',
                item.inf_compl_6 ? item.inf_compl_6 : '',
                item.inf_compl_7 ? item.inf_compl_7 : '',
                item.inf_compl_8 ? item.inf_compl_8 : '',
                item.inf_compl_9 ? item.inf_compl_9 : '',
                item.inf_compl_10 ? item.inf_compl_10 : '',
                item.id_parceiro ? item.id_parceiro.nome : '',
                item.id_nivel_loc_1 ? item.id_nivel_loc_1.descricao : '',
                item.id_nivel_loc_2 ? item.id_nivel_loc_2.descricao : '',
                item.id_nivel_loc_3 ? item.id_nivel_loc_3.descricao : '',
                item.id_nivel_loc_4 ? item.id_nivel_loc_4.descricao : '',
                item.observacao ? item.observacao : '',
                item.valor ? item.valor.toString() : '0',
                item.up_1 ? item.up_1 : '',
                item.up_2 ? item.up_2 : '',
                item.up_3 ? item.up_3 : '',
                item.up_4 ? item.up_4 : '',
                item.up_5 ? item.up_5 : '',
                item.dt_criacao ? item.dt_criacao : '',
                item.dt_alteracao ? item.dt_alteracao : '',
                item.id_usuario && item.id_usuario.nome? item.id_usuario.nome : '',
                //item.status_estoque && item.status_estoque != '' ? item.status_estoque : 'Sem Status',
                (item.foto && noFoto.indexOf(item.foto) == -1) ? `${process.env.REACT_APP_API_URL}/${item.foto}` : 'Sem foto',
                item.id_marca && brandList.filter((brand) =>  {if(brand?._id === item.id_marca) {return brand}})[0]?.marca?.length > 0 ? brandList.filter((brand) =>  {if(brand?._id === item.id_marca) {return brand}})[0]?.marca : '',
                item.id_modelo ? modelList.filter((model) =>  {if(model?._id === item.id_modelo) {return model}})[0]?.descricao : ''
            ]
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
        return [...body2]       
    }

    function csvFotoCompl() {
        let body = exportList.filter((item, index) => {if(exportListSelected.includes(index)) {return item}}).map((item, index) => {
            const noFoto = ['', '/static/media/sem_foto.b83884cf.png', 'assets/imgs/sem_foto.png']
            return item.foto_compl
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
        return [...body2]
    }

    function csvError(type) {
        let body = importList.filter((item) => {
            if(type == 'repetido' && item[28] == 'repetido'){ return item}
            if(type == 'erro' && item[28] == 'erro'){ return item}
            if(type == 'registrado' && item[28] == 'registrado'){ return item}
            if(type == 'ok' && item[28] == 'ok'){ return item}
        }).map((item, index) => {
            return [
                item[0] ? item[0] : 'NA',
                item[1] ? item[1] : '',
                item[2] ? item[2] : '1',
                item[3] ? item[3] : '',
                item[4] ? item[4] : '',
                item[5] ? item[5] : '',
                item[6] ? item[6] : '',
                item[7] ? item[7] : '',
                item[8] ? item[8] : '',
                item[9] ? item[9] : '',
                item[10] ? item[10] : '',
                item[11] ? item[11] : '',
                item[12] ? item[12] : '',
                item[13] ? item[13] : '',
                item[14] ? item[14] : '',
                item[15] ? item[15] : '',
                item[16] ? item[16] : '',
                item[17] ? item[17] : '',
                item[18] ? item[18] : '',
                item[19] ? item[19] : '',
                item[20] ? item[20] : '',
                item[21] ? item[21] : '',
                item[22] ? item[22] : '',
            ]
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
        if(body2.length){
            return [...body2]
          }else{
            return [['']]
          }
    }


    function changeImage(file) {
        if(categoriaNew == ''){
            Swal.fire({
                title: "Ops... !",
                text: `${t('messages.error_categorie_missing')}`,
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })
        }else if(tagNew == '') {
            Swal.fire({
                title: "Ops... !",
                text: `${t('messages.error_tag_missing')}`,
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })
        }else{
            let tagConf = tagNew
            if(!tagConf.includes(':')) {
                tagConf = "000000000000000000000000" + tagConf
                tagConf = tagConf.substr(tagConf.length - 24);
            }
            tagConf = tagConf.toUpperCase()
            let reg = []
            reg.push({
                ativo: ativoNew,
                foto: fotoUp,
                id_categoria: categoriaNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_usuario: sessionStorage.getItem('idUser'),
                id_externo: codExternoNew,
                id_item_vinculado: itemVinculadoNew,
                id_nivel_loc_1: nivelLoc1New,
                id_nivel_loc_2: nivelLoc2New,
                id_nivel_loc_3: nivelLoc3New,
                id_nivel_loc_4: nivelLoc4New,
                id_parceiro: parceiroNew,
                id_registro: idRegNew,
                inf_compl_1: inf1New,
                inf_compl_2: inf2New,
                inf_compl_3: inf3New,
                inf_compl_4: inf4New,
                inf_compl_5: inf5New,
                lido_registro: lidoReg,
                observacao: observacaoNew,
                tag: tagConf,
                up_1: up1New,
                up_2: up2New,
                up_3: up3New,
                up_4: up4New,
                up_5: up5New,
                _id: idItens
            })

            var reader = new FileReader()
            reader.readAsDataURL(file)

            reader.onloadend = async function(e, file) {
                var b64 = await b64Resize(this.result, 500, 500);

                let option = {
                    headers: { 'Content-Type': ['application/json'] }
                }

                api.post(`/save_jpeg/${sessionStorage.getItem('idConta')}`, [{foto: b64}], option).then(
                    response => {
                        let fotoRes = response.data[0].id_foto
                        setFotoUp(fotoRes)
                        setFoto(fotoRes && fotoRes != '' && fotoRes != '/static/media/sem_foto.b83884cf.png' && fotoRes != 'assets/imgs/sem_foto.png'
                        ? "https://api.inovacode.app.br/" + fotoRes : NoImg)
                        
                        reg[0].foto = fotoRes

                        api.post('/itemBO', reg, option).then(
                            response=> {
                                Swal.fire({
                                    title: "Sucesso !",
                                    text: 'Imagem salva com sucesso',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                                editItem(response.data[0])
                            },
                            response=> {
                                Swal.fire({
                                    title: "Ops... !",
                                    text: `${t('messages.error')}`,
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                            }
                        )
                    },
                    response => {
                        Swal.fire({
                            title: "Ops... !",
                            text: `${t('messages.error')}`,
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }
                )
            }
        }
    }

    async function chooseFileImport(file) {
        setImportLoading(true)
        setUploaded([])
        setImportList([])
        setImportPage(0)
        setImportErro(0)
        setImportRepetido(0)
        setImportRegistrado(0)
        const text = await file.text()
        const result = await parse(text)
        if(result.data.length <= 70000) {
            let taglist = []
            let erro = 0
            let repetido = 0
            let registrado = 0
            let okay = 0
            let tagSerialList = 0
            await setImportList(result.data.filter((item, index) => {
                if(item.length != 1) {
                    let tagSerial
                    let tagConfI
                    if(item[3] !== 0 && item[3] !== ''){
                        tagConfI = item[3]
                    }else if(!isNaN(item[1])){
                        tagConfI = item[1]
                    }else{
                        tagSerialList++
                        tagSerial = String(tagSerialList).padStart(5, '0')
                        tagSerial = tagSerial.padStart(24, '9')
                        tagConfI = tagSerial
                    }
                    taglist.push(tagConfI)
                    let importItem = item
                    if(!importItem[17]) {
                        importItem[17] = '0'
                    }
                    if(!tagConfI.includes(':')) {
                        tagConfI = "000000000000000000000000" + tagConfI
                        tagConfI = tagConfI.substr(tagConfI.length - 24);
                    }
                    tagConfI = tagConfI.toUpperCase()
                    if(
                        (tagConfI.match(/[^a-f0-9:]/gi)) ||
                        (item[17].match(/[^0-9.]/gi)) ||
                        (item[4] == '') ||
                        (item[15] != '' && item[14] == '') ||
                        (item[14] != '' && item[13] == '') ||
                        (item[13] != '' && item[12] == '')
                    ) {
                        importItem[28] = 'erro'
                        erro++
                    }else if(tagConfI != "000000000000000000000000" && taglist.indexOf(item[3] !== 0 && item[3] !== '' ? item[3] : !isNaN(item[1]) ? item[1] : tagSerial) < index) {
                        importItem[28] = 'repetido'
                        repetido++
                    }else if(tagConfI != "000000000000000000000000" && (inBaseProps.tags.includes(tagConfI))) {
                        importItem[28] = 'registrado'
                        importItem[29] = inBaseProps.tags_id[inBaseProps.tags.indexOf(tagConfI)]
                        importItem[18] = importItem[18] && importItem[18] != '' ? importItem[18] : inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].inf_compl_6
                        importItem[19] = importItem[19] && importItem[19] != '' ? importItem[19] : inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].inf_compl_7
                        importItem[20] = importItem[20] && importItem[20] != '' ? importItem[20] : inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].inf_compl_8
                        importItem[21] = importItem[21] && importItem[21] != '' ? importItem[21] : inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].inf_compl_9
                        importItem[22] = importItem[22] && importItem[22] != '' ? importItem[22] : inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].inf_compl_10
                        importItem[23] = importItem[23] && importItem[23] != '' ? importItem[23] : inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].up_1
                        importItem[24] = importItem[24] && importItem[24] != '' ? importItem[24] : inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].up_2
                        importItem[25] = importItem[25] && importItem[25] != '' ? importItem[25] : inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].up_3
                        importItem[26] = importItem[26] && importItem[26] != '' ? importItem[26] : inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].up_4
                        importItem[27] = importItem[27] && importItem[27] != '' ? importItem[27] : inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].up_5
                        importItem[30] = importItem[30] && importItem[30] != '' ? importItem[30] : inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].latitude
                        importItem[31] = importItem[31] && importItem[31] != '' ? importItem[31] : inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].longitude
                        importItem[36] = inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].foto
                        importItem[37] = inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].foto_compl
                        importItem[32] = importItem[32] && importItem[32] != '' ? importItem[32] : inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].id_marca
                        importItem[33] = importItem[33] && importItem[33] != '' ? importItem[33] : inBaseProps.item[inBaseProps.tags.indexOf(tagConfI)].id_modelo
                        registrado++
                    }else{
                        importItem[28] = 'ok'
                        importItem[29] = ''
                        okay++
                    }
                    importItem[3] = tagConfI
                    //importItem[17] = Number(importItem[17])
                    return importItem
                }
            }))
            setImportOkay(okay)
            setImportErro(erro)
            setImportRepetido(repetido)
            setImportRegistrado(registrado)
        }else {
            Swal.fire({
                title: "Ops... !",
                text: `${t('messages.error_file_size')}`,
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })  
        }
        setImportLoading(false)
    }

    /*function chamadoAlerta(params) {
        Swal.fire({
            title: "Atenção !",
            text: "Deseja realmente excluir este Item ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    Swal.fire({
                        title: "Sucesso !",
                        text: 'vc disse sim',
                        icon: 'success',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }else{
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Algo deu errado, por favor tente novamente mais tarde',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })               
                }
            }
        )
    }*/

    pdfMake.vfs = pdfFonts.pdfMake.vfs
    const visualizarImpressao = async (b64) => {
        let pdfFilter = await exportCounterList.filter((item, index) => {if(exportListSelectedCount.includes(index)) {return item}}).map((item, index) => {
            return [[index + 1], [item.descricao], [item.contador], [item.inf_compl_1]]
        })

        let linkeres = {}

        let pdfSignatures = []

        let dt = new Date()
        let dt_reg = ("0" + dt.getDate()).slice(-2) + "/" + ("0" + (dt.getMonth() + 1)).slice(-2) + "/" + dt.getFullYear()

        let headerDesc = `Relatório de contagem extraido dia: ${dt_reg}\n`
        headerDesc += `Status: ${exportCounterListInfo[0]}\n`
        headerDesc += `Nivel_1: ${exportCounterListInfo[1]}\nNivel_2: ${exportCounterListInfo[2]}\nNivel_3: ${exportCounterListInfo[3]}\nNivel_4: ${exportCounterListInfo[4]}`

        let classeImpressao = await new Impressao({
            PDFOrientation: 'portrait',
            imagesCompl: false,
            hLogo: b64?b64:'',
            hTitle: 'Relatório de Contagem',
            hDescription: headerDesc,
            hItems: ['#','CATEGORIA','QUANTIDADE','INF. COMPLEMENTAR 1'],
            images: linkeres,
            bItems: pdfFilter,
            fSignatures: pdfSignatures
        });

        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
    }

    function changeImageCount(file) {
        var reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onloadend = async function(e, file) {
            var b64 = await b64Resize(this.result, 500, 500);
            visualizarImpressao(b64)
        }
    }

    function askByFoto() {
        Swal.fire({
            title: `${t('messages.attention')}`,
            html: `${t('messages.add_logo')}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: `${t('messages.yes')}`,
            cancelButtonText: `${t('messages.no')}`
        }).then(
            result => {
                if(result.isConfirmed) {
                    let getFile = window.document.getElementById('itemImageCount')
                    getFile.click()
                }else{
                    visualizarImpressao()
                }
            }
        )
    }

    function addAllToListCount() {
        setExportListSelectedCount(exportCounterList.map((item, index) => {
            return index
        }))
    }
    
    function addOrRemoveListImportCount(index) {
        if(exportListSelectedCount.indexOf(index) == -1) {
            setExportListSelectedCount([...exportListSelectedCount, index].sort())
        }else{
            let lindex = exportListSelectedCount.indexOf(index)
            setExportListSelectedCount([...(exportListSelectedCount.slice(0, lindex)), ...(exportListSelectedCount.slice(lindex + 1, exportListSelectedCount.length))])
        }
    }
    
    function selectedIndexCount(index) {
        return exportListSelectedCount.includes(index)
    }

    function csvContador() {
        let body = exportCounterList.filter((item, index) => {if(exportListSelectedCount.includes(index)) {return item}}).map((item, index) => {
            return [
                item.descricao,
                item.contador,
            ]
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
    
        let dt = new Date()
        let dt_reg = ("0" + dt.getDate()).slice(-2) + "/" + ("0" + (dt.getMonth() + 1)).slice(-2) + "/" + dt.getFullYear()
    
        let header = [
            [`Extraido:`,dt_reg],
            [`Status:`,exportCounterListInfo[0]],
            [`Nivel_1:`,exportCounterListInfo[1]],
            [`Nivel_2:`,exportCounterListInfo[2]],
            [`Nivel_3:`,exportCounterListInfo[3]],
            [`Nivel_4:`,exportCounterListInfo[4]],
        ]
        if(body2.length){
            return [...header,[''],...body2]
          }else{
            return [...header,['']]
          }
    }

    function addFtCompl(file) {
        if(categoriaNew == ''){
            Swal.fire({
                title: "Ops... !",
                text: `${t('messages.error_categorie_missing')}`,
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })
        }else if(tagNew == '') {
            Swal.fire({
                title: "Ops... !",
                text: `${t('messages.error_tag_missing')}`,
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })
        }else{
            let tagConf = tagNew
            if(!tagConf.includes(':')) {
                tagConf = "000000000000000000000000" + tagConf
                tagConf = tagConf.substr(tagConf.length - 24);
            }
            tagConf = tagConf.toUpperCase()
            let reg = []
            reg.push({
                ativo: ativoNew,
                foto_compl: fotosCompl,
                id_categoria: categoriaNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_usuario: sessionStorage.getItem('idUser'),
                id_externo: codExternoNew,
                id_item_vinculado: itemVinculadoNew,
                id_parceiro: parceiroNew,
                observacao: observacaoNew,
                tag: tagConf,
                _id: idItens
            })

            var reader = new FileReader()
            reader.readAsDataURL(file)

            reader.onloadend = async function(e, file) {
                var b64 = await b64Resize(this.result, 500, 500);

                let option = {
                    headers: { 'Content-Type': ['application/json'] }
                }

                api.post(`/save_jpeg/${sessionStorage.getItem('idConta')}`, [{foto: b64}], option).then(
                    response => {
                        let fotoRes = response.data[0].id_foto
                        setFotosCompl([...fotosCompl, `https://api.inovacode.app.br/${fotoRes}`])
                        
                        reg[0].foto_compl = [...fotosCompl, `https://api.inovacode.app.br/${fotoRes}`]

                        api.post('/itemBO', reg, option).then(
                            response=> {
                                Swal.fire({
                                    title: `${t('messages.success')} !`,
                                    text: `${t('messages.success_image')}`,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                                //editItem(response.data[0])
                            },
                            response=> {
                                Swal.fire({
                                    title: "Ops... !",
                                    text: `${t('messages.error')}`,
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                            }
                        )
                    },
                    response => {
                        Swal.fire({
                            title: "Ops... !",
                            text: `${t('messages.error')}`,
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }
                )
            }
        }
    }

    function modifyCategorie(lista) {
        Swal.fire({
            title: "Atenção !",
            text: "Deseja realmente criar este Item ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    let reg = lista.map((item) => {
                        item.id_categoria.descricao = item.id_categoria.descricao.replace(find, replace);
                        return item;
                    });
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
                    api.post(`/itensDef/${sessionStorage.getItem('idConta')}`, reg, option
                    ).then(
                        response => {
                            Swal.fire({
                                title: "Sucesso !",
                                text: 'Categoria editada com sucesso',
                                icon: 'success',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                            cleaner()
                        },
                        response => {
                            if(response.response.data.error && response.response.data.error == 'Descrição já existente!') {
                                Swal.fire({
                                    title: "Ops... !",
                                    html: 'Esta descrição já está cadastrada em sua base',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '4000'
                                })
                            }else{
                                Swal.fire({
                                    title: "Ops... !",
                                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                            }
                        }
                    )
                    
                }
            }
        )
    }

    function findReplace(){
        let lista = items.filter((item)=>{
            {if(item.id_categoria?.descricao && item.id_categoria.descricao.includes(find)) return item}
        })

        if(lista.length == 0){
            Swal.fire({
                title: "Ops... !",
                text: 'Nenhum item encontrado',
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })
        }else{
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
            if(findtype == 'categoria' || findtype == ''){
                modifyCategorie(lista)
            }else if(findtype == 'tag'){
                for(let i = 0; i < lista.length; i++){
                    lista[i].tag.replace(find, replace)
                }
            }else if(findtype == 'observacao'){
                for(let i = 0; i < lista.length; i++){
                    lista[i].observacao.replace(find, replace)
                }
            }
            itemsBO()
        }
    }


    const handleMarkerClick = (item) => {
        console.log(item)
        setSelectedItem(item);
    };
  
    const handleInfoWindowClose = () => {
        setSelectedItem(null);
    };

    function generateRandomColor(i) {
        let cores = ['#ff595e', '#ffca3a', '#8ac926', '#1982c4', '#6a4c93'] 
        if(i > cores.length){
            console.log("failed")
            var r = Math.floor(Math.random() * 256);
            var g = Math.floor(Math.random() * 256);
            var b = Math.floor(Math.random() * 256);

            // Criar a string de cor no formato RGB
            var cor = 'rgb(' + r + ', ' + g + ', ' + b + ')';

            return cor;
        }else{
           return cores[i];
        }        

    } 

    function changeFunction(key) {
        switch (key) {
            case 0:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}> 
                        <Container fluid>
                            <Row className="justify-content-between">
                                <Col><h3 >{t('common.items')}</h3></Col>
                                <Col className="container-btn" sm={12} md={10}>
                                    <Dropdown align="end">
                                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                                            <span className="px-1">{t('actions.export')}</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={e=> {getExportList()}}>{t('actions.export')} {t('common.items')}</Dropdown.Item>
                                            <Dropdown.Item onClick={e=> {getExportListCount()}}>{t('actions.export')} {t('common.export_report')}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Button variant="light" className="ms-2" onClick={e => {setChange(1); getExportBrand()}}>{t('actions.import')}</Button>
                                    <Button variant="outline-custom-primary" className="mx-2" onClick={e=>{setChange(3)}}><FiMap className="me-2" style={{fontSize:'14px'}}/>{t('actions.map')}</Button>
                                    <Button variant="light" style={{background: '#2488f7', color:'#fff'}} onClick={e => {setChange(2);getInfoUser()}}>
                                        <span className="me-2">+</span>
                                        {t('actions.create')}
                                    </Button>
                                </Col>
                            </Row>
                           
                            <div className="p-4 bg-white border rounded mt-3">
                                <Row className="justify-content-between">
                                    <Col><h3>{t('common.items')}</h3></Col>
                                    <Col className="container-btn">
                                        <Button variant="light" className="shadow-sm m-1" onClick={e=>{getDashValues()}}><TbChartBar className="me-2"/>Carregar gráficos</Button>
                                    </Col>
                                </Row>
                                {showDash ? 
                                    <>
                                        {dashLoaded?
                                            <>
                                                <DashItems
                                                    dataItens={dashDataList}
                                                    totQtd={dashTotalQtd}
                                                    totVal={dashTotalValue}
                                                />
                                            </>
                                        : 
                                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                                <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                            </div>
                                        }
                                    </>
                                : ''} 
                                <Row className="p-3 flex-wrap">
                                    <Col className="rounded p-3" style={{background:'var(--bg-filter)'}}>
                                        <div className="d-flex align-items-center h5 fw-bold">
                                            <FiFilter/>
                                            <span className="px-2">{t('common.filter')}</span>
                                        </div>
                                        <Form>
                                            <Row className='g-2'>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3 px-1 fw-bold">
                                                        <Form.Label>Tag</Form.Label>
                                                        <Form.Control 
                                                            placeholder={t('items.placeholder_tag')}
                                                            value={tag == '*' ? '' : tag}
                                                            onChange={e=> {setTag(e.target.value == '' ? '*' : e.target.value)}}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3 px-1 fw-bold">
                                                        <Form.Label>{t('common.extern_code')}</Form.Label>
                                                        <Form.Control 
                                                            placeholder={t('items.placeholder_code')}
                                                            value={idExternoFilter == '*' ? '' : idExternoFilter}
                                                            onChange={e=> {setIdExternoFilter(e.target.value == '' ? '*' : e.target.value)}}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3 px-1 fw-bold">
                                                        <Form.Label>Status</Form.Label>
                                                        <Form.Select className="text-secondary" value={ativoNew} onChange={e=> {setStatus(e.target.value)}}>
                                                            <option value={'*'}></option>
                                                            <option value={'0'}>{t('common.inactive')}</option>
                                                            <option value={'1'}>{t('common.active')}</option>
                                                            <option value={'2'}>{t('common.disposal')}</option>
                                                            <option value={'3'}>{t('common.deprecated')}</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="filter fw-bold">
                                                        <Form.Label>{t('common.categories')}</Form.Label>
                                                        <SearchSelect
                                                            dataName={'categoria'}
                                                            lista={categoriaList ? categoriaList : []}
                                                            target={'descricao'}
                                                            preValue={''}
                                                            returnId={(e) => {setCategoria(e)}}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="filter fw-bold">
                                                        <Form.Label>{sessionStorage.getItem('idConta') === 'c68AeC-CZ' ? 'Geo' : `${t('common.level')} 1`}</Form.Label>
                                                        <SearchSelect
                                                            dataName={'nivel1'}
                                                            lista={nivelLoc1List ? nivelLoc1List : []}
                                                            target={'descricao'}
                                                            preValue={nivelLoc1}
                                                            returnId={(e) => {
                                                                setNivelLoc1(e)
                                                            }}
                                                            disable={sessionStorage.getItem('filterView') == 'true' && sessionStorage.getItem('nivel1') ? sessionStorage.getItem('filterView') : false}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="filter fw-bold">
                                                        <Form.Label>{sessionStorage.getItem('idConta') === 'c68AeC-CZ' ? 'Unidade' : `${t('common.level')} 2`}</Form.Label>
                                                        <SearchSelect
                                                            dataName={'nivel2'}
                                                            lista={nivelLoc2List ? nivelLoc2List : []}
                                                            target={'descricao'}
                                                            preValue={''}
                                                            returnId={(e) => {
                                                                setNivelLoc2(e)
                                                            }}
                                                            disable={sessionStorage.getItem('filterView') == 'true' && sessionStorage.getItem('nivel2') ? sessionStorage.getItem('filterView') : false}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="filter fw-bold">
                                                        <Form.Label>{sessionStorage.getItem('idConta') === 'c68AeC-CZ' ? 'Area' : `${t('common.level')} 3`}</Form.Label>
                                                        <SearchSelect
                                                            dataName={'nivel3'}
                                                            lista={nivelLoc3List ? nivelLoc3List : []}
                                                            target={'descricao'}
                                                            preValue={''}
                                                            returnId={(e) => {
                                                                setNivelLoc3(e)
                                                            }}
                                                            disable={sessionStorage.getItem('filterView') == 'true' && sessionStorage.getItem('nivel3') ? sessionStorage.getItem('filterView') : false}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="filter fw-bold">
                                                        <Form.Label>{sessionStorage.getItem('idConta') === 'c68AeC-CZ' ? 'Departamento' : `${t('common.level')} 4`}</Form.Label>
                                                        <SearchSelect
                                                            dataName={'nivel4'}
                                                            lista={nivelLoc4List ? nivelLoc4List : []}
                                                            target={'descricao'}
                                                            preValue={''}
                                                            returnId={(e) => {
                                                                setNivelLoc4(e)
                                                            }}
                                                            disable={sessionStorage.getItem('filterView') == 'true' && sessionStorage.getItem('nivel4') ? sessionStorage.getItem('filterView') : false}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col md={3}>
                                                    <Form.Label className="fw-bold">Informação Complementar</Form.Label>
                                                    <Form.Select value={advancedFilter} onChange={e=>{setAdvancedFilter(e.target.value)}}>
                                                        {headersCSV.slice(7, 17).map((options, index)=>(
                                                            <option value={index+1}>{options}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Col>
                                                <Col md={3} className="d-flex align-items-end">
                                                    <Form.Control placeholder="Pesquisar por..." value={advancedFilterValue} onChange={e=>{setAdvancedFilterValue(e.target.value)}}/>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label className="fw-bold">{t('common.partners')}</Form.Label>
                                                    <Form.Select value={filterByPartner} onChange={e=>{setFilterByPartner(e.target.value)}}>
                                                        <option value=""></option>
                                                        {parceiroList.map((options, index)=>(
                                                            <option value={options._id}>{options.nome}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-end p-3">
                                                <Button 
                                                    variant="primary"
                                                    style={{width:'150px'}} 
                                                    onClick={async e => {
                                                        await parceiroGet()
                                                        if(pageNumber != 0) {
                                                            setPageNumber(0)
                                                        }
                                                        else{
                                                            if(advancedFilterValue){
                                                                handleAdvancedFilter()
                                                            }else{
                                                                itemsBO()
                                                            }
                                                        }
                                                    }}>{t('actions.search')}
                                                </Button>
                                            </Row>
                                        </Form>
                                        
                                    </Col>
                                </Row>
                                <Row className="my-3 justify-content-between">
                                    <Col>
                                        {items[items.length - 1] && items[items.length - 1].total_reg > 1000 ? 
                                            <div className="d-flex flex-row ">
                                                <div className="numberPages" onClick={e=> {pageNumber > 0 ? setPageNumber(pageNumber - 1) : setPageNumber(pageNumber)}}><FiChevronsLeft/></div>
                                                {numberListGet().map((number, index) => (
                                                    <div className="numberPages" key={index} onClick={e => {setPageNumber(number - 1)}}>{number}</div>
                                                ))}
                                                <div className="numberPages" onClick={e=> {pageNumber < Math.ceil(items[items.length - 1].total_reg/ 1000) - 1 ? setPageNumber(pageNumber + 1) : setPageNumber(pageNumber)}}><FiChevronsRight/></div>
                                                <div className="numberPages">{items[items.length - 1] ? items[items.length - 1].total_reg : 0} itens</div>
                                            </div>
                                        :
                                            <div className="pagesContent">
                                            <div className="numberPages">{items[items.length - 1] ? items[items.length - 1].total_reg : 0} itens</div>
                                        </div>
                                        }
                                    </Col>
                                    <Col>
                                        <Form.Group as={Row} className="mb-3 justify-content-end">
                                            <Form.Label column className='text-end text-nowrap fw-medium text-black-50'>
                                                Itens por página:
                                            </Form.Label>
                                            <Col sm={2}>
                                                <Form.Select onChange={e=> {setPageLimit(e.target.value)}}>
                                                    <option value="10">10</option>
                                                    <option value="100">100</option>
                                                    <option value="500">500</option>
                                                    <option value="1000">1000</option>
                                                </Form.Select>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {!itemsLoaded ?
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                    </div>
                                :
                                    <Table responsive className="border shadow-sm">
                                        <thead >
                                            <tr>
                                                <th className="text-center">ID</th>
                                                <th>{t('common.categories')}</th>
                                                <th>Info 1-5</th>
                                                <th>Info 5-3</th>
                                                <th>{t('common.locate')}</th>
                                                <th><GoLocation/></th>
                                                <th>{t('common.images')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.slice(0, items.length - 1).map((item, index) => {
                                                return(
                                                    <tr
                                                        key={index}
                                                        onClick={e=> {editItem(item)}}
                                                    >
                                                        <th className="text-center">{pageNumber * 1000 + index + 1}</th>
                                                        <th>
                                                            <div className="fw-bold">{item.id_categoria ? item.id_categoria.descricao : ''}</div>
                                                            <div className="text-secondary">{item.tag}</div>
                                                        </th>
                                                        <th>
                                                            <div>{item.inf_compl_1 ? item.inf_compl_1 : ''}</div>
                                                            <div>{item.inf_compl_2 ? item.inf_compl_2 : ''}</div>
                                                            <div>{item.inf_compl_3 ? item.inf_compl_3 : ''}</div>
                                                            <div>{item.inf_compl_4 ? item.inf_compl_4 : ''}</div>
                                                            <div>{item.inf_compl_5 ? item.inf_compl_5 : ''}</div>
                                                        </th>
                                                        <th>
                                                            <div>{item.inf_compl_6 ? item.inf_compl_6 : ''}</div>
                                                            <div>{item.inf_compl_7 ? item.inf_compl_7 : ''}</div>
                                                            <div>{item.inf_compl_8 ? item.inf_compl_8 : ''}</div>
                                                            <div>{item.inf_compl_9 ? item.inf_compl_9 : ''}</div>
                                                            <div>{item.inf_compl_10 ? item.inf_compl_10 : ''}</div>
                                                        </th>
                                                        <th>
                                                            {item.id_nivel_loc_1 ? <div>{item.id_nivel_loc_1.descricao}</div> : 'N / A'}
                                                            {item.id_nivel_loc_2 ? <div>{item.id_nivel_loc_2.descricao}</div> : ''}
                                                            {item.id_nivel_loc_3 ? <div>{item.id_nivel_loc_3.descricao}</div> : ''}
                                                            {item.id_nivel_loc_4 ? <div>{item.id_nivel_loc_4.descricao}</div> : ''}
                                                        </th>
                                                        <th>{item.lido_registro == 1 ? "✓" : 'X'}</th>
                                                        <th>{item.foto && !fotoNone.includes(item.foto) ? <img src={`https://api.inovacode.app.br/${item.foto}`} width={70} height={70}/> : <p>{t('common.no_foto')}</p> }</th>
                                                    </tr>
                                                    )
                                                })}
                                            </tbody>
                                    </Table>
                                }
                                </div>
                            </Container>
                    </div>
                )
                break
            case 1:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <Container fluid>
                            {uppingImport ?
                                <div className='extra'>
                                    <div style={{marginLeft: 'calc(50vw - 75px)', marginTop: 'calc(50vh - 75px)', minHeight: 'auto', height: '200px'}}>
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                        </div>
                                    </div>
                                </div>
                            :''}
                            <Col className="d-flex justify-content-between p-3">
                                <h3>{t('actions.import')} {t('common.items')}</h3>
                                <div>
                                    {importList[0] ? <Button variant="light" className="me-2 text-uppercase" onClick={e=> {importSave()}}><FiSave className="me-2"/>{t('actions.import')}</Button> : ''}
                                    <Button variant="light" className="text-uppercase" onClick={e => cleaner()}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                                </div>
                            </Col>
                            <Col className="border bg-white rounded px-3">
                                <Col className="border-bottom py-3">
                                    <h4>{t('items.manage_import')}</h4>
                                </Col>
                                {!importLoading ?
                                    <Col className="p-3">
                                        <input id='dropFile' style={{display: 'none'}} type='file' accept={fileTypes} onChange={e=> {
                                            e.preventDefault()
                                            if(e.target.files[0]) {
                                                chooseFileImport(e.target.files[0])
                                            }
                                        }}/>
                                        <div 
                                            className="p-5 rounded d-flex justify-content-center align-items-center flex-column input-file"
                                            style={{
                                                border:'3px dashed #d8e4f7'
                                            }}
                                            onDragOver={e=> {e.preventDefault()}}
                                            onDrop={e=> {
                                                e.preventDefault()
                                                if(fileTypes.includes(e.dataTransfer.files[0].type))
                                                chooseFileImport(e.dataTransfer.files[0])
                                            }}
                                            onClick={e=>{
                                                let getFile = window.document.getElementById('dropFile')
                                                getFile.click()
                                            }}
                                        >
                                            <FiUpload fontSize={64} style={{color:'var(--primary-color)'}}/>
                                            <h6>{t('items.upload_file')}</h6>
                                        </div>
                                    </Col>
                                : ' '}
                             {!importLoading ?
                                <Row className='g-3'>
                                    <Col>
                                        <CSVLink data={csvError('ok')} separator={';'} filename={'Relatório-de-novos.csv'}>
                                            <Button variant="outline-primary" className="w-100" style={{fontSize:'18px'}}><FaFileCsv/>Gerar relatório de itens novos</Button>
                                        </CSVLink>
                                    </Col>
                                    <Col>
                                        <CSVLink data={csvError('registrado')} separator={';'} filename={'Relatório-de-registrados.csv'}>
                                            <Button variant="outline-primary" className="w-100" style={{fontSize:'18px'}}><FaFileCsv/>Gerar relatório de itens registrados</Button>
                                        </CSVLink>
                                    </Col>
                                    <Col>
                                        <CSVLink data={csvError('repetido')} separator={';'} filename={'Relatório-de-repetidos.csv'}>
                                            <Button variant="outline-primary" className="w-100" style={{fontSize:'18px'}}><FaFileCsv/> {t('items.report_duplicated')}</Button>
                                        </CSVLink>
                                    </Col>
                                    <Col>
                                        <CSVLink data={csvError('erro')} separator={';'} filename={'Relatório-de-incorretos.csv'}>
                                            <Button variant="outline-primary" className="w-100" style={{fontSize:'18px'}}><FaFileCsv/>{t('items.report_wrong')}</Button>
                                        </CSVLink>
                                    </Col>
                                </Row>
                            :''}
                            {!importLoading ?
                            <Row className="py-3">
                                <Col>
                                    <h6>{t('items.show_items')}:</h6>
                                    <div className="d-flex flex-row me-3">
                                        <div className="custom-checkbox fw-bold me-1 border text-dark" style={{backgroundColor: '#FFFFFF', textAlign:'center', cursor: 'pointer'}} onClick={e=>{importViewChange(3)}}>{importView3 ? <FaCheck/> : ''}</div> Okay ({importOkay} itens)
                                    </div>
                                    <div className="d-flex flex-row me-3">
                                        <div className="custom-checkbox fw-bold me-1" style={{backgroundColor: '#d1e7dd', border:'1px solid #478d72', color:'#478d72', textAlign:'center', cursor: 'pointer'}} onClick={e=>{importViewChange(0)}}>{importView0 ? <FaCheck/> : ''}</div> {t('items.filter_registered')} ({importRegistrado} itens)
                                    </div>
                                    <div className="d-flex flex-row me-3">
                                        <div className="custom-checkbox fw-bold me-1" style={{backgroundColor: '#ced4da', border:'1px solid #576270', color:'#576270', textAlign:'center', cursor: 'pointer'}} onClick={e=>{importViewChange(1)}}>{importView1 ? <FaCheck/> : ''}</div> {t('items.filter_tag')} ({importRepetido} itens)
                                    </div>
                                    <div className="d-flex flex-row me-3">
                                        <div className="custom-checkbox fw-bold me-1" style={{backgroundColor: '#f1aeb5', border:'1px solid #c25559', color:'#c25559', textAlign:'center', cursor: 'pointer'}} onClick={e=>{importViewChange(2)}}>{importView2 ? <FaCheck/> : ''}</div> {t('items.filter_incorrect')} ({importErro} itens)
                                    </div>
                                </Col>
                                {importList.filter((item) => {
                                                if(importView0 && item[28] == 'registrado'){ return item}
                                                if(importView1 && item[28] == 'repetido'){ return item}
                                                if(importView2 && item[28] == 'erro'){ return item}
                                                if(importView3 && item[28] == 'ok'){ return item}
                                            }).length > 1000 ? 
                                    <Col className="d-flex align-items-end justify-content-end">
                                        <div className="pagesContent" style={{marginTop: '10px'}}>
                                        <div className="numberPages" onClick={e=> {importPage > 0 ? setImportPage(importPage - 1) : setImportPage(importPage)}}>Anterior</div>
                                        {importNumberListGet().map((number, index) => (
                                            <div className="numberPages" key={index} onClick={e => {setImportPage(number - 1)}}>{number}</div>
                                        ))}
                                        <div className="numberPages" onClick={e=> {importPage < Math.ceil(importList.length / 1000) - 1 ? setImportPage(importPage + 1) : setImportPage(importPage)}}>Próximo</div>
                                        <div className="numberPages">{t('common.items')}: {importList.length}</div>
                                        <div className="numberPages">{t('common.imported')}: {uploaded.length}</div>
                                        </div>
                                    </Col>
                                :
                                    <Col className="d-flex align-items-end justify-content-end">
                                        <div className="pagesContent" style={{marginTop: '10px'}}>
                                            <div className="numberPages">{t('common.items')}: {importList.length}</div>
                                            <div className="numberPages">{t('common.imported')}: {uploaded.length}</div>
                                        </div>
                                    </Col>
                                }
                                
                            </Row> : ''}
                        {importLoading ?
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '150px'}}>
                                <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                            </div>
                        :
                            <div>
                                <Table responsive className="rounded border text-uppercase">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>TAG_VINC</th>
                                            <th>{t('common.extern_code')}</th>
                                            <th>STATUS</th>
                                            <th>TAG</th>
                                            <th>{t('common.categories')}</th>
                                            <th>EAN</th>
                                            <th>INF._COMPL._1</th>
                                            <th>INF._COMPL._2</th>
                                            <th>INF._COMPL._3</th>
                                            <th>INF._COMPL._4</th>
                                            <th>INF._COMPL._5</th>
                                            <th>{t('common.partners')}</th>
                                            <th>NIVEL_1</th>
                                            <th>NIVEL_2</th>
                                            <th>NIVEL_3</th>
                                            <th>NIVEL_4</th>
                                            <th>{t('common.observation')}</th>
                                            <th>{t('common.value')}</th>
                                            <th>INF._COMPL._6</th>
                                            <th>INF._COMPL._7</th>
                                            <th>INF._COMPL._8</th>
                                            <th>INF._COMPL._9</th>
                                            <th>INF._COMPL._10</th>
                                            <th>UP_1</th>
                                            <th>UP_2</th>
                                            <th>UP_3</th>
                                            <th>UP_4</th>
                                            <th>UP_5</th>
                                            <th>LATITUDE</th>
                                            <th>LONGITUDE</th>
                                            <th>MARCA</th>
                                            <th>MODELO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {importList.filter((item) => {
                                            if(importView0 && item[28] == 'registrado'){ return item}
                                            if(importView1 && item[28] == 'repetido'){ return item}
                                            if(importView2 && item[28] == 'erro'){ return item}
                                            if(importView3 && item[28] == 'ok'){ return item}
                                        }).slice(importPage * 1000, (importPage * 1000) + 1000).map((item, index) => (
                                            <tr key={index}
                                                style={colorImportList(item[28])}
                                            >
                                                <th>{(importPage * 1000) + index + 1}</th>
                                                <th>{item[0]}</th>
                                                <th>{item[1]}</th>
                                                <th>{item[2]}</th>
                                                <th>{item[3]}</th>
                                                <th>{item[4]}</th>
                                                <th>{item[5]}</th>
                                                <th>{item[6]}</th>
                                                <th>{item[7]}</th>
                                                <th>{item[8]}</th>
                                                <th>{item[9]}</th>
                                                <th>{item[10]}</th>
                                                <th>{item[11]}</th>
                                                <th>{item[12]}</th>
                                                <th>{item[13]}</th>
                                                <th>{item[14]}</th>
                                                <th>{item[15]}</th>
                                                <th>{item[16]}</th>
                                                <th>{item[17]}</th>
                                                <th>{item[18]}</th>
                                                <th>{item[19]}</th>
                                                <th>{item[20]}</th>
                                                <th>{item[21]}</th>
                                                <th>{item[22]}</th>
                                                <th>{item[23]}</th>
                                                <th>{item[24]}</th>
                                                <th>{item[25]}</th>
                                                <th>{item[26]}</th>
                                                <th>{item[27]}</th>
                                                <th>{item[30]}</th>
                                                <th>{item[31]}</th>
                                                <th>{item[32]}</th>
                                                <th>{item[33]}</th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        }
                            </Col>
                        </Container>
                    </div>
                )
                break
            case 2:
                return(
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <ItemDetails item={editingItem} infoComplLabels={infoNamesList} onChange={e=>{setChange(e)}}/>
                    </div>
                )
                break
            case 3:
                return(
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer p-0" : "m-5"}>
                        <Container fluid>
                            {!itemsLoaded ?
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                </div>
                            :
                                <>
                                    { editing == 1 ?
                                        <>
                                            <Row className="justify-content-between p-3">
                                                <Col><h1>{t('common.map')}</h1></Col>
                                                <Col className="d-flex justify-content-end">
                                                    <Button variant="light" onClick={e => {setChange(2)}}><FiArrowLeft/>{t('actions.exit')}</Button>
                                                </Col>
                                            </Row>
                                            <Container fluid>
                                                <GoogleMap
                                                    className="rounded-5"
                                                    mapContainerStyle={mapContainerStyle}
                                                    zoom={20}
                                                    center={{lat: Number.parseFloat(latitude), lng: Number.parseFloat(longitude)}}
                                                >
                                                    {mapData.filter((item) => {
                                                            if(item.longitude) {
                                                                return item
                                                            }
                                                        }).map((item, index)=> {
                                                        return (
                                                            <Marker
                                                                key = {index}
                                                                position={{
                                                                    lat: Number.parseFloat(item.latitude),
                                                                    lng: Number.parseFloat(item.longitude)
                                                                }}
                                                            />
                                                        )}
                                                )} 
                                                </GoogleMap>
                                            </Container>
                                        </>
                                    :
                                        <>
                                            { showMap && isLoaded ?
                                                <>
                                                    <Row className="justify-content-between p-3">
                                                        <Col><h1>{t('common.map')}</h1></Col>
                                                        <Col className="d-flex justify-content-end">
                                                            <Button variant="light" onClick={e => {setChange(0)}}><FiArrowLeft/>{t('actions.exit')}</Button>
                                                        </Col>
                                                    </Row>
                                                    <Row className="px-3 flex-wrap">
                                                        <Col className="rounded p-3">
                                                            <div className="d-flex align-items-center h5">
                                                                <FiFilter/>
                                                                <span className="px-2">{t('common.filter')}</span>
                                                            </div>
                                                            <Form>
                                                                <Row className='g-2'>
                                                                    <Col md={3}>
                                                                        <Form.Group className="mb-3 px-1">
                                                                            <Form.Label>Tag</Form.Label>
                                                                            <Form.Control 
                                                                                placeholder={t('items.placeholder_tag')}
                                                                                value={tag == '*' ? '' : tag}
                                                                                onChange={e=> {setTag(e.target.value == '' ? '*' : e.target.value)}}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <Form.Group className="mb-3 px-1">
                                                                            <Form.Label>{t('common.extern_code')}</Form.Label>
                                                                            <Form.Control 
                                                                                placeholder={t('items.placeholder_code')}
                                                                                value={idExternoFilter == '*' ? '' : idExternoFilter}
                                                                                onChange={e=> {setIdExternoFilter(e.target.value == '' ? '*' : e.target.value)}}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <Form.Group className="mb-3 px-1">
                                                                            <Form.Label>Status</Form.Label>
                                                                            <Form.Select className="text-secondary" value={status} onChange={e=> {setStatus(e.target.value)}}>
                                                                                <option value={'*'}></option>
                                                                                <option value={'0'}>{t('common.inactive')}</option>
                                                                                <option value={'1'}>{t('common.active')}</option>
                                                                                <option value={'2'}>{t('common.disposal')}</option>
                                                                                <option value={'3'}>{t('common.deprecated')}</option>
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="filter">
                                                                            <Form.Label>{t('common.categories')}</Form.Label>
                                                                            <SearchSelect
                                                                                dataName={'categoria'}
                                                                                lista={categoriaList ? categoriaList : []}
                                                                                target={'descricao'}
                                                                                preValue={''}
                                                                                returnId={(e) => {setCategoria(e)}}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="filter">
                                                                            <Form.Label>{t('common.level')} 1</Form.Label>
                                                                            <SearchSelect
                                                                                dataName={'nivel1'}
                                                                                lista={nivelLoc1List ? nivelLoc1List : []}
                                                                                target={'descricao'}
                                                                                preValue={nivelLoc1}
                                                                                returnId={(e) => {
                                                                                    setNivelLoc1(e)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="filter">
                                                                            <Form.Label>{t('common.level')} 2</Form.Label>
                                                                            <SearchSelect
                                                                                dataName={'nivel2'}
                                                                                lista={nivelLoc2List ? nivelLoc2List : []}
                                                                                target={'descricao'}
                                                                                preValue={''}
                                                                                returnId={(e) => {
                                                                                    setNivelLoc2(e)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="filter">
                                                                            <Form.Label>{t('common.level')} 3</Form.Label>
                                                                            <SearchSelect
                                                                                dataName={'nivel3'}
                                                                                lista={nivelLoc3List ? nivelLoc3List : []}
                                                                                target={'descricao'}
                                                                                preValue={''}
                                                                                returnId={(e) => {
                                                                                    setNivelLoc3(e)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <div className="filter">
                                                                            <Form.Label>{t('common.level')} 4</Form.Label>
                                                                            <SearchSelect
                                                                                dataName={'nivel4'}
                                                                                lista={nivelLoc4List ? nivelLoc4List : []}
                                                                                target={'descricao'}
                                                                                preValue={''}
                                                                                returnId={(e) => {
                                                                                    setNivelLoc4(e)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="justify-content-end p-3">
                                                                    <Button 
                                                                        variant="primary" 
                                                                        style={{width:'150px'}} 
                                                                        onClick={async e => {
                                                                            await parceiroGet()
                                                                            if(pageNumber != 0) {
                                                                                setPageNumber(0)
                                                                            }else{
                                                                                itemsBO()
                                                                            }
                                                                        }}>{t('actions.search')}
                                                                        
                                                                    </Button>
                                                                </Row>
                                                            </Form>
                                                        </Col>
                                                    </Row>
                                                    <Container fluid>
                                                        <GoogleMap
                                                                className='mapStyle'
                                                                mapContainerStyle={mapContainerStyle}
                                                                zoom={18}
                                                                center={{lat: Number.parseFloat(items[0].latitude), lng: Number.parseFloat(items[0].longitude)}}
                                                            >
                                                                {mapData.filter((item) => {
                                                                        if(item.longitude) {
                                                                            return item
                                                                        }
                                                                        
                                                                    }).map((item, index)=> {
                                                                    return (
                                                                        <>
                                                                            <Circle
                                                                                key = {index}
                                                                                center={{
                                                                                    lat: Number.parseFloat(item.latitude),
                                                                                    lng: Number.parseFloat(item.longitude)
                                                                                }}
                                                                                onClick={() => handleMarkerClick(item)}
                                                                                options = {{
                                                                                    strokeColor: colorMap[item.id_nivel_loc_1._id] ?? '#ee685c',
                                                                                    strokeOpacity: 0.5,
                                                                                    strokeWeight: 10,
                                                                                    fillColor: colorMap[item.id_nivel_loc_1._id] ?? '#ee685c',
                                                                                    fillOpacity: 1,
                                                                                    radius: 3,
                                                                                    zIndex: 1
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                                )}
                                                                {selectedItem && (
                                                                    <InfoWindow
                                                                    position={{
                                                                        lat: Number.parseFloat(selectedItem.latitude),
                                                                        lng: Number.parseFloat(selectedItem.longitude),
                                                                    }}
                                                                    onCloseClick={handleInfoWindowClose}
                                                                    >
                                                                        <div>
                                                                            {/* Exiba as informações relevantes do item aqui */}
                                                                            <h5 className='fw-bold text-uppercase'>{selectedItem.id_categoria.descricao}</h5>
                                                                            <p className='text-secondary m-1'>Local: <span className='text-primary'>{selectedItem.latitude}, {selectedItem.longitude}</span></p>
                                                                            <p className='text-secondary m-1'>Tag: <span className='text-primary'>{selectedItem.tag}</span></p>
                                                                            <div className='d-flex'>
                                                                                <p className='text-secondary m-1 me-2'>Niveis:</p>
                                                                                <div>
                                                                                    <p className='text-primary m-1'>{selectedItem.id_nivel_loc_1?.descricao}</p>
                                                                                    <p className='text-primary m-1'>{selectedItem.id_nivel_loc_2?.descricao}</p>
                                                                                    <p className='text-primary m-1'>{selectedItem.id_nivel_loc_3?.descricao}</p>
                                                                                    <p className='text-primary m-1'>{selectedItem.id_nivel_loc_4?.descricao}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                                                <Button
                                                                                    variant="outline-secondary"
                                                                                    className='mt-3 mx-auto rounded-circle d-flex justify-content-center align-items-center p-0'
                                                                                    style={{width: '24px', height: '24px'}}
                                                                                    onClick={() => setOpen(!open)}
                                                                                    aria-controls="example-collapse-text"
                                                                                    aria-expanded={open}
                                                                                >
                                                                                    {open ? <IoIosArrowUp/> :  <IoIosArrowDown />}
                                                                                </Button>
                                                                                <Collapse in={open}>
                                                                                    <div id="example-collapse-text">
                                                                                        <p className='text-secondary m-1'>{infoNamesList[0] ?? `${t('common.info_label')} 1`}: <span className='text-primary'>{selectedItem.inf_compl_1}</span></p>
                                                                                        <p className='text-secondary m-1'>{infoNamesList[1]?? `${t('common.info_label')} 2`}: <span className='text-primary'>{selectedItem.inf_compl_2}</span></p>
                                                                                        <p className='text-secondary m-1'>{infoNamesList[2]?? `${t('common.info_label')} 3`}: <span className='text-primary'>{selectedItem.inf_compl_3}</span></p>
                                                                                        <p className='text-secondary m-1'>{infoNamesList[3]?? `${t('common.info_label')} 4`}: <span className='text-primary'>{selectedItem.inf_compl_4}</span></p>
                                                                                        <p className='text-secondary m-1'>{infoNamesList[4]?? `${t('common.info_label')} 5`}: <span className='text-primary'>{selectedItem.inf_compl_5}</span></p>
                                                                                        <p className='text-secondary m-1'>{infoNamesList[5]?? `${t('common.info_label')} 6`}: <span className='text-primary'>{selectedItem.inf_compl_6}</span></p>
                                                                                        <p className='text-secondary m-1'>{infoNamesList[6]?? `${t('common.info_label')} 7`}: <span className='text-primary'>{selectedItem.inf_compl_7}</span></p>
                                                                                        <p className='text-secondary m-1'>{infoNamesList[7]?? `${t('common.info_label')} 8`}: <span className='text-primary'>{selectedItem.inf_compl_8}</span></p>
                                                                                        <p className='text-secondary m-1'>{infoNamesList[8]?? `${t('common.info_label')} 9`}: <span className='text-primary'>{selectedItem.inf_compl_9}</span></p>
                                                                                        <p className='text-secondary m-1'>{infoNamesList[9]?? `${t('common.info_label')} 10`}: <span className='text-primary'>{selectedItem.inf_compl_10}</span></p>
                                                                                    </div>
                                                                                </Collapse>
                                                                            </div>
                                                                        </div>
                                                                    </InfoWindow>
                                                                )}
                                                        </GoogleMap>
                                                    </Container>
                                                </>
                                            :
                                            <div className="title">
                                                <h1>Itens não possuem localização cadastrada</h1>
                                                <div>
                                                    <button onClick={e => {cleaner()}}>VOLTAR</button>
                                                </div>
                                            </div>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </Container>
                    </div>
                )
            break
            case 4:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>                  
                        <Container fluid>
                            <Col className="d-flex justify-content-between p-3">
                                <h3>{t('actions.export')} {t('common.items')}</h3>
                                <div>
                                    {importList[0] ? <Button variant="light" className="me-2 text-uppercase" onClick={e=> {importSave()}}><FiSave className="me-2"/>{t('actions.import')}</Button> : ''}
                                    <Button variant="light" className="text-uppercase" onClick={e => cleaner()}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                                </div>
                            </Col>
                            {exportListLoading ?
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: '150px'}}>
                                    <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                </div>
                            : 
                                <Col className="border bg-white p-4 rounded">
                                    <Form.Group as={Col} md={3} className="my-3">
                                        <Form.Label>{t('actions.search_by')} {t('common.observation')}</Form.Label>
                                        <span className='d-flex flex-row'>
                                            <Form.Control
                                                type="text"
                                                value={observacao}
                                                onChange={(e)=> {setObservacao(e.target.value)}}
                                            />
                                            <Button onClick={ExportListFilter} className="px-3 ms-2"><FiSearch/></Button>
                                        </span>
                                    </Form.Group>
                                    <Col className="border-bottom d-flex justify-content-between p-2">
                                        <h4>{t('items.manage_itens')}</h4>
                                        <div>
                                            <Button variant="light" className="me-2 text-uppercase px-3" style={{height: '38px'}} onClick={e => {setChange(6)}}><FaFilePdf className="me-2"/>{t('actions.export')} PDF</Button>
                                            <Button variant="light" className="text-uppercase px-3" style={{height: '38px'}} onClick={e => {setChange(5)}}><FaFileCsv className="me-2"/>{t('actions.export')} CSV</Button>
                                        </div>
                                    </Col>
                                    <Col></Col>
                                    <Row className='my-2'>
                                        {exportList.length > 1000 ? 
                                            <Col>
                                                <div className="pagesContent" style={{marginTop: '10px'}}>
                                                    <div className="numberPages" onClick={e=> {exportPage > 0 ? setExportPage(exportPage - 1) : setExportPage(exportPage)}}>Anterior</div>
                                                    {exportNumberListGet().map((number, index) => (
                                                        <div className="numberPages" key={index} onClick={e => {setExportPage(number - 1)}}>{number}</div>
                                                    ))}
                                                    <div className="numberPages" onClick={e=> {exportPage < Math.ceil(exportList.length / 1000) - 1 ? setExportPage(exportPage + 1) : setExportPage(exportPage)}}>Próximo</div>
                                                    <div className="numberPages">{t('common.items')}: {exportList.length}</div>
                                                </div>
                                            </Col>
                                        :
                                            <Col>
                                                <div className="pagesContent" style={{marginTop: '10px'}}>
                                                    <div className="numberPages">{t('common.items')}: {exportList.length}</div>
                                                </div>
                                            </Col>
                                        }
                                        <Col className="d-flex justify-content-end align-items-center gx-3">
                                            <div>
                                                <label className='fw-semibold'>{t('actions.sort_by')}</label>
                                                <select
                                                    value={ordenationExport}
                                                    onChange={ordenateBy}
                                                    className="p-1 ms-2 border-0 border-bottom rounded-0"
                                                >
                                                    <option value={''}></option>
                                                    <option value={'0'}>{t('common.observation')}</option>
                                                    <option value={'1'}>{t('common.created_at')}</option>
                                                </select>
                                            </div>
                                            <Button variant="light" className='mx-3' style={{fontSize:'14px', height:'36px', background: '#2488f7', color:'#fff'}} onClick={e=> {addAllToList()}}>{t('actions.select_all')}</Button>
                                            <Button variant="light" style={{fontSize:'14px', height:'36px'}} onClick={e => {setExportListSelected([])}}>{t('actions.deselect_all')}</Button>
                                        </Col>
                                    </Row>
                                    <Table responsive className="border rounded">
                                        <thead className="text-uppercase">
                                            <tr>
                                                <th>#</th>
                                                <th>{t('common.categories')}</th>
                                                <th>{t('common.info')}</th>
                                                <th>{t('common.observation')}</th>
                                                <th><GoLocation className="table_icons"/></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {exportList.slice(exportPage * 1000, (exportPage * 1000) + 1000).map((item, index) => {
                                            return (
                                                <tr
                                                    className='edit'
                                                    style={selectedIndex(((exportPage * 1000) + index)) ? {background: '#919191', color: '#F8F8F8'} : {}}
                                                    key={((exportPage * 1000) + index)}
                                                    onClick ={e=> {addOrRemoveListImport(((exportPage * 1000) + index))}}
                                                >
                                                    <th>{(exportPage * 1000) + index + 1}</th>
                                                    <th>
                                                        <div>{item.id_categoria ? item.id_categoria.descricao : ''}</div>
                                                        <div>{item.tag}</div>
                                                    </th>
                                                    <th>
                                                        {item.inf_compl_1 ? <div>{item.inf_compl_1}</div> : 'N / A'}
                                                        {item.inf_compl_2 ? <div>{item.inf_compl_2}</div> : ''}
                                                        {item.inf_compl_3 ? <div>{item.inf_compl_3}</div> : ''}
                                                        {item.inf_compl_4 ? <div>{item.inf_compl_4}</div> : ''}
                                                        {item.inf_compl_5 ? <div>{item.inf_compl_5}</div> : ''}
                                                    </th>
                                                    <th>
                                                        {item.observacao ? <div>{item.observacao}</div> : 'N / A'}
                                                    </th>
                                                    <th>{item.lido_registro == 1 ? "✓" : 'X'}</th>
                                                </tr>
                                            )})}
                                        </tbody>
                                    </Table>  
                                </Col>
                            }
                        </Container>
                    </div>
                )
                break
            case 5:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <Container fluid>
                            <Col className="d-flex justify-content-between" id="colection">
                                <h3>{t('actions.generate')} CSV</h3>
                                <Button variant="light" className="text-uppercase" onClick={e => {setChange(4)}}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                            </Col>
                            <ExportCSV headers={headersCSV} listItens={csv()} listFotosCompl={csvFotoCompl()} modulo={'itens'}/>
                        </Container>
                    </div>
                )
                break
            case 6:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>                  
                        <Container fluid>
                            <Col className="d-flex justify-content-between" id="colection">
                                <h3>{t('actions.generate')} PDF</h3>
                                <Button variant="light" className="text-uppercase" onClick={e => {setChange(4)}}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                            </Col>
                            <ExportPDF headers={headersCSV} listItens={csv()} listFotosCompl={csvFotoCompl()} modulo={'itens'}/>
                        </Container>
                    </div>
                )
                break
            case 7:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>                  
                        <Container fluid>
                            <Col className="d-flex justify-content-between p-3">
                                <h3>{t('common.export_report')}</h3>
                                <div>
                                    {importList[0] ? <Button variant="light" className="me-2 text-uppercase" onClick={e=> {importSave()}}><FiSave className="me-2"/>{t('actions.import')}</Button> : ''}
                                    <Button variant="light" className="text-uppercase" onClick={e => cleaner()}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                                </div>
                            </Col>
                            {exportCounterListLoading ?
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: '150px'}}>
                                    <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                </div>
                            :
                                <Col className="border bg-white p-3 rounded">
                                    <Col className="border-bottom d-flex justify-content-between p-2">
                                        <h4>{t('items.manage_itens')}</h4>
                                        <div>
                                            <input id='itemImageCount' style={{display: 'none'}} type='file' accept='image/*' onChange={e=> {
                                                e.preventDefault()
                                                if(e.target.files[0]) {
                                                    changeImageCount(e.target.files[0])
                                                }
                                                e.target.value = ''
                                            }}/>
                                            <Button variant="light" className="me-2 text-uppercase px-3" style={{height: '38px'}} onClick={e => {askByFoto()}}><FaFilePdf className="me-2"/>{t('actions.export')} PDF</Button>
                                            <CSVLink className="csv" data={csvContador()} separator={';'} filename={'Relatório-de-contagem.csv'}>
                                                <Button variant="light" className="text-uppercase px-3 link-dark" style={{height: '38px'}}><FaFileCsv className="me-2"/>{t('actions.export')} CSV</Button>
                                            </CSVLink>
                                        </div>
                                    </Col>
                                    <Col className='d-flex justify-content-end p-2'>
                                        <Button variant="light" className="me-1" style={{fontSize:'14px', height:'36px', background: '#2488f7', color:'#fff'}} onClick={e=> {addAllToListCount()}}>{t('actions.select_all')}</Button>
                                        <Button variant="light" style={{fontSize:'14px', height:'36px'}} onClick={e => {setExportListSelectedCount([])}}>{t('actions.deselect_all')}</Button>
                                    </Col>
                                    <Table responsive className="border rounded">
                                        <thead>
                                            <tr id="header">
                                                <th>#</th>
                                                <th>{t('common.categories')}</th>
                                                <th>{t('common.count')}</th>
                                                <th>{t('common.info_label')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {exportCounterList.map((item, index) => (
                                                <tr key={index}
                                                    className='edit'
                                                    style={selectedIndexCount((index)) ? {background: '#919191', color: '#F8F8F8'} : {}}
                                                    onClick={e=> {addOrRemoveListImportCount(index)}}
                                                >
                                                    <th>{index + 1}</th>
                                                    <th>{item.descricao}</th>
                                                    <th>{item.contador}</th>
                                                    <th>{item.inf_compl_1}</th>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            }
                        </Container>
                    </div>
                )
                break
            case 8:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <Container fluid>
                            
                            <Col className="p-3 bg-white border rounded shadow-sm">
                                <Row className="justify-content-between py-3">
                                    <Col>
                                        <h3>{t('common.info')}</h3>
                                    </Col>
                                    <Col className="container-btn mb-3">
                                        <Button variant="light" className="text-uppercase" onClick={e => {setChange(2)}}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                                    </Col>
                                </Row>
                                <Tab.Container defaultActiveKey="info_1">
                                    <Row className='justify-content-between'>
                                        <Col>
                                            <Nav variant="pills">
                                                <Nav.Item className="mb-2 w-100">
                                                    <Nav.Link eventKey="info_1">{infoNamesList[0] != '' ? infoNamesList[0] : `${t('common.info_label')} 1`}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="mb-2 w-100">
                                                    <Nav.Link eventKey="info_2">{infoNamesList[1] != '' ? infoNamesList[1] : `${t('common.info_label')} 2`}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="mb-2 w-100">
                                                    <Nav.Link eventKey="info_3">{infoNamesList[2] != '' ? infoNamesList[2] : `${t('common.info_label')} 3`}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="mb-2 w-100">
                                                    <Nav.Link eventKey="info_4">{infoNamesList[3] != '' ? infoNamesList[3] : `${t('common.info_label')} 4`}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="mb-2 w-100">
                                                    <Nav.Link eventKey="info_5">{infoNamesList[4] != '' ? infoNamesList[4] : `${t('common.info_label')} 5`}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="mb-2 w-100">
                                                    <Nav.Link eventKey="info_6">{infoNamesList[5] != '' ? infoNamesList[5] : `${t('common.info_label')} 6`}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="mb-2 w-100">
                                                    <Nav.Link eventKey="info_7">{infoNamesList[6] != '' ? infoNamesList[6] : `${t('common.info_label')} 7`}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="mb-2 w-100">
                                                    <Nav.Link eventKey="info_8">{infoNamesList[7] != '' ? infoNamesList[7] : `${t('common.info_label')} 8`}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="mb-2 w-100">
                                                    <Nav.Link eventKey="info_9">{infoNamesList[8] != '' ? infoNamesList[8] : `${t('common.info_label')} 9`}</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="mb-2 w-100">
                                                    <Nav.Link eventKey="info_10">{infoNamesList[9] != '' ? infoNamesList[9] : `${t('common.info_label')} 10`}</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={10} className='px-5'>
                                            <Tab.Content >
                                                <Tab.Pane eventKey="info_1">
                                                    <Row className="justify-content-between py-3">
                                                        <Col><h5>{infoNamesList[0] != '' ? infoNamesList[0] : `${t('common.info_label')} 1`}</h5></Col>
                                                        <Col className="container-btn">
                                                            <Button className="text-uppercase" onClick={e => {newInfoCompl(0)}}><FiPlus className="me-2"/>{t('actions.new')}</Button>
                                                        </Col>
                                                    </Row>
                                                    <Table className='rounded'>
                                                        <thead className='bg-white'>
                                                            <tr>
                                                                <th>Informações</th>
                                                                <th></th>
                                                                <th></th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {infoComplList[0] ? infoComplList[0].valores.map((info) => (
                                                                <tr>
                                                                    <th>{info}</th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th>
                                                                        <Button variant="outline-dark"   className="mx-1" onClick={e=>{editInfoCompl(0, info)}}><BiEditAlt/></Button>
                                                                        <Button variant="outline-danger" className="mx-1" onClick={e=>{deleteInfoCompl(0, info)}}><AiOutlineDelete/></Button>
                                                                    </th>
                                                                </tr>    
                                                            )):''}
                                                        </tbody>
                                                    </Table>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="info_2">
                                                    <Row className="justify-content-between py-3">
                                                        <Col><h5>{infoNamesList[1] != '' ? infoNamesList[1] : `${t('common.info_label')} 2`}</h5></Col>
                                                        <Col className="container-btn">
                                                            <Button className="text-uppercase" onClick={e => {newInfoCompl(1)}}><FiPlus className="me-2"/>{t('actions.new')}</Button>
                                                        </Col>
                                                    </Row>
                                                    <Table className='rounded'>
                                                        <thead className='bg-white'>
                                                            <tr>
                                                                <th>Informações</th>
                                                                <th></th>
                                                                <th></th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            
                                                                {infoComplList[1] ? infoComplList[1].valores.map((info) => (
                                                                    <tr>
                                                                        <th>{info}</th>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th>
                                                                            <Button variant="outline-dark"   className="mx-1" onClick={e=>{editInfoCompl(1, info)}}><BiEditAlt/></Button>
                                                                            <Button variant="outline-danger" className="mx-1" onClick={e=>{deleteInfoCompl(1, info)}}><AiOutlineDelete/></Button>
                                                                        </th>
                                                                    </tr>
                                                                )):''}
                                                            
                                                        </tbody>
                                                    </Table>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="info_3">
                                                    <Row className="justify-content-between py-3">
                                                        <Col><h5>{infoNamesList[2] != '' ? infoNamesList[2] : `${t('common.info_label')} 3`}</h5></Col>
                                                        <Col className="container-btn">
                                                            <Button className="text-uppercase" onClick={e => {newInfoCompl(2)}}><FiPlus className="me-2"/>{t('actions.new')}</Button>
                                                        </Col>
                                                    </Row>
                                                    <Table className='rounded'>
                                                        <thead className='bg-white'>
                                                            <tr>
                                                                <th>Informações</th>
                                                                <th></th>
                                                                <th></th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            
                                                                {infoComplList[2] ? infoComplList[2].valores.map((info) => (
                                                                    <tr>
                                                                        <th>{info}</th>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th>
                                                                            <Button variant="outline-dark"   className="mx-1" onClick={e=>{editInfoCompl(2, info)}}><BiEditAlt/></Button>
                                                                            <Button variant="outline-danger" className="mx-1" onClick={e=>{deleteInfoCompl(2, info)}}><AiOutlineDelete/></Button>
                                                                        </th>
                                                                    </tr>
                                                                )):''}
                                                            
                                                        </tbody>
                                                    </Table>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="info_4">
                                                    <Row className="justify-content-between py-3">
                                                        <Col><h5>{infoNamesList[3] != '' ? infoNamesList[3] : `${t('common.info_label')} 4`}</h5></Col>
                                                        <Col className="container-btn">
                                                            <Button className="text-uppercase" onClick={e => {newInfoCompl(3)}}><FiPlus className="me-2"/>{t('actions.new')}</Button>
                                                        </Col>
                                                    </Row>
                                                    <Table className='rounded'>
                                                        <thead className='bg-white'>
                                                            <tr>
                                                                <th>Informações</th>
                                                                <th></th>
                                                                <th></th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            
                                                                {infoComplList[3] ? infoComplList[3].valores.map((info) => (
                                                                    <tr>
                                                                        <th>{info}</th>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th>
                                                                            <Button variant="outline-dark"   className="mx-1" onClick={e=>{editInfoCompl(3, info)}}><BiEditAlt/></Button>
                                                                            <Button variant="outline-danger" className="mx-1" onClick={e=>{deleteInfoCompl(3, info)}}><AiOutlineDelete/></Button>
                                                                        </th>
                                                                    </tr>
                                                                )):''}
                                                            
                                                        </tbody>
                                                    </Table>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="info_5">
                                                    <Row className="justify-content-between py-3">
                                                        <Col><h5>{infoNamesList[4] != '' ? infoNamesList[4] : `${t('common.info_label')} 5`}</h5></Col>
                                                        <Col className="container-btn">
                                                            <Button className="text-uppercase" onClick={e => {newInfoCompl(4)}}><FiPlus className="me-2"/>{t('actions.new')}</Button>
                                                        </Col>
                                                    </Row>
                                                    <Table className='rounded'>
                                                        <thead className='bg-white'>
                                                            <tr>
                                                                <th>Informações</th>
                                                                <th></th>
                                                                <th></th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            
                                                                {infoComplList[4] ? infoComplList[4].valores.map((info) => (
                                                                    <tr>
                                                                        <th>{info}</th>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th>
                                                                            <Button variant="outline-dark"   className="mx-1" onClick={e=>{editInfoCompl(4, info)}}><BiEditAlt/></Button>
                                                                            <Button variant="outline-danger" className="mx-1" onClick={e=>{deleteInfoCompl(4, info)}}><AiOutlineDelete/></Button>
                                                                        </th>
                                                                    </tr>
                                                                )):''}
                                                            
                                                        </tbody>
                                                    </Table>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="info_6">
                                                    <Row className="justify-content-between py-3">
                                                        <Col><h5>{infoNamesList[5] != '' ? infoNamesList[5] : `${t('common.info_label')} 6`}</h5></Col>
                                                        <Col className="container-btn">
                                                            <Button className="text-uppercase" onClick={e => {newInfoCompl(5)}}><FiPlus className="me-2"/>{t('actions.new')}</Button>
                                                        </Col>
                                                    </Row>
                                                    <Table className='rounded'>
                                                        <thead className='bg-white'>
                                                            <tr>
                                                                <th>Informações</th>
                                                                <th></th>
                                                                <th></th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                                {infoComplList[5] ? infoComplList[5].valores.map((info) => (
                                                                    <tr>
                                                                        <th>{info}</th>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th>
                                                                            <Button variant="outline-dark"   className="mx-1" onClick={e=>{editInfoCompl(5, info)}}><BiEditAlt/></Button>
                                                                            <Button variant="outline-danger" className="mx-1" onClick={e=>{deleteInfoCompl(5, info)}}><AiOutlineDelete/></Button>
                                                                        </th>
                                                                    </tr>
                                                                )):''}
                                                        </tbody>
                                                    </Table>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="info_7">
                                                    <Row className="justify-content-between py-3">
                                                        <Col><h5>{infoNamesList[6] != '' ? infoNamesList[6] : `${t('common.info_label')} 7`}</h5></Col>
                                                        <Col className="container-btn">
                                                            <Button className="text-uppercase" onClick={e => {newInfoCompl(6)}}><FiPlus className="me-2"/>{t('actions.new')}</Button>
                                                        </Col>
                                                    </Row>
                                                    <Table className='rounded'>
                                                        <thead className='bg-white'>
                                                            <tr>
                                                                <th>Informações</th>
                                                                <th></th>
                                                                <th></th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            
                                                                {infoComplList[6] ? infoComplList[6].valores.map((info) => (
                                                                    <tr>
                                                                        <th>{info}</th>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th>
                                                                            <Button variant="outline-dark"   className="mx-1" onClick={e=>{editInfoCompl(6, info)}}><BiEditAlt/></Button>
                                                                            <Button variant="outline-danger" className="mx-1" onClick={e=>{deleteInfoCompl(6, info)}}><AiOutlineDelete/></Button>
                                                                        </th>
                                                                    </tr>
                                                                )):''}
                                                            
                                                        </tbody>
                                                    </Table>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="info_8">
                                                    <Row className="justify-content-between py-3">
                                                        <Col><h5>{infoNamesList[7] != '' ? infoNamesList[7] : `${t('common.info_label')} 8`}</h5></Col>
                                                        <Col className="container-btn">
                                                            <Button className="text-uppercase" onClick={e => {newInfoCompl(7)}}><FiPlus className="me-2"/>{t('actions.new')}</Button>
                                                        </Col>
                                                    </Row>
                                                    <Table className='rounded'>
                                                        <thead className='bg-white'>
                                                            <tr>
                                                                <th>Informações</th>
                                                                <th></th>
                                                                <th></th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            
                                                                {infoComplList[7] ? infoComplList[7].valores.map((info) => (
                                                                    <tr>
                                                                        <th>{info}</th>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th>
                                                                            <Button variant="outline-dark"   className="mx-1" onClick={e=>{editInfoCompl(7, info)}}><BiEditAlt/></Button>
                                                                            <Button variant="outline-danger" className="mx-1" onClick={e=>{deleteInfoCompl(7, info)}}><AiOutlineDelete/></Button>
                                                                        </th>
                                                                    </tr>
                                                                )):''}
                                                            
                                                        </tbody>
                                                    </Table>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="info_9">
                                                    <Row className="justify-content-between py-3">
                                                        <Col><h5>{infoNamesList[8] != '' ? infoNamesList[8] : `${t('common.info_label')} 9`}</h5></Col>
                                                        <Col className="container-btn">
                                                            <Button className="text-uppercase" onClick={e => {newInfoCompl(8)}}><FiPlus className="me-2"/>{t('actions.new')}</Button>
                                                        </Col>
                                                    </Row>
                                                    <Table className='rounded'>
                                                        <thead className='bg-white'>
                                                            <tr>
                                                                <th>Informações</th>
                                                                <th></th>
                                                                <th></th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            
                                                                {infoComplList[8] ? infoComplList[8].valores.map((info) => (
                                                                    <tr>
                                                                        <th>{info}</th>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th>
                                                                            <Button variant="outline-dark"   className="mx-1" onClick={e=>{editInfoCompl(8, info)}}><BiEditAlt/></Button>
                                                                            <Button variant="outline-danger" className="mx-1" onClick={e=>{deleteInfoCompl(8, info)}}><AiOutlineDelete/></Button>
                                                                        </th>
                                                                    </tr>
                                                                )):''}
                                                            
                                                        </tbody>
                                                    </Table>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="info_10">
                                                    <Row className="justify-content-between py-3">
                                                        <Col><h5>{infoNamesList[9] != '' ? infoNamesList[9] : `${t('common.info_label')} 10`}</h5></Col>
                                                        <Col className="container-btn">
                                                            <Button className="text-uppercase" onClick={e => {newInfoCompl(9)}}><FiPlus className="me-2"/>{t('actions.new')}</Button>
                                                        </Col>
                                                    </Row>
                                                    <Table className='rounded'>
                                                        <thead className='bg-white'>
                                                            <tr>
                                                                <th>Informações</th>
                                                                <th></th>
                                                                <th></th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {infoComplList[9] ? infoComplList[9].valores.map((info) => (
                                                                <tr>
                                                                    <th>{info}</th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th>
                                                                        <Button variant="outline-dark"   className="mx-1" onClick={e=>{editInfoCompl(9, info)}}><BiEditAlt/></Button>
                                                                        <Button variant="outline-danger" className="mx-1" onClick={e=>{deleteInfoCompl(9, info)}}><AiOutlineDelete/></Button>
                                                                    </th>
                                                                </tr>
                                                            )):''}
                                                        </tbody>
                                                    </Table>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Col>
                        </Container>
                    </div>
                )
                break
            default:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>Error</div>
                )
                break
        }
    }

    function numberListGet() {
        let list = []
        let qtdpages = Math.ceil((items[items.length - 1].total_reg / 1000))
        
        if(qtdpages >= 2 && qtdpages <= 5) {
            for(let i= 1; i <= qtdpages; i++) {
                list.push(i)
            }
        }else if(qtdpages > 5) {
            if(pageNumber <= 1) {
                for(let i= 1; i <= 5; i++) {
                    list.push(i)
                }
            } else if(pageNumber > 1 && pageNumber < (qtdpages - 2)) {
                for(let i= (pageNumber - 1); i <= (pageNumber + 3); i++) {
                    list.push(i)
                }
            } else {
                for(let i= (qtdpages - 4); i <= qtdpages; i++) {
                    list.push(i)
                }
            }
        }
        return list
    }

    function importNumberListGet() {
        let list = []
        let qtdpages = Math.ceil((importList.filter((item) => {
            if(importView0 && item[28] == 'registrado'){ return item}
            if(importView1 && item[28] == 'repetido'){ return item}
            if(importView2 && item[28] == 'erro'){ return item}
            if(importView3 && item[28] == 'ok'){ return item}
        }).length / 1000))
        
        if(qtdpages >= 2 && qtdpages <= 5) {
            for(let i= 1; i <= qtdpages; i++) {
                list.push(i)
            }
        }else if(qtdpages > 5) {
            if(importPage <= 1) {
                for(let i= 1; i <= 5; i++) {
                    list.push(i)
                }
            } else if(importPage > 1 && importPage < (qtdpages - 2)) {
                for(let i= (importPage - 1); i <= (importPage + 3); i++) {
                    list.push(i)
                }
            } else {
                for(let i= (qtdpages - 4); i <= qtdpages; i++) {
                    list.push(i)
                }
            }
        }
        return list
    }

    function exportNumberListGet() {
        let list = []
        let qtdpages = Math.ceil((exportList.length / 1000))
        
        if(qtdpages >= 2 && qtdpages <= 5) {
            for(let i= 1; i <= qtdpages; i++) {
                list.push(i)
            }
        }else if(qtdpages > 5) {
            if(exportPage <= 1) {
                for(let i= 1; i <= 5; i++) {
                    list.push(i)
                }
            } else if(exportPage > 1 && exportPage < (qtdpages - 2)) {
                for(let i= (exportPage - 1); i <= (exportPage + 3); i++) {
                    list.push(i)
                }
            } else {
                for(let i= (qtdpages - 4); i <= qtdpages; i++) {
                    list.push(i)
                }
            }
        }
        return list
    }

    function formatDate(data) {
        let dt = new Date(data)
        let dt_reg = ("0" + dt.getDate()).slice(-2) + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + dt.getFullYear()
        dt_reg += " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" +dt.getMinutes()).slice(-2) + ":" + ("0" +dt.getSeconds()).slice(-2)
        return dt_reg
    }

    function treeCond(cond1) {
        if(cond1) {
            if(cond1._id && cond1 != '') {
                return cond1._id
            }else if(cond1) {
                return cond1
            }else{
                return ''
            }
        }else{
            return ''
        }
    }

    function getInfoDetails(){
        setInfoDetails([{
                show: true,
                modo: infoComplList[0] && infoComplList[0]?.valores?.length ? 'lista' : 'texto',
                value: inf1New,
                label: infoNamesList[0] ? infoNamesList[0] : `${t('common.info_label')} 1`
            },
            {
                show: showAll || inf2New || infoNamesList[1] ? true : false,
                modo: infoComplList[1] && infoComplList[1]?.valores?.length ? 'lista' : 'texto',
                value: inf2New,
                label: infoNamesList[1] ? infoNamesList[1] : `${t('common.info_label')} 2`
            },
                {
                show: showAll || inf3New || infoNamesList[2] ? true : false,
                modo: infoComplList[2] && infoComplList[2]?.valores?.length ? 'lista' : 'texto',
                value: inf3New,
                label: infoNamesList[2] ? infoNamesList[2] : `${t('common.info_label')} 3`
            },
            {
                show: showAll || inf4New || infoNamesList[3] ? true : false,
                modo: infoComplList[3] && infoComplList[3]?.valores?.length ? 'lista' : 'texto',
                value: inf4New,
                label: infoNamesList[3] ? infoNamesList[3] : `${t('common.info_label')} 4`
            },
            {
                show: showAll || inf5New || infoNamesList[4] ? true : false,
                modo: infoComplList[4] && infoComplList[4]?.valores?.length ? 'lista' : 'texto',
                value: inf5New,
                label: infoNamesList[4] ? infoNamesList[4] : `${t('common.info_label')} 5`
            },
            {
                show: showAll || inf6New || infoNamesList[5] ? true : false,
                modo: infoComplList[5] && infoComplList[5]?.valores?.length ? 'lista' : 'texto',
                value: inf6New,
                label: infoNamesList[5] ? infoNamesList[5] : `${t('common.info_label')} 6`
            },
            {
                show: showAll || inf7New || infoNamesList[6] ? true : false,
                modo: infoComplList[6] && infoComplList[6]?.valores?.length ? 'lista' : 'texto',
                value: inf7New,
                label: infoNamesList[6] ? infoNamesList[6] : `${t('common.info_label')} 7`

            },
            {
                show: showAll || inf8New || infoNamesList[7] ? true : false,
                modo: infoComplList[7] && infoComplList[7]?.valores?.length ? 'lista' : 'texto',
                value: inf8New,
                label: infoNamesList[7] ? infoNamesList[7] : `${t('common.info_label')} 8`
            },
            {
                show: showAll || inf9New || infoNamesList[8] ? true : false,
                modo: infoComplList[8] && infoComplList[8]?.valores?.length ? 'lista' : 'texto',
                value: inf9New,
                label: infoNamesList[8] ? infoNamesList[8] : `${t('common.info_label')} 9`

            },
            {
                show: showAll || inf10New || infoNamesList[9] ? true : false,
                modo: infoComplList[9] && infoComplList[9]?.valores?.length ? 'lista' : 'texto',
                value: inf10New,
                label: infoNamesList[9] ? infoNamesList[9] : `${t('common.info_label')} 10`
            }
        ])
    }

    function handleInfoChange(value, i){
        let change = infoDetails
        change[i].value = value
        setInfoDetails()
    }

    const [editingItem, setEditingItem] = useState()

    function editItem(item, salvoUnity = false) {
        setChange(2)
        setEditingItem(item)
    }

    function cleaner() {
        itemsBO()
        setOrdenationExport('')
        setChange(0)
        setEditing(0)
        setIdItens('0')
        setCategoriaNew('')
        setTagNew('')
        setAtivoNew('1')
        setUserCadNew('')
        setDtCadNew('')
        setAltCadNew('')
        setInf1New('')
        setInf2New('')
        setInf3New('')
        setInf4New('')
        setInf5New('')
        setInf6New('')
        setInf7New('')
        setInf8New('')
        setInf9New('')
        setInf10New('')
        setLatitudeNew('')
        setLongitudeNew('')
        setRaioNew('')
        setUp1New('')
        setUp2New('')
        setUp3New('')
        setUp4New('')
        setUp5New('')
        setValorNew(0)
        setLidoReg('1')
        setIdRegNew('')
        setItemVinculadoNew('')
        setCodExternoNew('')
        setNivelLoc1New('')
        setNivelLoc2New('')
        setNivelLoc3New('')
        setNivelLoc4New('')
        setParceiroNew('')
        setRegistroNew('')
        setObservacaoNew('')
        setSatusEstoque('')
        setFoto(NoImg)
        setFotoUp('')
        nivel1Get()
        nivel2Get(nivelLoc1)
        nivel3Get(nivelLoc2)
        nivel4Get(nivelLoc3)
        itemsBO()
        setExportList([])
        setExportListSelected([])
        setExportListSelectedCount([])
        setImportList([])
        setUploaded([])
        setImportLoading(false)
        setImportPage(0)
        setImportOkay(0)
        setImportErro(0)
        setImportRepetido(0)
        setImportRegistrado(0)
        setDtRegNew('')
        setAltRegNew('')
        setFotosCompl([])
        setLatitude('0')
        setLongitude('0')
        setBrandNew([])
        setModelNew([])
    }

    function removeFotoCompl(indexRemove) {
        Swal.fire({
            title: `${t('messages.attention')} `,
            text: `${t('messages.warning_delete_image')}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: `${t('messages.yes')}`,
            cancelButtonText: `${t('messages.no')}`
        }).then(
            result => {
                if(result.isConfirmed) {
                    if(result.isConfirmed) {
                        if(categoriaNew == ''){
                            Swal.fire({
                                title: "Ops... !",
                                text: `${t('messages.error_categorie_missing')}`,
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }else if(tagNew == '') {
                            Swal.fire({
                                title: "Ops... !",
                                text: `${t('messages.error_tag_missing')}`,
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }else if(tagNew.match(/[^a-f0-9:]/gi)) {
                            Swal.fire({
                                title: "Ops... !",
                                text: `${t('messages.error_tag_incorrect')}`,
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }else{
                            let tagConf = tagNew
                            if(!tagConf.includes(':')) {
                                tagConf = "000000000000000000000000" + tagConf
                                tagConf = tagConf.substr(tagConf.length - 24);
                            }
                            tagConf = tagConf.toUpperCase()
                            let fotoList = fotosCompl

                            fotoList.splice(indexRemove, 1)
                            let reg = []
                            reg.push({
                                ativo: ativoNew,
                                foto: fotoUp,
                                id_categoria: categoriaNew,
                                id_conta: sessionStorage.getItem('idConta'),
                                id_usuario: sessionStorage.getItem('idUser'),
                                id_externo: codExternoNew,
                                id_item_vinculado: itemVinculadoNew,
                                id_nivel_loc_1: nivelLoc1New,
                                id_nivel_loc_2: nivelLoc2New,
                                id_nivel_loc_3: nivelLoc3New,
                                id_nivel_loc_4: nivelLoc4New,
                                id_parceiro: parceiroNew,
                                id_registro: Object.keys(regNew).length != 0 ? regNew : null,
                                inf_compl_1: inf1New,
                                inf_compl_2: inf2New,
                                inf_compl_3: inf3New,
                                inf_compl_4: inf4New,
                                inf_compl_5: inf5New,
                                inf_compl_6: inf6New,
                                inf_compl_7: inf7New,
                                inf_compl_8: inf8New,
                                inf_compl_9: inf9New,
                                inf_compl_10: inf10New,
                                status_estoque: statusEstoque,
                                latitude: latitudeNew,
                                longitude: longitudeNew,
                                raio: raioNew,
                                lido_registro: lidoReg,
                                observacao: observacaoNew,
                                tag: tagConf,
                                up_1: up1New,
                                up_2: up2New,
                                up_3: up3New,
                                up_4: up4New,
                                up_5: up5New,
                                _id: idItens,
                                foto_compl: fotoList
                            })
                            let option = {
                                headers: { 'Content-Type': ['application/json'] }
                            }
                
                            api.post('/itemBO', reg, option
                            ).then(
                                async response=> {
                                    Swal.fire({
                                        title: `${t('messages.success')} `,
                                        text: `${t('messages.success_image_delete')} `,
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '2500'
                                    })
                                    await setFotosCompl(fotoList.filter(ft => (ft)))
                                },
                                response=> {
                                    if(response.response.data.erro && response.response.data.erro == 'Tag já exixtente!') {
                                        Swal.fire({
                                            title: "Ops... !",
                                            html: `${t('messages.error_tag_repeated')} `,
                                            icon: 'error',
                                            showConfirmButton: false,
                                            timerProgressBar: true,
                                            timer: '4000'
                                        })
                                    }else{
                                        Swal.fire({
                                            title: "Ops... !",
                                            text: `${t('messages.error')} `,
                                            icon: 'error',
                                            showConfirmButton: false,
                                            timerProgressBar: true,
                                            timer: '2500'
                                        })
                                    }
                                }
                            )
                        }
                    }
                }
            }
        )
    }

    function removeFotoMain() {
        Swal.fire({
            title: "Atenção !",
            text: "Deseja realmente excluir esta Foto ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    if(result.isConfirmed) {
                        if(categoriaNew == ''){
                            Swal.fire({
                                title: "Ops... !",
                                text: `${t('messages.error_categorie_missing')} `,
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }else if(tagNew == '') {
                            Swal.fire({
                                title: "Ops... !",
                                text: `${t('messages.error_tag_missing')} `,
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }else if(tagNew.match(/[^a-f0-9:]/gi)) {
                            Swal.fire({
                                title: "Ops... !",
                                text: `${t('messages.error_tag_incorrect')} `,
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }else{
                            let tagConf = tagNew
                            if(!tagConf.includes(':')) {
                                tagConf = "000000000000000000000000" + tagConf
                                tagConf = tagConf.substr(tagConf.length - 24);
                            }
                            tagConf = tagConf.toUpperCase()
                            let reg = []
                            reg.push({
                                ativo: ativoNew,
                                foto: '',
                                id_categoria: categoriaNew,
                                id_conta: sessionStorage.getItem('idConta'),
                                id_usuario: sessionStorage.getItem('idUser'),
                                id_externo: codExternoNew,
                                id_item_vinculado: itemVinculadoNew,
                                id_registro: Object.keys(regNew).length != 0 ? regNew : null,
                                tag: tagConf,
                                _id: idItens,
                            })
                
                            let option = {
                                headers: { 'Content-Type': ['application/json'] }
                            }
                
                            api.post('/itemBO', reg, option
                            ).then(
                                async response=> {
                                    Swal.fire({
                                        title: "Sucesso !",
                                        text: `${t('messages.success_image_delete')} `,
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '2500'
                                    })
                                    setFotoUp('')
                                    setFoto(NoImg)
                                },
                                response=> {
                                    if(response.response.data.erro && response.response.data.erro == 'Tag já exixtente!') {
                                        Swal.fire({
                                            title: "Ops... !",
                                            html: `${t('messages.error_tag_repeated')}`,
                                            icon: 'error',
                                            showConfirmButton: false,
                                            timerProgressBar: true,
                                            timer: '4000'
                                        })
                                    }else{
                                        Swal.fire({
                                            title: "Ops... !",
                                            text: `${t('messages.error')} `,
                                            icon: 'error',
                                            showConfirmButton: false,
                                            timerProgressBar: true,
                                            timer: '2500'
                                        })
                                    }
                                }
                            )
                        }
                    }
                }
            }
        )
    }

    function save() {
        Swal.fire({
            title: `${t('messages.attention')} `,
            text: idItens == '0' ? `${t('messages.warning_create')} ` : `${t('messages.warning_edit')} `,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    const missingInfo = []
                    if (obrigatorio?.includes('marca') && brandNew.length === 0) {
                        missingInfo.push("Marca")
                    }
                        
                    if (obrigatorio?.includes('modelo') && modelNew.length === 0) {
                        missingInfo.push("Modelo")
                    }
                        
                    if (obrigatorio?.includes('info_compl_1') && !inf1New) {
                        missingInfo.push("Informação Complementar 1")
                    }
                        
                    if (obrigatorio?.includes('info_compl_2') && !inf2New) {
                        missingInfo.push("Informação Complementar 2")
                    }
                        
                    if (obrigatorio?.includes('info_compl_3') && !inf3New) {
                        missingInfo.push("Informação Complementar 3")
                    }
                        
                    if (obrigatorio?.includes('info_compl_4') && !inf4New) {
                        missingInfo.push("Informação Complementar 4")
                    }
                    
                    if (obrigatorio?.includes('info_compl_5') && !inf5New) {
                        missingInfo.push("Informação Complementar 5")
                    }
                    
                    if (obrigatorio?.includes('info_compl_6') && !inf6New) {
                        missingInfo.push("Informação Complementar 6")
                    }
                    
                    if (obrigatorio?.includes('info_compl_7') && !inf7New) {
                        missingInfo.push("Informação Complementar 7")
                    }
                    
                    if (obrigatorio?.includes('info_compl_8') && !inf8New) {
                        missingInfo.push("Informação Complementar 8")
                    }
                    
                    if (obrigatorio?.includes('info_compl_9') && !inf9New) {
                        missingInfo.push("Informação Complementar 9")
                    }
                    
                    if (obrigatorio?.includes('info_compl_10') && !inf10New) {
                        missingInfo.push("Informação Complementar 10")
                    }
                    
                    if (obrigatorio?.includes('nivel_1') && !nivelLoc1New) {
                        missingInfo.push("Nível 1")
                    }
                    
                    if (obrigatorio?.includes('nivel_2') && !nivelLoc2New) {
                        missingInfo.push("Nível 2")
                    }
                    
                    if (obrigatorio?.includes('nivel_3') && !nivelLoc3New) {
                        missingInfo.push("Nível 3")
                    }
                    
                    if (obrigatorio?.includes('nivel_4') && !nivelLoc4New) {
                        missingInfo.push("Nível 4")
                    }
                    
                    if (obrigatorio?.includes('parceiro') && !parceiroNew) {
                        missingInfo.push("Parceiro")
                    }
                    
                    if (obrigatorio?.includes('valor') && !valorNew) {
                        missingInfo.push("Valor")
                    }
                    
                    if (obrigatorio?.includes('observacao') && !observacaoNew) {
                        missingInfo.push("Observação")
                    }
                    
                    if (obrigatorio?.includes('latitude') && !latitudeNew) {
                        missingInfo.push("Latitude")
                    }
                    
                    if (obrigatorio?.includes('longitude') && !longitudeNew) {
                        missingInfo.push("Longitude")
                    }  

                    if(missingInfo.length){
                        handleError(missingInfo.join(', '));
                        return
                    }
                    if (editing == 1 && sessionStorage.getItem('filterEdit') == 'true' ) {
                        if(
                            sessionStorage.getItem('nivel1') && sessionStorage.getItem('nivel1') != '' && sessionStorage.getItem('nivel1') !== nivelLoc1New || 
                            sessionStorage.getItem('nivel2') && sessionStorage.getItem('nivel2') != '' && sessionStorage.getItem('nivel2') !== nivelLoc2New ||
                            sessionStorage.getItem('nivel3') && sessionStorage.getItem('nivel3') != '' && sessionStorage.getItem('nivel3') !== nivelLoc3New ||
                            sessionStorage.getItem('nivel4') && sessionStorage.getItem('nivel4') != '' && sessionStorage.getItem('nivel4') !== nivelLoc4New
                        ){
                            handlePermissionError("Erro: Você não tem permissão para editar esse item");
                            return
                        }
                    }
                    if (sessionStorage.getItem('filterCreate') == 'true' ) {
                        if(
                            sessionStorage.getItem('nivel1') && sessionStorage.getItem('nivel1') != '' && sessionStorage.getItem('nivel1') !== nivelLoc1New || 
                            sessionStorage.getItem('nivel2') && sessionStorage.getItem('nivel2') != '' && sessionStorage.getItem('nivel2') !== nivelLoc2New ||
                            sessionStorage.getItem('nivel3') && sessionStorage.getItem('nivel3') != '' && sessionStorage.getItem('nivel3') !== nivelLoc3New ||
                            sessionStorage.getItem('nivel4') && sessionStorage.getItem('nivel4') != '' && sessionStorage.getItem('nivel4') !== nivelLoc4New
                        ){
                            handlePermissionError("Erro: Você não pode criar itens fora da sua localidade.");
                            return
                        }
                    }
                    if(categoriaNew == '' ){
                        Swal.fire({
                            title: "Ops... !",
                            text: `${t('messages.error_categorie_missing')} `,
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }else if(tagNew == '') {
                        Swal.fire({
                            title: "Ops... !",
                            text: `${t('messages.error_tag_missing')} `,
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }else if(tagNew.match(/[^a-f0-9:]/gi)) {
                        Swal.fire({
                            title: "Ops... !",
                            text: `${t('messages.error_tag_incorrect')} `,
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                    }else{
                        let tagConf = tagNew
                        if(!tagConf.includes(':')) {
                            tagConf = "000000000000000000000000" + tagConf
                            tagConf = tagConf.substr(tagConf.length - 24);
                        }
                        tagConf = tagConf.toUpperCase()
                        let reg = []
                        reg.push({
                            ativo: ativoNew,
                            foto: fotoUp,
                            id_categoria: categoriaNew,
                            id_conta: sessionStorage.getItem('idConta'),
                            id_usuario: sessionStorage.getItem('idUser'),
                            id_externo: codExternoNew,
                            id_item_vinculado: itemVinculadoNew,
                            id_nivel_loc_1: nivelLoc1New,
                            id_nivel_loc_2: nivelLoc1New ? nivelLoc2New : '',
                            id_nivel_loc_3: nivelLoc1New && nivelLoc2New ? nivelLoc3New : '',
                            id_nivel_loc_4: nivelLoc1New && nivelLoc2New && nivelLoc3New ? nivelLoc4New : '',
                            id_parceiro: parceiroNew,
                            id_registro: idRegNew,
                            inf_compl_1: inf1New,
                            inf_compl_2: inf2New,
                            inf_compl_3: inf3New,
                            inf_compl_4: inf4New,
                            inf_compl_5: inf5New,
                            inf_compl_6: inf6New,
                            inf_compl_7: inf7New,
                            inf_compl_8: inf8New,
                            inf_compl_9: inf9New,
                            inf_compl_10: inf10New,
                            status_estoque: statusEstoque,
                            latitude: latitudeNew,
                            longitude: longitudeNew,
                            raio: raioNew,
                            lido_registro: lidoReg,
                            observacao: observacaoNew,
                            tag: tagConf,
                            up_1: up1New,
                            up_2: up2New,
                            up_3: up3New,
                            up_4: up4New,
                            up_5: up5New,
                            valor: Number(valorNew),
                            _id: idItens,
                            id_marca: brandNew?._id,
                            id_modelo: modelNew?._id,
                        })
            
                        let option = {
                            headers: { 'Content-Type': ['application/json'] }
                        }
            
                        api.post('/itemBO', reg, option
                        ).then(
                            response=> {
                                Swal.fire({
                                    title: `${t('messages.success')} !`,
                                    text: idItens == '0' ? `${t('messages.success_create')}` : `${t('messages.success_edit')} `,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                                //editItem(response.data[0])
                            },
                            response=> {
                                if(response.response.data.erro && response.response.data.erro == 'Tag já exixtente!') {
                                    Swal.fire({
                                        title: "Ops... !",
                                        html: `${t('messages.error_tag_repeted')} `,
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '4000'
                                    })
                                }else{
                                    Swal.fire({
                                        title: "Ops... !",
                                        text: `${t('messages.error')} `,
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '2500'
                                    })
                                }
                            }
                        )
                    }
                }
            }
        )
    }

    function handleError(errors){
        Swal.fire({
            title: "Ops... !",
            html: "Alguns campos obrigatórios não foram preenchidos <br> Campos obrigatórios: " + errors,
            icon: 'error',
            showConfirmButton: false,
            timerProgressBar: true,
            timer: '4000'
        })
    }

    function handlePermissionError(Error){
        Swal.fire({
            title: "Ops... !",
            html: `${Error}`,
            icon: 'error',
            showConfirmButton: false,
            timerProgressBar: true,
            timer: '4000'
        })
    }

    function getID(){	
        return 'xxxxxxxx-yxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
            return v.toString(16)
        })
    }

    function getItemID() {
        // Obtém a data e a hora atuais
        const now = new Date();
        
        // Formata a data no formato AAAAMMDD
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Mês de 0-11 para 1-12
        const day = String(now.getDate()).padStart(2, '0'); // Dia do mês de 1-31
    
        // Concatena a data e a hora no formato AAAAMMDD-HHMMSS
        const formattedDateTime = `${year}${month}${day}`;
    
        // Gera a parte aleatória do ID
        const randomID = 'xxxxxxxx-yxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    
        // Combina a data e a hora com o ID aleatório
        return `${randomID}_${sessionStorage.getItem('idConta')}${formattedDateTime}`;
    } 

    function getSmallID(){
        let code = 'xxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
            return v.toString(16)
        })

        return sessionStorage.getItem('idConta') + code
    }

    function colorImportList(stat) {
        if(stat == 'erro') {
            return { background: '#C40233', color: '#F8F8F8'}
        }else if(stat == 'repetido') {
            return {background: '#909090', color: '#F8F8F8'}
        }else if(stat == 'registrado') {
            return {background: '#338333', color: '#F8F8F8'}
        }else if(stat == 'ok'){
            return {}
        }
    }

    async function importSave() {  
        getExportBrand()
        Swal.fire({
            title: "Atenção !",
            html: 'Itens repetidos e incorretos serão ignorados.<br/>Itens já registrados serão atualizados.<br/>Tem certeza que deseja importar estes itens?',
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            async result => {
                if(result.isConfirmed) {
                    setUppingImport(true)
                    let categoriasI = []
                    let nivel1I = []
                    let nivel2I = []
                    let nivel3I = []
                    let nivel4I = []
                    let parceirosI = []
                    let itensI = []
                    let importado = []
                    let status = true
                    let marcaLista = []
                    let listaTest = importList
                    await importList.map(async (item, index) => {
                        if(item[28] == 'ok' || item[28] == 'registrado') {
                            if(status) {
                                if(item[4] != ''){
                                    let ok = true
                                    let have = false
                                    categoriasI.map((it) => {
                                        if(it.descricao == item[4]) {
                                            ok = false
                                        }
                                    })
                                    if(ok){
                                        have = false
                                        inBaseProps.categories.map((itemB) => {
                                            if(itemB.descricao == item[4]) {
                                                categoriasI.push({
                                                    descricao: itemB.descricao,
                                                    id_conta: sessionStorage.getItem('idConta'),
                                                    _id: itemB._id,
                                                    ean: item[5],
                                                })
                                                have = true
                                            }
                                        })
                                        if(!have){
                                            categoriasI.push({
                                                descricao: item[4],
                                                id_conta: sessionStorage.getItem('idConta'),
                                                _id: getID(),
                                                ean: item[5],
                                            })
                                        }
                                    }
                                
                                    if(item[12] != ''){
                                        ok = true
                                        nivel1I.map((it) => {
                                            if(it.descricao == item[12]) {
                                                ok = false
                                            }
                                        })
                                        if(ok) {
                                            have = false
                                            inBaseProps.nivel1.map((itemB) => {
                                                if(itemB.descricao == item[12]) {
                                                    nivel1I.push({
                                                        descricao: itemB.descricao,
                                                        id_conta: sessionStorage.getItem('idConta'),
                                                        _id: itemB._id
                                                    })
                                                    have = true
                                                }
                                            })
                                            if(!have){
                                                nivel1I.push({
                                                    descricao: item[12],
                                                    id_conta: sessionStorage.getItem('idConta'),
                                                    _id: getID()
                                                })
                                            }
                                        }
                                        if(item[13] != ''){
                                            ok = true
                                            let n1id
                                            nivel1I.map(it => {
                                                if(it.descricao == item[12]) {
                                                    n1id = it._id
                                                }
                                            })
                                            nivel2I.map((it) => {
                                                if(it.descricao == item[13] && it.id_nivel_loc1 == n1id) {
                                                    ok = false
                                                }
                                            })
                                            if(ok){
                                                have = false
                                                inBaseProps.nivel2.map((itemB) => {
                                                    if(itemB.descricao == item[13] && itemB.id_nivel_loc1 == n1id) {
                                                        nivel2I.push({
                                                            descricao: itemB.descricao,
                                                            id_conta: sessionStorage.getItem('idConta'),
                                                            id_nivel_loc1: n1id,
                                                            _id: itemB._id
                                                        })
                                                        have = true
                                                    }
                                                })
                                                if(!have){
                                                    nivel2I.push({
                                                        descricao: item[13],
                                                        id_conta: sessionStorage.getItem('idConta'),
                                                        id_nivel_loc1: n1id,
                                                        _id: getID()
                                                    })
                                                }
                                            }
                                            if(item[14] != '') {
                                                ok = true
                                                let n2id
                                                nivel2I.map(it => {
                                                    if(it.descricao == item[13]) {
                                                        n2id = it._id
                                                    }
                                                })
                                                nivel3I.map((it) => {
                                                    if(it.descricao == item[14] && it.id_nivel_loc2 == n2id) {
                                                        ok = false
                                                    }
                                                })
                                                if(ok) {
                                                    inBaseProps.nivel3.map((itemB) => {
                                                        if(itemB.descricao == item[14] && itemB.id_nivel_loc2 == n2id) {
                                                            nivel3I.push({
                                                                descricao: itemB.descricao,
                                                                id_conta: sessionStorage.getItem('idConta'),
                                                                id_nivel_loc2: n2id,
                                                                _id: itemB._id
                                                            })
                                                            have = true
                                                        }
                                                    })
                                                    if(!have){
                                                        nivel3I.push({
                                                            descricao: item[14],
                                                            id_conta: sessionStorage.getItem('idConta'),
                                                            id_nivel_loc2: n2id,
                                                            _id: getID()
                                                        })
                                                    }
                                                }
                                                if(item[15] != '') {
                                                    ok = true
                                                    let n3id
                                                    nivel3I.map(it => {
                                                        if(it.descricao == item[14]) {
                                                            n3id = it._id
                                                        }
                                                    })
                                                    nivel4I.map((it) => {
                                                        if(it.descricao == item[15] && it.id_nivel_loc3 == n3id) {
                                                            ok = false
                                                        }
                                                    })
                                                    if(ok){
                                                        inBaseProps.nivel4.map((itemB) => {
                                                            if(itemB.descricao == item[15] && itemB.id_nivel_loc3 == n3id) {
                                                                nivel4I.push({
                                                                    descricao: itemB.descricao,
                                                                    id_conta: sessionStorage.getItem('idConta'),
                                                                    id_nivel_loc3: n3id,
                                                                    _id: itemB._id
                                                                })
                                                                have = true
                                                            }
                                                        })
                                                        if(!have){
                                                            nivel4I.push({
                                                                descricao: item[15],
                                                                id_conta: sessionStorage.getItem('idConta'),
                                                                id_nivel_loc3: n3id,
                                                                _id: getID()
                                                            })
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                            
                                    if(item[11] != '') {
                                        ok = true
                                        parceirosI.map((it) => {
                                            if(it.nome == item[11]) {
                                                ok = false
                                            }
                                        })
                                        if(ok){
                                            inBaseProps.parceiro.map((itemB) => {
                                                if(itemB.nome == item[11]) {
                                                    parceirosI.push({
                                                        nome: itemB.nome,
                                                        id_conta: sessionStorage.getItem('idConta'),
                                                        _id: itemB._id
                                                    })
                                                    have = true
                                                }
                                            })
                                            if(!have){
                                                parceirosI.push({
                                                    nome: item[11],
                                                    id_conta: sessionStorage.getItem('idConta'),
                                                    _id: getID()
                                                })
                                            }
                                        }
                                    }
                    
                                    let catId
                                    categoriasI.map((it) => {
                                        if(it.descricao == item[4]) {
                                            catId = it._id
                                        }
                                    })
                                    let brand
                                    let model
                                    if(item[32]){
                                        if(!brandList.filter((iMarca) =>  {if(iMarca.marca === item[32] && iMarca.id_categoria == catId) {return iMarca}}).length){
                                            if(marcaLista.filter((iMarca) =>  {if(iMarca.marca === item[32] && iMarca.id_categoria == catId) {return iMarca}}).length){
                                                brand = marcaLista.filter((iMarca) =>  {if(iMarca.marca === item[32] && iMarca.id_categoria == catId) {return iMarca}})[0]
                                                if(item[33]){
                                                    if(brand.modelo.filter((iModel) =>  {if(iModel.descricao === item[33]) {return iModel}}).length){
                                                        model = brand.modelo.filter((iModel) =>  {if(iModel.descricao === item[33]) {return iModel}})[0]._id
                                                    }else{
                                                        model = brand._id + "_" + getSmallID()
                                                        brand.modelo.push({"_id": model,"descricao": item[33]})
                                                        let newNodel = marcaLista.findIndex(iMarca => iMarca._id === brand._id)
                                                        marcaLista[newNodel] = brand
                                                    }
                                                }
                                                brand = brand._id
                                            }else{
                                               brand = getSmallID()
                                                if(item[33]){
                                                    model = brand + "_" + getSmallID()
                                                }
                                                let newBrand = {
                                                    "_id": brand,
                                                    "id_conta": sessionStorage.getItem('idConta'),
                                                    "id_categoria": catId,
                                                    "marca": item[32],
                                                    "modelo": model ? [{"_id": model,"descricao": item[33]}] : []
                                                }
                                                marcaLista.push(newBrand) 
                                            }
                                        } else{
                                            brand = brandList.filter((iMarca) =>  {if(iMarca.marca === item[32] && iMarca.id_categoria == catId) {return iMarca}})[0]
                                            if(item[33]){        
                                                model = brand?.modelo.filter((iModel) =>  {if(iModel.descricao === item[33]) {return iModel}})
                                                model = model[0]?._id
                                            }
                                            brand = brand._id
                                        }
                                    }
                                    if(item[12] != '') {
                                        var idn1 = null
                                        nivel1I.map(it => {
                                            if(it.descricao == item[12]) {
                                                idn1 = it._id
                                            }
                                        })
                                        if(item[13] != '') {
                                            var idn2
                                            nivel2I.map(it => {
                                                if(it.descricao == item[13] && it.id_nivel_loc1 == idn1) {
                                                    idn2 = it._id
                                                }
                                            })
                                            if(item[14] != '') {
                                                var idn3
                                                nivel3I.map(it => {
                                                    if(it.descricao == item[14] && it.id_nivel_loc2 == idn2) {
                                                        idn3 = it._id
                                                    }
                                                })
                                                if(item[15] != '') {
                                                    var idn4
                                                    nivel4I.map(it => {
                                                        if(it.descricao == item[15] && it.id_nivel_loc3 == idn3) {
                                                            idn4 = it._id
                                                        }
                                                    })
                                                }
                                            }
                                        }
                                    }
                                    let parId
                                    parceirosI.map((it) => {
                                        if(it.nome == item[11]) {
                                            parId = it._id
                                        }
                                    })
                                    let tagConf = item[3]
                                    if(item[3] != '') {
                                        if(!tagConf.includes(':')) {
                                            tagConf = "000000000000000000000000" + tagConf
                                            tagConf = tagConf.substr(tagConf.length - 24);
                                        }
                                        tagConf = tagConf.toUpperCase()
                                    }
                                    let itemInfo = {
                                        "ativo": item[2],
                                        "categoria": item[4],
                                        "foto": item[36] ? item[36]: '',
                                        "foto_compl": item[37] ? item[37]: [],
                                        "longitude": item[31] ? item[31] : '',                                        
                                        "latitude": item[30]? item[30] : '',
                                        "id_marca": brand,
                                        "id_modelo": model,
                                        "id_categoria": catId,
                                        "id_conta": sessionStorage.getItem('idConta'),
                                        "id_usuario": sessionStorage.getItem('idUser'),
                                        "id_externo": item[1] ? item[1] : '',
                                        "id_item": "0",
                                        "id_item_vinculado": "",
                                        "id_nivel_loc_1": idn1 ? idn1 : '',
                                        "id_nivel_loc_2": idn2 ? idn2 : '',
                                        "id_nivel_loc_3": idn3 ? idn3 : '',
                                        "id_nivel_loc_4": idn4 ? idn4 : '',
                                        "id_parceiro": parId ? parId : '',
                                        "inf_compl_1": item[6] ? item[6] : '',
                                        "inf_compl_2": item[7] ? item[7] : '',
                                        "inf_compl_3": item[8] ? item[8] : '',
                                        "inf_compl_4": item[9] ? item[9] : '',
                                        "inf_compl_5": item[10] ? item[10] : '',
                                        "inf_compl_6": item[18] ? item[18] : '',
                                        "inf_compl_7": item[19] ? item[19] : '',
                                        "inf_compl_8": item[20] ? item[20] : '',
                                        "inf_compl_9": item[21] ? item[21] : '',
                                        "inf_compl_10": item[22] ? item[22] : '',
                                        "status_estoque": '',
                                        "nivel_loc_1": idn1 ? item[12] : null,
                                        "nivel_loc_2": idn2 ? item[13] : null,
                                        "nivel_loc_3": idn3 ? item[14] : null,
                                        "nivel_loc_4": idn4 ? item[15] : null,
                                        "observacao": item[16] ? item[16] : '',
                                        "valor": !Number.isNaN(item[17]) ? Number(item[17]) : 0,
                                        "up_1": item[23] ? item[23] : '',
                                        "up_2": item[24] ? item[24] : '',
                                        "up_3": item[25] ? item[25] : '',
                                        "up_4": item[26] ? item[26] : '',
                                        "up_5": item[27] ? item[27] : '',
                                        "parceiro": item[11],
                                        "tag": tagConf,
                                        "tag_vinculado": item[0] == 'NA' ? '' : item[0],
                                        "_id": (item[29] == '' || tagConf == "000000000000000000000000") ? getItemID() : item[29]                                    
                                    }
                                    itensI.push(itemInfo)
                                    importado.push(index)
                                    listaTest[index][28] = 'registrado'
                                    listaTest[index][29] = itemInfo._id
                                }else{
                                    status = false
                                }
                            }
                        }
                    })
                    if(status) {
                        let option = {
                        headers: { 
                                'Content-Type': ['application/json']
                            }
                        }
                        if(marcaLista.length > 0){
                            api.post('/infs_compl', marcaLista, option)
                        }
                        await api.post(`/itensDef/${sessionStorage.getItem('idConta')}`,
                        [{
                            categoria: categoriasI.filter((item) => {
                                let drop = false
                                inBaseProps.categories.map((itemB) => {
                                    if(item._id == itemB._id) {
                                        drop = true
                                    }
                                })
                                if(!drop){
                                    return item
                                }
                            }),
                            itens: itensI,
                            nivel1: nivel1I.filter((item) => {
                                let drop = false
                                inBaseProps.nivel1.map((itemB) => {
                                    if(item._id == itemB._id) {
                                        drop = true
                                    }
                                })
                                if(!drop){
                                    return item
                                }
                            }),
                            nivel2: nivel2I.filter((item) => {
                                let drop = false
                                inBaseProps.nivel2.map((itemB) => {
                                    if(item._id == itemB._id) {
                                        drop = true
                                    }
                                })
                                if(!drop){
                                    return item
                                }
                            }),
                            nivel3: nivel3I.filter((item) => {
                                let drop = false
                                inBaseProps.nivel3.map((itemB) => {
                                    if(item._id == itemB._id) {
                                        drop = true
                                    }
                                })
                                if(!drop){
                                    return item
                                }
                            }),
                            nivel4: nivel4I.filter((item) => {
                                let drop = false
                                inBaseProps.nivel4.map((itemB) => {
                                    if(item._id == itemB._id) {
                                        drop = true
                                    }
                                })
                                if(!drop){
                                    return item
                                }
                            }),
                            parceiros: parceirosI.filter((item) => {
                                let drop = false
                                inBaseProps.parceiro.map((itemB) => {
                                    if(item._id == itemB._id) {
                                        drop = true
                                    }
                                })
                                if(!drop){
                                    return item
                                }
                            })
                        }], option).then(
                            async response => {
                                Swal.fire({
                                    title: "Sucesso !",
                                    text: 'Itens importados com sucesso',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                                setUppingImport(false)
                                await setImportList(listaTest)
                                await verififyBase()
                                setUploaded(importado)
                                categoriaGet()
                                parceiroGet()
                                nivel1Get()
                            },
                            response => {
                                const itensIncorretos = itensI.filter(a => isNaN(a.ativo))
                                if(itensIncorretos.length > 0){
                                    Swal.fire({
                                        title: "Ops... !",
                                        text: 'Caracteres inválidos na coluna "status", insira um valor entre 0 e 4',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '2500'
                                    })
                                }else{
                                    Swal.fire({
                                        title: "Ops... !",
                                        text: 'Algo deu errado, por favor tente novamente mais tarde',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: '2500'
                                    })
                                }
                                
                                setUppingImport(false)
                            }
                        )
                    }
                }
            }
        )
    }

    function remove() {
        Swal.fire({
            title: `${t('messages.warning')}`,
            html: `TAG: ${tagNew}<br/>${t('messages.warning_delete')}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: `${t('messages.yes')}`,
            cancelButtonText: `${t('messages.no')}`
        }).then(
            result => {
                if(result.isConfirmed) {
                    if (sessionStorage.getItem('filterExclude') == 'true' ) {
                        if(
                            sessionStorage.getItem('nivel1') && sessionStorage.getItem('nivel1') != '' && sessionStorage.getItem('nivel1') !== nivelLoc1New || 
                            sessionStorage.getItem('nivel2') && sessionStorage.getItem('nivel2') != '' && sessionStorage.getItem('nivel2') !== nivelLoc2New ||
                            sessionStorage.getItem('nivel3') && sessionStorage.getItem('nivel3') != '' && sessionStorage.getItem('nivel3') !== nivelLoc3New ||
                            sessionStorage.getItem('nivel4') && sessionStorage.getItem('nivel4') != '' && sessionStorage.getItem('nivel4') !== nivelLoc4New
                        ){
                            handlePermissionError("Erro: Você não tem permissão para excluir esse item");
                            return
                        }
                    }
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
            
                    api.delete(`/item/${idItens}?id_conta=${sessionStorage.getItem('idConta')}?id_usuario=${sessionStorage.getItem('idUser')}`, option
                    ).then(
                        response=> {
                            itemsBO()
                            Swal.fire({
                                title: `${t('messages.success')} !`,
                                text: `${t('messages.success_delete')}`,
                                icon: 'success',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                            cleaner()
                        },
                        response=> {
                            Swal.fire({
                                title: "Ops... !",
                                text: `${t('messages.error')}`,
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }
                    )
                }
            }
        )
    }

    function extra() {
        if(displayExtra) {
            setDisplayExtra(false)
            setRegValor([])
            
        }else {
            setDisplayExtra(true)
            api.get(`/registro_valor/${sessionStorage.getItem('idConta')}/*/*/${idItens}/*/*/*`
            ).then(
                response=> {setRegValor(response.data)}
            )
        }

    }

    function novaCategoria(fechar) {
        if(fechar == 'fechar') {
            setInputNew('')
            setCategoriaNew('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                ean: "",
                id_categoria: "0",
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                inf_compl_1: "",
                inf_compl_2: "",
                inf_compl_3: "",
                inf_compl_4: "",
                inf_compl_5: "",
                observacao: ""
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post('/categoria', reg, option).then(
                response=> {
                    setInputNew('')
                    categoriaGet()
                    setCategoriaNew(response.data[0]._id)

                },
                response=> {
                    Swal.fire({
                        title: "Ops... !",
                        text: `${t('messages.success')}`,
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
        }
    }

    function novoNivel1(fechar) {
        if(fechar == 'fechar') {
            setInputNew('')
            setNivelLoc1New('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc1: "0",
                observacao: "",
                tag: "",
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post('/nivel_loc1', reg, option).then(
                response=> {
                    setInputNew('')
                    nivel1Get()
                    setNivelLoc1New(response.data[0]._id)

                },
                response=> {
                    Swal.fire({
                        title: "Ops... !",
                        text: `${t('messages.error')}`,
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
        }
    }

    function novoNivel2(fechar) {
        if(fechar == 'fechar') {
            setInputNew('')
            setNivelLoc2New('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc1: nivelLoc1New,
                id_nivel_loc2: "0",
                observacao: "",
                tag: "",
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post('/nivel_loc2', reg, option).then(
                response=> {
                    setInputNew('')
                    nivel2Get(nivelLoc1New)
                    setNivelLoc2New(response.data[0]._id)

                },
                response=> {
                    Swal.fire({
                        title: "Ops... !",
                        text: `${t('messages.error')}`,
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
        }
    }

    function novoNivel3(fechar) {
        if(fechar == 'fechar') {
            setInputNew('')
            setNivelLoc3New('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc2: nivelLoc2New,
                id_nivel_loc3: "0",
                observacao: "",
                tag: ""
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post('/nivel_loc3', reg, option).then(
                response=> {
                    setInputNew('')
                    nivel3Get(nivelLoc2New)
                    setNivelLoc3New(response.data[0]._id)

                },
                response=> {
                    Swal.fire({
                        title: "Ops... !",
                        text: `${t('messages.error')}`,
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
        }
    }

    function novoNivel4(fechar) {
        if(fechar == 'fechar') {
            setInputNew('')
            setNivelLoc4New('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc3: nivelLoc3New,
                id_nivel_loc4: "0",
                observacao: "",
                tag: ""
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post('/nivel_loc4', reg, option).then(
                response=> {
                    setInputNew('')
                    nivel4Get(nivelLoc3New)
                    setNivelLoc4New(response.data[0]._id)

                },
                response=> {
                    Swal.fire({
                        title: "Ops... !",
                        text: `${t('messages.error')}`,
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
        }
    }

    function novoParceiro(fechar) {
        if(fechar == 'fechar') {
            setInputNew('')
            setParceiroNew('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                endereco: "",
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_parceiro: "0",
                nome: inputNew,
                observacao: "",
                telefone: "",
                uuid: ""
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post('/parceiro', reg, option).then(
                response=> {
                    setInputNew('')
                    parceiroGet()
                    setParceiroNew(response.data[0]._id)

                },
                response=> {
                    Swal.fire({
                        title: "Ops... !",
                        text: `${t('messages.error')}`,
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            )
        }
    }

    return (
        <div className="mainContainer"
        style={JSON.parse(sessionStorage.getItem('collors'))}
        >
            <Menu/>
            <div id="background">
                {changeFunction(change)}
            </div>
        </div>
    )
}

export default Items