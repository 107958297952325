import { useEffect, useState } from "react";

import { Icon } from "@iconify/react/dist/iconify.js";

import { Box, Chip, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import { blue, grey } from "@mui/material/colors";

import api from "../../../services/api";

//---------------------------------------------------

export default function TableTracking(){
    const [items, setItems] = useState([])

    const [categoriaList, setCategoriaList] = useState([])

    useEffect(()=>{
        function getCategorias(){
            api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/*`).then(
                response=>{
                    response.data.pop()
                    if(response.data.length){
                        setCategoriaList(response.data)
                    }
                }
            )
        }

        getCategorias()
    },[])

    useEffect(()=>{
        function getData(){
            api.get(`/reg_mov_itens/${sessionStorage.getItem('idConta')}/*/*/*/*/*/*/*`).then(
                response=>{
                    setItems(response.data)
                }
            )
        }

        getData()
    },[])

    function getCategory(_id){
        console.log(_id)
        const category = categoriaList.find(categoria => categoria._id === _id)?.descricao || 'Sem descrição'

        return category
    }

    return (
        <>
            <Stack 
                component={Paper} 
                spacing={3} 
                sx={{
                    p: 3, 
                    borderRadius: 2, 
                    height: '100%'
                }}
            >
                
                <Stack direction="row" alignItems="center" spacing={2}>
                    <IconButton color="primary" sx={{borderRadius: 1, backgroundColor: 'primary.lighter'}}>
                        <Icon icon="pepicons-pencil:file-loop"/>
                    </IconButton>
                    <Typography variant="h2">Monitoramento</Typography>
                </Stack>

                <TableContainer sx={{borderRadius: 3}}>
                    <Table size="small" stickyHeader sx={{border: 1, borderColor: grey[200]}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Categoria</TableCell>
                                <TableCell>Tag</TableCell>
                                <TableCell>Data de Movimentação</TableCell>
                                <TableCell>Localização</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item)=>(
                                <TableRow
                                    hover
                                    
                                >
                                    <TableCell sx={{borderRight: 1, borderColor: grey[200], padding: 2}}>{getCategory(item?.id_item?.id_categoria)}</TableCell>
                                    <TableCell sx={{borderRight: 1, borderColor: grey[200], padding: 2}}>{item?.id_item?.tag}</TableCell>
                                    <TableCell sx={{borderRight: 1, borderColor: grey[200], padding: 2}}>{new Date(item?.dt_movimentacao).toLocaleDateString('pt-BR', {day: '2-digit',month: '2-digit',year: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false,timeZone: 'UTC'})}</TableCell>
                                    <TableCell sx={{borderRight: 1, borderColor: grey[200], padding: 2}}>
                                        <Typography>{item?.id_nivel_loc1?.descricao}</Typography>
                                        <Typography>{item?.id_nivel_loc2?.descricao}</Typography>
                                        <Typography>{item?.id_nivel_loc3?.descricao}</Typography>
                                        <Typography>{item?.id_nivel_loc4?.descricao}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>                         
                    </Table>
                </TableContainer>
            </Stack>
        </>
    )
}