import React, {useState, useEffect} from 'react'

import ReactLoading from 'react-loading'

import {CSVLink} from 'react-csv'

import Swal from 'sweetalert2'

import {parse} from 'papaparse'

import api from '../../services/api'

import axios from 'axios'

import Menu from '../../components/Menu'

import './style.css'

import gpcList from '../../assets/json/gcpprefixformatlist.json'

import epcTds from 'epc-tds'

import { Container, Row, Col, Button, Table, Form, Modal, FormControl } from 'react-bootstrap';

import {FaFileCsv, FaFilePdf, FaArrowDown} from 'react-icons/fa'

import {FiFilter, FiArrowLeft, FiSave, FiUpload} from 'react-icons/fi'

import { useTranslation } from 'react-i18next'

function Print() {
    const { t } = useTranslation();

    const [arquivoImportacao, setArquivoImportacao] = useState('csv')
    const [codbarCsv, setCodbarCsv] = useState('')
    const [codbarDestino, setCodbarDestino] = useState('')
    const [codbarFonte, setCodbarFonte] = useState('')
    const [codbarx, setCodbarx] = useState('')
    const [codbary, setCodbary] = useState('')
    const [csv01, setCsv01] = useState('')
    const [csv02, setCsv02] = useState('')
    const [csv03, setCsv03] = useState('')
    const [csv04, setCsv04] = useState('')
    const [csv05, setCsv05] = useState('')
    const [csv06, setCsv06] = useState('')
    const [csv07, setCsv07] = useState('')
    const [csv08, setCsv08] = useState('')
    const [csv09, setCsv09] = useState('')
    const [csv10, setCsv10] = useState('')
    const [csv11, setCsv11] = useState('')
    const [csv12, setCsv12] = useState('')
    const [csv13, setCsv13] = useState('')
    const [csv14, setCsv14] = useState('')
    const [csv15, setCsv15] = useState('')
    const [descricao01, setDescricao01] = useState('')
    const [descricao02, setDescricao02] = useState('')
    const [descricao03, setDescricao03] = useState('')
    const [descricao04, setDescricao04] = useState('')
    const [descricao05, setDescricao05] = useState('')
    const [descricao06, setDescricao06] = useState('')
    const [descricao07, setDescricao07] = useState('')
    const [descricao08, setDescricao08] = useState('')
    const [descricao09, setDescricao09] = useState('')
    const [descricao10, setDescricao10] = useState('')
    const [descricao11, setDescricao11] = useState('')
    const [descricao12, setDescricao12] = useState('')
    const [descricao13, setDescricao13] = useState('')
    const [descricao14, setDescricao14] = useState('')
    const [descricao15, setDescricao15] = useState('')
    const [destino01, setDestino01] = useState('')
    const [destino02, setDestino02] = useState('')
    const [destino03, setDestino03] = useState('')
    const [destino04, setDestino04] = useState('')
    const [destino05, setDestino05] = useState('')
    const [destino06, setDestino06] = useState('')
    const [destino07, setDestino07] = useState('')
    const [destino08, setDestino08] = useState('')
    const [destino09, setDestino09] = useState('')
    const [destino10, setDestino10] = useState('')
    const [destino11, setDestino11] = useState('')
    const [destino12, setDestino12] = useState('')
    const [destino13, setDestino13] = useState('')
    const [destino14, setDestino14] = useState('')
    const [destino15, setDestino15] = useState('')
    const [enderecoImpressora, setEnderecoImpressora] = useState('')
    const [fonte01, setFonte01] = useState('')
    const [fonte02, setFonte02] = useState('')
    const [fonte03, setFonte03] = useState('')
    const [fonte04, setFonte04] = useState('')
    const [fonte05, setFonte05] = useState('')
    const [fonte06, setFonte06] = useState('')
    const [fonte07, setFonte07] = useState('')
    const [fonte08, setFonte08] = useState('')
    const [fonte09, setFonte09] = useState('')
    const [fonte10, setFonte10] = useState('')
    const [fonte11, setFonte11] = useState('')
    const [fonte12, setFonte12] = useState('')
    const [fonte13, setFonte13] = useState('')
    const [fonte14, setFonte14] = useState('')
    const [fonte15, setFonte15] = useState('')
    const [idConta, setIdConta] = useState(sessionStorage.getItem('idConta'))
    const [imprimirDescricao, setImprimirDescricao] = useState('')
    const [logoCsv, setLogoCsv] = useState('')
    const [logoGrf, setLogoGrf] = useState('')
    const [logox, setLogox] = useState('')
    const [logoy, setLogoy] = useState('')
    const [montCampo01, setMontCampo01] = useState('')
    const [montCampo02, setMontCampo02] = useState('')
    const [montCampo03, setMontCampo03] = useState('')
    const [montCampo04, setMontCampo04] = useState('')
    const [montCampo05, setMontCampo05] = useState('')
    const [montCompr01, setMontCompr01] = useState('')
    const [montCompr02, setMontCompr02] = useState('')
    const [montCompr03, setMontCompr03] = useState('')
    const [montCompr04, setMontCompr04] = useState('')
    const [montCompr05, setMontCompr05] = useState('')
    const [montDigito, setMontDigito] = useState('0')
    const [montDigitoCsv, setMontDigitoCsv] = useState('')
    const [montPini01, setMontPini01] = useState('')
    const [montPini02, setMontPini02] = useState('')
    const [montPini03, setMontPini03] = useState('')
    const [montPini04, setMontPini04] = useState('')
    const [montPini05, setMontPini05] = useState('')
    const [posicaox01, setPosicaox01] = useState('')
    const [posicaox02, setPosicaox02] = useState('')
    const [posicaox03, setPosicaox03] = useState('')
    const [posicaox04, setPosicaox04] = useState('')
    const [posicaox05, setPosicaox05] = useState('')
    const [posicaox06, setPosicaox06] = useState('')
    const [posicaox07, setPosicaox07] = useState('')
    const [posicaox08, setPosicaox08] = useState('')
    const [posicaox09, setPosicaox09] = useState('')
    const [posicaox10, setPosicaox10] = useState('')
    const [posicaox11, setPosicaox11] = useState('')
    const [posicaox12, setPosicaox12] = useState('')
    const [posicaox13, setPosicaox13] = useState('')
    const [posicaox14, setPosicaox14] = useState('')
    const [posicaox15, setPosicaox15] = useState('')
    const [posicaoy01, setPosicaoy01] = useState('')
    const [posicaoy02, setPosicaoy02] = useState('')
    const [posicaoy03, setPosicaoy03] = useState('')
    const [posicaoy04, setPosicaoy04] = useState('')
    const [posicaoy05, setPosicaoy05] = useState('')
    const [posicaoy06, setPosicaoy06] = useState('')
    const [posicaoy07, setPosicaoy07] = useState('')
    const [posicaoy08, setPosicaoy08] = useState('')
    const [posicaoy09, setPosicaoy09] = useState('')
    const [posicaoy10, setPosicaoy10] = useState('')
    const [posicaoy11, setPosicaoy11] = useState('')
    const [posicaoy12, setPosicaoy12] = useState('')
    const [posicaoy13, setPosicaoy13] = useState('')
    const [posicaoy14, setPosicaoy14] = useState('')
    const [posicaoy15, setPosicaoy15] = useState('')
    const [qrcodeCsv, setQrcodeCsv] = useState('')
    const [qrcodeDestino, setQrcodeDestino] = useState('')
    const [qrcodeFonte, setQrcodeFonte] = useState('')
    const [qrcodex, setQrcodex] = useState('')
    const [qrcodey, setQrcodey] = useState('')
    const [quebraLinha, setQuebraLinha] = useState('')
    const [preSet, setPreSet] = useState('')
    const [tipoImpressao, setTipoImpressao] = useState('print')
    const [listaImg, setListaImg] = useState({})

    const [ativoCS, setAtivoCS] = useState('')
    const [descricaoCS, setDescricaoCS] = useState('')
    const [eanCS, setEanCS] = useState('')

    const [ptypeValue, setPtypeValue] = useState('nf')

    const [importCustom, setImportCustom] = useState([])
    const [id, setId] = useState("0")
    const [idtouser, setIdtouser] = useState('0')
    const [change, setChange] = useState(0)
    const [changeNew, setChangeNew] = useState(0)

    const [importList, setImportList] = useState([])
    const [uploaded, setUploaded] = useState([])
    const [importLoadingReg, setImportLoadingReg] = useState(false)
    const [importLoading, setImportLoading] = useState(false)
    const [importPage, setImportPage] = useState(0)

    const [listCampos, setListCampos] = useState([])
    const [listDestino, setListDestino] = useState({})

    const [loading, setLoading] = useState(false)

    const [inBaseProps, setInBaseProps] = useState({})
    const [fieldDigit, setFieldDigit] = useState('')
    const [epcForm, setEpcForm] = useState('')
    const [left, setLeft] = useState('')

    const [userAdress, setUserAdress] = useState('')

    const [userFilter, setUserFilter] = useState(sessionStorage.getItem('idUser'))

    const [orderId, setOrderId] = useState('')
    const [nLote, setNLote] = useState('')
    const [nLoteInterno, setNLoteInterno] = useState('')
    const [nLoteCorreios, setNLoteCorreios] = useState('')
    const [voidEstimado, setVoidEstimado] = useState(0)
    const [qtdLote, setQtdLote] = useState(2500)
    const [orderLoading, setOrderLoading] = useState(false)

    const [jaExiste, setJaExiste] = useState(false)

    const [adressList, setAdressList] = useState([])
    const [selectedPrinter, setSelectedPrinter] = useState('')
    
    const [rotaInovaCenter, setRotaInovaCenter] = useState('')
    const [identify, setIdentify] = useState('')
    const [enderecoApi, setEnderecoApi] = useState('')
    const [loadingCenter, setLoadingCenter] = useState('')

    const [listCenterloaded, setListCenterloaded] = useState([])
    const [uncommingList, setUncommingList] = useState([])
    const [indexCenterSelected, setIndexCenterSelected] = useState([])

    const [listCatToUpload, setListCatToUpload] = useState([])
    const [listCatloaded, setListCatloaded] = useState([])
    const [indexCatSelected, setIndexCatSelected] = useState([])

    const [choice, setChoice] = useState(false)
    const [choiceQtd, setChoiceQtd] = useState(false)

    const [usuarioRunas, setUsuarioRunas] = useState('')

    const [gtinList, setGtinList] = useState([])

    const [displayView, setDisplayView] = useState(true)
    
    const [sendUpdateCat, setSendUpdateCat] = useState([])

    const fileTypes = ["text/csv", "application/vnd.ms-excel", "text/plain"]

    const [upcommingEPCList, setUpcommingEPCList] = useState([])

    useEffect(() => {
        getConfig()
    }, [userFilter])

    useEffect(() => {
        verififyBase()
    }, [])
    
    useEffect(() => {
        getUserAdress()
    }, [])
    
    useEffect(() => {
        getAdressList()
    }, [])

    async function getGtinList() {
        return new Promise(resolve => {
            api.get(`/serialGtin/*/${sessionStorage.getItem('idConta')}`).then(
                async response => {
                    await setGtinList(response.data)
                    resolve(response.data)
                }
            )
        })
    }
    
    async function updateGtinList(update) {
        let options = {
            headers: { 'Content-Type': ['application/json'] }
        }
        await api.post(`/serialGtin`, update, options).then(
            async response => {
                await getGtinList()
                await Swal.fire({
                    title: "Sucesso !",
                    text: 'Lista de serialização Gtin atualizada',
                    icon: 'success',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }
        )
    }

    function textConvert(text = '', type = '') {
        if(text == '' || type == '') {
            return text.toUpperCase()
        }else{
            const charArray = (texto) => {
                let charList = []
                for(let i = 0; i < texto.length ;i++) {
                    charList[i] = texto.charCodeAt(i)
                }
                return charList
            }
      
            const charArrayconvert = (lista, tipo) => {
                let convertedList = []
                let typeToString = 0
                switch (tipo) {
                    case 'hexa':
                        typeToString = 16
                        break;
                    case 'bin':
                        typeToString = 2
                        break;
                    default:
                        typeToString = 10 
                        break;
                }
        
                for(let i = 0; i < lista.length;i++) {
                    convertedList[i] = lista[i].toString(typeToString)
                }
                return convertedList.join('').toUpperCase()
            }
    
          let charLista = charArray(text)
          let convertedJoin = charArrayconvert(charLista, type)
          return convertedJoin
        }
    }

    function sizeFormat(value, size) {
        value = value.replaceAll("R", "").replaceAll("$", "").replaceAll(" ", "").replaceAll(".", "").replaceAll(',','')
        let baseClear = ('0').repeat(size)
        return baseClear.substring(0, baseClear.length - value.length) + value
    }

    function getUserAdress() {
        api.get(`/usuario/${sessionStorage.getItem('idConta')}/${sessionStorage.getItem('idUser')}/*/*/*`).then(
            response => {
                setUserAdress(response.data[0] ? response.data[0].endereco_impressora : '')
            }
        )
    }

    function getAdressList() {
        api.get(`/endereco_impressao/${sessionStorage.getItem('idConta')}/*`).then(
            response => {
                setAdressList(response.data)
            }
        )
    }

    function editListCenterloadedQtd(index, value) {
        let lista = listCenterloaded
        //[8] qtd
        lista[index][8] = value
        setListCenterloaded(lista.filter((item) => (true)))
    }
    
    function editListCatloadedQtd(index, value) {
        let lista = listCatloaded
        //[2] qtd
        lista[index][2] = value
        setListCatloaded(lista.filter((item) => (true)))
        
    }

    function loopGetListbyInovaCenter() {
        let loopGet = setInterval(() => {
            let route = `${enderecoApi}/${sessionStorage.getItem('idConta')}/${rotaInovaCenter}`
            let query = choiceQtd ? `?editList=vq`: choice ? `?editList=v` : ''
            query += choice || choiceQtd ? `&`:'?'
            query+= ptypeValue? 'reqMode=' : ''
            query+= ptypeValue ?? ''
            axios.get(route+query).then(
                async response => {
                    if(response.data.length > 0) {
                        axios.delete(route).then(
                            async response2 => {
                                clearInterval(loopGet)
                                if(choice){
                                    setDisplayView(false)
                                    setListCenterloaded(response.data)
                                }else{
                                    await fileProcess(response)
                                }
                                setUncommingList(response.data)
                                setLoadingCenter(false)
                            },
                            response2 => {  
                                Swal.fire({
                                    title: "Ops... !",
                                    text: 'Houve um erro ao carregar itens do iNOVACENTER --> Err:.D',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })                                
                            }
                        )
                        
                    }
                },
                err => {
                    setLoadingCenter(false)
                    if(err.response.status === 400){
                        Swal.fire({
                            title: "Ops... !",
                            text: err.response.data ?? 'Houve um erro ao carregar itens do iNOVACENTER --> Err:.G',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })
                        setLoadingCenter(false)
                        clearInterval(loopGet)
                    }else{
                        Swal.fire({
                            title: "Ops... !",
                            text: 'Houve um erro ao carregar itens do iNOVACENTER --> Err:.G',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '2500'
                        })    
                    }
                },
            )
        }, 5000)
    }

    function getListbyInovaCenter() {
        setLoadingCenter(true)
        let ende = enderecoApi.replace('/impressao','/autoImp')
        let query = ptypeValue? '?reqMode=' : ''
        query+= ptypeValue ?? ''
        axios.post(`${ende}/${sessionStorage.getItem('idConta')}/${rotaInovaCenter}${query}`).then(
            response => {
                loopGetListbyInovaCenter()
            },
            response => {
                setLoadingCenter(false)
                Swal.fire({
                    title: "Ops... !",
                    text: 'Houve um erro ao carregar itens do iNOVACENTER --> Err:.P',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }
        )
    }

    async function getListbyCategoria() {
        setLoadingCenter(true)
        
        await api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/${descricaoCS == '' ? '*' : descricaoCS}/${eanCS == '' ? '*' : eanCS}/${ativoCS == '' ? '*' : ativoCS}`).then(
            async response => {
                setListCatToUpload(response.data)
                setListCatloaded(response.data.map((cat) => {
                    return [
                        cat._id,
                        cat.descricao,
                        0,
                        cat.ean,
                        cat.inf_compl_1,
                        cat.inf_compl_2,
                        cat.inf_compl_3,
                        cat.inf_compl_4,
                        cat.inf_compl_5,
                        cat.up_1,
                        cat.up_2,
                        cat.up_3,
                        cat.up_4,
                        cat.up_5,
                        cat.observacao,
                        cat.serializacao
                    ]
                }))
                setLoadingCenter(false)
            }
        )

    }


    async function getSelectedListbyInovaCenter() {
        let lista = await listCenterloaded.filter((item, index) => {
            if (indexCenterSelected.includes(index)) {
                return item
            }
        })
        await fileProcess({data: lista})
        clearListCenter()
    }
    
    async function getSelectedListbyCategories() {
        let lista = await listCatloaded.filter((item, index) => {
            if (indexCatSelected.includes(index)) {
                return item
            }
        })
        await fileProcess({data: lista})
        clearListCat()
    }

    function addOrRemoveListImport(index) {
        if(indexCenterSelected.indexOf(index) == -1) {
            setIndexCenterSelected([...indexCenterSelected, index].sort())
        }else{
            let lindex = indexCenterSelected.indexOf(index)
            setIndexCenterSelected([...(indexCenterSelected.slice(0, lindex)), ...(indexCenterSelected.slice(lindex + 1, indexCenterSelected.length))])
        }
    }
    
    function addOrRemoveListCatImport(index) {
        if(indexCatSelected.indexOf(index) == -1) {
            setIndexCatSelected([...indexCatSelected, index].sort())
        }else{
            let lindex = indexCatSelected.indexOf(index)
            setIndexCatSelected([...(indexCatSelected.slice(0, lindex)), ...(indexCatSelected.slice(lindex + 1, indexCatSelected.length))])
        }
    }

    function addAllToList() {
        setIndexCenterSelected(listCenterloaded.map((item, index) => {
            return index
        }))
    }
    
    function addAllToListCat() {
        setIndexCatSelected(listCatloaded.map((item, index) => {
            return index
        }))
    }
    
    function selectedIndex(index) {
        return indexCenterSelected.includes(index)
    }
    
    function selectedIndexCat(index) {
        return indexCatSelected.includes(index)
    }

    function clearListCenter() {
        setListCenterloaded([])
        setIndexCenterSelected([])
        setDisplayView(true)
    }
    
    function clearListCat() {
        setListCatloaded([])
        setIndexCatSelected([])
    }

    function EPCAdd(EPC, ad) {
        let EPCArray = []
        let toAdd = ad
        for(let i = EPC.length - 1; i >= 0; i--) {
            if(isNaN(EPC[i])) {
                EPCArray = [EPC[i], ...EPCArray]
            }else {
                EPCArray = [((Number(EPC[i]) + toAdd)%10).toString(), ...EPCArray]
                
                toAdd = (Math.floor((Number(EPC[i]) + toAdd)/10))
            }
        }
        return EPCArray.join('')
    }

    function montaListaCampos() {
        let campos = []
    
        if(qrcodeCsv != ''){
            campos.push({
                "valor": "qrCode",
                "coluna": qrcodeCsv,
                "px": qrcodex,
                "py": qrcodey,
                "fonte": qrcodeFonte,
                "destino": qrcodeDestino
            })
        }
    
        if(codbarCsv != ''){
            campos.push({
                "valor": "Código de Barras",
                "coluna": codbarCsv,
                "px": codbarx,
                "py": codbary,
                "fonte": codbarFonte,
                "destino": codbarDestino
            })
        }
    
        if(descricao01 != ''){
            campos.push({
                "valor": descricao01,
                "coluna": csv01,
                "px": posicaox01,
                "py": posicaoy01,
                "fonte": fonte01,
                "destino": destino01
            })
        }
    
        if(descricao02 != ''){
            campos.push({
                "valor": descricao02,
                "coluna": csv02,
                "px": posicaox02,
                "py": posicaoy02,
                "fonte": fonte02,
                "destino": destino02
            })
        }
    
        if(descricao03 != ''){
            campos.push({
                "valor": descricao03,
                "coluna": csv03,
                "px": posicaox03,
                "py": posicaoy03,
                "fonte": fonte03,
                "destino": destino03
            })
        }
    
        if(descricao04 != ''){
            campos.push({
                "valor": descricao04,
                "coluna": csv04,
                "px": posicaox04,
                "py": posicaoy04,
                "fonte": fonte04,
                "destino": destino04
            })
        }
    
        if(descricao05 != ''){
            campos.push({
                "valor": descricao05,
                "coluna": csv05,
                "px": posicaox05,
                "py": posicaoy05,
                "fonte": fonte05,
                "destino": destino05
            })
        }
    
        if(descricao06 != ''){
            campos.push({
                "valor": descricao06,
                "coluna": csv06,
                "px": posicaox06,
                "py": posicaoy06,
                "fonte": fonte06,
                "destino": destino06
            })
        }
    
        if(descricao07 != ''){
            campos.push({
                "valor": descricao07,
                "coluna": csv07,
                "px": posicaox07,
                "py": posicaoy07,
                "fonte": fonte07,
                "destino": destino07
            })
        }
    
        if(descricao08 != ''){
            campos.push({
                "valor": descricao08,
                "coluna": csv08,
                "px": posicaox08,
                "py": posicaoy08,
                "fonte": fonte08,
                "destino": destino08
            })
        }
    
        if(descricao09 != ''){
            campos.push({
                "valor": descricao09,
                "coluna": csv09,
                "px": posicaox09,
                "py": posicaoy09,
                "fonte": fonte09,
                "destino": destino09
            })
        }
    
        if(descricao10 != ''){
            campos.push({
                "valor": descricao10,
                "coluna": csv10,
                "px": posicaox10,
                "py": posicaoy10,
                "fonte": fonte10,
                "destino": destino10
            })
        }

        if(descricao11 != ''){
            campos.push({
                "valor": descricao11,
                "coluna": csv11,
                "px": posicaox11,
                "py": posicaoy11,
                "fonte": fonte11,
                "destino": destino11
            })
        }

        if(descricao12 != ''){
            campos.push({
                "valor": descricao12,
                "coluna": csv12,
                "px": posicaox12,
                "py": posicaoy12,
                "fonte": fonte12,
                "destino": destino12
            })
        }

        if(descricao13 != ''){
            campos.push({
                "valor": descricao13,
                "coluna": csv13,
                "px": posicaox13,
                "py": posicaoy13,
                "fonte": fonte13,
                "destino": destino13
            })
        }

        if(descricao14 != ''){
            campos.push({
                "valor": descricao14,
                "coluna": csv14,
                "px": posicaox14,
                "py": posicaoy14,
                "fonte": fonte14,
                "destino": destino14
            })
        }

        if(descricao15 != ''){
            campos.push({
                "valor": descricao15,
                "coluna": csv15,
                "px": posicaox15,
                "py": posicaoy15,
                "fonte": fonte15,
                "destino": destino15
            })
        }
        setListCampos(campos)
    }

    useEffect(()=> {
        if(changeNew == 2) {
            montaListaCampos()
        }
    }, [changeNew])

    useEffect(() => {
        montaListaCampos()
    }, [montCampo01, importList])

    function getConfig() {
        api.get(`/impressao_config/${sessionStorage.getItem('idConta')}/*`, {}
        ).then(
            response => {
                let index = response.data.findIndex((value) => (value.id_usuario == userFilter))
                setIdtouser(index >= 0 ? response.data[index]._id : "0")

                let geralIndex = response.data.findIndex((value) => (value.id_usuario == '*'))
                setId(response.data[geralIndex] ? response.data[geralIndex]._id : "0")

                if(index == -1) {
                    index = geralIndex
                }

                if(index != -1 && response.data[index]){
                    setArquivoImportacao(response.data[index].arquivo_importacao)
                    setCodbarCsv(response.data[index].codbar_csv)
                    setCodbarDestino(response.data[index].codbar_destino)
                    setCodbarFonte(response.data[index].codbar_fonte)
                    setCodbarx(response.data[index].codbarx)
                    setCodbary(response.data[index].codbary)
                    setCsv01(response.data[index].csv_01)
                    setCsv02(response.data[index].csv_02)
                    setCsv03(response.data[index].csv_03)
                    setCsv04(response.data[index].csv_04)
                    setCsv05(response.data[index].csv_05)
                    setCsv06(response.data[index].csv_06)
                    setCsv07(response.data[index].csv_07)
                    setCsv08(response.data[index].csv_08)
                    setCsv09(response.data[index].csv_09)
                    setCsv10(response.data[index].csv_10)
                    setCsv11(response.data[index].csv_11)
                    setCsv12(response.data[index].csv_12)
                    setCsv13(response.data[index].csv_13)
                    setCsv14(response.data[index].csv_14)
                    setCsv15(response.data[index].csv_15)
                    setDescricao01(response.data[index].descricao_01)
                    setDescricao02(response.data[index].descricao_02)
                    setDescricao03(response.data[index].descricao_03)
                    setDescricao04(response.data[index].descricao_04)
                    setDescricao05(response.data[index].descricao_05)
                    setDescricao06(response.data[index].descricao_06)
                    setDescricao07(response.data[index].descricao_07)
                    setDescricao08(response.data[index].descricao_08)
                    setDescricao09(response.data[index].descricao_09)
                    setDescricao10(response.data[index].descricao_10)
                    setDescricao11(response.data[index].descricao_11 ?? '')
                    setDescricao12(response.data[index].descricao_12 ?? '')
                    setDescricao13(response.data[index].descricao_13 ?? '')
                    setDescricao14(response.data[index].descricao_14 ?? '')
                    setDescricao15(response.data[index].descricao_15 ?? '')
                    setDestino01(response.data[index].destino_01)
                    setDestino02(response.data[index].destino_02)
                    setDestino03(response.data[index].destino_03)
                    setDestino04(response.data[index].destino_04)
                    setDestino05(response.data[index].destino_05)
                    setDestino06(response.data[index].destino_06)
                    setDestino07(response.data[index].destino_07)
                    setDestino08(response.data[index].destino_08)
                    setDestino09(response.data[index].destino_09)
                    setDestino10(response.data[index].destino_10)
                    setDestino11(response.data[index].destino_11)
                    setDestino12(response.data[index].destino_12)
                    setDestino13(response.data[index].destino_13)
                    setDestino14(response.data[index].destino_14)
                    setDestino15(response.data[index].destino_15)
                    setEnderecoImpressora(response.data[index].endereco_impressora)
                    setFonte01(response.data[index].fonte_01)
                    setFonte02(response.data[index].fonte_02)
                    setFonte03(response.data[index].fonte_03)
                    setFonte04(response.data[index].fonte_04)
                    setFonte05(response.data[index].fonte_05)
                    setFonte06(response.data[index].fonte_06)
                    setFonte07(response.data[index].fonte_07)
                    setFonte08(response.data[index].fonte_08)
                    setFonte09(response.data[index].fonte_09)
                    setFonte10(response.data[index].fonte_10)
                    setFonte11(response.data[index].fonte_11)
                    setFonte12(response.data[index].fonte_12)
                    setFonte13(response.data[index].fonte_13)
                    setFonte14(response.data[index].fonte_14)
                    setFonte15(response.data[index].fonte_15)
                    setIdConta(response.data[index].id_conta)
                    setImprimirDescricao(response.data[index].imprimir_descricao)
                    setLogoCsv(response.data[index].logo_csv)
                    setLogoGrf(response.data[index].logo_grf)
                    setLogox(response.data[index].logox)
                    setLogoy(response.data[index].logoy)
                    setMontCampo01(response.data[index].mont_campo_01)
                    setMontCampo02(response.data[index].mont_campo_02)
                    setMontCampo03(response.data[index].mont_campo_03)
                    setMontCampo04(response.data[index].mont_campo_04)
                    setMontCampo05(response.data[index].mont_campo_05)
                    setMontCompr01(response.data[index].mont_compr_01)
                    setMontCompr02(response.data[index].mont_compr_02)
                    setMontCompr03(response.data[index].mont_compr_03)
                    setMontCompr04(response.data[index].mont_compr_04)
                    setMontCompr05(response.data[index].mont_compr_05)
                    setMontDigito(response.data[index].mont_digito.replace('-ASC-', '').replace('-LEFT-', ''))
                    setEpcForm(response.data[index].mont_digito.includes('-ASC-') ? '-ASC-' : '')
                    setLeft(response.data[index].mont_digito.includes('-LEFT-') ? '-LEFT-' : '')
                    setMontDigitoCsv(response.data[index].mont_digito_csv)
                    setMontPini01(response.data[index].mont_pini_01)
                    setMontPini02(response.data[index].mont_pini_02)
                    setMontPini03(response.data[index].mont_pini_03)
                    setMontPini04(response.data[index].mont_pini_04)
                    setMontPini05(response.data[index].mont_pini_05)
                    setPosicaox01(response.data[index].posicaox_01)
                    setPosicaox02(response.data[index].posicaox_02)
                    setPosicaox03(response.data[index].posicaox_03)
                    setPosicaox04(response.data[index].posicaox_04)
                    setPosicaox05(response.data[index].posicaox_05)
                    setPosicaox06(response.data[index].posicaox_06)
                    setPosicaox07(response.data[index].posicaox_07)
                    setPosicaox08(response.data[index].posicaox_08)
                    setPosicaox09(response.data[index].posicaox_09)
                    setPosicaox10(response.data[index].posicaox_10)
                    setPosicaox11(response.data[index].posicaox_11)
                    setPosicaox12(response.data[index].posicaox_12)
                    setPosicaox13(response.data[index].posicaox_13)
                    setPosicaox14(response.data[index].posicaox_14)
                    setPosicaox15(response.data[index].posicaox_15)
                    setPosicaoy01(response.data[index].posicaoy_01)
                    setPosicaoy02(response.data[index].posicaoy_02)
                    setPosicaoy03(response.data[index].posicaoy_03)
                    setPosicaoy04(response.data[index].posicaoy_04)
                    setPosicaoy05(response.data[index].posicaoy_05)
                    setPosicaoy06(response.data[index].posicaoy_06)
                    setPosicaoy07(response.data[index].posicaoy_07)
                    setPosicaoy08(response.data[index].posicaoy_08)
                    setPosicaoy09(response.data[index].posicaoy_09)
                    setPosicaoy10(response.data[index].posicaoy_10)
                    setPosicaoy11(response.data[index].posicaoy_11)
                    setPosicaoy12(response.data[index].posicaoy_12)
                    setPosicaoy13(response.data[index].posicaoy_13)
                    setPosicaoy14(response.data[index].posicaoy_14)
                    setPosicaoy15(response.data[index].posicaoy_15)
                    setQrcodeCsv(response.data[index].qrcode_csv)
                    setQrcodeDestino(response.data[index].qrcode_destino)
                    setQrcodeFonte(response.data[index].qrcode_fonte)
                    setQrcodex(response.data[index].qrcodex)
                    setQrcodey(response.data[index].qrcodey)
                    setQuebraLinha(response.data[index].quebra_linha)
                    setPreSet(response.data[index].pre_set ? response.data[index].pre_set : '')
                    setTipoImpressao(response.data[index].tipo_impressao ? response.data[index].tipo_impressao : 'print')
                    setEnderecoApi(response.data[index].endereco_api)
                    setUsuarioRunas(response.data[index].usuario_runas)
                    setImportCustom(response.data[index].import_custom)
                    if(response.data[index].mont_digito.includes('Campo-')){
                        if(response.data[index].descricao_01 != '' && response.data[index].mont_digito.includes(response.data[index].descricao_01)) {setFieldDigit('campo3')}
                        if(response.data[index].descricao_02 != '' && response.data[index].mont_digito.includes(response.data[index].descricao_02)) {setFieldDigit('campo4')}
                        if(response.data[index].descricao_03 != '' && response.data[index].mont_digito.includes(response.data[index].descricao_03)) {setFieldDigit('campo5')}
                        if(response.data[index].descricao_04 != '' && response.data[index].mont_digito.includes(response.data[index].descricao_04)) {setFieldDigit('campo6')}
                        if(response.data[index].descricao_05 != '' && response.data[index].mont_digito.includes(response.data[index].descricao_05)) {setFieldDigit('campo7')}
                        if(response.data[index].descricao_06 != '' && response.data[index].mont_digito.includes(response.data[index].descricao_06)) {setFieldDigit('campo8')}
                        if(response.data[index].descricao_07 != '' && response.data[index].mont_digito.includes(response.data[index].descricao_07)) {setFieldDigit('campo9')}
                        if(response.data[index].descricao_08 != '' && response.data[index].mont_digito.includes(response.data[index].descricao_08)) {setFieldDigit('campo10')}
                        if(response.data[index].descricao_09 != '' && response.data[index].mont_digito.includes(response.data[index].descricao_09)) {setFieldDigit('campo11')}
                        if(response.data[index].descricao_10 != '' && response.data[index].mont_digito.includes(response.data[index].descricao_10)) {setFieldDigit('campo12')}
                        if(response.data[index].descricao_11 != '' && response.data[index].mont_digito.includes(response.data[index].descricao_11)) {setFieldDigit('campo13')}
                        if(response.data[index].descricao_12 != '' && response.data[index].mont_digito.includes(response.data[index].descricao_12)) {setFieldDigit('campo14')}
                        if(response.data[index].descricao_13 != '' && response.data[index].mont_digito.includes(response.data[index].descricao_13)) {setFieldDigit('campo15')}
                        if(response.data[index].descricao_14 != '' && response.data[index].mont_digito.includes(response.data[index].descricao_14)) {setFieldDigit('campo16')}
                        if(response.data[index].descricao_15 != '' && response.data[index].mont_digito.includes(response.data[index].descricao_15)) {setFieldDigit('campo17')}
                    }
                    let logo = {}
                    if(response.data[index].descricao_01.includes('-logo-')){
                        let z64 = response.data[index].fonte_01.replace('^GFA,', '')
                        let zz64 = z64.slice(z64.indexOf(','))
                        logo['logo_01'] = `~DGR:logo_01.GRF${zz64}`
                    }
                    if(response.data[index].descricao_02.includes('-logo-')){
                        let z64 = response.data[index].fonte_02.replace('^GFA,', '')
                        let zz64 = z64.slice(z64.indexOf(','))
                        logo['logo_02'] = `~DGR:logo_02.GRF${zz64}`
                    }
                    if(response.data[index].descricao_03.includes('-logo-')){
                        let z64 = response.data[index].fonte_03.replace('^GFA,', '')
                        let zz64 = z64.slice(z64.indexOf(','))
                        logo['logo_03'] = `~DGR:logo_03.GRF${zz64}`
                    }
                    if(response.data[index].descricao_04.includes('-logo-')){
                        let z64 = response.data[index].fonte_04.replace('^GFA,', '')
                        let zz64 = z64.slice(z64.indexOf(','))
                        logo['logo_04'] = `~DGR:logo_04.GRF${zz64}`
                    }
                    if(response.data[index].descricao_05.includes('-logo-')){
                        let z64 = response.data[index].fonte_05.replace('^GFA,', '')
                        let zz64 = z64.slice(z64.indexOf(','))
                        logo['logo_05'] = `~DGR:logo_05.GRF${zz64}`
                    }
                    if(response.data[index].descricao_06.includes('-logo-')){
                        let z64 = response.data[index].fonte_06.replace('^GFA,', '')
                        let zz64 = z64.slice(z64.indexOf(','))
                        logo['logo_06'] = `~DGR:logo_06.GRF${zz64}`
                    }
                    if(response.data[index].descricao_07.includes('-logo-')){
                        let z64 = response.data[index].fonte_07.replace('^GFA,', '')
                        let zz64 = z64.slice(z64.indexOf(','))
                        logo['logo_07'] = `~DGR:logo_07.GRF${zz64}`
                    }
                    if(response.data[index].descricao_08.includes('-logo-')){
                        let z64 = response.data[index].fonte_08.replace('^GFA,', '')
                        let zz64 = z64.slice(z64.indexOf(','))
                        logo['logo_08'] = `~DGR:logo_08.GRF${zz64}`
                    }
                    if(response.data[index].descricao_09.includes('-logo-')){
                        let z64 = response.data[index].fonte_09.replace('^GFA,', '')
                        let zz64 = z64.slice(z64.indexOf(','))
                        logo['logo_09'] = `~DGR:logo_09.GRF${zz64}`
                    }
                    if(response.data[index].descricao_10.includes('-logo-')){
                        let z64 = response.data[index].fonte_10.replace('^GFA,', '')
                        let zz64 = z64.slice(z64.indexOf(','))
                        logo['logo_10'] = `~DGR:logo_10.GRF${zz64}`
                    }
                    if(response.data[index].descricao_11?.includes('-logo-')){
                        let z64 = response.data[index].fonte_11.replace('^GFA,', '')
                        let zz64 = z64.slice(z64.indexOf(','))
                        logo['logo_11'] = `~DGR:logo_11.GRF${zz64}`
                    }
                    if(response.data[index].descricao_12?.includes('-logo-')){
                        let z64 = response.data[index].fonte_12.replace('^GFA,', '')
                        let zz64 = z64.slice(z64.indexOf(','))
                        logo['logo_12'] = `~DGR:logo_12.GRF${zz64}`
                    }
                    if(response.data[index].descricao_13?.includes('-logo-')){
                        let z64 = response.data[index].fonte_13.replace('^GFA,', '')
                        let zz64 = z64.slice(z64.indexOf(','))
                        logo['logo_13'] = `~DGR:logo_13.GRF${zz64}`
                    }
                    if(response.data[index].descricao_14?.includes('-logo-')){
                        let z64 = response.data[index].fonte_14.replace('^GFA,', '')
                        let zz64 = z64.slice(z64.indexOf(','))
                        logo['logo_14'] = `~DGR:logo_14.GRF${zz64}`
                    }
                    if(response.data[index].descricao_15?.includes('-logo-')){
                        let z64 = response.data[index].fonte_15.replace('^GFA,', '')
                        let zz64 = z64.slice(z64.indexOf(','))
                        logo['logo_15'] = `~DGR:logo_15.GRF${zz64}`
                    }
                    setListaImg(logo)
                }
            }
        )
    }

    async function gerarZPL(printOrderInfo = null) {
        return new Promise(async (resolve, reject) => {
            let dt  = new Date();
            let id = sessionStorage.getItem('idUser') ? sessionStorage.getItem('idUser') : sessionStorage.getItem('idConta')
            id  += ("0" + dt.getDate()).slice(-2) + ("0" + (dt.getMonth() + 1)).slice(-2) +  dt.getFullYear()
            id += ("0" + dt.getHours()).slice(-2) + ("0" +dt.getMinutes()).slice(-2) + ("0" +dt.getSeconds()).slice(-2)
        
            let zpl = ""
            let c
            let descValor
            
            (Object.keys(listaImg)).map((item) => {
                zpl += listaImg[item] + '\n'
            })
             
            if(selectedPrinter != '') {
                let options = {
                    headers: { 'Content-Type': ['application/json'] }
                }
                var someProblem = false
                var epctofilter = []
                if(printOrderInfo) {
                    let poi = printOrderInfo
                    poi.arquivo_bat = `${process.env.REACT_APP_API_URL}/print${id}.bat`
                    poi.arquivo_grf = `${process.env.REACT_APP_API_URL}/logo${id}.grf`
                    poi.arquivo_csv = `${process.env.REACT_APP_API_URL}/tags${id}.csv`
                    await api.post('/ordem_impressao_hoje2', [poi], options).then(
                        async response3 => {
                            epctofilter = await response3.data[0].epcs
                        },
                        response3 => {
                            if(response3.response.data.error) {
                                Swal.fire({
                                    title: "Ops... !",
                                    text: response3.response.data.error,
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                                if(response3.response.data.error == "ORDEM COM EPCS EXISTENTE!") {
                                    setJaExiste(true)
                                }
                            }else{
                                Swal.fire({
                                    title: "Ops... !",
                                    html: 'Algo deu errado ao gerar ordem de impressão,</br> por favor tente novamente mais tarde',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                    timer: '2500'
                                })
                            }
                            setOrderLoading(false)
                            someProblem = true
                        }
                    )
                }
                if(importList.length != 0) {
                    importList.map(async (item, index) => {
                        if(tipoImpressao != 'print-order' || (tipoImpressao == 'print-order' && epctofilter.includes(item.epc))){//!inBaseProps.tags.includes(item.epc)) {
                            zpl += "^XA"
                            //zpl += "^MD12" 
                            //zpl += "^RS8,B8,20,10,N"
                            zpl += preSet.trim()
                            zpl += "^CI28"
                    
                            //epc
                            zpl += "^RFW,H^FD" + item.epc + "^FS" 
                        
                            if(logox != '' && logoy != '' && logoGrf != '') {
                                //logo
                                zpl += "^FO" + logox +","+ logoy + "^XGR:logo" + id + ".grf,1,1^FS"
                            }
                    
                            //qrCode
                            if(qrcodeCsv != '') {
                    
                                if(qrcodeCsv=="epc") {
                                    descValor = item.epc
                                    
                                } else if(qrcodeCsv=="count") {
                                    descValor = item.count
                    
                                } else {
                                    descValor = item.campo1
                                }
                    
                                zpl += "^FO" + qrcodex + "," + qrcodey + "^BQN,2," + qrcodeFonte + ",Q,7^FDMAN" + descValor + "^FS"
                                //qrcode_fonte é altura
                            }
            
                            //Codigo de Barras
                            if(codbarCsv != '' ) {
            
                                if(codbarCsv=="epc") {
                                    descValor = item.epc
                                    
                                } else if(codbarCsv=="count") {
                                    descValor = item.count
            
                                } else {
                                    descValor = item.campo2
                                }
            
                                zpl += "^FO" + codbarx + "," + codbary + "^BY2,3.0^BCN," + codbarFonte + ",N,N,N,N^FD" + descValor + "^FS" 
                                //codbar_fonte é altura
                            }
                    
                            //Linha 1
                            if(descricao01 != '' && posicaox01 != '') {
                                c   = listCampos.findIndex(k => k.valor == descricao01)
                                
                                if(descricao01.toLowerCase().includes('-hidden-')){
                                }else if(descricao01.toLowerCase().includes('codebar')) {
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    if(descricao01.toLowerCase().includes('-code-39-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^B3N,N" + "," + fonteSplit[1] + ",N,N^FD" + item.campo3 + "^FS"
                                    }else if(descricao01.toLowerCase().includes('-varejo-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +"^BEN," + fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + "^FD" + item.campo3 + "^FS" 
                                    }else if(descricao01.toLowerCase().includes('-gs1-128-')){
                                        //zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCN,,N,N^FD>;>${item.campo3}^FS`
                                        zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCI,,N,N^FH\\^FD>;>8${item.campo3}^FS`
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^BCN,"+ fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + ",N,N,N^FD" + item.campo3 + "^FS" 
                                    }
                                }else if(descricao01.toLowerCase().includes('-variavel')){
                                    let fonteSplit = listCampos[c].fonte
                                    let customized = fonteSplit.replace('${}',item.campo3)

                                    zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + fonteSplit[0] + customized + "^FS"
                                }else if(descricao01.toLowerCase().includes('qrcode')){
                                    //aqui Gabi
                                    if(descricao01.toLowerCase().includes('-custom-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        let customized = fonteSplit[1].replace('${}',item.campo3)
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + fonteSplit[0] + ",Q,7^FDMAN" + customized + "^FS"
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + listCampos[c].fonte + ",Q,7^FDMAN" + item.campo3 + "^FS"
                                    }
                                }else if(descricao01.toLowerCase().includes('-logo-')){
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^XGR:logo_01.GRF,1,1^FS`
                                }else if(descricao01.toLowerCase().includes('-fixed-')){
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^A0N,${fonteSplit[0]},${fonteSplit[1]}^FD${fonteSplit[2]}^FS`
                                }else{
                                    if(imprimirDescricao=="1"){
                                        descValor = descricao01 + ": " + item.campo3
                                    }else if(descricao01.toLowerCase().includes('-desc-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        descValor = fonteSplit[2] + ": " + item.campo3
                                    } else {
                                        descValor = item.campo3
                                    }
                                    
                                    if(descValor.length >= parseInt(quebraLinha) ) {
                                        let line1 = descValor.substring(0, parseInt(quebraLinha))
                                        zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + line1 + "^FS" 	
                                        
                                        let line2 = descValor.substring(parseInt(quebraLinha), (parseInt(quebraLinha)*2))
                                        zpl += "^FO" + listCampos[c].px +","+ (parseInt(listCampos[c].py) + parseInt(listCampos[c].fonte)) + "^A0N," + listCampos[c].fonte + "^FD" + line2 + "^FS" 	
                                        
                                        if(item.campo3.length>=parseInt(quebraLinha)*2) {
                                            let line3 = descValor.substring((parseInt(quebraLinha)*2), ((parseInt(quebraLinha)*2) + parseInt(quebraLinha)) )
                                            zpl += "^FO" + listCampos[c].px +","+ (parseInt(listCampos[c].py) + (parseInt(listCampos[c].fonte) * 2)) + "^A0N," + listCampos[c].fonte + "^FD" + line3 + "^FS" 	
                                        }
                                    } else {
                                        zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + descValor + "^FS" 	
                                    }
                                }
                                
                            }
                    
                            //Linha 2
                            if(descricao02!='' && posicaox02!='') {
                                c   = listCampos.findIndex(k => k.valor==descricao02)
            
                                if(descricao02.toLowerCase().includes('-hidden-')){
                                }else if(descricao02.toLowerCase().includes('codebar')) {
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    if(descricao02.toLowerCase().includes('-code-39-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^B3N,N" + "," + fonteSplit[1] + ",N,N^FD" + item.campo4 + "^FS"
                                    }else if(descricao02.toLowerCase().includes('-varejo-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +"^BEN," + fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + "^FD" + item.campo4 + "^FS" 
                                    }else if(descricao02.toLowerCase().includes('-gs1-128-')){
                                        //zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCN,,N,N^FD>;>${item.campo4}^FS`
                                        zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCI,,N,N^FH\\^FD>;>8${item.campo4}^FS`
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^BCN,"+ fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + ",N,N,N^FD" + item.campo4 + "^FS" 
                                    }
                                }else if(descricao02.toLowerCase().includes('-variavel')){
                                    let fonteSplit = listCampos[c].fonte
                                    let customized = fonteSplit.replace('${}',item.campo4)

                                    zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + fonteSplit[0] + customized + "^FS"
                                }else if(descricao02.toLowerCase().includes('qrcode')){
                                    //aqui Gabi
                                    if(descricao02.toLowerCase().includes('-custom-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        let customized = fonteSplit[1].replace('${}',item.campo4)
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + fonteSplit[0] + ",Q,7^FDMAN" + customized + "^FS"
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + listCampos[c].fonte + ",Q,7^FDMAN" + item.campo4 + "^FS"
                                    }
                                }else if(descricao02.toLowerCase().includes('-logo-')){
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^XGR:logo_02.GRF,1,1^FS`
                                }else if(descricao02.toLowerCase().includes('-fixed-')){
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^A0N,${fonteSplit[0]},${fonteSplit[1]}^FD${fonteSplit[2]}^FS`
                                }else{
                                    if(listCampos[c].coluna=="epc") {
                                        descValor = item.epc
                                        
                                    } else if(listCampos[c].coluna=="count") {
                                        descValor = item.count
                        
                                    } else if(imprimirDescricao=="1"){
                                        descValor = descricao02 + ": " + item.campo4
                                    }else if(descricao02.toLowerCase().includes('-desc-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        descValor = fonteSplit[2] + ": " + item.campo4
                                    } else {
                                        descValor = item.campo4
                                    }
                        
                                    zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + descValor + "^FS" 	
                                }
                            }
                    
                            //Linha 3
                            if(descricao03!='' && posicaox03!='') {
                                
                                c   = listCampos.findIndex(k => k.valor==descricao03)
            
                                if(descricao03.toLowerCase().includes('-hidden-')){
                                }else if(descricao03.toLowerCase().includes('codebar')) {
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    if(descricao03.toLowerCase().includes('-code-39-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^B3N,N" + "," + fonteSplit[1] + ",N,N^FD" + item.campo5 + "^FS"
                                    }else if(descricao03.toLowerCase().includes('-varejo-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +"^BEN," + fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + "^FD" + item.campo5 + "^FS" 
                                    }else if(descricao03.toLowerCase().includes('-gs1-128-')){
                                        //zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCN,,N,N^FD>;>${item.campo5}^FS`
                                        zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCI,,N,N^FH\\^FD>;>8${item.campo5}^FS`
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^BCN,"+ fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + ",N,N,N^FD" + item.campo5 + "^FS" 
                                    }
                                }else if(descricao03.toLowerCase().includes('-variavel')){
                                    let fonteSplit = listCampos[c].fonte
                                    let customized = fonteSplit.replace('${}',item.campo5)

                                    zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + fonteSplit[0] + customized + "^FS"
                                }else if(descricao03.toLowerCase().includes('qrcode')){
                                    //aqui Gabi
                                    if(descricao03.toLowerCase().includes('-custom-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        let customized = fonteSplit[1].replace('${}',item.campo5)
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + fonteSplit[0] + ",Q,7^FDMAN" + customized + "^FS"
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + listCampos[c].fonte + ",Q,7^FDMAN" + item.campo5 + "^FS"
                                    }
                                }else if(descricao03.toLowerCase().includes('-logo-')){
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^XGR:logo_03.GRF,1,1^FS`
                                }else if(descricao03.toLowerCase().includes('-fixed-')){
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^A0N,${fonteSplit[0]},${fonteSplit[1]}^FD${fonteSplit[2]}^FS`
                                }else{
                                    if(listCampos[c].coluna=="epc") {
                                        descValor = item.epc
                                        
                                    } else if(listCampos[c].coluna=="count") {
                                        descValor = item.count
                        
                                    } else if(imprimirDescricao=="1"){
                                        descValor = descricao03 + ": " + item.campo5
                                    }else if(descricao03.toLowerCase().includes('-desc-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        descValor = fonteSplit[2] + ": " + item.campo5
                                    } else {
                                        descValor = item.campo5
                                    }
                                    
                                    zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + descValor + "^FS" 	
                                }
                            }				
                    
                            //Linha 4
                            if(descricao04!='' && posicaox04!='') {
                                c   = listCampos.findIndex(k => k.valor==descricao04)
                                
                                if(descricao04.toLowerCase().includes('-hidden-')){
                                }else if(descricao04.toLowerCase().includes('codebar')) {
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    if(descricao04.toLowerCase().includes('-code-39-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^B3N,N" + "," + fonteSplit[1] + ",N,N^FD" + item.campo6 + "^FS"
                                    }else if(descricao04.toLowerCase().includes('-varejo-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +"^BEN," + fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + "^FD" + item.campo6 + "^FS" 
                                    }else if(descricao04.toLowerCase().includes('-gs1-128-')){
                                        //zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCN,,N,N^FD>;>${item.campo6}^FS`
                                        zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCI,,N,N^FH\\^FD>;>8${item.campo6}^FS`
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^BCN,"+ fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + ",N,N,N^FD" + item.campo6 + "^FS" 
                                    }
                                }else if(descricao04.toLowerCase().includes('-variavel')){
                                    let fonteSplit = listCampos[c].fonte
                                    let customized = fonteSplit.replace('${}',item.campo6)

                                    zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + fonteSplit[0] + customized + "^FS"
                                }else if(descricao04.toLowerCase().includes('qrcode')){
                                    //aqui Gabi
                                    if(descricao04.toLowerCase().includes('-custom-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        let customized = fonteSplit[1].replace('${}',item.campo6)
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + fonteSplit[0] + ",Q,7^FDMAN" + customized + "^FS"
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + listCampos[c].fonte + ",Q,7^FDMAN" + item.campo6 + "^FS"
                                    }
                                }else if(descricao04.toLowerCase().includes('-logo-')){
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^XGR:logo_04.GRF,1,1^FS`
                                }else if(descricao04.toLowerCase().includes('-fixed-')){
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^A0N,${fonteSplit[0]},${fonteSplit[1]}^FD${fonteSplit[2]}^FS`
                                }else{
                                    if(listCampos[c].coluna=="epc") {
                                        descValor = item.epc
                                        
                                    } else if(listCampos[c].coluna=="count") {
                                        descValor = item.count
                        
                                    } else if(imprimirDescricao=="1"){
                                        descValor = descricao04 + ": " + item.campo6
                                    }else if(descricao04.toLowerCase().includes('-desc-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        descValor = fonteSplit[2] + ": " + item.campo6
                                    } else {
                                        descValor = item.campo6
                                    }
                                    
                                    zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + descValor + "^FS" 	
                                }
                            }	
                    
                            //Linha 5
                            if(descricao05!='' && posicaox05!='') {
                                c   = listCampos.findIndex(k => k.valor==descricao05)
                                if(descricao05.toLowerCase().includes('-hidden-')){
                                }else if(descricao05.toLowerCase().includes('codebar')) {
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    if(descricao05.toLowerCase().includes('-code-39-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^B3N,N" + "," + fonteSplit[1] + ",N,N^FD" + item.campo7 + "^FS"
                                    }else if(descricao05.toLowerCase().includes('-varejo-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +"^BEN," + fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + "^FD" + item.campo7 + "^FS" 
                                    }else if(descricao05.toLowerCase().includes('-gs1-128-')){
                                        //zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCN,,N,N^FD>;>${item.campo7}^FS`
                                        zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCI,,N,N^FH\\^FD>;>8${item.campo7}^FS`
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^BCN,"+ fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + ",N,N,N^FD" + item.campo7 + "^FS" 
                                    }
                                }else if(descricao05.toLowerCase().includes('-variavel')){
                                    let fonteSplit = listCampos[c].fonte
                                    let customized = fonteSplit.replace('${}',item.campo7)

                                    zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + fonteSplit[0] + customized + "^FS"
                                }else if(descricao05.toLowerCase().includes('qrcode')){
                                    //aqui Gabi
                                    if(descricao05.toLowerCase().includes('-custom-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        let customized = fonteSplit[1].replace('${}',item.campo7)
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + fonteSplit[0] + ",Q,7^FDMAN" + customized + "^FS"
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + listCampos[c].fonte + ",Q,7^FDMAN" + item.campo7 + "^FS"
                                    }
                                }else if(descricao05.toLowerCase().includes('-logo-')){
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^XGR:logo_05.GRF,1,1^FS`
                                }else if(descricao05.toLowerCase().includes('-fixed-')){
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^A0N,${fonteSplit[0]},${fonteSplit[1]}^FD${fonteSplit[2]}^FS`
                                }else{
                                    if(listCampos[c].coluna=="epc") {
                                        descValor = item.epc
                                        
                                    } else if(listCampos[c].coluna=="count") {
                                        descValor = item.count
                        
                                    } else if(imprimirDescricao=="1"){
                                        descValor = descricao05 + ": " + item.campo7
                                    }else if(descricao05.toLowerCase().includes('-desc-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        descValor = fonteSplit[2] + ": " + item.campo7
                                    } else {
                                        descValor = item.campo7
                                    }
                        
                                    zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + descValor + "^FS" 	
                                }
                            }	
                    
                            //Linha 6
                            if(descricao06!='' && posicaox06!='') {
                                c   = listCampos.findIndex(k => k.valor==descricao06)
                                if(descricao06.toLowerCase().includes('-hidden-')){
                                }else if(descricao06.toLowerCase().includes('codebar')) {
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    if(descricao06.toLowerCase().includes('-code-39-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^B3N,N" + "," + fonteSplit[1] + ",N,N^FD" + item.campo8 + "^FS"
                                    }else if(descricao06.toLowerCase().includes('-varejo-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +"^BEN," + fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + "^FD" + item.campo8 + "^FS" 
                                    }else if(descricao06.toLowerCase().includes('-gs1-128-')){
                                        //zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCN,,N,N^FD>;>${item.campo8}^FS`
                                        zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCI,,N,N^FH\\^FD>;>8${item.campo8}^FS`
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^BCN,"+ fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + ",N,N,N^FD" + item.campo8 + "^FS" 
                                    }
                                }else if(descricao06.toLowerCase().includes('-variavel')){
                                    let fonteSplit = listCampos[c].fonte
                                    let customized = fonteSplit.replace('${}',item.campo6)

                                    zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + fonteSplit[0] + customized + "^FS"
                                }else if(descricao06.toLowerCase().includes('qrcode')){
                                    //aqui Gabi
                                    if(descricao06.toLowerCase().includes('-custom-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        let customized = fonteSplit[1].replace('${}',item.campo8)
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + fonteSplit[0] + ",Q,7^FDMAN" + customized + "^FS"
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + listCampos[c].fonte + ",Q,7^FDMAN" + item.campo8 + "^FS"
                                    }
                                }else if(descricao06.toLowerCase().includes('-logo-')){
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^XGR:logo_06.GRF,1,1^FS`
                                }else if(descricao06.toLowerCase().includes('-fixed-')){
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^A0N,${fonteSplit[0]},${fonteSplit[1]}^FD${fonteSplit[2]}^FS`
                                }else{
                                    if(listCampos[c].coluna=="epc") {
                                        descValor = item.epc
                                        
                                    } else if(listCampos[c].coluna=="count") {
                                        descValor = item.count
                        
                                    } else if(imprimirDescricao=="1"){
                                        descValor = descricao06 + ": " + item.campo8
                                    }else if(descricao06.toLowerCase().includes('-desc-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        descValor = fonteSplit[2] + ": " + item.campo8
                                    } else {
                                        descValor = item.campo8
                                    }
                                    
                                    zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + descValor + "^FS" 	
                                }
                            }	
                    
                            //Linha 7
                            if(descricao07!='' && posicaox07!='') {
                                c   = listCampos.findIndex(k => k.valor==descricao07)
                                if(descricao07.toLowerCase().includes('-hidden-')){
                                }else if(descricao07.toLowerCase().includes('codebar')) {
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    if(descricao07.toLowerCase().includes('-code-39-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^B3N,N" + "," + fonteSplit[1] + ",N,N^FD" + item.campo9 + "^FS"
                                    }else if(descricao07.toLowerCase().includes('-varejo-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +"^BEN," + fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + "^FD" + item.campo9 + "^FS" 
                                    }else if(descricao07.toLowerCase().includes('-gs1-128-')){
                                        //zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCN,,N,N^FD>;>${item.campo9}^FS`
                                        zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCI,,N,N^FH\\^FD>;>8${item.campo9}^FS`
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^BCN,"+ fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + ",N,N,N^FD" + item.campo9 + "^FS" 
                                    }
                                }else if(descricao07.toLowerCase().includes('-variavel')){
                                    let fonteSplit = listCampos[c].fonte
                                    let customized = fonteSplit.replace('${}',item.campo9)

                                    zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + fonteSplit[0] + customized + "^FS"
                                }else if(descricao07.toLowerCase().includes('qrcode')){
                                    //aqui Gabi
                                    if(descricao07.toLowerCase().includes('-custom-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        let customized = fonteSplit[1].replace('${}',item.campo9)
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + fonteSplit[0] + ",Q,7^FDMAN" + customized + "^FS"
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + listCampos[c].fonte + ",Q,7^FDMAN" + item.campo9 + "^FS"
                                    }
                                }else if(descricao07.toLowerCase().includes('-logo-')){
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^XGR:logo_07.GRF,1,1^FS`
                                }else if(descricao07.toLowerCase().includes('-fixed-')){
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^A0N,${fonteSplit[0]},${fonteSplit[1]}^FD${fonteSplit[2]}^FS`
                                }else{
                                    if(listCampos[c].coluna=="epc") {
                                        descValor = item.epc
                                        
                                    } else if(listCampos[c].coluna=="count") {
                                        descValor = item.count
                        
                                    } else if(imprimirDescricao=="1"){
                                        descValor = descricao07 + ": " + item.campo9
                                    }else if(descricao07.toLowerCase().includes('-desc-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        descValor = fonteSplit[2] + ": " + item.campo9
                                    } else {
                                        descValor = item.campo9
                                    }
                                    
                                    zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + descValor + "^FS" 	
                                }
                            }	
                    
                            //Linha 8
                            if(descricao08!='' && posicaox08!='') {
                                c   = listCampos.findIndex(k => k.valor==descricao08)
                                if(descricao08.toLowerCase().includes('-hidden-')){
                                }else if(descricao08.toLowerCase().includes('codebar')) {
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    if(descricao08.toLowerCase().includes('-code-39-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^B3N,N" + "," + fonteSplit[1] + ",N,N^FD" + item.campo10 + "^FS"
                                    }else if(descricao08.toLowerCase().includes('-varejo-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +"^BEN," + fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + "^FD" + item.campo10 + "^FS" 
                                    }else if(descricao08.toLowerCase().includes('-gs1-128-')){
                                        //zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCN,,N,N^FD>;>${item.campo10}^FS`
                                        zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCI,,N,N^FH\\^FD>;>8${item.campo10}^FS`
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^BCN,"+ fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + ",N,N,N^FD" + item.campo10 + "^FS" 
                                    }
                                }else if(descricao08.toLowerCase().includes('-variavel')){
                                    let fonteSplit = listCampos[c].fonte
                                    let customized = fonteSplit.replace('${}',item.campo10)

                                    zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + fonteSplit[0] + customized + "^FS"
                                    
                                }else if(descricao08.toLowerCase().includes('qrcode')){
                                    //aqui Gabi
                                    if(descricao08.toLowerCase().includes('-custom-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        let customized = fonteSplit[1].replace('${}',item.campo10)
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + fonteSplit[0] + ",Q,7^FDMAN" + customized + "^FS"
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + listCampos[c].fonte + ",Q,7^FDMAN" + item.campo10 + "^FS"
                                    }
                                }else if(descricao08.toLowerCase().includes('-logo-')){
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^XGR:logo_08.GRF,1,1^FS`
                                }else if(descricao08.toLowerCase().includes('-fixed-')){
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^A0N,${fonteSplit[0]},${fonteSplit[1]}^FD${fonteSplit[2]}^FS`
                                }else{
                                    if(listCampos[c].coluna=="epc") {
                                        descValor = item.epc
                                        
                                    } else if(listCampos[c].coluna=="count") {
                                        descValor = item.count
                        
                                    } else if(imprimirDescricao=="1"){
                                        descValor = descricao08 + ": " + item.campo10
                                    }else if(descricao08.toLowerCase().includes('-desc-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        descValor = fonteSplit[2] + ": " + item.campo10
                                    } else {
                                        descValor = item.campo10
                                    }
                                    
                                    zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + descValor + "^FS" 	
                                }
                            }	
                    
                            //Linha 9
                            if(descricao09!='' && posicaox09!='') {
                                c   = listCampos.findIndex(k => k.valor==descricao09)
                                if(descricao09.toLowerCase().includes('-hidden-')){
                                }else if(descricao09.toLowerCase().includes('codebar')) {
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    if(descricao09.toLowerCase().includes('-code-39-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^B3N,N" + "," + fonteSplit[1] + ",N,N^FD" + item.campo11 + "^FS"
                                    }else if(descricao09.toLowerCase().includes('-varejo-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +"^BEN," + fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + "^FD" + item.campo11 + "^FS" 
                                    }else if(descricao09.toLowerCase().includes('-gs1-128-')){
                                        //zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCN,,N,N^FD>;>${item.campo11}^FS`
                                        zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCI,,N,N^FH\\^FD>;>8${item.campo11}^FS`
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^BCN,"+ fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + ",N,N,N^FD" + item.campo11 + "^FS" 
                                    }
                                }else if(descricao09.toLowerCase().includes('-variavel')){
                                    let fonteSplit = listCampos[c].fonte
                                    let customized = fonteSplit.replace('${}',item.campo11)

                                    zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + fonteSplit[0] + customized + "^FS"
                                    
                                }else if(descricao09.toLowerCase().includes('qrcode')){
                                    //aqui Gabi
                                    if(descricao09.toLowerCase().includes('-custom-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        let customized = fonteSplit[1].replace('${}',item.campo11)
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + fonteSplit[0] + ",Q,7^FDMAN" + customized + "^FS"
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + listCampos[c].fonte + ",Q,7^FDMAN" + item.campo11 + "^FS"
                                    }
                                }else if(descricao09.toLowerCase().includes('-logo-')){
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^XGR:logo_09.GRF,1,1^FS`
                                }else if(descricao09.toLowerCase().includes('-fixed-')){
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^A0N,${fonteSplit[0]},${fonteSplit[1]}^FD${fonteSplit[2]}^FS`
                                }else{
                                    if(listCampos[c].coluna=="epc") {
                                        descValor = item.epc
                                        
                                    } else if(listCampos[c].coluna=="count") {
                                        descValor = item.count
                        
                                    } else if(imprimirDescricao=="1"){
                                        descValor = descricao09 + ": " + item.campo11
                                    }else if(descricao09.toLowerCase().includes('-desc-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        descValor = fonteSplit[2] + ": " + item.campo11
                                    } else {
                                        descValor = item.campo11
                                    }
                                    
                                    zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + descValor + "^FS" 	
                                }
                            }	
                    
                            //Linha 10
                            if(descricao10!='' && posicaox10!='') {
                                c   = listCampos.findIndex(k => k.valor==descricao10)
                                if(descricao10.toLowerCase().includes('-hidden-')){
                                }else if(descricao10.toLowerCase().includes('codebar')) {
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    if(descricao10.toLowerCase().includes('-code-39-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^B3N,N" + "," + fonteSplit[1] + ",N,N^FD" + item.campo12 + "^FS"
                                    }else if(descricao10.toLowerCase().includes('-varejo-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +"^BEN," + fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + "^FD" + item.campo12 + "^FS" 
                                    }else if(descricao10.toLowerCase().includes('-gs1-128-')){
                                        //zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCN,,N,N^FD>;>${item.campo12}^FS`
                                        zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCI,,N,N^FH\\^FD>;>8${item.campo12}^FS`
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^BCN,"+ fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + ",N,N,N^FD" + item.campo12 + "^FS" 
                                    }
                                }else if(descricao10.toLowerCase().includes('-variavel')){
                                    let fonteSplit = listCampos[c].fonte
                                    let customized = fonteSplit.replace('${}',item.campo12)

                                    zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + fonteSplit[0] + customized + "^FS"

                                }else if(descricao10.toLowerCase().includes('qrcode')){
                                    //aqui Gabi
                                    if(descricao10.toLowerCase().includes('-custom-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        let customized = fonteSplit[1].replace('${}',item.campo12)
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + fonteSplit[0] + ",Q,7^FDMAN" + customized + "^FS"
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + listCampos[c].fonte + ",Q,7^FDMAN" + item.campo12 + "^FS"
                                    }
                                }else if(descricao10.toLowerCase().includes('-logo-')){
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^XGR:logo_10.GRF,1,1^FS`
                                }else if(descricao10.toLowerCase().includes('-fixed-')){
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^A0N,${fonteSplit[0]},${fonteSplit[1]}^FD${fonteSplit[2]}^FS`
                                }else{
                                    if(listCampos[c].coluna=="epc") {
                                        descValor = item.epc
                                        
                                    } else if(listCampos[c].coluna=="count") {
                                        descValor = item.count
                        
                                    } else if(imprimirDescricao=="1"){
                                        descValor = descricao10 + ": " + item.campo12
                                    }else if(descricao10.toLowerCase().includes('-desc-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        descValor = fonteSplit[2] + ": " + item.campo12
                                    } else {
                                        descValor = item.campo12
                                    }
                                    
                                    zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + descValor + "^FS" 	
                                }
                            }

                            //Linha 11
                            if(descricao11!='' && posicaox11!='') {
                                c   = listCampos.findIndex(k => k.valor==descricao11)
                                if(descricao11.toLowerCase().includes('-hidden-')){
                                }else if(descricao11.toLowerCase().includes('codebar')) {
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    if(descricao11.toLowerCase().includes('-code-39-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^B3N,N" + "," + fonteSplit[1] + ",N,N^FD" + item.campo13 + "^FS"
                                    }else if(descricao11.toLowerCase().includes('-varejo-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +"^BEN," + fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + "^FD" + item.campo13 + "^FS" 
                                    }else if(descricao11.toLowerCase().includes('-gs1-128-')){
                                        //zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCN,,N,N^FD>;>${item.campo13}^FS`
                                        zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCI,,N,N^FH\\^FD>;>8${item.campo13}^FS`
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^BCN,"+ fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + ",N,N,N^FD" + item.campo13 + "^FS" 
                                    }
                                }else if(descricao11.toLowerCase().includes('-variavel')){
                                    let fonteSplit = listCampos[c].fonte
                                    let customized = fonteSplit.replace('${}',item.campo13)

                                    zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + fonteSplit[0] + customized + "^FS"
                                    
                                }else if(descricao11.toLowerCase().includes('qrcode')){
                                    //aqui Gabi
                                    if(descricao11.toLowerCase().includes('-custom-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        let customized = fonteSplit[1].replace('${}',item.campo13)
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + fonteSplit[0] + ",Q,7^FDMAN" + customized + "^FS"
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + listCampos[c].fonte + ",Q,7^FDMAN" + item.campo13 + "^FS"
                                    }
                                }else if(descricao11.toLowerCase().includes('-logo-')){
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^XGR:logo_11.GRF,1,1^FS`
                                }else if(descricao11.toLowerCase().includes('-fixed-')){
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^A0N,${fonteSplit[0]},${fonteSplit[1]}^FD${fonteSplit[2]}^FS`
                                }else{
                                    if(listCampos[c].coluna=="epc") {
                                        descValor = item.epc
                                        
                                    } else if(listCampos[c].coluna=="count") {
                                        descValor = item.count
                        
                                    } else if(imprimirDescricao=="1"){
                                        descValor = descricao11 + ": " + item.campo13
                                    }else if(descricao11.toLowerCase().includes('-desc-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        descValor = fonteSplit[2] + ": " + item.campo13
                                    } else {
                                        descValor = item.campo13
                                    }
                                    
                                    zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + descValor + "^FS" 	
                                }
                            }

                            //Linha 12
                            if(descricao12!='' && posicaox11!='') {
                                c   = listCampos.findIndex(k => k.valor==descricao12)
                                if(descricao12.toLowerCase().includes('-hidden-')){
                                }else if(descricao12.toLowerCase().includes('codebar')) {
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    if(descricao12.toLowerCase().includes('-code-39-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^B3N,N" + "," + fonteSplit[1] + ",N,N^FD" + item.campo14 + "^FS"
                                    }else if(descricao12.toLowerCase().includes('-varejo-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +"^BEN," + fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + "^FD" + item.campo14 + "^FS" 
                                    }else if(descricao12.toLowerCase().includes('-gs1-128-')){
                                        //zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCN,,N,N^FD>;>${item.campo14}^FS`
                                        zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCI,,N,N^FH\\^FD>;>8${item.campo14}^FS`
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^BCN,"+ fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + ",N,N,N^FD" + item.campo14 + "^FS" 
                                    }
                                }else if(descricao12.toLowerCase().includes('-variavel')){
                                    let fonteSplit = listCampos[c].fonte
                                    let customized = fonteSplit.replace('${}',item.campo14)

                                    zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + fonteSplit[0] + customized + "^FS"
                                
                                }else if(descricao12.toLowerCase().includes('qrcode')){
                                    //aqui Gabi
                                    if(descricao12.toLowerCase().includes('-custom-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        let customized = fonteSplit[1].replace('${}',item.campo14)
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + fonteSplit[0] + ",Q,7^FDMAN" + customized + "^FS"
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + listCampos[c].fonte + ",Q,7^FDMAN" + item.campo14 + "^FS"
                                    }
                                }else if(descricao12.toLowerCase().includes('-logo-')){
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^XGR:logo_12.GRF,1,1^FS`
                                }else if(descricao12.toLowerCase().includes('-fixed-')){
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^A0N,${fonteSplit[0]},${fonteSplit[1]}^FD${fonteSplit[2]}^FS`
                                }else{
                                    if(listCampos[c].coluna=="epc") {
                                        descValor = item.epc
                                        
                                    } else if(listCampos[c].coluna=="count") {
                                        descValor = item.count
                        
                                    } else if(imprimirDescricao=="1"){
                                        descValor = descricao12 + ": " + item.campo14
                                    }else if(descricao12.toLowerCase().includes('-desc-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        descValor = fonteSplit[2] + ": " + item.campo14
                                    } else {
                                        descValor = item.campo14
                                    }
                                    
                                    zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + descValor + "^FS" 	
                                }
                            }

                            //Linha 13
                            if(descricao13!='' && posicaox11!='') {
                                c   = listCampos.findIndex(k => k.valor==descricao13)
                                if(descricao13.toLowerCase().includes('-hidden-')){
                                }else if(descricao13.toLowerCase().includes('codebar')) {
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    if(descricao13.toLowerCase().includes('-code-39-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^B3N,N" + "," + fonteSplit[1] + ",N,N^FD" + item.campo15 + "^FS"
                                    }else if(descricao13.toLowerCase().includes('-varejo-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +"^BEN," + fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + "^FD" + item.campo15 + "^FS" 
                                    }else if(descricao13.toLowerCase().includes('-gs1-128-')){
                                        //zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCN,,N,N^FD>;>${item.campo15}^FS`
                                        zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCI,,N,N^FH\\^FD>;>8${item.campo15}^FS`
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^BCN,"+ fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + ",N,N,N^FD" + item.campo15 + "^FS" 
                                    }
                                }else if(descricao13.toLowerCase().includes('-variavel')){
                                    let fonteSplit = listCampos[c].fonte
                                    let customized = fonteSplit.replace('${}',item.campo15)

                                    zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + fonteSplit[0] + customized + "^FS"
                                
                                }else if(descricao13.toLowerCase().includes('qrcode')){
                                    //aqui Gabi
                                    if(descricao13.toLowerCase().includes('-custom-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        let customized = fonteSplit[1].replace('${}',item.campo15)
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + fonteSplit[0] + ",Q,7^FDMAN" + customized + "^FS"
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + listCampos[c].fonte + ",Q,7^FDMAN" + item.campo15 + "^FS"
                                    }
                                }else if(descricao13.toLowerCase().includes('-logo-')){
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^XGR:logo_13.GRF,1,1^FS`
                                }else if(descricao13.toLowerCase().includes('-fixed-')){
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^A0N,${fonteSplit[0]},${fonteSplit[1]}^FD${fonteSplit[2]}^FS`
                                }else{
                                    if(listCampos[c].coluna=="epc") {
                                        descValor = item.epc
                                        
                                    } else if(listCampos[c].coluna=="count") {
                                        descValor = item.count
                        
                                    } else if(imprimirDescricao=="1"){
                                        descValor = descricao13 + ": " + item.campo15
                                    }else if(descricao13.toLowerCase().includes('-desc-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        descValor = fonteSplit[2] + ": " + item.campo15
                                    } else {
                                        descValor = item.campo15
                                    }
                                    
                                    zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + descValor + "^FS" 	
                                }
                            }

                            //Linha 14
                            if(descricao14!='' && posicaox11!='') {
                                c   = listCampos.findIndex(k => k.valor==descricao14)
                                if(descricao14.toLowerCase().includes('-hidden-')){
                                }else if(descricao14.toLowerCase().includes('codebar')) {
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    if(descricao14.toLowerCase().includes('-code-39-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^B3N,N" + "," + fonteSplit[1] + ",N,N^FD" + item.campo16 + "^FS"
                                    }else if(descricao14.toLowerCase().includes('-varejo-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +"^BEN," + fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + "^FD" + item.campo16 + "^FS" 
                                    }else if(descricao14.toLowerCase().includes('-gs1-128-')){
                                        //zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCN,,N,N^FD>;>${item.campo16}^FS`
                                        zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCI,,N,N^FH\\^FD>;>8${item.campo16}^FS`
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^BCN,"+ fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + ",N,N,N^FD" + item.campo16 + "^FS" 
                                    }
                                }else if(descricao14.toLowerCase().includes('-variavel')){
                                    let fonteSplit = listCampos[c].fonte
                                    let customized = fonteSplit.replace('${}',item.campo16)

                                    zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + fonteSplit[0] + customized + "^FS"
                                
                                }else if(descricao14.toLowerCase().includes('qrcode')){
                                    //aqui Gabi
                                    if(descricao14.toLowerCase().includes('-custom-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        let customized = fonteSplit[1].replace('${}',item.campo16)
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + fonteSplit[0] + ",Q,7^FDMAN" + customized + "^FS"
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + listCampos[c].fonte + ",Q,7^FDMAN" + item.campo16 + "^FS"
                                    }
                                }else if(descricao14.toLowerCase().includes('-logo-')){
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^XGR:logo_14.GRF,1,1^FS`
                                }else if(descricao14.toLowerCase().includes('-fixed-')){
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^A0N,${fonteSplit[0]},${fonteSplit[1]}^FD${fonteSplit[2]}^FS`
                                }else{
                                    if(listCampos[c].coluna=="epc") {
                                        descValor = item.epc
                                        
                                    } else if(listCampos[c].coluna=="count") {
                                        descValor = item.count
                        
                                    } else if(imprimirDescricao=="1"){
                                        descValor = descricao14 + ": " + item.campo16
                                    }else if(descricao14.toLowerCase().includes('-desc-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        descValor = fonteSplit[2] + ": " + item.campo16
                                    } else {
                                        descValor = item.campo16
                                    }
                                    
                                    zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + descValor + "^FS" 	
                                }
                            }

                            //Linha 15
                            if(descricao15!='' && posicaox11!='') {
                                c   = listCampos.findIndex(k => k.valor==descricao15)
                                if(descricao15.toLowerCase().includes('-hidden-')){
                                }else if(descricao15.toLowerCase().includes('codebar')) {
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    if(descricao15.toLowerCase().includes('-code-39-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^B3N,N" + "," + fonteSplit[1] + ",N,N^FD" + item.campo17 + "^FS"
                                    }else if(descricao15.toLowerCase().includes('-varejo-')) {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +"^BEN," + fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + "^FD" + item.campo17 + "^FS" 
                                    }else if(descricao15.toLowerCase().includes('-gs1-128-')){
                                        //zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCN,,N,N^FD>;>${item.campo17}^FS`
                                        zpl += `^BY${fonteSplit[0]},3,${fonteSplit[1]}^FT${listCampos[c].px},${listCampos[c].py}^BCI,,N,N^FH\\^FD>;>8${item.campo17}^FS`
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BY" + fonteSplit[0] +",3.0^BCN,"+ fonteSplit[1] + "," + (fonteSplit[2] ? fonteSplit[2] : 'N') + ",N,N,N^FD" + item.campo17 + "^FS" 
                                    }
                                }else if(descricao15.toLowerCase().includes('-variavel')){
                                    let fonteSplit = listCampos[c].fonte
                                    let customized = fonteSplit.replace('${}',item.campo17)

                                    zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + fonteSplit[0] + customized + "^FS"
                                
                                }else if(descricao15.toLowerCase().includes('qrcode')){
                                    if(descricao15.toLowerCase().includes('-custom-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        let customized = fonteSplit[1].replace('${}',item.campo17)
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + fonteSplit[0] + ",Q,7^FDMAN" + customized + "^FS"
                                    }else {
                                        zpl += "^FO" + listCampos[c].px + "," + listCampos[c].py + "^BQN,2," + listCampos[c].fonte + ",Q,7^FDMAN" + item.campo17 + "^FS"
                                    }
                                }else if(descricao15.toLowerCase().includes('-logo-')){
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^XGR:logo_15.GRF,1,1^FS`
                                }else if(descricao15.toLowerCase().includes('-fixed-')){
                                    let fonteSplit = listCampos[c].fonte.split(',')
                                    zpl += `^FO${listCampos[c].px},${listCampos[c].py}^A0N,${fonteSplit[0]},${fonteSplit[1]}^FD${fonteSplit[2]}^FS`
                                }else{
                                    if(listCampos[c].coluna=="epc") {
                                        descValor = item.epc
                                        
                                    } else if(listCampos[c].coluna=="count") {
                                        descValor = item.count
                        
                                    } else if(imprimirDescricao=="1"){
                                        descValor = descricao15 + ": " + item.campo17
                                    }else if(descricao15.toLowerCase().includes('-desc-')) {
                                        let fonteSplit = listCampos[c].fonte.split(',')
                                        descValor = fonteSplit[2] + ": " + item.campo17
                                    } else {
                                        descValor = item.campo17
                                    }
                                    
                                    zpl += "^FO" + listCampos[c].px +","+ listCampos[c].py + "^A0N," + listCampos[c].fonte + "^FD" + descValor + "^FS" 	
                                }
                            }
    
                            if(false) {
                                zpl += `^PP`
                            }
                    
                            zpl += "^XZ" + '\n'  
                        }
                        if(index+1==importList.length){
                            if(tipoImpressao == 'print-order') {
                                let dt  = new Date();
                                let dtf = ("0" + dt.getDate()).slice(-2) + ("0" + (dt.getMonth() + 1)).slice(-2) +  dt.getFullYear()
                                dtf += ("0" + dt.getHours()).slice(-2) + ("0" +dt.getMinutes()).slice(-2)
                                let dtfv = ("0" + dt.getDate()).slice(-2) + '/' + ("0" + (dt.getMonth() + 1)).slice(-2) + '/' + dt.getFullYear()
                                dtfv += ' ' + ("0" + dt.getHours()).slice(-2) + ':' +  ("0" +dt.getMinutes()).slice(-2)
                                zpl += ''
                                let interno = ('000000').substring(0, 6 - nLoteInterno.length) + nLoteInterno.substring(nLoteInterno.length - 6, nLoteInterno.length)
                                let cliente = ('000000').substring(0, 6 - nLoteCorreios.length) + nLoteCorreios.substring(nLoteCorreios.length - 6, nLoteCorreios.length)
                                
                                zpl += `^XA`
                                zpl += `^CI28`
                                zpl += `^RFW,H^FD${dtf}${cliente}${interno}^FS`
                                zpl += `^FO125,60^A0N,40,40^FDData: ${dtfv}^FS`
                                zpl += `^FO125,95^A0N,40,40^FDLote Interno: ${interno}^FS`
                                zpl += `^FO125,130^A0N,40,40^FDLote Correios: ${cliente}^FS`
                                zpl += `^FO125,165^A0N,40,40^FDInicial: ${epcToSscc(importList[0].epc)}^FS`
                                zpl += `^FO125,200^A0N,40,40^FDFinal: ${epcToSscc(importList[importList.length - 1].epc)}^FS`
                                zpl += `^PP`
                                zpl += `^XZ\n`
                            }
        
                            let reg = []
                            reg.push({
                                "zpl": zpl,
                                "logo": logoGrf,
                                "endereco_impressora": selectedPrinter,
                                "usuario_runas": usuarioRunas
                            })
                
                            if(!someProblem) {
                                await api.post(`/zpl/${id}`, reg, options).then(
                                    async response => {
                                        if(!printOrderInfo) {

                                            if(arquivoImportacao=='cat') {
                                                await api.post(`/itensDef/${sessionStorage.getItem('idConta')}`,sendUpdateCat).then(
                                                    response => (
                                                        console.log('categorias atualizadas')
                                                    )
                                                )
                                            }

                                            await Swal.fire({
                                                title: "Sucesso !",
                                                text: 'Os arquivos foram gerados para impressão',
                                                icon: 'success',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '2500'
                                            })
                                            await setTimeout(() => {window.open(`${process.env.REACT_APP_API_URL}/print${id}.bat`)}, 300);
                                            await setTimeout(() => {window.open(`${process.env.REACT_APP_API_URL}/logo${id}.grf`)}, 600)
                                            await setTimeout(() => {window.open(`${process.env.REACT_APP_API_URL}/tags${id}.csv`)}, 900)
                                            
                                            if(gtinList.length) {
                                                await updateGtinList(gtinList)
                                            }

                                            if(arquivoImportacao=='cat') {
                                                setImportList([])
                                                setSendUpdateCat([])
                                            }
                                        }else{

                                            if(arquivoImportacao=='cat') {
                                                await api.post(`/itensDef/${sessionStorage.getItem('idConta')}`,sendUpdateCat).then(
                                                    response => (
                                                        console.log('categorias atualizadas')
                                                    )
                                                )
                                            }

                                            await Swal.fire({
                                                title: "Sucesso !",
                                                html: `Ordem de impressão criado com sucesso</br>${epctofilter.length} EPCS foram para ordem`,
                                                icon: 'success',
                                                showConfirmButton: false,
                                                timerProgressBar: true,
                                                timer: '2500'
                                            })
                                            setJaExiste(true)
                                        }
                                        setOrderLoading(false)
                                        resolve('ok')
                                    }, 
                                    response => {
                                        Swal.fire({
                                            title: "Ops... !",
                                            text: 'Algo deu errado, por favor tente novamente mais tarde',
                                            icon: 'error',
                                            showConfirmButton: false,
                                            timerProgressBar: true,
                                            timer: '2500'
                                        })
                                        reject('erro')
                                    }
                                )
                            }
                        }
                    })
                }else{
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Não há itens a serem gerados',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                    setOrderLoading(false)
                    reject('erro')
                }
            }else {
                Swal.fire({
                    title: "Ops... !",
                    text: 'Informe um endereço de impressora',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
                setOrderLoading(false)
                reject('erro')
            }
        })
    }

    function epcToSscc(epc) {
        //epcHeader - filter - partition - prefix - serial - reserved
        const formatLength = (qtd, value) => {
            let clearValue = ('0').repeat(qtd)
            return `${clearValue.substring(0, clearValue.length - value.length)}${value}`
        }
    
        let binary = ''
        for(let i=0;i<epc.length; i++){
            let val = parseInt(epc[i], 16).toString(2)
            binary += ('0000').substring(0, 4 - val.length) + val
        }
    
        let epcHeader = binary.slice(0,8)
        
        let filter = binary.slice(8,11)
        
        let partition = binary.slice(11,14)
        
    
        let reserved = ('0').repeat(24)
        let prefixSerial = binary.slice(epcHeader.length + filter.length + partition.length)
        prefixSerial = prefixSerial.slice(0, prefixSerial.length - reserved.length)
        
        //determina quantos digitos binários são prefixo e quantos são serial
        let qtdPrefixDec = 12 - parseInt(partition, 2)
        let qtdPrefixBin = Math.ceil((40*qtdPrefixDec)/12)
        //let qtdSerialBin = 58 - qtdPrefixBin
    
        let prefix = prefixSerial.slice(0, qtdPrefixBin)
        prefix = parseInt(prefix, 2).toString()
        prefix = formatLength(qtdPrefixDec, prefix)
        
        let serial = prefixSerial.slice(qtdPrefixBin)
        serial = parseInt(serial, 2).toString()
        serial = formatLength((17 - qtdPrefixDec), serial)
    
        let finalDecimal = `${prefix}${serial}`
        finalDecimal = finalDecimal[qtdPrefixDec] + finalDecimal.slice(0,qtdPrefixDec) + finalDecimal.slice(qtdPrefixDec + 1)
        let checkDigit = 0
        for(let i=0; i<finalDecimal.length; i++) {
            if(i%2 == 0) {
                checkDigit += parseInt(finalDecimal[i]) * 3
            }else{
                checkDigit += parseInt(finalDecimal[i])
            }
        }
        if(checkDigit%10 == 0) {
            checkDigit = 0
        }else{
            checkDigit = 10 - checkDigit%10
        }
        finalDecimal = '(00)' + finalDecimal + checkDigit.toString()
        console.log(finalDecimal)
        return finalDecimal
    }

    function ssccToEpc(sscc) {
        let listaGcp = gpcList.GCPPrefixFormatList.entry
        //epcHeader - filter - partition - prefix - serial - reserved
        const formatLength = (qtd, value) => {
            let clearValue = ('0').repeat(qtd)
            return `${clearValue.substring(0, clearValue.length - value.length)}${value}`
        }
    
        const stringToByte = (group, value) => {
            let byteSeq = ''
            for(let i=0;i<value.length; i++){
                byteSeq += formatLength(group, parseInt(value[i]).toString(2))
            }
            return byteSeq
        }
    
        //primeiro digito do sscc parte do serial
        //epcHeader - filter - partition -> 8-3-3 14 -> em conjuntos de 16 bits -> 4 primeiro digitos
    
        //remove "(??)" do inicio caso tenha
        let value = sscc.replace(/\([0-9][0-9]\)/,'')
    
        let findGcp = listaGcp.find(item => (value.slice(1, 11).indexOf(item.prefix)==0))
        
        let epcHeader = formatLength(8, Number(49).toString(2))
        
        let filter = formatLength(3, Number(6).toString(2))
        
        let partition = formatLength(3, Number(12 - findGcp.gcpLength).toString(2))
    
        //Determinar length de prefix e serial
        let qtdPrefix = Math.ceil((40*findGcp.gcpLength)/12)
        let qtdSerial = 58 - qtdPrefix
    
        let prefix = formatLength(qtdPrefix, Number(value.slice(1,1+findGcp.gcpLength)).toString(2))
    
        //bin
        let serial = formatLength(qtdSerial, Number(`${value.slice(0,1)}${value.slice(1+findGcp.gcpLength, value.length-1)}`).toString(2))
        
        let reserved = ('0').repeat(24)
        
        let finalBin = `${epcHeader}${filter}${partition}${prefix}${serial}${reserved}`
        let finalHex = ''
        for(let i=0;i<finalBin.length; i+=4){
            finalHex += parseInt(finalBin.slice(i, i+4), 2).toString(16)
    
        }
        finalHex = finalHex.toUpperCase()
        return finalHex
    }
    
    function ssccSerialized(prefixo,serialImp) {
        //epcHeader - filter - partition - prefix - serial - reserved
        const formatLength = (qtd, value) => {
            let clearValue = ('0').repeat(qtd)
            return `${clearValue.substring(0, clearValue.length - value.length)}${value}`
        }
    
        //EPCHEADER FIXO
        let epcHeader = formatLength(8, Number(49).toString(2))
        
        let filter = formatLength(3, Number(6).toString(2))
        
        let partition = formatLength(3, Number(12 - prefixo.length).toString(2))
    
        //PREFIXO+SERIAL BINÁRIO 58 CARACTERES (40~20)+(18~38) 
        //PREFIXO+SERIAL HEXADECIMAL 17 CARACTERES (12~6)+(5~11) 
        
        //Determinar length de prefix e serial
        let qtdPrefix = Math.ceil((40*prefixo.length)/12)
        let qtdSerial = 58 - qtdPrefix
    
        //bin
        let prefix = formatLength(qtdPrefix, Number(prefixo).toString(2))
        
        //bin
        let serial = formatLength(qtdSerial, Number(serialImp).toString(2))
        
        //RESERVADO FIXO
        let reserved = ('0').repeat(24)
        
        let finalBin = `${epcHeader}${filter}${partition}${prefix}${serial}${reserved}`
        
        let finalHex = ''
        for(let i=0;i<finalBin.length; i+=4){
            finalHex += parseInt(finalBin.slice(i, i+4), 2).toString(16)
        }
        finalHex = finalHex.toUpperCase()
        
        console.log(finalHex)
        return finalHex
    }

    function gtinToEpc(gtin, serial) {
        function sizeFormat(value, size) {
            let baseClear = ('0').repeat(size)
            return baseClear.substring(0, baseClear.length - value.length) + value
        }
        let epcHeader = '00110000'
        let filter = sizeFormat(Number(1).toString(2), 3)
        let partition = sizeFormat(Number(5).toString(2), 3)
        let value = sizeFormat(gtin, 14)
        let prefix = sizeFormat(Number(value.slice(1,8)).toString(2), 24)
        let itemReference = value.replace(value.slice(1,8), '')
        itemReference = itemReference.slice(0, itemReference.length - 1)
        itemReference = sizeFormat(Number(itemReference).toString(2), 20)
        let serialBin = sizeFormat(Number(serial).toString(2), 38)
        let finalBin = `${epcHeader}${filter}${partition}${prefix}${itemReference}${serialBin}`
        let finalHex = ''
        for(let i=0;i<finalBin.length; i+=4){
            finalHex += parseInt(finalBin.slice(i, i+4), 2).toString(16)
    
        }
        finalHex = finalHex.toUpperCase()
        return finalHex
    }

    async function verififyBase() {
        let inBase = {
            tags_id: [],
            tags: [],
            categories_id: [],
            categories_desc: [],
            categories: [],
            nivel1: [],
            nivel2: [],
            nivel3: [],
            nivel4: [],
            parceiro: []
        }

        setImportLoadingReg(true)
        
        await api.get(`/item/${sessionStorage.getItem('idConta')}/*/*/*/*/*/*/*/*/*/*/*/*/*`).then(
            async response => {
                await response.data.map((item, index) => {
                    if(inBase.tags.indexOf(item.tag) < 0) {
                        inBase.tags.push(item.tag)
                        inBase.tags_id.push(item._id)
                    }
                })
            }
        )
        
        await api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/*`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.categories.push({
                        _id: item._id,
                        descricao: item.descricao
                    })
                    inBase.categories_id.push(item._id)
                    inBase.categories_desc.push(item.descricao)
                })
            }
        )
        await api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.nivel1.push({
                        _id: item._id,
                        descricao: item.descricao
                    })
                })
            }
        )
        await api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.nivel2.push({
                        _id: item._id,
                        descricao: item.descricao,
                        id_nivel_loc1: item.id_nivel_loc1
                    })
                })
            }
        )
        await api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.nivel3.push({
                        _id: item._id,
                        descricao: item.descricao,
                        id_nivel_loc2: item.id_nivel_loc2
                    })
                })
            }
        )
        await api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.nivel4.push({
                        _id: item._id,
                        descricao: item.descricao,
                        id_nivel_loc3: item.id_nivel_loc3
                    })
                })
            }
        )

        await api.get(`/parceiro/${sessionStorage.getItem('idConta')}/*/*/*/1`).then(
            async response => {
                await response.data.map((item) => {
                    inBase.parceiro.push({
                        _id: item._id,
                        nome: item.nome
                    })
                })
            }
        )
        
        setInBaseProps(inBase)
        setImportLoadingReg(false)
    }

    function importarItens(importType) {
        Swal.fire({
            title: "Atenção !",
            text: "Deseja realmente importar estes itens ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            async result => {
                if(result.isConfirmed) {
                    let baseprops = inBaseProps
                    let listTemp = []
                    let catTemp = []
                    let niv1Temp = []
                    let niv2Temp = []
                    let niv3Temp = []
                    let niv4Temp = []
                    let c
                    if(importType == 'item' || importType == 'pedido'){
                        await importList.map((item, index) => {
                            if(!inBaseProps.tags.includes(item.epc)){
                                listTemp.push({
                                    "_id": getID(),
                                    "id_item": "0",
                                    "id_conta": sessionStorage.getItem('idConta'),
                                    "id_externo": "",
                                    "ativo": "1",
                                    "tag": item.epc,
                                    "id_categoria": "",
                                    "categoria": "",
                                    "ean": "",
                                    "inf_compl_1": "",
                                    "inf_compl_2": "",
                                    "inf_compl_3": "",
                                    "inf_compl_4": "",
                                    "inf_compl_5": "",
                                    "id_parceiro": "",
                                    "parceiro": "",
                                    "id_nivel_loc_1": "",
                                    "id_nivel_loc_2": "",
                                    "id_nivel_loc_3": "",
                                    "id_nivel_loc_4": "",
                                    "nivel_loc_1": "",
                                    "nivel_loc_2": "",
                                    "nivel_loc_3": "",
                                    "nivel_loc_4": "",
                                    "foto": "",
                                    "observacao": "",
                                })
                                
                            
                                if(descricao01!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao01)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo3				
                                }
                                
                                if(descricao02!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao02)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo4				
                                }
                                
                                if(descricao03!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao03)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo5				
                                }
                                
                                if(descricao04!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao04)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo6				
                                }
                                
                                if(descricao05!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao05)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo7				
                                }
                                
                                if(descricao06!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao06)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo8				
                                }
                                
                                if(descricao07!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao07)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo9				
                                }
                                
                                if(descricao08!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao08)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo10				
                                }
                                
                                if(descricao09!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao09)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo11				
                                }
                                
                                if(descricao10!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao10)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo12				
                                }
    
                                let have
    
                                if(listTemp[listTemp.length-1].categoria != ''){
                                    have = false
                                    baseprops.categories.map((item) => {
                                        if(listTemp[listTemp.length-1].categoria == item.descricao) {
                                            have = true
                                            listTemp[listTemp.length-1].id_categoria = item._id
                                        }
                                    })
                                    if(!have) {
                                        let catId = getID()
                                        listTemp[listTemp.length-1].id_categoria = catId
    
                                        baseprops.categories.push({
                                            _id: catId,
                                            descricao: listTemp[listTemp.length-1].categoria
                                        })
                                        catTemp.push({
                                            _id: catId,
                                            id_conta: sessionStorage.getItem('idConta'),
                                            descricao: listTemp[listTemp.length-1].categoria
                                        })
                                    }
                                }
    
                                if(listTemp[listTemp.length-1].nivel_loc_1 != ''){
                                    have = false
                                    baseprops.nivel1.map((item) => {
                                        if(listTemp[listTemp.length-1].nivel_loc_1 == item.descricao) {
                                            have = true
                                            listTemp[listTemp.length-1].id_nivel_loc_1 = item._id
                                        }
                                    })
    
                                    if(!have) {
                                        let nivel1Id = getID()
                                        listTemp[listTemp.length-1].id_nivel_loc_1 = nivel1Id
    
                                        baseprops.nivel1.push({
                                            _id: nivel1Id,
                                            descricao: listTemp[listTemp.length-1].nivel_loc_1
                                        })
                                        niv1Temp.push({
                                            _id: nivel1Id,
                                            id_conta: sessionStorage.getItem('idConta'),
                                            descricao: listTemp[listTemp.length-1].nivel_loc_1
                                        })
                                    }
    
                                    if(listTemp[listTemp.length-1].nivel_loc_2 != '') {
                                        have = false
                                        baseprops.nivel2.map((item) => {
                                            if(
                                                listTemp[listTemp.length-1].nivel_loc_2 == item.descricao
                                                && listTemp[listTemp.length-1].id_nivel_loc_1 == item.id_nivel_loc1
                                            ) {
                                                have = true
                                                listTemp[listTemp.length-1].id_nivel_loc_2 = item._id
                                            }
                                        })
    
                                        if(!have) {
                                            let nivel2Id = getID()
                                            listTemp[listTemp.length-1].id_nivel_loc_2 = nivel2Id
    
                                            baseprops.nivel2.push({
                                                _id: nivel2Id,
                                                descricao: listTemp[listTemp.length-1].nivel_loc_2,
                                                id_nivel_loc1: listTemp[listTemp.length-1].id_nivel_loc_1
                                            })
                                            niv2Temp.push({
                                                _id: nivel2Id,
                                                id_conta: sessionStorage.getItem('idConta'),
                                                descricao: listTemp[listTemp.length-1].nivel_loc_2,
                                                id_nivel_loc1: listTemp[listTemp.length-1].id_nivel_loc_1
                                            })
                                        }
    
                                        if(listTemp[listTemp.length-1].nivel_loc_3 != '') {
                                            have = false
                                            baseprops.nivel3.map((item) => {
                                                if(
                                                    listTemp[listTemp.length-1].nivel_loc_3 == item.descricao
                                                    && listTemp[listTemp.length-1].id_nivel_loc_2 == item.id_nivel_loc2
                                                ) {
                                                    have = true
                                                    listTemp[listTemp.length-1].id_nivel_loc_3 = item._id
                                                }
                                            })
                    
                                            if(!have) {
                                                let nivel3Id = getID()
                                                listTemp[listTemp.length-1].id_nivel_loc_3 = nivel3Id
                    
                                                baseprops.nivel3.push({
                                                    _id: nivel3Id,
                                                    descricao: listTemp[listTemp.length-1].nivel_loc_3,
                                                    id_nivel_loc2: listTemp[listTemp.length-1].id_nivel_loc_2
                                                })
                                                niv3Temp.push({
                                                    _id: nivel3Id,
                                                    id_conta: sessionStorage.getItem('idConta'),
                                                    descricao: listTemp[listTemp.length-1].nivel_loc_3,
                                                    id_nivel_loc2: listTemp[listTemp.length-1].id_nivel_loc_2
                                                })
                                            }
    
                                            if(listTemp[listTemp.length-1].nivel_loc_4 != '') {
                                                have = false
                                                baseprops.nivel4.map((item) => {
                                                    if(
                                                        listTemp[listTemp.length-1].nivel_loc_4 == item.descricao
                                                        && listTemp[listTemp.length-1].id_nivel_loc_3 == item.id_nivel_loc3
                                                    ) {
                                                        have = true
                                                        listTemp[listTemp.length-1].id_nivel_loc_4 = item._id
                                                    }
                                                })
                        
                                                if(!have) {
                                                    let nivel4Id = getID()
                                                    listTemp[listTemp.length-1].id_nivel_loc_4 = nivel4Id
                        
                                                    baseprops.nivel4.push({
                                                        _id: nivel4Id,
                                                        descricao: listTemp[listTemp.length-1].nivel_loc_4,
                                                        id_nivel_loc3: listTemp[listTemp.length-1].id_nivel_loc_3
                                                    })
                                                    niv4Temp.push({
                                                        _id: nivel4Id,
                                                        id_conta: sessionStorage.getItem('idConta'),
                                                        descricao: listTemp[listTemp.length-1].nivel_loc_4,
                                                        id_nivel_loc3: listTemp[listTemp.length-1].id_nivel_loc_3
                                                    })
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        })
                    }else if(importType == 'categoria'){
                        await importList.map((item, index) => {
                            if(!inBaseProps.tags.includes(item.epc)){
                                listTemp.push({
                                    "_id": getID(),
                                    "id_item": "0",
                                    "id_conta": sessionStorage.getItem('idConta'),
                                    "id_externo": "",
                                    "ativo": "1",
                                    "tag": item.epc,
                                    "id_categoria": "",
                                    "categoria": "",
                                    "ean": "",
                                    "inf_compl_1": "",
                                    "inf_compl_2": "",
                                    "inf_compl_3": "",
                                    "inf_compl_4": "",
                                    "inf_compl_5": "",
                                    "id_parceiro": "",
                                    "parceiro": "",
                                    "id_nivel_loc_1": "",
                                    "id_nivel_loc_2": "",
                                    "id_nivel_loc_3": "",
                                    "id_nivel_loc_4": "",
                                    "nivel_loc_1": "",
                                    "nivel_loc_2": "",
                                    "nivel_loc_3": "",
                                    "nivel_loc_4": "",
                                    "foto": "",
                                    "observacao": "",
                                })
                                
                            
                                if(descricao01!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao01)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo3				
                                }
                                
                                if(descricao02!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao02)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo4				
                                }
                                
                                if(descricao03!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao03)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo5				
                                }
                                
                                if(descricao04!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao04)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo6				
                                }
                                
                                if(descricao05!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao05)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo7				
                                }
                                
                                if(descricao06!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao06)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo8				
                                }
                                
                                if(descricao07!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao07)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo9				
                                }
                                
                                if(descricao08!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao08)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo10				
                                }
                                
                                if(descricao09!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao09)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo11				
                                }
                                
                                if(descricao10!='') {											
                                    c = listCampos.findIndex(k => k.valor==descricao10)
                                    listTemp[listTemp.length-1][listCampos[c].destino] = item.campo11				
                                }
    
                                let have
    
                                if(listTemp[listTemp.length-1].categoria != ''){
                                    have = false
                                    baseprops.categories.map((item) => {
                                        if(listTemp[listTemp.length-1].categoria == item.descricao) {
                                            have = true
                                            listTemp[listTemp.length-1].id_categoria = item._id
                                        }
                                    })
                                    if(!have) {
                                        let catId = getID()
                                        listTemp[listTemp.length-1].id_categoria = catId
    
                                        baseprops.categories.push({
                                            _id: catId,
                                            descricao: listTemp[listTemp.length-1].categoria
                                        })
                                        catTemp.push({
                                            _id: catId,
                                            id_conta: sessionStorage.getItem('idConta'),
                                            descricao: listTemp[listTemp.length-1].categoria,
                                            inf_compl_1: listTemp[listTemp.length-1].inf_compl_1,
                                            inf_compl_2: listTemp[listTemp.length-1].inf_compl_2,
                                            inf_compl_3: listTemp[listTemp.length-1].inf_compl_3,
                                            inf_compl_4: listTemp[listTemp.length-1].inf_compl_4,
                                            inf_compl_5: listTemp[listTemp.length-1].inf_compl_5,
                                        })
                                    }
                                }
    
                                if(listTemp[listTemp.length-1].nivel_loc_1 != ''){
                                    have = false
                                    baseprops.nivel1.map((item) => {
                                        if(listTemp[listTemp.length-1].nivel_loc_1 == item.descricao) {
                                            have = true
                                            listTemp[listTemp.length-1].id_nivel_loc_1 = item._id
                                        }
                                    })
    
                                    if(!have) {
                                        let nivel1Id = getID()
                                        listTemp[listTemp.length-1].id_nivel_loc_1 = nivel1Id
    
                                        baseprops.nivel1.push({
                                            _id: nivel1Id,
                                            descricao: listTemp[listTemp.length-1].nivel_loc_1
                                        })
                                        niv1Temp.push({
                                            _id: nivel1Id,
                                            id_conta: sessionStorage.getItem('idConta'),
                                            descricao: listTemp[listTemp.length-1].nivel_loc_1
                                        })
                                    }
    
                                    if(listTemp[listTemp.length-1].nivel_loc_2 != '') {
                                        have = false
                                        baseprops.nivel2.map((item) => {
                                            if(
                                                listTemp[listTemp.length-1].nivel_loc_2 == item.descricao
                                                && listTemp[listTemp.length-1].id_nivel_loc_1 == item.id_nivel_loc1
                                            ) {
                                                have = true
                                                listTemp[listTemp.length-1].id_nivel_loc_2 = item._id
                                            }
                                        })
    
                                        if(!have) {
                                            let nivel2Id = getID()
                                            listTemp[listTemp.length-1].id_nivel_loc_2 = nivel2Id
    
                                            baseprops.nivel2.push({
                                                _id: nivel2Id,
                                                descricao: listTemp[listTemp.length-1].nivel_loc_2,
                                                id_nivel_loc1: listTemp[listTemp.length-1].id_nivel_loc_1
                                            })
                                            niv2Temp.push({
                                                _id: nivel2Id,
                                                id_conta: sessionStorage.getItem('idConta'),
                                                descricao: listTemp[listTemp.length-1].nivel_loc_2,
                                                id_nivel_loc1: listTemp[listTemp.length-1].id_nivel_loc_1
                                            })
                                        }
    
                                        if(listTemp[listTemp.length-1].nivel_loc_3 != '') {
                                            have = false
                                            baseprops.nivel3.map((item) => {
                                                if(
                                                    listTemp[listTemp.length-1].nivel_loc_3 == item.descricao
                                                    && listTemp[listTemp.length-1].id_nivel_loc_2 == item.id_nivel_loc2
                                                ) {
                                                    have = true
                                                    listTemp[listTemp.length-1].id_nivel_loc_3 = item._id
                                                }
                                            })
                    
                                            if(!have) {
                                                let nivel3Id = getID()
                                                listTemp[listTemp.length-1].id_nivel_loc_3 = nivel3Id
                    
                                                baseprops.nivel3.push({
                                                    _id: nivel3Id,
                                                    descricao: listTemp[listTemp.length-1].nivel_loc_3,
                                                    id_nivel_loc2: listTemp[listTemp.length-1].id_nivel_loc_2
                                                })
                                                niv3Temp.push({
                                                    _id: nivel3Id,
                                                    id_conta: sessionStorage.getItem('idConta'),
                                                    descricao: listTemp[listTemp.length-1].nivel_loc_3,
                                                    id_nivel_loc2: listTemp[listTemp.length-1].id_nivel_loc_2
                                                })
                                            }
    
                                            if(listTemp[listTemp.length-1].nivel_loc_4 != '') {
                                                have = false
                                                baseprops.nivel4.map((item) => {
                                                    if(
                                                        listTemp[listTemp.length-1].nivel_loc_4 == item.descricao
                                                        && listTemp[listTemp.length-1].id_nivel_loc_3 == item.id_nivel_loc3
                                                    ) {
                                                        have = true
                                                        listTemp[listTemp.length-1].id_nivel_loc_4 = item._id
                                                    }
                                                })
                        
                                                if(!have) {
                                                    let nivel4Id = getID()
                                                    listTemp[listTemp.length-1].id_nivel_loc_4 = nivel4Id
                        
                                                    baseprops.nivel4.push({
                                                        _id: nivel4Id,
                                                        descricao: listTemp[listTemp.length-1].nivel_loc_4,
                                                        id_nivel_loc3: listTemp[listTemp.length-1].id_nivel_loc_3
                                                    })
                                                    niv4Temp.push({
                                                        _id: nivel4Id,
                                                        id_conta: sessionStorage.getItem('idConta'),
                                                        descricao: listTemp[listTemp.length-1].nivel_loc_4,
                                                        id_nivel_loc3: listTemp[listTemp.length-1].id_nivel_loc_3
                                                    })
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        })
                    }

                    if(arquivoImportacao=='xml') {
                        catTemp = listxml.map((item) => {
                            return {
                                _id:  item[0],
                                descricao: item[1],
                                id_conta: sessionStorage.getItem('idConta'),
                                ean: item[2],
                                inf_compl_1: item[6],
                                inf_compl_2: '',
                                inf_compl_3: '',
                                inf_compl_4: '',
                                inf_compl_5: '',
                                up_1: '',
                                up_2: '',
                                up_3: '',
                                up_4: '',
                                up_5: '',
                                observacao: '',
                                serializacao: ''
                            }
                        })
                    }
                    console.log(catTemp)
                    
                    setLoading(true)
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
                    let importJSON = []

                    if(importType == 'item'){
                        importJSON.push({
                            categoria: catTemp,
                            itens: listTemp,
                            nivel1: niv1Temp,
                            nivel2: niv2Temp,
                            nivel3: niv3Temp,
                            nivel4: niv4Temp
                        })
                    }else if(importType == 'categoria'){
                        importJSON.push({
                            categoria: catTemp
                        })
                    }

                    api.post(`/itensDef/${sessionStorage.getItem('idConta')}`, importJSON, option).then(
                        response => {
                            verififyBase()
                            Swal.fire({
                                title: "Sucesso !",
                                text: 'Itens cadastrados com sucesso',
                                icon: 'success',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                            setLoading(false)
                        },
                        response => {
                            Swal.fire({
                                title: "Ops... !",
                                text: 'Algo deu errado, por favor tente novamente mais tarde',
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                            setLoading(false)
                        }
                    )
                }
            }
        )
    }

    function updateCategoriesXML(){
        console.log(listxml)
        let importJSON = []

        if(arquivoImportacao=='xml') {
            let jsonUpdate = listxml.map((item) => {
                return {
                    _id: item[0],
                    descricao: item[1],
                    id_conta: sessionStorage.getItem('idConta'),
                    ean: item[2],
                    inf_compl_1: item[6],
                    inf_compl_2: '',
                    inf_compl_3: '',
                    inf_compl_4: '',
                    inf_compl_5: '',
                    up_1: '',
                    up_2: '',
                    up_3: '',
                    up_4: '',
                    up_5: '',
                    observacao: '',
                    serializacao: ''
                }
            })

            importJSON.push({
                categoria: jsonUpdate
            })
        }
        
        let option = {
            headers: { 'Content-Type': ['application/json'] }
        }
        

        api.post(`/itensDef/${sessionStorage.getItem('idConta')}`, importJSON, option).then(
            response => {
                verififyBase()
                console.log(importJSON)
                Swal.fire({
                    title: "Sucesso !",
                    text: 'Itens cadastrados com sucesso',
                    icon: 'success',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
                setLoading(false)
            },
            response => {
                Swal.fire({
                    title: "Ops... !",
                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
                setLoading(false)
            }
        )
    }

    function getID(){	
        return 'xxxxxxxx-yxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
            return v.toString(16)
        })
    }

    function getColor(tag) {
        if(jaExiste){
            return {
                backgroundColor: '#20B2AA',
                color: '#fff'
            }
        }else if(inBaseProps.tags.includes(tag)){
            return {
                backgroundColor: '#338333',
                color: '#fff'
            }
        }else{
            return {}
        }
    }

    function uniformEan(value) {
        let valor = value.length > 13 ? value.substring(value.length, (value.length - 13)) : value
        let cleanEan = ('0000000000000')
        cleanEan = cleanEan.substring(0, (cleanEan.length-valor.length)) + valor
        return cleanEan
    }

    function typeOfLoadItens() {
        switch (arquivoImportacao) {
            case 'csv':
                return(
                    <div className="filters">
                        <Col>
                            <input id='dropFile' style={{display: 'none'}} type='file' accept={fileTypes} onChange={e=> {
                                e.preventDefault()
                                if(e.target.files[0]) {
                                    chooseFileImport(e.target.files[0])
                                }
                            }}/>
                            <div
                                className="p-5 rounded d-flex justify-content-center align-items-center flex-column input-file"
                                style={{
                                    border:'3px dashed #d8e4f7'
                                }}
                                onDragOver={e=> {e.preventDefault()}}
                                onDrop={e=> {
                                    e.preventDefault()
                                    if(fileTypes.includes(e.dataTransfer.files[0].type))
                                    chooseFileImport(e.dataTransfer.files[0])
                                }}
                                onClick={e=>{
                                    let getFile = window.document.getElementById('dropFile')
                                    getFile.click()
                                }}
                            >
                                <FiUpload fontSize={64} style={{color:'var(--primary-color)'}}/>
                                <h6>{t('items.upload_file')}</h6>
                            </div>
                        </Col>
                    </div>
                );
            case 'inc':
                return(
                    <Col className="p-3">
                        <Row className='justify-between mb-3'>
                            <Col><label>Endereço iNOVACENTER {'-->'} {`${enderecoApi}/${sessionStorage.getItem('idConta')}/${rotaInovaCenter}${ptypeValue? `?reqMode=${ptypeValue}` : ''}`}</label></Col>
                            <Col lg={3}>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Tipo</Form.Label>
                                        <Form.Select aria-label="Default select example" onClick={e=>{setPtypeValue(e.target.value)}}>
                                            <option value="nf">Nf</option>
                                            <option value="benef">Benef</option>
                                            <option value="of">Of</option>
                                            <option value="ref">Ref</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Col>
                                        <Form.Check 
                                            style={{ display: displayView?'inherit':'none'}}
                                            reverse
                                            type="switch"
                                            onChange={e=> {setChoice(!choice)}}
                                            label="Editar Lista"
                                            disabled={sessionStorage.getItem('perfil') !== 'admin' ? true : false}
                                        />
                                        <Form.Check
                                            reverse
                                            style={{display: displayView&&choice?'inherit':'none'}}
                                            type="switch"
                                            onChange={e=> {setChoiceQtd(!choiceQtd)}}
                                            label={choice?<p>Editar Qtd</p>:null}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Form.Control
                                className='my-3'
                                value={identify}
                                onChange={e=> {setRotaInovaCenter(e.target.value.replace('/', '%2F'), setIdentify(e.target.value))}}
                                placeholder={'identificador'}
                            />
                            <Button className='text-uppercase' onClick={e=>{getListbyInovaCenter()}}>Carregar lista</Button>
                        </Row>
                    </Col>
                );
            case 'cat':
                return(
                    <div className='p-3 d-flex justify-content-center'>
                        <Button className='fst-normal shadow-sm p-2' variant="dark" onClick={e=>{getListbyCategoria()}}><FaArrowDown fontSize={14} className='me-2'/> Carregar lista de categorias</Button>
                    </div>
                );
            case 'xml':
                return(
                    <div className="filters">
                        <Form.Group className="mb-3">
                            <Form.Label>Insira um arquivo Xml</Form.Label>
                            <Form.Control type="file" accept={"text/xml"} onChange={e=>{getListbyXml(e.target.files[0])}}/>
                        </Form.Group>
                    </div>
                );
            default:
                return(
                    <Container fluid>
                        <Row className='justify-content-center'>
                            <input id='dropFile' style={{display: 'none'}} type='file' accept={fileTypes} onChange={e=> {
                                e.preventDefault()
                                if(e.target.files[0]) {
                                    chooseFileImport(e.target.files[0])
                                }
                            }}/>
                            <Col>
                            <input id='dropFile' style={{display: 'none'}} type='file' accept={fileTypes} onChange={e=> {
                                e.preventDefault()
                                if(e.target.files[0]) {
                                    chooseFileImport(e.target.files[0])
                                }
                            }}/>
                                <div
                                    className="p-5 rounded d-flex justify-content-center align-items-center flex-column input-file"
                                    style={{
                                        border:'3px dashed #d8e4f7'
                                    }}
                                    onDragOver={e=> {e.preventDefault()}}
                                    onDrop={e=> {
                                        e.preventDefault()
                                        if(fileTypes.includes(e.dataTransfer.files[0].type))
                                        chooseFileImport(e.dataTransfer.files[0])
                                    }}
                                    onClick={e=>{
                                        let getFile = window.document.getElementById('dropFile')
                                        getFile.click()
                                    }}
                                >
                                    <FiUpload fontSize={64} style={{color:'var(--primary-color)'}}/>
                                    <h6>{t('items.upload_file')}</h6>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                );
        }
    }

    

    const [listxml, setListXml] = useState([])
    const [xmlHeader, setXmlHeader] = useState([])
    const [showModalXml, setShowModalXml] = useState(false)


    
    async function getListbyXml(file) {
        setLoading(true)
        const text = await file.text()
        var XMLParser = await require('react-xml-parser');
        const xml = new XMLParser().parseFromString(text);

        let lista = []
        
        let serial = formateSerial(xml.getElementsByTagName('dhEmi')[0].value)
        for (var i = 0; i < xml.getElementsByTagName('prod').length; i++) {
            let info
            let id_prod
            await api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/${descricaoCS == '' ? '*' : descricaoCS}/${validateEan(xml.getElementsByTagName('cEAN')[i].value)}/${ativoCS == '' ? '*' : ativoCS}`).then(
                response => {                            
                    if(response.data[0]){
                        id_prod = response.data[0]._id
                        info = response.data[0].inf_compl_1 
                    }
                }   
            )
            lista.push({
                '_id': id_prod ? id_prod : getID(),
                'descricao': xml.getElementsByTagName('xProd')[i].value,
                'ean': validateEan(xml.getElementsByTagName('cEAN')[i].value),
                'quantidade': xml.getElementsByTagName('qCom')[i].value.replace(/\./g, ""),
                'sgtin': gtinToSgtin(xml.getElementsByTagName('cEAN')[i].value, serial),
                'valor_unitario': Number(xml.getElementsByTagName('vUnCom')[i].value),
                'valor_venda': info ? info : '',
                'epc': gtinToEpc(xml.getElementsByTagName('cEAN')[i].value, serial.padStart(8, '0'))
            });
        }
        setListXml(lista.map((list, index)=>{
            return [
                list._id,
                list.descricao,
                list.ean,
                list.quantidade,
                list.sgtin,
                list.valor_unitario,
                list.valor_venda,
                list.epc,
                serial
            ]
        }))
        setShowModalXml(true)  
        setLoading(false
            )      
    }

    function validateEan(gtin) {
        if(gtin.length == 14){
            gtin = gtin.substring(1)
        }
        return gtin
    }

    function formateSerial(datestr) {
        let date = new Date(datestr);
        let mes = String(date.getMonth() + 1).substring(0);
        let ano = date.getFullYear().toString().substr(-2);
        let serial = mes + ano;
        return serial
    }

    function gtinToSgtin(gtin, serial) {            // Conversor gtin para SGTIN
        if(gtin.length < 14){
            gtin = "0" + gtin                       
        }
        let firstChar = gtin.substring(0, 1);       // Extrai o primeiro caractere
        gtin = gtin.substring(1);                   // Remove o primeiro valor 
        let prefix = gtin.slice(0, 7)               // Extrai o prefixo do ean (7 primeiros numeros)
        let suffix = gtin.slice(7,-1);              // Extrai o sufixo do ean (7 ultimos numeros, removendo o ultimo)
        const sgtin = prefix + "." + firstChar + suffix + "." + serial
        return sgtin
    }

    function editXml(index, value, atribute){
        let lista = listxml
        lista[index][atribute] = value
        setListXml(lista)
    }

    async function saveXml(){
        await fileProcess({data: listxml})
        setShowModalXml(false)
    }

    async function chooseFileImport(file) {
        const text = await file.text()
        const result = await parse(text)
        await fileProcess(result)
    }

    async function fileProcess(result) {
        let lista_gtin = []
        let EPCList = []
        setImportLoading(true)
        if(montDigito.includes('Campo')){
            if(montDigito.includes('-GTIN-')){
                lista_gtin = await getGtinList()
            }
        }
        setJaExiste(false)
        setUploaded([])
        setImportList([])
        setImportPage(0)

        let dt = new Date()
        let dt_mont =  dt.getFullYear() + ("0" + (dt.getMonth() + 1)).slice(-2) + ("0" + dt.getDate()).slice(-2)
        dt_mont += ("0" + dt.getHours()).slice(-2) + ("0" +dt.getMinutes()).slice(-2) + ("0" +dt.getSeconds()).slice(-2)

        if(result.data.length <= 70000) {
            let temp = []
            let catToUpdate = result.data
            await result.data.filter((item) => {
                if(!(item.length == 1 && item[0] == '')) {
                    return item
                }
            }).map((item, index) => {
                let obj = {}
                let d
                if(montDigitoCsv == ''){
                    obj["key_i"] = index
                }
                if(qrcodeCsv != ''){
                    obj["campo1"] = item[qrcodeCsv]
                }
                if(codbarCsv != ''){
                    obj["campo2"] = item[codbarCsv]
                }
                if(descricao01 != ''){
                    obj["campo3"] = item[csv01]
                }
                if(descricao02 != ''){
                    obj["campo4"] = item[csv02];
                }
                if(descricao03 != ''){
                    obj["campo5"] = item[csv03];
                }
                if(descricao04 != ''){
                    obj["campo6"] = item[csv04];
                }
                if(descricao05 != ''){
                    obj["campo7"] = item[csv05];
                }
                if(descricao06 != ''){
                    obj["campo8"] = item[csv06];
                }
                if(descricao07 != ''){
                    obj["campo9"] = item[csv07];
                }
                if(descricao08 != ''){
                    obj["campo10"] = item[csv08];
                }
                if(descricao09 != ''){
                    obj["campo11"] = item[csv09];
                }
                if(descricao10 != ''){
                    obj["campo12"] = item[csv10];
                }
                if(descricao11 != ''){
                    obj["campo13"] = item[csv11];
                }
                if(descricao12 != ''){
                    obj["campo14"] = item[csv12];
                }
                if(descricao13 != ''){
                    obj["campo15"] = item[csv13];
                }
                if(descricao14 != ''){
                    obj["campo16"] = item[csv14];
                }
                if(descricao15 != ''){
                    obj["campo17"] = item[csv15];
                }

                if(montDigito.includes('Campo')){
                    if(montDigito.includes('-GTIN-')){
                        //abc
                        let baseClear = ('0').repeat(14)
                        let gtin14char = baseClear.substring(0, baseClear.length - obj[fieldDigit].length) + obj[fieldDigit]
                        let indexGtin = lista_gtin.findIndex((gtin) => gtin.gtin == gtin14char)
                        if(indexGtin <= -1) {
                            let gtinSelected = {
                                id_conta: sessionStorage.getItem('idConta'),
                                gtin: gtin14char,
                                serial: 0
                            }
                            lista_gtin.push(gtinSelected)
                            indexGtin = lista_gtin.findIndex((gtin) => gtin.gtin == gtin14char)
                        }
                        for(let c = 0; c < parseInt(montDigitoCsv != '' ? item[montDigitoCsv] : '1'); c++){
                            let objD = {}
                            
                            //get gtin epc
                            let valueField = gtinToEpc(lista_gtin[indexGtin].gtin, lista_gtin[indexGtin].serial + 1)//EPCAdd(obj[fieldDigit], c)
                            //add +1 in gtin serial
                            lista_gtin[indexGtin].serial += 1

                            let tagD = valueField
                            tagD = tagD.toUpperCase()
    
                            objD["key_i"] = tagD;
    
                            objD["epc"] =  tagD;	
    
                            objD["count"] = d;
    
                            if(qrcodeCsv!=''){
                                objD["campo1"] = obj.campo1
                            }
    
                            if(codbarCsv!=''){
                                objD["campo2"] = obj.campo2
                            }
                            
                            if(descricao01!=''){
                                if(descricao01.includes('-EPC-')){
                                    objD["campo3"] = objD.epc
                                }else if(descricao01.includes('-GSERIAL-')){
                                    objD["campo3"] = lista_gtin[indexGtin].serial
                                }else if(descricao01.includes('-SSCC-')){
                                    objD["campo3"] = epcToSscc(objD.epc)
                                }else if(descricao01.toLowerCase().includes('-13ean-')){
                                    objD["campo3"] = uniformEan(obj.campo3)
                                }else if(descricao01.includes('-SEQ-')){
                                    objD["campo3"] = (EPCAdd(obj.campo3.toString(), c))
                                }else if(descricao01.toLowerCase().includes('-logo-')){
                                    objD["campo3"] = 'arquivo .grf'
                                }else if(descricao01.toLowerCase().includes('-fixed-')){
                                    objD["campo3"] = 'Texto fixo'
                                }else{
                                    objD["campo3"] = obj.campo3
                                    console.log(obj.campo3)
                                }
                            }
                
                            if(descricao02!=''){
                                if(descricao02.includes('-EPC-')){
                                    objD["campo4"] = objD.epc
                                }else if(descricao02.includes('-GSERIAL-')){
                                    objD["campo4"] = lista_gtin[indexGtin].serial
                                }else if(descricao02.includes('-SSCC-')){
                                    objD["campo4"] = epcToSscc(objD.epc)
                                }else if(descricao02.toLowerCase().includes('-13ean-')){
                                    objD["campo4"] = uniformEan(obj.campo4)
                                }else if(descricao02.includes('-SEQ-')){
                                    objD["campo4"] = (EPCAdd(obj.campo4.toString(), c))
                                }else if(descricao02.toLowerCase().includes('-logo-')){
                                    objD["campo4"] = 'arquivo .grf'
                                }else if(descricao02.toLowerCase().includes('-fixed-')){
                                    objD["campo4"] = 'Texto fixo'
                                }else{
                                    objD["campo4"] = obj.campo4
                                    console.log(obj.campo4)
                                }
                            }
    
                            if(descricao03!=''){
                                if(descricao03.includes('-EPC-')){
                                    objD["campo5"] = objD.epc
                                }else if(descricao03.includes('-GSERIAL-')){
                                    objD["campo5"] = lista_gtin[indexGtin].serial
                                }else if(descricao03.includes('-SSCC-')){
                                    objD["campo5"] = epcToSscc(objD.epc)
                                }else if(descricao03.toLowerCase().includes('-13ean-')){
                                    objD["campo5"] = uniformEan(obj.campo5)
                                }else if(descricao03.includes('-SEQ-')){
                                    objD["campo5"] = (EPCAdd(obj.campo5.toString(), c))
                                }else if(descricao03.toLowerCase().includes('-logo-')){
                                    objD["campo5"] = 'arquivo .grf'
                                }else if(descricao03.toLowerCase().includes('-fixed-')){
                                    objD["campo5"] = 'Texto fixo'
                                }else{
                                    objD["campo5"] = obj.campo5
                                    console.log(obj.campo5)
                                }
                            }
    
                            if(descricao04!=''){
                                if(descricao04.includes('-EPC-')){
                                    objD["campo6"] = objD.epc
                                }else if(descricao04.includes('-GSERIAL-')){
                                    objD["campo6"] = lista_gtin[indexGtin].serial
                                }else if(descricao04.includes('-SSCC-')){
                                    objD["campo6"] = epcToSscc(objD.epc)
                                }else if(descricao04.toLowerCase().includes('-13ean-')){
                                    objD["campo6"] = uniformEan(obj.campo6)
                                }else if(descricao04.includes('-SEQ-')){
                                    objD["campo6"] = (EPCAdd(obj.campo6.toString(), c))
                                }else if(descricao04.toLowerCase().includes('-logo-')){
                                    objD["campo6"] = 'arquivo .grf'
                                }else if(descricao04.toLowerCase().includes('-fixed-')){
                                    objD["campo6"] = 'Texto fixo'
                                }else{
                                    objD["campo6"] = obj.campo6
                                    console.log(obj.campo6)
                                }
                            }
    
                            if(descricao05!=''){
                                if(descricao05.includes('-EPC-')){
                                    objD["campo7"] = objD.epc
                                }else if(descricao05.includes('-GSERIAL-')){
                                    objD["campo7"] = lista_gtin[indexGtin].serial
                                }else if(descricao05.includes('-SSCC-')){
                                    objD["campo7"] = epcToSscc(objD.epc)
                                }else if(descricao05.toLowerCase().includes('-13ean-')){
                                    objD["campo7"] = uniformEan(obj.campo7)
                                }else if(descricao05.includes('-SEQ-')){
                                    objD["campo7"] = (EPCAdd(obj.campo7.toString(), c))
                                }else if(descricao05.toLowerCase().includes('-logo-')){
                                    objD["campo7"] = 'arquivo .grf'
                                }else if(descricao05.toLowerCase().includes('-fixed-')){
                                    objD["campo7"] = 'Texto fixo'
                                }else{
                                    objD["campo7"] = obj.campo7
                                    console.log(obj.campo7)
                                }
                            }
    
                            if(descricao06!=''){
                                if(descricao06.includes('-EPC-')){
                                    objD["campo8"] = objD.epc
                                }else if(descricao06.includes('-GSERIAL-')){
                                    objD["campo8"] = lista_gtin[indexGtin].serial
                                }else if(descricao06.includes('-SSCC-')){
                                    objD["campo8"] = epcToSscc(objD.epc)
                                }else if(descricao06.toLowerCase().includes('-13ean-')){
                                    objD["campo8"] = uniformEan(obj.campo8)
                                }else if(descricao06.includes('-SEQ-')){
                                    objD["campo8"] = (EPCAdd(obj.campo8.toString(), c))
                                }else if(descricao06.toLowerCase().includes('-logo-')){
                                    objD["campo8"] = 'arquivo .grf'
                                }else if(descricao06.toLowerCase().includes('-fixed-')){
                                    objD["campo8"] = 'Texto fixo'
                                }else{
                                    objD["campo8"] = obj.campo8
                                    console.log(obj.campo8)
                                }
                            }
    
                            if(descricao07!=''){
                                if(descricao07.includes('-EPC-')){
                                    objD["campo9"] = objD.epc
                                }else if(descricao07.includes('-GSERIAL-')){
                                    objD["campo9"] = lista_gtin[indexGtin].serial
                                }else if(descricao07.includes('-SSCC-')){
                                    objD["campo9"] = epcToSscc(objD.epc)
                                }else if(descricao07.toLowerCase().includes('-13ean-')){
                                    objD["campo9"] = uniformEan(obj.campo9)
                                }else if(descricao07.includes('-SEQ-')){
                                    objD["campo9"] = (EPCAdd(obj.campo9.toString(), c))
                                }else if(descricao07.toLowerCase().includes('-logo-')){
                                    objD["campo9"] = 'arquivo .grf'
                                }else if(descricao07.toLowerCase().includes('-fixed-')){
                                    objD["campo9"] = 'Texto fixo'
                                }else{
                                    objD["campo9"] = obj.campo9
                                    console.log(obj.campo9)
                                }
                            }
    
                            if(descricao08!=''){
                                if(descricao08.includes('-EPC-')){
                                    objD["campo10"] = objD.epc
                                }else if(descricao08.includes('-GSERIAL-')){
                                    objD["campo10"] = lista_gtin[indexGtin].serial
                                }else if(descricao08.includes('-SSCC-')){
                                    objD["campo10"] = epcToSscc(objD.epc)
                                }else if(descricao08.toLowerCase().includes('-13ean-')){
                                    objD["campo10"] = uniformEan(obj.campo10)
                                }else if(descricao08.includes('-SEQ-')){
                                    objD["campo10"] =(EPCAdd(obj.campo10.toString(), c))
                                }else if(descricao08.toLowerCase().includes('-logo-')){
                                    objD["campo10"] ='arquivo .grf'
                                }else if(descricao08.toLowerCase().includes('-fixed-')){
                                    objD["campo10"] = 'Texto fixo'
                                }else{
                                    objD["campo10"] = obj.campo10
                                    console.log(obj.campo10)
                                }
                            }
    
                            if(descricao09!=''){
                                if(descricao09.includes('-EPC-')){
                                    objD["campo11"] = objD.epc
                                }else if(descricao09.includes('-GSERIAL-')){
                                    objD["campo11"] = lista_gtin[indexGtin].serial
                                }else if(descricao09.includes('-SSCC-')){
                                    objD["campo11"] = epcToSscc(objD.epc)
                                }else if(descricao09.toLowerCase().includes('-13ean-')){
                                    objD["campo11"] = uniformEan(obj.campo11)
                                }else if(descricao09.includes('-SEQ-')){
                                    objD["campo11"] =(EPCAdd(obj.campo11.toString(), c))
                                }else if(descricao09.toLowerCase().includes('-logo-')){
                                    objD["campo11"] ='arquivo .grf'
                                }else if(descricao09.toLowerCase().includes('-fixed-')){
                                    objD["campo11"] = 'Texto fixo'
                                }else{
                                    objD["campo11"] = obj.campo11
                                    console.log(obj.campo11)
                                }
                            }
    
                            if(descricao10!=''){
                                if(descricao10.includes('-EPC-')){
                                    objD["campo12"] = objD.epc
                                }else if(descricao10.includes('-GSERIAL-')){
                                    objD["campo12"] = lista_gtin[indexGtin].serial
                                }else if(descricao10.includes('-SSCC-')){
                                    objD["campo12"] = epcToSscc(objD.epc)
                                }else if(descricao10.toLowerCase().includes('-13ean-')){
                                    objD["campo12"] = uniformEan(obj.campo12)
                                }else if(descricao10.includes('-SEQ-')){
                                    objD["campo12"] =(EPCAdd(obj.campo12.toString(), c))
                                }else if(descricao10.toLowerCase().includes('-logo-')){
                                    objD["campo12"] ='arquivo .grf'
                                }else if(descricao10.toLowerCase().includes('-fixed-')){
                                    objD["campo12"] = 'Texto fixo'
                                }else{
                                    objD["campo12"] = obj.campo12
                                    console.log(obj.campo12)
                                }
                            }

                            if(descricao11!=''){
                                if(descricao11.includes('-EPC-')){
                                    objD["campo13"] = objD.epc
                                }else if(descricao11.includes('-GSERIAL-')){
                                    objD["campo13"] = lista_gtin[indexGtin].serial
                                }else if(descricao11.includes('-SSCC-')){
                                    objD["campo13"] = epcToSscc(objD.epc)
                                }else if(descricao11.toLowerCase().includes('-13ean-')){
                                    objD["campo13"] = uniformEan(obj.campo13)
                                }else if(descricao11.includes('-SEQ-')){
                                    objD["campo13"] =(EPCAdd(obj.campo13.toString(), c))
                                }else if(descricao11.toLowerCase().includes('-logo-')){
                                    objD["campo13"] ='arquivo .grf'
                                }else if(descricao11.toLowerCase().includes('-fixed-')){
                                    objD["campo13"] = 'Texto fixo'
                                }else{
                                    objD["campo13"] = obj.campo13
                                    console.log(obj.campo13)
                                }
                            }

                            if(descricao12!=''){
                                if(descricao12.includes('-EPC-')){
                                    objD["campo14"] = objD.epc
                                }else if(descricao12.includes('-GSERIAL-')){
                                    objD["campo14"] = lista_gtin[indexGtin].serial
                                }else if(descricao12.includes('-SSCC-')){
                                    objD["campo14"] = epcToSscc(objD.epc)
                                }else if(descricao12.toLowerCase().includes('-13ean-')){
                                    objD["campo14"] = uniformEan(obj.campo14)
                                }else if(descricao12.includes('-SEQ-')){
                                    objD["campo14"] =(EPCAdd(obj.campo14.toString(), c))
                                }else if(descricao12.toLowerCase().includes('-logo-')){
                                    objD["campo14"] ='arquivo .grf'
                                }else if(descricao12.toLowerCase().includes('-fixed-')){
                                    objD["campo14"] = 'Texto fixo'
                                }else{
                                    objD["campo14"] = obj.campo14
                                    console.log(obj.campo14)
                                }
                            }

                            if(descricao13!=''){
                                if(descricao13.includes('-EPC-')){
                                    objD["campo15"] = objD.epc
                                }else if(descricao13.includes('-GSERIAL-')){
                                    objD["campo15"] = lista_gtin[indexGtin].serial
                                }else if(descricao13.includes('-SSCC-')){
                                    objD["campo15"] = epcToSscc(objD.epc)
                                }else if(descricao13.toLowerCase().includes('-13ean-')){
                                    objD["campo15"] = uniformEan(obj.campo15)
                                }else if(descricao13.includes('-SEQ-')){
                                    objD["campo15"] =(EPCAdd(obj.campo15.toString(), c))
                                }else if(descricao13.toLowerCase().includes('-logo-')){
                                    objD["campo15"] ='arquivo .grf'
                                }else if(descricao13.toLowerCase().includes('-fixed-')){
                                    objD["campo15"] = 'Texto fixo'
                                }else{
                                    objD["campo15"] = obj.campo15
                                    console.log(obj.campo15)
                                }
                            }

                            if(descricao14!=''){
                                if(descricao14.includes('-EPC-')){
                                    objD["campo16"] = objD.epc
                                }else if(descricao14.includes('-GSERIAL-')){
                                    objD["campo16"] = lista_gtin[indexGtin].serial
                                }else if(descricao14.includes('-SSCC-')){
                                    objD["campo16"] = epcToSscc(objD.epc)
                                }else if(descricao14.toLowerCase().includes('-13ean-')){
                                    objD["campo16"] = uniformEan(obj.campo16)
                                }else if(descricao14.includes('-SEQ-')){
                                    objD["campo16"] =(EPCAdd(obj.campo16.toString(), c))
                                }else if(descricao14.toLowerCase().includes('-logo-')){
                                    objD["campo16"] ='arquivo .grf'
                                }else if(descricao14.toLowerCase().includes('-fixed-')){
                                    objD["campo16"] = 'Texto fixo'
                                }else{
                                    objD["campo16"] = obj.campo16
                                    console.log(obj.campo16)
                                }
                            }

                            if(descricao15!=''){
                                if(descricao15.includes('-EPC-')){
                                    objD["campo17"] = objD.epc
                                }else if(descricao15.includes('-GSERIAL-')){
                                    objD["campo17"] = lista_gtin[indexGtin].serial
                                }else if(descricao15.includes('-SSCC-')){
                                    objD["campo17"] = epcToSscc(objD.epc)
                                }else if(descricao15.toLowerCase().includes('-13ean-')){
                                    objD["campo17"] = uniformEan(obj.campo17)
                                }else if(descricao15.includes('-SEQ-')){
                                    objD["campo17"] =(EPCAdd(obj.campo17.toString(), c))
                                }else if(descricao15.toLowerCase().includes('-logo-')){
                                    objD["campo17"] ='arquivo .grf'
                                }else if(descricao15.toLowerCase().includes('-fixed-')){
                                    objD["campo17"] = 'Texto fixo'
                                }else{
                                    objD["campo17"] = obj.campo17
                                    console.log(obj.campo17)
                                }
                            }
    
                            if(inBaseProps.tags.indexOf(objD.epc) < 0) {
                                objD["registred"] = false
                            } else {
                                objD["registred"] = true
                            }
    
                            temp.push(objD)	
                        }
                    }else{
                        for(let c = 0; c < parseInt(montDigitoCsv != '' ? item[montDigitoCsv] : '1'); c++){
                            let objD = {}
                            
                            let counter = 0
                            if(arquivoImportacao == 'cat') {
                                catToUpdate[index][item.length-1] += 1
                                counter = catToUpdate[index][item.length-1]
                            }else{
                                counter = c
                            }

                            let valueField = EPCAdd(obj[fieldDigit], counter)
    
                            let cleanBase = ''
                            for(let z= 0; z < obj[fieldDigit].length; z++) {
                                cleanBase += '0'
                            }
    
                            if(epcForm == '-ASC-'){
                                let valueFieldStr = valueField.toString()
                                let valueFieldAsc = ''
                                for(let i = 0; i < valueFieldStr.length; i++) {
                                    valueFieldAsc += `3${valueFieldStr[i]}`
                                }
                                if(left == '-LEFT-'){
                                    d = valueFieldAsc + ("000000000000000000000000").substr(valueFieldAsc.toString().length)
                                }else{
                                    d = "000000000000000000000000" + valueFieldAsc
                                    d =  d.substr(valueFieldAsc.toString().length);
                                }
                            }else{
                                if(left == '-LEFT-'){
                                    d = valueField.toString() + ("000000000000000000000000").substr(valueField.toString().length);
                                }else{
                                    d = "000000000000000000000000" + valueField.toString()
                                    d =  d.substr(valueField.toString().length);
                                }
                            }
    
                            let tagD = d
                            tagD = tagD.toUpperCase()
    
                           

                            objD["key_i"] = tagD;
                            console.log(tagD)
    
                            objD["epc"] =  tagD;	

                            objD["count"] = d;
    
                            if(qrcodeCsv!=''){
                                objD["campo1"] = obj.campo1
                            }
    
                            if(codbarCsv!=''){
                                objD["campo2"] = obj.campo2
                            }
    
                            if(descricao01!=''){
                                if(descricao01.includes('-EPC-')){
                                    objD["campo3"] = objD.epc
                                }else if(descricao01.includes('-SSCC-')){
                                    objD["campo3"] = epcToSscc(objD.epc)
                                }else if(descricao01.toLowerCase().includes('-13ean-')){
                                    objD["campo3"] = uniformEan(obj.campo3)
                                }else if(descricao01.includes('-SEQ-')){
                                    objD["campo3"] = (EPCAdd(obj.campo3.toString(), counter))
                                }else if(descricao01.toLowerCase().includes('-logo-')){
                                    objD["campo3"] = 'arquivo .grf'
                                }else if(descricao01.toLowerCase().includes('-fixed-')){
                                    objD["campo3"] = 'Texto fixo'
                                }else{
                                    objD["campo3"] = obj.campo3
                                }
                            }
                
                            if(descricao02!=''){
                                if(descricao02.includes('-EPC-')){
                                    objD["campo4"] = objD.epc
                                }else if(descricao02.includes('-SSCC-')){
                                    objD["campo4"] = epcToSscc(objD.epc)
                                }else if(descricao02.toLowerCase().includes('-13ean-')){
                                    objD["campo4"] = uniformEan(obj.campo4)
                                }else if(descricao02.includes('-SEQ-')){
                                    objD["campo4"] = (EPCAdd(obj.campo4.toString(), counter))
                                }else if(descricao02.toLowerCase().includes('-logo-')){
                                    objD["campo4"] = 'arquivo .grf'
                                }else if(descricao02.toLowerCase().includes('-fixed-')){
                                    objD["campo4"] = 'Texto fixo'
                                }else{
                                    objD["campo4"] = obj.campo4
                                }
                            }
    
                            if(descricao03!=''){
                                if(descricao03.includes('-EPC-')){
                                    objD["campo5"] = objD.epc
                                }else if(descricao03.includes('-SSCC-')){
                                    objD["campo5"] = epcToSscc(objD.epc)
                                }else if(descricao03.toLowerCase().includes('-13ean-')){
                                    objD["campo5"] = uniformEan(obj.campo5)
                                }else if(descricao03.includes('-SEQ-')){
                                    objD["campo5"] = (EPCAdd(obj.campo5.toString(), counter))
                                }else if(descricao03.toLowerCase().includes('-logo-')){
                                    objD["campo5"] = 'arquivo .grf'
                                }else if(descricao03.toLowerCase().includes('-fixed-')){
                                    objD["campo5"] = 'Texto fixo'
                                }else{
                                    objD["campo5"] = obj.campo5
                                }
                            }
    
                            if(descricao04!=''){
                                if(descricao04.includes('-EPC-')){
                                    objD["campo6"] = objD.epc
                                }else if(descricao04.includes('-SSCC-')){
                                    objD["campo6"] = epcToSscc(objD.epc)
                                }else if(descricao04.toLowerCase().includes('-13ean-')){
                                    objD["campo6"] = uniformEan(obj.campo6)
                                }else if(descricao04.includes('-SEQ-')){
                                    objD["campo6"] = (EPCAdd(obj.campo6.toString(), counter))
                                }else if(descricao04.toLowerCase().includes('-logo-')){
                                    objD["campo6"] = 'arquivo .grf'
                                }else if(descricao04.toLowerCase().includes('-fixed-')){
                                    objD["campo6"] = 'Texto fixo'
                                }else{
                                    objD["campo6"] = obj.campo6
                                }
                            }
    
                            if(descricao05!=''){
                                if(descricao05.includes('-EPC-')){
                                    objD["campo7"] = objD.epc
                                }else if(descricao05.includes('-SSCC-')){
                                    objD["campo7"] = epcToSscc(objD.epc)
                                }else if(descricao05.toLowerCase().includes('-13ean-')){
                                    objD["campo7"] = uniformEan(obj.campo7)
                                }else if(descricao05.includes('-SEQ-')){
                                    objD["campo7"] = (EPCAdd(obj.campo7.toString(), counter))
                                }else if(descricao05.toLowerCase().includes('-logo-')){
                                    objD["campo7"] = 'arquivo .grf'
                                }else if(descricao05.toLowerCase().includes('-fixed-')){
                                    objD["campo7"] = 'Texto fixo'
                                }else{
                                    objD["campo7"] = obj.campo7
                                }
                            }
    
                            if(descricao06!=''){
                                if(descricao06.includes('-EPC-')){
                                    objD["campo8"] = objD.epc
                                }else if(descricao06.includes('-SSCC-')){
                                    objD["campo8"] = epcToSscc(objD.epc)
                                }else if(descricao06.toLowerCase().includes('-13ean-')){
                                    objD["campo8"] = uniformEan(obj.campo8)
                                }else if(descricao06.includes('-SEQ-')){
                                    objD["campo8"] = (EPCAdd(obj.campo8.toString(), counter))
                                }else if(descricao06.toLowerCase().includes('-logo-')){
                                    objD["campo8"] = 'arquivo .grf'
                                }else if(descricao06.toLowerCase().includes('-fixed-')){
                                    objD["campo8"] = 'Texto fixo'
                                }else{
                                    objD["campo8"] = obj.campo8
                                }
                            }
    
                            if(descricao07!=''){
                                if(descricao07.includes('-EPC-')){
                                    objD["campo9"] = objD.epc
                                }else if(descricao07.includes('-SSCC-')){
                                    objD["campo9"] = epcToSscc(objD.epc)
                                }else if(descricao07.toLowerCase().includes('-13ean-')){
                                    objD["campo9"] = uniformEan(obj.campo9)
                                }else if(descricao07.includes('-SEQ-')){
                                    objD["campo9"] = (EPCAdd(obj.campo9.toString(), counter))
                                }else if(descricao07.toLowerCase().includes('-logo-')){
                                    objD["campo9"] = 'arquivo .grf'
                                }else if(descricao07.toLowerCase().includes('-fixed-')){
                                    objD["campo9"] = 'Texto fixo'
                                }else{
                                    objD["campo9"] = obj.campo9
                                }
                            }
    
                            if(descricao08!=''){
                                if(descricao08.includes('-EPC-')){
                                    objD["campo10"] = objD.epc
                                }else if(descricao08.includes('-SSCC-')){
                                    objD["campo10"] = epcToSscc(objD.epc)
                                }else if(descricao08.toLowerCase().includes('-13ean-')){
                                    objD["campo10"] = uniformEan(obj.campo10)
                                }else if(descricao08.includes('-SEQ-')){
                                    objD["campo10"] =(EPCAdd(obj.campo10.toString(), counter))
                                }else if(descricao08.toLowerCase().includes('-logo-')){
                                    objD["campo10"] ='arquivo .grf'
                                }else if(descricao08.toLowerCase().includes('-fixed-')){
                                    objD["campo10"] = 'Texto fixo'
                                }else{
                                    objD["campo10"] = obj.campo10
                                }
                            }
    
                            if(descricao09!=''){
                                if(descricao09.includes('-EPC-')){
                                    objD["campo11"] = objD.epc
                                }else if(descricao09.includes('-SSCC-')){
                                    objD["campo11"] = epcToSscc(objD.epc)
                                }else if(descricao09.toLowerCase().includes('-13ean-')){
                                    objD["campo11"] = uniformEan(obj.campo11)
                                }else if(descricao09.includes('-SEQ-')){
                                    objD["campo11"] =(EPCAdd(obj.campo11.toString(), counter))
                                }else if(descricao09.toLowerCase().includes('-logo-')){
                                    objD["campo11"] ='arquivo .grf'
                                }else if(descricao09.toLowerCase().includes('-fixed-')){
                                    objD["campo11"] = 'Texto fixo'
                                }else{
                                    objD["campo11"] = obj.campo11
                                }
                            }
    
                            if(descricao10!=''){
                                if(descricao10.includes('-EPC-')){
                                    objD["campo12"] = objD.epc
                                }else if(descricao10.includes('-SSCC-')){
                                    objD["campo12"] = epcToSscc(objD.epc)
                                }else if(descricao10.toLowerCase().includes('-13ean-')){
                                    objD["campo12"] = uniformEan(obj.campo12)
                                }else if(descricao10.includes('-SEQ-')){
                                    objD["campo12"] =(EPCAdd(obj.campo12.toString(), counter))
                                }else if(descricao10.toLowerCase().includes('-logo-')){
                                    objD["campo12"] ='arquivo .grf'
                                }else if(descricao10.toLowerCase().includes('-fixed-')){
                                    objD["campo12"] = 'Texto fixo'
                                }else{
                                    objD["campo12"] = obj.campo12
                                }
                            }

                            if(descricao11!=''){
                                if(descricao11.includes('-EPC-')){
                                    objD["campo13"] = objD.epc
                                }else if(descricao11.includes('-SSCC-')){
                                    objD["campo13"] = epcToSscc(objD.epc)
                                }else if(descricao11.toLowerCase().includes('-13ean-')){
                                    objD["campo13"] = uniformEan(obj.campo13)
                                }else if(descricao11.includes('-SEQ-')){
                                    objD["campo13"] = (EPCAdd(obj.campo13.toString(), counter))
                                }else if(descricao11.toLowerCase().includes('-logo-')){
                                    objD["campo13"] = 'arquivo .grf'
                                }else if(descricao11.toLowerCase().includes('-fixed-')){
                                    objD["campo13"] = 'Texto fixo'
                                }else{
                                    objD["campo13"] = obj.campo13
                                }
                            }
                
                            if(descricao12!=''){
                                if(descricao12.includes('-EPC-')){
                                    objD["campo14"] = objD.epc
                                }else if(descricao12.includes('-SSCC-')){
                                    objD["campo14"] = epcToSscc(objD.epc)
                                }else if(descricao12.toLowerCase().includes('-13ean-')){
                                    objD["campo14"] = uniformEan(obj.campo14)
                                }else if(descricao12.includes('-SEQ-')){
                                    objD["campo14"] = (EPCAdd(obj.campo14.toString(), counter))
                                }else if(descricao12.toLowerCase().includes('-logo-')){
                                    objD["campo14"] = 'arquivo .grf'
                                }else if(descricao12.toLowerCase().includes('-fixed-')){
                                    objD["campo14"] = 'Texto fixo'
                                }else{
                                    objD["campo14"] = obj.campo14
                                }
                            }
    
                            if(descricao13!=''){
                                if(descricao13.includes('-EPC-')){
                                    objD["campo15"] = objD.epc
                                }else if(descricao13.includes('-SSCC-')){
                                    objD["campo15"] = epcToSscc(objD.epc)
                                }else if(descricao13.toLowerCase().includes('-13ean-')){
                                    objD["campo15"] = uniformEan(obj.campo15)
                                }else if(descricao13.includes('-SEQ-')){
                                    objD["campo15"] = (EPCAdd(obj.campo15.toString(), counter))
                                }else if(descricao13.toLowerCase().includes('-logo-')){
                                    objD["campo15"] = 'arquivo .grf'
                                }else if(descricao13.toLowerCase().includes('-fixed-')){
                                    objD["campo15"] = 'Texto fixo'
                                }else{
                                    objD["campo15"] = obj.campo15
                                }
                            }
    
                            if(descricao14!=''){
                                if(descricao14.includes('-EPC-')){
                                    objD["campo16"] = objD.epc
                                }else if(descricao14.includes('-SSCC-')){
                                    objD["campo16"] = epcToSscc(objD.epc)
                                }else if(descricao14.toLowerCase().includes('-13ean-')){
                                    objD["campo16"] = uniformEan(obj.campo16)
                                }else if(descricao14.includes('-SEQ-')){
                                    objD["campo16"] = (EPCAdd(obj.campo16.toString(), counter))
                                }else if(descricao14.toLowerCase().includes('-logo-')){
                                    objD["campo16"] = 'arquivo .grf'
                                }else if(descricao14.toLowerCase().includes('-fixed-')){
                                    objD["campo16"] = 'Texto fixo'
                                }else{
                                    objD["campo16"] = obj.campo16
                                }
                            }
    
                            if(descricao15!=''){
                                if(descricao15.includes('-EPC-')){
                                    objD["campo17"] = objD.epc
                                }else if(descricao15.includes('-SSCC-')){
                                    objD["campo17"] = epcToSscc(objD.epc)
                                }else if(descricao15.toLowerCase().includes('-13ean-')){
                                    objD["campo17"] = uniformEan(obj.campo17)
                                }else if(descricao15.includes('-SEQ-')){
                                    objD["campo17"] = (EPCAdd(obj.campo17.toString(), counter))
                                }else if(descricao15.toLowerCase().includes('-logo-')){
                                    objD["campo17"] = 'arquivo .grf'
                                }else if(descricao15.toLowerCase().includes('-fixed-')){
                                    objD["campo17"] = 'Texto fixo'
                                }else{
                                    objD["campo17"] = obj.campo17
                                }
                            }
    
                            if(inBaseProps.tags.indexOf(objD.epc) < 0) {
                                objD["registred"] = false
                            } else {
                                objD["registred"] = true
                            }
    
                            temp.push(objD)	
                        }
                    }
                }else {
                    for(let c = 0; c < parseInt((montDigitoCsv != '' ? item[montDigitoCsv] : '1')); c++){
                        
                        let counter = 0
                        if(arquivoImportacao == 'cat') {
                            catToUpdate[index][item.length-1] += 1
                            counter = catToUpdate[index][item.length-1]
                        }else{
                            counter = c
                        }

                        let cMont
                        let cEan
                        let tag = ''
                        let objD = {}
                        let tagD = ''
                        let serial
                        let codeGtin = ''
                        if(montDigito == 'sscc') {
                            tagD = ssccSerialized(item[3],counter)
                        }else{
                        //if(!montDigito.includes('Campo')){
                            if(montCampo01!=''){
                                let montPart = ''
                                if(montCampo01=='dthr'){
                                    montPart =  dt_mont.substring( parseInt(montPini01) -1, (parseInt(montPini01) - 1) + parseInt(montCompr01));
                                }else {
                                    cMont   = listCampos.findIndex(k => k.valor==montCampo01)
                                    if(listCampos[cMont].valor.toLowerCase().includes('-hexa-')) {
                                        montPart =  textConvert((item[listCampos[cMont].coluna]).substring( parseInt(montPini01) -1, (parseInt(montPini01) - 1) + parseInt(montCompr01)), 'hexa');
                                    } else if(listCampos[cMont].valor.toLowerCase().includes('-13ean-')){
                                        montPart =  uniformEan(item[listCampos[cMont].coluna]).substring( parseInt(montPini01) -1, (parseInt(montPini01) - 1) + parseInt(montCompr01));
                                    }else if(listCampos[cMont].valor.toUpperCase().includes('-SEQ-')){
                                        montPart =  EPCAdd(item[listCampos[cMont].coluna].substring( parseInt(montPini01) -1, (parseInt(montPini01) - 1) + parseInt(montCompr01)), c);
                                        serial = montPart
                                    }else{
                                        montPart =  item[listCampos[cMont].coluna].replaceAll("R", "").replaceAll("$", "").replaceAll(" ", "").replaceAll(".", "").replaceAll(',','').substring( parseInt(montPini01) -1, (parseInt(montPini01) - 1) + parseInt(montCompr01));
                                        codeGtin += montPart
                                    }						
                                }

                                //tag = montPart
                                tag = sizeFormat(montPart, parseInt(montCompr01))
                            }
                            if(montCampo02!=''){
                                let montPart = ''
                                if(montCampo02=='dthr'){
                                    montPart = dt_mont.substring( parseInt(montPini02) -1, (parseInt(montPini02) - 1) + parseInt(montCompr02));
                                }else {
                                    cMont = listCampos.findIndex(k => k.valor==montCampo02)
                                    if(listCampos[cMont].valor.toLowerCase().includes('-hex-')) {
                                        montPart = textConvert((item[listCampos[cMont].coluna]).substring( parseInt(montPini02) -1, (parseInt(montPini02) - 1) + parseInt(montCompr02)), 'hexa');
                                    } else if(listCampos[cMont].valor.toLowerCase().includes('-13ean-')){
                                        montPart = uniformEan(item[listCampos[cMont].coluna]).substring( parseInt(montPini02) -1, (parseInt(montPini02) - 1) + parseInt(montCompr02));
                                    }else if(listCampos[cMont].valor.toUpperCase().includes('-SEQ-')){
                                        montPart =  EPCAdd(item[listCampos[cMont].coluna].substring( parseInt(montPini02) -1, (parseInt(montPini02) - 1) + parseInt(montCompr02)), c);
                                        serial = montPart
                                    }else{
                                        montPart = item[listCampos[cMont].coluna].replaceAll("R", "").replaceAll("$", "").replaceAll(" ", "").replaceAll(".", "").replaceAll(',','').substring( parseInt(montPini02) -1, (parseInt(montPini02) - 1) + parseInt(montCompr02));
                                        codeGtin += montPart
                                    }
                                }

                                //tag += montPart
                                tag += sizeFormat(montPart, parseInt(montCompr02))
                            }
                            if(montCampo03!=''){
                                let montPart = ''
                                if(montCampo03=='dthr'){
                                    montPart = dt_mont.substring( parseInt(montPini03) -1, (parseInt(montPini03) - 1) + parseInt(montCompr03));
                                }else {
                                    cMont   = listCampos.findIndex(k => k.valor==montCampo03)
                                    if(listCampos[cMont].valor.toLowerCase().includes('-hex-')) {
                                        montPart = textConvert((item[listCampos[cMont].coluna]).substring( parseInt(montPini03) -1, (parseInt(montPini03) - 1) + parseInt(montCompr03)), 'hexa');
                                    } else if(listCampos[cMont].valor.toLowerCase().includes('-13ean-')){
                                        montPart = uniformEan(item[listCampos[cMont].coluna]).substring( parseInt(montPini03) -1, (parseInt(montPini03) - 1) + parseInt(montCompr03));
                                    }else if(listCampos[cMont].valor.toUpperCase().includes('-SEQ-')){
                                        montPart =  EPCAdd(item[listCampos[cMont].coluna].substring( parseInt(montPini03) -1, (parseInt(montPini03) - 1) + parseInt(montCompr03)), c);
                                        serial = montPart
                                    }else{
                                        codeGtin += montPart
                                        montPart = item[listCampos[cMont].coluna].replaceAll("R", "").replaceAll("$", "").replaceAll(" ", "").replaceAll(".", "").replaceAll(',','').substring( parseInt(montPini03) -1, (parseInt(montPini03) - 1) + parseInt(montCompr03));
                                    }
                                }

                                //tag += montPart
                                tag += sizeFormat(montPart, parseInt(montCompr03))
                            }
                            if(montCampo04!=''){
                                let montPart = ''
                                if(montCampo04=='dthr'){
                                    montPart = dt_mont.substring( parseInt(montPini04) -1, (parseInt(montPini04) - 1) + parseInt(montCompr04));
                                }else {
                                    cMont   = listCampos.findIndex(k => k.valor==montCampo04)
                                        if(listCampos[cMont].valor.toLowerCase().includes('-hex-')) {
                                            montPart = textConvert((item[listCampos[cMont].coluna]).substring( parseInt(montPini04) -1, (parseInt(montPini04) - 1) + parseInt(montCompr04)), 'hexa');
                                        } else if(listCampos[cMont].valor.toLowerCase().includes('-13ean-')){
                                            montPart = uniformEan(item[listCampos[cMont].coluna]).substring( parseInt(montPini04) -1, (parseInt(montPini04) - 1) + parseInt(montCompr04));
                                        }else if(listCampos[cMont].valor.toUpperCase().includes('-SEQ-')){
                                            montPart =  EPCAdd(item[listCampos[cMont].coluna], c).substring( parseInt(montPini04) -1, (parseInt(montPini04) - 1) + parseInt(montCompr04));
                                            serial = montPart
                                        }else{
                                            montPart = item[listCampos[cMont].coluna].replaceAll("R", "").replaceAll("$", "").replaceAll(" ", "").replaceAll(".", "").replaceAll(',','').substring( parseInt(montPini04) -1, (parseInt(montPini04) - 1) + parseInt(montCompr04));
                                            codeGtin += montPart
                                        }
                                }

                                //tag += montPart
                                tag += sizeFormat(montPart, parseInt(montCompr04))
                            }
                            if(montCampo05!=''){
                                let montPart = ''
                                if(montCampo05=='dthr'){
                                    montPart = dt_mont.substring( parseInt(montPini05) -1, (parseInt(montPini05) - 1) + parseInt(montCompr05));
                                }else {
                                    cMont   = listCampos.findIndex(k => k.valor==montCampo05)
                                    if(listCampos[cMont]){
                                        if(listCampos[cMont].valor.toLowerCase().includes('-hex-')) {
                                            montPart = textConvert((item[listCampos[cMont].coluna]).substring( parseInt(montPini05) -1, (parseInt(montPini05) - 1) + parseInt(montCompr05)), 'hexa');
                                        } else if(listCampos[cMont].valor.toLowerCase().includes('-13ean-')){
                                            montPart = uniformEan(item[listCampos[cMont].coluna]).substring( parseInt(montPini05) -1, (parseInt(montPini05) - 1) + parseInt(montCompr05));
                                        }else if(listCampos[cMont].valor.toUpperCase().includes('-SEQ-')){
                                            montPart =  EPCAdd(item[listCampos[cMont].coluna], c).substring( parseInt(montPini05) -1, (parseInt(montPini05) - 1) + parseInt(montCompr05));
                                            serial = montPart
                                        }
                                        else{
                                            codeGtin += montPart
                                            montPart = item[listCampos[cMont].coluna].replaceAll("R", "").replaceAll("$", "").replaceAll(" ", "").replaceAll(".", "").replaceAll(',','').substring( parseInt(montPini05) -1, (parseInt(montPini05) - 1) + parseInt(montCompr05));
                                        }
                                    }else{
                                        setMontCampo05('')
                                    }
                                }

                                //tag += montPart
                                tag += sizeFormat(montPart, parseInt(montCompr05))
                            }
                        //}                                

                        if(arquivoImportacao =='cat') {
                            d = "0000000000000000000" + (counter)
                        }else{
                            d = "0000000000000000000" + (c + 1)
                        }
                        d =  d.substr(d.length - montDigito);

                        tagD = tag + d

                        
                        if(left == '-LEFT-'){
                            tagD = tagD + ("000000000000000000000000").substr(tagD.length)
                        }else{
                            tagD = "000000000000000000000000" + tagD
                            tagD = tagD.substr(tagD.length - 24);
                        }
                    }
                        tagD = tagD.toUpperCase()

                        objD["key_i"] = tagD;

                        if(serial){
                            const gtin = tagD.replace(serial, "")
                            let epc1 = new epcTds.Sgtin96().setFilter(0)
                                .setPartition(5)
                                .setGtin(gtin)
                                .setSerial(serial);
                            tagD = epc1.toHexString()
                            EPCList.push(tagD)
                        }
                        

                        objD["epc"] =  tagD;	

                        objD["count"] = d;

                        if(qrcodeCsv!=''){
                            objD["campo1"] = obj.campo1
                        }

                        if(codbarCsv!=''){
                            objD["campo2"] = obj.campo2
                        }

                        if(descricao01!=''){
                            if(descricao01.includes('-EPC-')){
                                objD["campo3"] = objD.epc
                            }else if(descricao01.includes('-SSCC-')){
                                objD["campo3"] = epcToSscc(objD.epc)
                            }else if(descricao01.toLowerCase().includes('-13ean-')){
                                objD["campo3"] = uniformEan(obj.campo3)
                            }else if(descricao01.includes('-SEQ-')){
                                objD["campo3"] = EPCAdd(obj.campo3.toString(), c)
                            }else if(descricao01.toLowerCase().includes('-logo-')){
                                objD["campo3"] = 'arquivo .grf'
                            }else if(descricao01.toLowerCase().includes('-fixed-')){
                                objD["campo3"] = 'Texto fixo'
                            }else{
                                objD["campo3"] = obj.campo3
                            }
                        }
            
                        if(descricao02!=''){
                            if(descricao02.includes('-EPC-')){
                                objD["campo4"] = objD.epc
                            }else if(descricao02.includes('-SSCC-')){
                                objD["campo4"] = epcToSscc(objD.epc)
                            }else if(descricao02.toLowerCase().includes('-13ean-')){
                                objD["campo4"] = uniformEan(obj.campo4)
                            }else if(descricao02.includes('-SEQ-')){
                                objD["campo4"] = EPCAdd(obj.campo4.toString(), c)
                            }else if(descricao02.toLowerCase().includes('-logo-')){
                                objD["campo4"] = 'arquivo .grf'
                            }else if(descricao02.toLowerCase().includes('-fixed-')){
                                objD["campo4"] = 'Texto fixo'
                            }else{
                                objD["campo4"] = obj.campo4
                            }
                        }

                        if(descricao03!=''){
                            if(descricao03.includes('-EPC-')){
                                objD["campo5"] = objD.epc
                            }else if(descricao03.includes('-SSCC-')){
                                objD["campo5"] = epcToSscc(objD.epc)
                            }else if(descricao03.toLowerCase().includes('-13ean-')){
                                objD["campo5"] = uniformEan(obj.campo5)
                            }else if(descricao03.includes('-SEQ-')){
                                objD["campo5"] = EPCAdd(obj.campo5.toString(), c)
                            }else if(descricao03.toLowerCase().includes('-logo-')){
                                objD["campo5"] = 'arquivo .grf'
                            }else if(descricao03.toLowerCase().includes('-fixed-')){
                                objD["campo5"] = 'Texto fixo'
                            }else{
                                objD["campo5"] = obj.campo5
                            }
                        }

                        if(descricao04!=''){
                            if(descricao04.includes('-EPC-')){
                                objD["campo6"] = objD.epc
                            }else if(descricao04.includes('-SSCC-')){
                                objD["campo6"] = epcToSscc(objD.epc)
                            }else if(descricao04.toLowerCase().includes('-13ean-')){
                                objD["campo6"] = uniformEan(obj.campo6)
                            }else if(descricao04.includes('-SEQ-')){
                                objD["campo6"] = EPCAdd(obj.campo6.toString(), c)
                            }else if(descricao04.toLowerCase().includes('-logo-')){
                                objD["campo6"] = 'arquivo .grf'
                            }else if(descricao04.toLowerCase().includes('-fixed-')){
                                objD["campo6"] = 'Texto fixo'
                            }else{
                                objD["campo6"] = obj.campo6
                            }
                        }

                        if(descricao05!=''){
                            if(descricao05.includes('-EPC-')){
                                objD["campo7"] = objD.epc
                            }else if(descricao05.includes('-SSCC-')){
                                objD["campo7"] = epcToSscc(objD.epc)
                            }else if(descricao05.toLowerCase().includes('-13ean-')){
                                objD["campo7"] = uniformEan(obj.campo7)
                            }else if(descricao05.includes('-SEQ-')){
                                objD["campo7"] = EPCAdd(obj.campo7.toString(), c)
                            }else if(descricao05.toLowerCase().includes('-logo-')){
                                objD["campo7"] = 'arquivo .grf'
                            }else if(descricao05.toLowerCase().includes('-fixed-')){
                                objD["campo7"] = 'Texto fixo'
                            }else{
                                objD["campo7"] = obj.campo7
                            }
                        }

                        if(descricao06!=''){
                            if(descricao06.includes('-EPC-')){
                                objD["campo8"] = objD.epc
                            }else if(descricao06.includes('-SSCC-')){
                                objD["campo8"] = epcToSscc(objD.epc)
                            }else if(descricao06.toLowerCase().includes('-13ean-')){
                                objD["campo8"] = uniformEan(obj.campo8)
                            }else if(descricao06.includes('-SEQ-')){
                                objD["campo8"] = EPCAdd(obj.campo8.toString(), c)
                            }else if(descricao06.toLowerCase().includes('-logo-')){
                                objD["campo8"] = 'arquivo .grf'
                            }else if(descricao06.toLowerCase().includes('-fixed-')){
                                objD["campo8"] = 'Texto fixo'
                            }else{
                                objD["campo8"] = obj.campo8
                            }
                        }

                        if(descricao07!=''){
                            if(descricao07.includes('-EPC-')){
                                objD["campo9"] = objD.epc
                            }else if(descricao07.includes('-SSCC-')){
                                objD["campo9"] = epcToSscc(objD.epc)
                            }else if(descricao07.toLowerCase().includes('-13ean-')){
                                objD["campo9"] = uniformEan(obj.campo9)
                            }else if(descricao07.includes('-SEQ-')){
                                objD["campo9"] = EPCAdd(obj.campo9.toString(), c)
                            }else if(descricao07.toLowerCase().includes('-logo-')){
                                objD["campo9"] = 'arquivo .grf'
                            }else if(descricao07.toLowerCase().includes('-fixed-')){
                                objD["campo9"] = 'Texto fixo'
                            }else{
                                objD["campo9"] = obj.campo9
                            }
                        }

                        if(descricao08!=''){
                            if(descricao08.includes('-EPC-')){
                                objD["campo10"] = objD.epc
                            }else if(descricao08.includes('-SSCC-')){
                                objD["campo10"] = epcToSscc(objD.epc)
                            }else if(descricao08.toLowerCase().includes('-13ean-')){
                                objD["campo10"] = uniformEan(obj.campo10)
                            }else if(descricao08.includes('-SEQ-')){
                                objD["campo10"] = EPCAdd(obj.campo10.toString(), c)
                            }else if(descricao08.toLowerCase().includes('-logo-')){
                                objD["campo10"] = 'arquivo .grf'
                            }else if(descricao08.toLowerCase().includes('-fixed-')){
                                objD["campo10"] = 'Texto fixo'
                            }else{
                                objD["campo10"] = obj.campo10
                            }
                        }

                        if(descricao09!=''){
                            if(descricao09.includes('-EPC-')){
                                objD["campo11"] = objD.epc
                            }else if(descricao09.includes('-SSCC-')){
                                objD["campo11"] = epcToSscc(objD.epc)
                            }else if(descricao09.toLowerCase().includes('-13ean-')){
                                objD["campo11"] = uniformEan(obj.campo11)
                            }else if(descricao09.includes('-SEQ-')){
                                objD["campo11"] = EPCAdd(obj.campo11.toString(), c)
                            }else if(descricao09.toLowerCase().includes('-logo-')){
                                objD["campo11"] = 'arquivo .grf'
                            }else if(descricao09.toLowerCase().includes('-fixed-')){
                                objD["campo11"] = 'Texto fixo'
                            }else{
                                objD["campo11"] = obj.campo11
                            }
                        }

                        if(descricao10!=''){
                            if(descricao10.includes('-EPC-')){
                                objD["campo12"] = objD.epc
                            }else if(descricao10.includes('-SSCC-')){
                                objD["campo12"] = epcToSscc(objD.epc)
                            }else if(descricao10.toLowerCase().includes('-13ean-')){
                                objD["campo12"] = uniformEan(obj.campo12)
                            }else if(descricao10.includes('-SEQ-')){
                                objD["campo12"] = EPCAdd(obj.campo12.toString(), c)
                            }else if(descricao10.toLowerCase().includes('-logo-')){
                                objD["campo12"] = 'arquivo .grf'
                            }else if(descricao10.toLowerCase().includes('-fixed-')){
                                objD["campo12"] = 'Texto fixo'
                            }else{
                                objD["campo12"] = obj.campo12
                            }
                        }

                        if(descricao11!=''){
                            if(descricao11.includes('-EPC-')){
                                objD["campo13"] = objD.epc
                            }else if(descricao11.includes('-SSCC-')){
                                objD["campo13"] = epcToSscc(objD.epc)
                            }else if(descricao11.toLowerCase().includes('-13ean-')){
                                objD["campo13"] = uniformEan(obj.campo13)
                            }else if(descricao11.includes('-SEQ-')){
                                objD["campo13"] = (EPCAdd(obj.campo13.toString(), c))
                            }else if(descricao11.toLowerCase().includes('-logo-')){
                                objD["campo13"] = 'arquivo .grf'
                            }else if(descricao11.toLowerCase().includes('-fixed-')){
                                objD["campo13"] = 'Texto fixo'
                            }else{
                                objD["campo13"] = obj.campo13
                            }
                        }
            
                        if(descricao12!=''){
                            if(descricao12.includes('-EPC-')){
                                objD["campo14"] = objD.epc
                            }else if(descricao12.includes('-SSCC-')){
                                objD["campo14"] = epcToSscc(objD.epc)
                            }else if(descricao12.toLowerCase().includes('-13ean-')){
                                objD["campo14"] = uniformEan(obj.campo14)
                            }else if(descricao12.includes('-SEQ-')){
                                objD["campo14"] = (EPCAdd(obj.campo14.toString(), c))
                            }else if(descricao12.toLowerCase().includes('-logo-')){
                                objD["campo14"] = 'arquivo .grf'
                            }else if(descricao12.toLowerCase().includes('-fixed-')){
                                objD["campo14"] = 'Texto fixo'
                            }else{
                                objD["campo14"] = obj.campo14
                            }
                        }

                        if(descricao13!=''){
                            if(descricao13.includes('-EPC-')){
                                objD["campo15"] = objD.epc
                            }else if(descricao13.includes('-SSCC-')){
                                objD["campo15"] = epcToSscc(objD.epc)
                            }else if(descricao13.toLowerCase().includes('-13ean-')){
                                objD["campo15"] = uniformEan(obj.campo15)
                            }else if(descricao13.includes('-SEQ-')){
                                objD["campo15"] = (EPCAdd(obj.campo15.toString(), c))
                            }else if(descricao13.toLowerCase().includes('-logo-')){
                                objD["campo15"] = 'arquivo .grf'
                            }else if(descricao13.toLowerCase().includes('-fixed-')){
                                objD["campo15"] = 'Texto fixo'
                            }else{
                                objD["campo15"] = obj.campo15
                            }
                        }

                        if(descricao14!=''){
                            if(descricao14.includes('-EPC-')){
                                objD["campo16"] = objD.epc
                            }else if(descricao14.includes('-SSCC-')){
                                objD["campo16"] = epcToSscc(objD.epc)
                            }else if(descricao14.toLowerCase().includes('-13ean-')){
                                objD["campo16"] = uniformEan(obj.campo16)
                            }else if(descricao14.includes('-SEQ-')){
                                objD["campo16"] = (EPCAdd(obj.campo16.toString(), c))
                            }else if(descricao14.toLowerCase().includes('-logo-')){
                                objD["campo16"] = 'arquivo .grf'
                            }else if(descricao14.toLowerCase().includes('-fixed-')){
                                objD["campo16"] = 'Texto fixo'
                            }else{
                                objD["campo16"] = obj.campo16
                            }
                        }

                        if(descricao15!=''){
                            if(descricao15.includes('-EPC-')){
                                objD["campo17"] = objD.epc
                            }else if(descricao15.includes('-SSCC-')){
                                objD["campo17"] = epcToSscc(objD.epc)
                            }else if(descricao15.toLowerCase().includes('-13ean-')){
                                objD["campo17"] = uniformEan(obj.campo17)
                            }else if(descricao15.includes('-SEQ-')){
                                objD["campo17"] = (EPCAdd(obj.campo17.toString(), c))
                            }else if(descricao15.toLowerCase().includes('-logo-')){
                                objD["campo17"] = 'arquivo .grf'
                            }else if(descricao15.toLowerCase().includes('-fixed-')){
                                objD["campo17"] = 'Texto fixo'
                            }else{
                                objD["campo17"] = obj.campo17
                            }
                        }

                        if(inBaseProps.tags.indexOf(objD.epc) < 0) {
                            objD["registred"] = false
                        } else {
                            objD["registred"] = true
                        }

                        temp.push(objD)		

                    }
                }
                
            })
            await setImportList(temp)

            if(montDigito.includes('Campo')){
                if(montDigito.includes('-GTIN-')){
                    let lgtin = await lista_gtin.filter((gtin) => {return true})
                    await setGtinList(lgtin)
                }
            }

            if(arquivoImportacao=='cat') {
                let jsonUpdate = catToUpdate.map((item) => {
                    return {
                        _id: item[0],
                        descricao: item[1],
                        id_conta: sessionStorage.getItem('idConta'),
                        ean: item[3],
                        inf_compl_1: item[4],
                        inf_compl_2: item[5],
                        inf_compl_3: item[6],
                        inf_compl_4: item[7],
                        inf_compl_5: item[8],
                        up_1: item[9],
                        up_2: item[10],
                        up_3: item[11],
                        up_4: item[12],
                        up_5: item[13],
                        observacao: item [14],
                        serializacao: item[15]
                    }
                })
                let sendUpdate = {
                    categoria: jsonUpdate,
                    itens: [],
                    nivel1: [],
                    nivel2: [],
                    nivel3: [],
                    nivel4: [],
                    parceiros: []
                }

                setSendUpdateCat([sendUpdate])
            }
        }else {
            Swal.fire({
                title: "Ops... !",
                text: 'Seu arquivo excede 70K de itens',
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            }) 
        }
        setUpcommingEPCList(EPCList)
        setImportLoading(false)
    }

    function importNumberListGet() {
        let list = []
        let qtdpages = Math.ceil((importList.length / 1000))
        
        if(qtdpages >= 2 && qtdpages <= 5) {
            for(let i= 1; i <= qtdpages; i++) {
                list.push(i)
            }
        }else if(qtdpages > 5) {
            if(importPage <= 1) {
                for(let i= 1; i <= 5; i++) {
                    list.push(i)
                }
            } else if(importPage > 1 && importPage < (qtdpages - 2)) {
                for(let i= (importPage - 1); i <= (importPage + 3); i++) {
                    list.push(i)
                }
            } else {
                for(let i= (qtdpages - 4); i <= qtdpages; i++) {
                    list.push(i)
                }
            }
        }
        return list
    }

    function changeFunction(key) {
        switch (key) {
            case 0:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <Row className="flex-wrap">
                            <Col><h3>Impressão</h3></Col>
                            <Col className="d-flex justify-content-end">
                                {(!importLoading) && (!importLoadingReg) ? showButtons(tipoImpressao) : ''}
                                <Button variant="custom-primary" className="m-1" onClick={e => {
                                    setChange(1)
                                    setChoice(false)
                                    setChoiceQtd(false)
                                }}>PARÂMETROS</Button>
                            </Col>
                        </Row>
                        <Col className="bg-white border rounded p-3">
                            {typeOfLoadItens()}
                            {tipoImpressao == 'print-order' ? 
                                <Row>
                                    <Form.Group>
                                        <Form.Label>Identificador</Form.Label>
                                        <Form.Control
                                            value={orderId}
                                            onChange={e=>{setOrderId(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Nº do lote fornecedor</Form.Label>
                                        <Form.Control
                                            value={nLote}
                                            onChange={e=>{setNLote(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Nº do lote interno</Form.Label>
                                        <Form.Control
                                            value={nLoteInterno}
                                            onChange={e=>{setNLoteInterno(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Nº do lote correios</Form.Label>
                                        <Form.Control
                                            value={nLoteCorreios}
                                            onChange={e=>{setNLoteCorreios(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Void estimado</Form.Label>
                                        <Form.Control
                                            value={voidEstimado}
                                            onChange={e=>{setVoidEstimado(e.target.value)}}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Qtd. do lote</Form.Label>
                                        <Form.Control
                                            value={qtdLote}
                                            onChange={e=>{setQtdLote(e.target.value)}}
                                        />
                                    </Form.Group>
                                </Row>
                            : ''}
                            <Row className='p-3'>
                                <Form.Label>Impressora</Form.Label>
                                <Form.Select
                                    value={selectedPrinter}
                                    onChange={e=>{setSelectedPrinter(e.target.value)}}
                                >
                                    <option className="text-secundary" value={''}>{'Selecione uma impressora'}</option>
                                    <optgroup label="Parametros">
                                        <option value={userAdress != '' ? userAdress : enderecoImpressora}>{userAdress != '' ? userAdress : enderecoImpressora}</option>
                                    </optgroup>
                                    <optgroup label="Lista de impressoras">
                                        {adressList.map((adress, index) => (
                                            <option key={index} value={adress.endereco}>{adress.nome}</option>
                                        ))}
                                    </optgroup>
                                </Form.Select>
                            </Row>
                            <Row>
                                <Col>
                                    {importList.length > 1000 ? 
                                        <div className="pagesContent" style={{marginTop: '10px'}}>
                                            <div className="numberPages" onClick={e=> {importPage > 0 ? setImportPage(importPage - 1) : setImportPage(importPage)}}>Anterior</div>
                                            {importNumberListGet().map((number, index) => (
                                                <div className="numberPages" key={index} onClick={e => {setImportPage(number - 1)}}>{number}</div>
                                            ))}
                                            <div className="numberPages" onClick={e=> {importPage < Math.ceil(importList.length / 1000) - 1 ? setImportPage(importPage + 1) : setImportPage(importPage)}}>Próximo</div>
                                            <div className="numberPages">Itens: {importList.length}</div>
                                        </div>
                                    :
                                        <div className="pagesContent" style={{marginTop: '10px'}}>
                                            <div className="numberPages">Itens: {importList.length}</div>
                                        </div>
                                    }
                                </Col>
                                <Col className='import-info' style={{marginTop: 'auto'}}>
                                    <div className='d-flex flex-row me-3' style={{marginTop:'10px'}}>
                                        <div className='custom-checkbox me-2' style={{backgroundColor: '#338333'}} /> Já registrado
                                    </div>
                                    <div className='d-flex flex-row' style={{marginTop:'10px'}}>
                                        <div className='custom-checkbox me-2' style={{backgroundColor: '#20B2AA'}} /> Já em ordem de impressão
                                    </div>
                                </Col>
                            </Row>
                            {(!importLoading) && (!importLoadingReg) ?
                            <div className="list">
                                <Table responsive>
                                    <thead>
                                        <tr id="header">
                                            <th>#</th>
                                            {qrcodeCsv != '' ? <th>{'qrCode'}</th> : <th style={{display:'none'}}/>}
                                            {codbarCsv != '' ? <th>{'Código de Barras'}</th> : <th style={{display:'none'}}/>}
                                            {descricao01 != '' ? <th>{descricao01}</th> : <th style={{display:'none'}}/>}
                                            {descricao02 != '' ? <th>{descricao02}</th> : <th style={{display:'none'}}/>}
                                            {descricao03 != '' ? <th>{descricao03}</th> : <th style={{display:'none'}}/>}
                                            {descricao04 != '' ? <th>{descricao04}</th> : <th style={{display:'none'}}/>}
                                            {descricao05 != '' ? <th>{descricao05}</th> : <th style={{display:'none'}}/>}
                                            {descricao06 != '' ? <th>{descricao06}</th> : <th style={{display:'none'}}/>}
                                            {descricao07 != '' ? <th>{descricao07}</th> : <th style={{display:'none'}}/>}
                                            {descricao08 != '' ? <th>{descricao08}</th> : <th style={{display:'none'}}/>}
                                            {descricao09 != '' ? <th>{descricao09}</th> : <th style={{display:'none'}}/>}
                                            {descricao10 != '' ? <th>{descricao10}</th> : <th style={{display:'none'}}/>}
                                            {descricao11 != '' ? <th>{descricao11}</th> : <th style={{display:'none'}}/>}
                                            {descricao12 != '' ? <th>{descricao12}</th> : <th style={{display:'none'}}/>}
                                            {descricao13 != '' ? <th>{descricao13}</th> : <th style={{display:'none'}}/>}
                                            {descricao14 != '' ? <th>{descricao14}</th> : <th style={{display:'none'}}/>}
                                            {descricao15 != '' ? <th>{descricao15}</th> : <th style={{display:'none'}}/>}
                                            <th>EPC</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {importList.slice(importPage * 1000, (importPage * 1000) + 1000).map((item, index) => (
                                            <tr key={(importPage * 1000) + index} style={getColor(item.epc)}>
                                                <th>{(importPage * 1000) + index + 1}</th>
                                                {qrcodeCsv != '' ? <th>{item.campo1}</th> : <th style={{display:'none'}}/>}
                                                {codbarCsv != '' ? <th>{item.campo2}</th> : <th style={{display:'none'}}/>}
                                                {descricao01 != '' ? <th>{item.campo3}</th> : <th style={{display:'none'}}/>}
                                                {descricao02 != '' ? <th>{item.campo4}</th> : <th style={{display:'none'}}/>}
                                                {descricao03 != '' ? <th>{item.campo5}</th> : <th style={{display:'none'}}/>}
                                                {descricao04 != '' ? <th>{item.campo6}</th> : <th style={{display:'none'}}/>}
                                                {descricao05 != '' ? <th>{item.campo7}</th> : <th style={{display:'none'}}/>}
                                                {descricao06 != '' ? <th>{item.campo8}</th> : <th style={{display:'none'}}/>}
                                                {descricao07 != '' ? <th>{item.campo9}</th> : <th style={{display:'none'}}/>}
                                                {descricao08 != '' ? <th>{item.campo10}</th> : <th style={{display:'none'}}/>}
                                                {descricao09 != '' ? <th>{item.campo11}</th> : <th style={{display:'none'}}/>}
                                                {descricao10 != '' ? <th>{item.campo12}</th> : <th style={{display:'none'}}/>}
                                                {descricao11 != '' ? <th>{item.campo13}</th> : <th style={{display:'none'}}/>}
                                                {descricao12 != '' ? <th>{item.campo14}</th> : <th style={{display:'none'}}/>}
                                                {descricao13 != '' ? <th>{item.campo15}</th> : <th style={{display:'none'}}/>}
                                                {descricao14 != '' ? <th>{item.campo16}</th> : <th style={{display:'none'}}/>}
                                                {descricao15 != '' ? <th>{item.campo17}</th> : <th style={{display:'none'}}/>}
                                                <th>{item.epc}</th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            :
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
                                <ReactLoading type={'bars'} color={'#8C0B3B'} height={150} width={150}/>
                            </div>
                            }
                        </Col>
                    </div>
                )
                break
            case 1:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>
                        <Container fluid>
                            <Col className="d-flex justify-content-between p-3">
                                <h3>Impressão</h3>
                                <div>
                                    <Button variant="danger" className='m-1' onClick={e=> {save()}}>SALVAR</Button>
                                    <Button variant="outline-dark" className='m-1' onClick={e => {setChange(0)}}>VOLTAR</Button>
                                </div>
                            </Col>
                            <ul className="nav custom-nav text-secondary mb-3">
                                <Button variant="nav" onClick={e=>setChangeNew(0)}>Textos</Button>
                                <Button variant="nav" onClick={e=>setChangeNew(1)}>Grafico</Button>
                                <Button variant="nav" className='text-nowrap' onClick={e=>setChangeNew(2)}>Montagem EPC</Button>
                                <Button variant="nav" onClick={e=>setChangeNew(3)}>Padrões</Button>
                                <Button variant="nav" onClick={e=>setChangeNew(4)}>Importar</Button>
                            </ul>
                        </Container>
                        {changeNewFunction(changeNew)}
                    </div>
                )
                break
            default:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>Error</div>
                )
                break
        }
    }

    function getNewDate() {
        let dt = new Date()
        let dt_reg = ("0" + dt.getDate()).slice(-2) + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + dt.getFullYear()
        dt_reg += " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" +dt.getMinutes()).slice(-2) + ":" + ("0" +dt.getSeconds()).slice(-2)
        return dt_reg
    }

    function generatePrintOrder() {
        //api.get(`ordem_impressao/${sessionStorage.getItem('idConta')}/*/*/*/*/*`).then(
            //response => {
                if(orderId == '') {
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Informe um identificador',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }else if(nLoteCorreios == ''){
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Informe o Nº do lote do cliente',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }else if(nLoteInterno == ''){
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Informe o Nº do lote interno',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                /*}else if(response.data.findIndex((value) => (value.identificador == orderId)) >= 0){
                    Swal.fire({
                        title: "Ops... !",
                        text: 'IDENTIFICADOR REPETIDO',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '3000'
                    })
                */}else{
                    let verify = -1
                    //verify = response.data.findIndex((val) => (val.epcs.includes(importList[0].epc)))
                    if(verify == -1) {
                        setOrderLoading(true)
                        
                        let printOrder = {
                            id_conta: sessionStorage.getItem('idConta'),
                            id_usuario: sessionStorage.getItem('idUser'),
                            identificador: orderId,
                            arquivo_bat: '',
                            arquivo_grf: '',
                            arquivo_csv: '',
                            epcs: importList.map(item => {return item.epc}),
                            epcs_impresso: [],
                            num_lote: nLote,
                            lote_correios: ('000000').substring(0, 6 - nLoteCorreios.length) + nLoteCorreios.substring(nLoteCorreios.length - 6, nLoteCorreios.length),
                            lote_interno: ('000000').substring(0, 6 - nLoteInterno.length) + nLoteInterno.substring(nLoteInterno.length - 6, nLoteInterno.length),
                            qtd_por_lote: qtdLote.toString(),
                            qtd_void_esperada: voidEstimado.toString(),
                            qtd_void_real: '0',
                            qtd_perdas_lidas: '0',
                            qtd_perdas_contabilizadas: '0',
                            qtd_ribbon: '0',
                            dt_reg_ordem: new Date(),
                            dt_inicio_ordem: null,
                            dt_termino_ordem: null,
                            concluido: 0,
                            observacao: [],
                            epcs_refazer: [],
                            endereco_impressora_def: selectedPrinter,
                            status_secundario: 'AGUARDANDO IMPRESSAO'
                        }
                
                        gerarZPL(printOrder)
                    }else{
                        setJaExiste(true)
                        Swal.fire({
                            title: "Ops... !",
                            text: 'LOTE REPETIDO',
                            icon: 'error',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: '3000'
                        })
                    }
                }
            //}
        //)

        /*let categoriaImport = ''
        listCampos.map(campo => {
            if(campo.destino == 'categoria') {
                let listaDesc = [
                    descricao01,
                    descricao02,
                    descricao03,
                    descricao04,
                    descricao05,
                    descricao06,
                    descricao07,
                    descricao08,
                    descricao09,
                    descricao10
                ]
                let indexCat = listaDesc.indexOf(campo.valor)
                categoriaImport = 'campo' + (indexCat + 2 + 1)
            }
        })*/
    }

    function vincularEPC(){
        setLoadingCenter(true)
        let lista = []
        for(let i=0; i<uncommingList.length; i++){
            lista.push({
                "id_item": uncommingList[i][3],
                "id_cor": uncommingList[i][15],
                "lote": uncommingList[i][1],
                "tag": upcommingEPCList[i],
            })
        }
        api.post(`${enderecoApi}/vincularEPC/${sessionStorage.getItem('idConta')}`, lista).then(
            response=>{
                setLoadingCenter(false)
                Swal.fire({
                    title: "Sucesso !",
                    text: 'EPC vinculado com sucesso',
                    icon: 'success',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            },
            err=>{
                setLoadingCenter(false)
                if(err.response.status === 400 && err.response._body){
                    Swal.fire({
                        title: "Ops... !",
                        text: err.response._body ?? 'Houve um erro ao vincular itens',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }else{
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Houve um erro ao vincular itens',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })
                }
            }
        )
    }

    function showButtons(key) {
        switch (key) {
            case "print-link-EPC": // Imprimir e Vincular EPC
                return (
                    <div>
                        <Button variant="light" onClick={async e => {
                            await gerarZPL().then(async () => {
                                await vincularEPC()
                            })
                        }}>IMPRIMIR E VINCULAR EPC</Button>
                    </div>
                )
            case "print-item-import":
                return (
                    <div>
                        <Button variant="light" onClick={async e => {
                            await gerarZPL().then(async () => {
                                await importarItens('item')
                            })
                        }}>IMPRIMIR E IMPORTAR ITENS</Button>
                    </div>
                )
                break
            case "print-and-item-import":
                return (
                    <div>
                        <Button variant="light" onClick={e => gerarZPL()}>IMPRIMIR</Button>
                        <Button variant="light" onClick={e => importarItens('item')}>IMPORTAR ITENS</Button>
                    </div>
                )
                break
            case "print-create-order":
                return (
                    <div>
                        <Button variant="light" onClick={async e => {
                            await gerarZPL()
                            await importarItens('pedido')
                        }}>IMPRIMIR E GERAR PEDIDO</Button>
                    </div>
                )
                break
            case "print-and-create-order":
                return (
                    <div>
                        <Button variant="light" onClick={e => gerarZPL()}>IMPRIMIR</Button>
                        <Button variant="light" onClick={e => importarItens('pedido')}>GERAR PEDIDO</Button>
                    </div>
                )
                break
            case "print-categories-import":
                return (
                    <div>
                        <Button variant="light" onClick={async e => {
                            await updateCategoriesXML('categoria')
                            await gerarZPL()
                        }}>IMPRIMIR E IMPORTAR CATEGORIAS</Button>
                    </div>
                )
                break
            case "print-and-categories-import":
                return (
                    <div>
                        <Button variant="light" className='m-1' onClick={e => gerarZPL()}>IMPRIMIR</Button>
                        <Button variant="light" className='m-1' onClick={e => importarItens('categoria')}>IMPORTAR CATEGORIAS</Button>
                    </div>
                )
                break
            case "print":
                return (
                    <div>
                        <Button variant="light" onClick={e => gerarZPL()}>IMPRIMIR</Button>
                    </div>
                )
                break
            case "item-import":
                return (
                    <div>
                        <Button variant="light" onClick={e => importarItens('item')}>IMPORTAR ITENS</Button>
                    </div>
                )
                break
            case "create-order":
                return (
                    <div>
                        <Button variant="light" onClick={e => importarItens('pedido')}>GERAR PEDIDO</Button>
                    </div>
                )
                break
            case "categories-import":
                return (
                    <div>
                        <Button variant="light" onClick={e => importarItens('categoria')}>IMPORTAR CATEGORIAS</Button>
                    </div>
                )
                break
            case "print-order":
                return (
                    <div>
                        <Button variant="light" onClick={e => {generatePrintOrder()}}>ORDEM DE IMPRESSÃO</Button>
                    </div>
                )
                break
            default:
                return (
                    <div>
                        <Button variant="light" onClick={e => gerarZPL()}>IMPRIMIR</Button>
                        <Button variant="light" onClick={e => importarItens('item')}>IMPORTAR ITENS</Button>
                    </div>
                )
                break
        }
    }

    function handleChangeDescricao(value, newValue){
        if(montCampo01 === value){
            setMontCampo01(newValue)
        } else if(montCampo02 === value){
            setMontCampo02(newValue)
        }else if(montCampo03 === value){
            setMontCampo03(newValue)
        } else if(montCampo04 === value){
            setMontCampo04(newValue)
        } else if(montCampo05 === value){
            setMontCampo05(newValue)
        }
    }

    function changeNewFunction(key) {
        switch (key) {
            case 0:
                return (
                    <div className="list_account">
                        <div className='infs'>
                            <div>
                                <label>Descrição</label>
                                <input type="text" value={descricao01} onChange={e=> {handleChangeDescricao(descricao01, e.target.value); setDescricao01(e.target.value)}}/>
                                <input type="text" value={descricao02} onChange={e=> {handleChangeDescricao(descricao02, e.target.value); setDescricao02(e.target.value)}}/>
                                <input type="text" value={descricao03} onChange={e=> {handleChangeDescricao(descricao03, e.target.value); setDescricao03(e.target.value)}}/>
                                <input type="text" value={descricao04} onChange={e=> {handleChangeDescricao(descricao04, e.target.value); setDescricao04(e.target.value)}}/>
                                <input type="text" value={descricao05} onChange={e=> {handleChangeDescricao(descricao05, e.target.value); setDescricao05(e.target.value)}}/>
                                <input type="text" value={descricao06} onChange={e=> {handleChangeDescricao(descricao06, e.target.value); setDescricao06(e.target.value)}}/>
                                <input type="text" value={descricao07} onChange={e=> {handleChangeDescricao(descricao07, e.target.value); setDescricao07(e.target.value)}}/>
                                <input type="text" value={descricao08} onChange={e=> {handleChangeDescricao(descricao08, e.target.value); setDescricao08(e.target.value)}}/>
                                <input type="text" value={descricao09} onChange={e=> {handleChangeDescricao(descricao09, e.target.value); setDescricao09(e.target.value)}}/>
                                <input type="text" value={descricao10} onChange={e=> {handleChangeDescricao(descricao10, e.target.value); setDescricao10(e.target.value)}}/>
                                <input type="text" value={descricao11} onChange={e=> {handleChangeDescricao(descricao11, e.target.value); setDescricao11(e.target.value)}}/>
                                <input type="text" value={descricao12} onChange={e=> {handleChangeDescricao(descricao12, e.target.value); setDescricao12(e.target.value)}}/>
                                <input type="text" value={descricao13} onChange={e=> {handleChangeDescricao(descricao13, e.target.value); setDescricao13(e.target.value)}}/>
                                <input type="text" value={descricao14} onChange={e=> {handleChangeDescricao(descricao14, e.target.value); setDescricao14(e.target.value)}}/>
                                <input type="text" value={descricao15} onChange={e=> {handleChangeDescricao(descricao15, e.target.value); setDescricao15(e.target.value)}}/>
                            </div>
                            <div>
                                <label>Posição X</label>
                                <input type="text" value={posicaox01} onChange={e=> {setPosicaox01(e.target.value)}}/>
                                <input type="text" value={posicaox02} onChange={e=> {setPosicaox02(e.target.value)}}/>
                                <input type="text" value={posicaox03} onChange={e=> {setPosicaox03(e.target.value)}}/>
                                <input type="text" value={posicaox04} onChange={e=> {setPosicaox04(e.target.value)}}/>
                                <input type="text" value={posicaox05} onChange={e=> {setPosicaox05(e.target.value)}}/>
                                <input type="text" value={posicaox06} onChange={e=> {setPosicaox06(e.target.value)}}/>
                                <input type="text" value={posicaox07} onChange={e=> {setPosicaox07(e.target.value)}}/>
                                <input type="text" value={posicaox08} onChange={e=> {setPosicaox08(e.target.value)}}/>
                                <input type="text" value={posicaox09} onChange={e=> {setPosicaox09(e.target.value)}}/>
                                <input type="text" value={posicaox10} onChange={e=> {setPosicaox10(e.target.value)}}/>
                                <input type="text" value={posicaox11} onChange={e=> {setPosicaox11(e.target.value)}}/>
                                <input type="text" value={posicaox12} onChange={e=> {setPosicaox12(e.target.value)}}/>
                                <input type="text" value={posicaox13} onChange={e=> {setPosicaox13(e.target.value)}}/>
                                <input type="text" value={posicaox14} onChange={e=> {setPosicaox14(e.target.value)}}/>
                                <input type="text" value={posicaox15} onChange={e=> {setPosicaox15(e.target.value)}}/>
                            </div>
                            <div>
                                <label>Posição Y</label>
                                <input type="text" value={posicaoy01} onChange={e=> {setPosicaoy01(e.target.value)}}/>
                                <input type="text" value={posicaoy02} onChange={e=> {setPosicaoy02(e.target.value)}}/>
                                <input type="text" value={posicaoy03} onChange={e=> {setPosicaoy03(e.target.value)}}/>
                                <input type="text" value={posicaoy04} onChange={e=> {setPosicaoy04(e.target.value)}}/>
                                <input type="text" value={posicaoy05} onChange={e=> {setPosicaoy05(e.target.value)}}/>
                                <input type="text" value={posicaoy06} onChange={e=> {setPosicaoy06(e.target.value)}}/>
                                <input type="text" value={posicaoy07} onChange={e=> {setPosicaoy07(e.target.value)}}/>
                                <input type="text" value={posicaoy08} onChange={e=> {setPosicaoy08(e.target.value)}}/>
                                <input type="text" value={posicaoy09} onChange={e=> {setPosicaoy09(e.target.value)}}/>
                                <input type="text" value={posicaoy10} onChange={e=> {setPosicaoy10(e.target.value)}}/>
                                <input type="text" value={posicaoy11} onChange={e=> {setPosicaoy11(e.target.value)}}/>
                                <input type="text" value={posicaoy12} onChange={e=> {setPosicaoy12(e.target.value)}}/>
                                <input type="text" value={posicaoy13} onChange={e=> {setPosicaoy13(e.target.value)}}/>
                                <input type="text" value={posicaoy14} onChange={e=> {setPosicaoy14(e.target.value)}}/>
                                <input type="text" value={posicaoy15} onChange={e=> {setPosicaoy15(e.target.value)}}/>
                            </div>
                            <div>
                                <label>Fonte</label>
                                <input type="text" value={fonte01} onChange={e=> {setFonte01(e.target.value)}}/>
                                <input type="text" value={fonte02} onChange={e=> {setFonte02(e.target.value)}}/>
                                <input type="text" value={fonte03} onChange={e=> {setFonte03(e.target.value)}}/>
                                <input type="text" value={fonte04} onChange={e=> {setFonte04(e.target.value)}}/>
                                <input type="text" value={fonte05} onChange={e=> {setFonte05(e.target.value)}}/>
                                <input type="text" value={fonte06} onChange={e=> {setFonte06(e.target.value)}}/>
                                <input type="text" value={fonte07} onChange={e=> {setFonte07(e.target.value)}}/>
                                <input type="text" value={fonte08} onChange={e=> {setFonte08(e.target.value)}}/>
                                <input type="text" value={fonte09} onChange={e=> {setFonte09(e.target.value)}}/>
                                <input type="text" value={fonte10} onChange={e=> {setFonte10(e.target.value)}}/>
                                <input type="text" value={fonte11} onChange={e=> {setFonte11(e.target.value)}}/>
                                <input type="text" value={fonte12} onChange={e=> {setFonte12(e.target.value)}}/>
                                <input type="text" value={fonte13} onChange={e=> {setFonte13(e.target.value)}}/>
                                <input type="text" value={fonte14} onChange={e=> {setFonte14(e.target.value)}}/>
                                <input type="text" value={fonte15} onChange={e=> {setFonte15(e.target.value)}}/>
                            </div>
                            <div>
                                {arquivoImportacao == 'xml' ?
                                    <>
                                        <label>Coluna XML</label>
                                        <Form.Select value={csv01} onChange={e=> {setCsv01(e.target.value)}}>
                                            <option value={''}></option>
                                            <option value={1}>Nome do Item</option>
                                            <option value={2}>Ean</option>
                                            <option value={3}>Quantidade</option>
                                            <option value={4}>Sgtin</option>
                                            <option value={5}>Valor de Venda</option>
                                            <option value={6}>Valor de Custo</option>
                                            <option value={8}>Serial</option>
                                        </Form.Select>
                                        <Form.Select value={csv02} onChange={e=> {setCsv02(e.target.value)}}>
                                            <option value={''}></option>
                                            <option value={1}>Nome do Item</option>
                                            <option value={2}>Ean</option>
                                            <option value={3}>Quantidade</option>
                                            <option value={4}>Sgtin</option>
                                            <option value={5}>Valor de custo</option>
                                            <option value={6}>Valor de venda</option>
                                            <option value={8}>Serial</option>
                                        </Form.Select>
                                        <Form.Select value={csv03} onChange={e=> {setCsv03(e.target.value)}}>
                                            <option value={''}></option>
                                            <option value={1}>Nome do Item</option>
                                            <option value={2}>Ean</option>
                                            <option value={3}>Quantidade</option>
                                            <option value={4}>Sgtin</option>
                                            <option value={5}>Valor de custo</option>
                                            <option value={6}>Valor de venda</option>
                                            <option value={8}>Serial</option>
                                        </Form.Select>
                                        <Form.Select value={csv04} onChange={e=> {setCsv04(e.target.value)}}>
                                            <option value={''}></option>
                                            <option value={1}>Nome do Item</option>
                                            <option value={2}>Ean</option>
                                            <option value={3}>Quantidade</option>
                                            <option value={4}>Sgtin</option>
                                            <option value={5}>Valor de custo</option>
                                            <option value={6}>Valor de venda</option>
                                            <option value={8}>Serial</option>
                                        </Form.Select>
                                        <Form.Select value={csv05} onChange={e=> {setCsv05(e.target.value)}}>
                                            <option value={''}></option>
                                            <option value={1}>Nome do Item</option>
                                            <option value={2}>Ean</option>
                                            <option value={3}>Quantidade</option>
                                            <option value={4}>Sgtin</option>
                                            <option value={5}>Valor de custo</option>
                                            <option value={6}>Valor de venda</option>
                                            <option value={8}>Serial</option>
                                        </Form.Select>
                                        <Form.Select value={csv06} onChange={e=> {setCsv06(e.target.value)}}>
                                            <option value={''}></option>
                                            <option value={1}>Nome do Item</option>
                                            <option value={2}>Ean</option>
                                            <option value={3}>Quantidade</option>
                                            <option value={4}>Sgtin</option>
                                            <option value={5}>Valor de custo</option>
                                            <option value={6}>Valor de venda</option>
                                            <option value={8}>Serial</option>
                                        </Form.Select>
                                        <Form.Select value={csv07} onChange={e=> {setCsv07(e.target.value)}}>
                                            <option value={''}></option>
                                            <option value={1}>Nome do Item</option>
                                            <option value={2}>Ean</option>
                                            <option value={3}>Quantidade</option>
                                            <option value={4}>Sgtin</option>
                                            <option value={5}>Valor de custo</option>
                                            <option value={6}>Valor de venda</option>
                                            <option value={8}>Serial</option>
                                        </Form.Select>
                                        <Form.Select value={csv08} onChange={e=> {setCsv08(e.target.value)}}>
                                            <option value={''}></option>
                                            <option value={1}>Nome do Item</option>
                                            <option value={2}>Ean</option>
                                            <option value={3}>Quantidade</option>
                                            <option value={4}>Sgtin</option>
                                            <option value={5}>Valor de custo</option>
                                            <option value={6}>Valor de venda</option>
                                            <option value={8}>Serial</option>
                                        </Form.Select>
                                        <Form.Select value={csv09} onChange={e=> {setCsv09(e.target.value)}}>
                                            <option value={''}></option>
                                            <option value={1}>Nome do Item</option>
                                            <option value={2}>Ean</option>
                                            <option value={3}>Quantidade</option>
                                            <option value={4}>Sgtin</option>
                                            <option value={5}>Valor de custo</option>
                                            <option value={6}>Valor de venda</option>
                                            <option value={8}>Serial</option>
                                        </Form.Select>
                                        <Form.Select value={csv10} onChange={e=> {setCsv10(e.target.value)}}>
                                            <option value={''}></option>
                                            <option value={1}>Nome do Item</option>
                                            <option value={2}>Ean</option>
                                            <option value={3}>Quantidade</option>
                                            <option value={4}>Sgtin</option>
                                            <option value={5}>Valor de custo</option>
                                            <option value={6}>Valor de venda</option>
                                            <option value={8}>Serial</option>
                                        </Form.Select>
                                    </>
                                : 
                                    <>
                                        <label>Coluna CSV</label>
                                        <input type="text" value={csv01} onChange={e=> {setCsv01(e.target.value)}}/>
                                        <input type="text" value={csv02} onChange={e=> {setCsv02(e.target.value)}}/>
                                        <input type="text" value={csv03} onChange={e=> {setCsv03(e.target.value)}}/>
                                        <input type="text" value={csv04} onChange={e=> {setCsv04(e.target.value)}}/>
                                        <input type="text" value={csv05} onChange={e=> {setCsv05(e.target.value)}}/>
                                        <input type="text" value={csv06} onChange={e=> {setCsv06(e.target.value)}}/>
                                        <input type="text" value={csv07} onChange={e=> {setCsv07(e.target.value)}}/>
                                        <input type="text" value={csv08} onChange={e=> {setCsv08(e.target.value)}}/>
                                        <input type="text" value={csv09} onChange={e=> {setCsv09(e.target.value)}}/>
                                        <input type="text" value={csv10} onChange={e=> {setCsv10(e.target.value)}}/>
                                        <input type="text" value={csv11} onChange={e=> {setCsv11(e.target.value)}}/>
                                        <input type="text" value={csv12} onChange={e=> {setCsv12(e.target.value)}}/>
                                        <input type="text" value={csv13} onChange={e=> {setCsv13(e.target.value)}}/>
                                        <input type="text" value={csv14} onChange={e=> {setCsv14(e.target.value)}}/>
                                        <input type="text" value={csv15} onChange={e=> {setCsv15(e.target.value)}}/>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                )
                break
            case 1:
                return (
                    <div className="list_account">
                        <div className='infs'>
                            <div>
                                <label>QrCode</label>
                            </div>
                            <div>
                                <label>Posição X</label>
                                <input type="text" value={qrcodex} onChange={e=> {setQrcodex(e.target.value)}}/>
                            </div>
                            <div>
                                <label>Posição Y</label>
                                <input type="text" value={qrcodey} onChange={e=> {setQrcodey(e.target.value)}}/>
                            </div>
                            <div>
                                <label>Altura</label>
                                <input type="text" value={qrcodeFonte} onChange={e=> {setQrcodeFonte(e.target.value)}}/>
                            </div>
                            <div>
                                <label>Coluna CSV</label>
                                <input type="text" value={qrcodeCsv} onChange={e=> {setQrcodeCsv(e.target.value)}}/>
                            </div>
                        </div>
                        <div className='infs'>
                            <div>
                                <label>Código de Barras</label>
                            </div>
                            <div>
                                <label>Posição X</label>
                                <input type="text" value={codbarx} onChange={e=> {setCodbarx(e.target.value)}}/>
                            </div>
                            <div>
                                <label>Posição Y</label>
                                <input type="text" value={codbary} onChange={e=> {setCodbary(e.target.value)}}/>
                            </div>
                            <div>
                                <label>Altura</label>
                                <input type="text" value={codbarFonte} onChange={e=> {setCodbarFonte(e.target.value)}}/>
                            </div>
                            <div>
                                <label>Coluna CSV</label>
                                <input type="text" value={codbarCsv} onChange={e=> {setCodbarCsv(e.target.value)}}/>
                            </div>
                        </div>
                        <div className='infs'>
                            <div>
                                <label>Logo</label>
                            </div>
                            <div>
                                <label>Posição X</label>
                                <input type="text" value={logox} onChange={e=> {setLogox(e.target.value)}}/>
                            </div>
                            <div>
                                <label>Posição Y</label>
                                <input type="text" value={logoy} onChange={e=> {setLogoy(e.target.value)}}/>
                            </div>
                            <div>
                                <label>GRF</label>
                                <input id="GRF" type="text" value={logoGrf} onChange={e=> {setLogoGrf(e.target.value)}}/>
                            </div>
                        </div>
                    </div>
                )
                break
            case 2:
                return (
                    <div className="list_account">
                        <div className='infs'>
                            <div>
                                <label>Campo</label>
                                <select value={montCampo01} onChange={e=> {
                                    setMontCampo01(e.target.value)
                                }}>
                                    <option value="">Vazio</option>
                                    {listCampos.map((campo, index) => {
                                        if(campo.coluna!='epc' && campo.coluna!='count') {
                                            return (
                                                <option key={index} value={campo?.valor}>{campo?.valor?.toUpperCase()}</option>
                                            )
                                        }
                                    })}
                                    <option value="dthr">Data/Hora</option>
                                </select>
                                <select value={montCampo02} onChange={e=> {
                                    setMontCampo02(e.target.value)
                                }}>
                                    <option value="">Vazio</option>
                                    {listCampos.map((campo, index) => {
                                        if(campo.coluna!='epc' && campo.coluna!='count') {
                                            return (
                                                <option key={index} value={campo?.valor}>{campo?.valor?.toUpperCase()}</option>
                                            )
                                        }
                                    })}
                                    <option value="dthr">Data/Hora</option>
                                </select>
                                <select value={montCampo03} onChange={e=> {
                                    setMontCampo03(e.target.value)
                                }}>
                                    <option value="">Vazio</option>
                                    {listCampos.map((campo, index) => {
                                        if(campo.coluna!='epc' && campo.coluna!='count') {
                                            return (
                                                <option key={index} value={campo?.valor}>{campo?.valor?.toUpperCase()}</option>
                                            )
                                        }
                                    })}
                                    <option value="dthr">Data/Hora</option>
                                </select>
                                <select value={montCampo04} onChange={e=> {
                                    setMontCampo04(e.target.value)
                                }}>
                                    <option value="">Vazio</option>
                                    {listCampos.map((campo, index) => {
                                        if(campo.coluna!='epc' && campo.coluna!='count') {
                                            return (
                                                <option key={index} value={campo?.valor}>{campo?.valor?.toUpperCase()}</option>
                                            )
                                        }
                                    })}
                                    <option value="dthr">Data/Hora</option>
                                </select>
                                <select value={montCampo05} onChange={e=> {
                                    setMontCampo05(e.target.value)
                                }}>
                                    <option value="">Vazio</option>
                                    {listCampos.map((campo, index) => {
                                        if(campo.coluna!='epc' && campo.coluna!='count') {
                                            return (
                                                <option key={index} value={campo?.valor}>{campo?.valor?.toUpperCase()}</option>
                                            )
                                        }
                                    })}
                                    <option value="dthr">Data/Hora</option>
                                </select>
                            </div>
                            <div>
                                <label>Posição Inicial</label>
                                <input type="text" value={montPini01} onChange={e=> {setMontPini01(montCampo01 == 'ean' ? '1' : e.target.value)}}/>
                                <input type="text" value={montPini02} onChange={e=> {setMontPini02(montCampo02 == 'ean' ? '1' : e.target.value)}}/>
                                <input type="text" value={montPini03} onChange={e=> {setMontPini03(montCampo03 == 'ean' ? '1' : e.target.value)}}/>
                                <input type="text" value={montPini04} onChange={e=> {setMontPini04(montCampo04 == 'ean' ? '1' : e.target.value)}}/>
                                <input type="text" value={montPini05} onChange={e=> {setMontPini05(montCampo05 == 'ean' ? '1' : e.target.value)}}/>
                            </div>
                            <div>
                                <label>Comprimento</label>
                                <input type="text" value={montCompr01} onChange={e=> {setMontCompr01(montCampo01 == 'ean' ? '13' : e.target.value)}}/>
                                <input type="text" value={montCompr02} onChange={e=> {setMontCompr02(montCampo02 == 'ean' ? '13' : e.target.value)}}/>
                                <input type="text" value={montCompr03} onChange={e=> {setMontCompr03(montCampo03 == 'ean' ? '13' : e.target.value)}}/>
                                <input type="text" value={montCompr04} onChange={e=> {setMontCompr04(montCampo04 == 'ean' ? '13' : e.target.value)}}/>
                                <input type="text" value={montCompr05} onChange={e=> {setMontCompr05(montCampo05 == 'ean' ? '13' : e.target.value)}}/>
                            </div>
                        </div>
                        <div className='infs'>
                            <div>
                                <label>Digitos Contador</label>
                                <select value={montDigito} onChange={e=> {setMontDigito(e.target.value)}}>
                                    <optgroup label="Digitos">
                                        <option value="0">Nenhum</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </optgroup>
                                    <optgroup label="Campos">
                                        {listCampos.map((campo, index) => {
                                            if(campo.coluna!='epc' && campo.coluna!='count') {
                                                return (
                                                    <option key={index} value={`Campo-${campo?.valor}`}>{campo.valor?.toUpperCase()}</option>
                                                )
                                            }
                                        })}
                                    </optgroup>
                                    <optgroup label='Categorias'>
                                        <option value="sscc">SSCC</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div>
                                <label>Formato EPC</label>
                                <select
                                    value={epcForm}
                                    onChange={e=>{setEpcForm(e.target.value)}}
                                >
                                    <option value=''>Padrão</option>
                                    <option value='-ASC-'>ASC</option>
                                </select>
                            </div>
                            <div>
                                <label>Inserção</label>
                                <select
                                    value={left}
                                    onChange={e=>{setLeft(e.target.value)}}
                                >
                                    <option value=''>Para Direita</option>
                                    <option value='-LEFT-'>Para Esquerda</option>
                                </select>
                            </div>
                            <div>
                                <label>Coluna Quantidade</label>
                                <input type="text" value={montDigitoCsv} onChange={e=> {setMontDigitoCsv(e.target.value)}}/>
                            </div>
                        </div>
                    </div>
                )
                break
            case 3:
                return (
                    <div className="list_account">
                        <div className='infs'>
                            <div>
                                <label>Arquivo</label>
                                <select value={arquivoImportacao} onChange={e=> {setArquivoImportacao(e.target.value)}}>
                                    <option value="csv">CSV</option>
                                    <option value="inc">iNOVACENTER</option>
                                    <option value="cat">CATEGORIAS</option>
                                    <option value="xml">XML</option>
                                </select>
                            </div>
                            <div>
                                <label>Botões de Impressão</label>
                                <select value={tipoImpressao} onChange={e=> {setTipoImpressao(e.target.value)}}>
                                    {true ? <option value="print-item-import">IMPRIMIR E IMPORTAR ITENS (1 BOTÃO)</option> : ''}
                                    <option value="print-and-item-import">IMPRIMIR | IMPORTAR ITENS (2 BOTÕES)</option>
                                    {false ? <option value="print-create-order">IMPRIMIR E GERAR PEDIDO (1 BOTÃO)</option> : ''}
                                    {false ? <option value="print-and-create-order">IMPRIMIR | GERAR PEDIDO (2 BOTÕES)</option> : ''}
                                    {false ? <option value="print-categories-import">IMPRIMIR E IMPORTAR CATEGORIAS  (1 BOTÃO)</option>: ''}
                                    <option value="print-and-categories-import">IMPRIMIR | IMPORTAR CATEGORIAS (2 BOTÕES)</option>
                                    <option value="print-order">ORDEM DE IMPRESSÃO (1 BOTÃO)</option>
                                    <option value="print">IMPRIMIR (1 BOTÃO)</option>
                                    <option value="item-import">IMPORTAR ITENS (1 BOTÃO)</option>
                                    {false ? <option value="create-order">GERAR PEDIDO (1 BOTÃO)</option> : ''}
                                    <option value="categories-import">IMPORTAR CATEGORIAS (1 BOTÃO)</option>
                                    <option value="print-link-EPC">IMPRIMIR E VINCULAR EPC</option>
                                </select>
                            </div>
                        </div>
                        <div className='infs'>
                            <div>
                                <label>Endereço API</label>
                                <input type="text" value={enderecoApi} onChange={e=> {setEnderecoApi(e.target.value)}}/>
                            </div>
                            <div>
                                <label>Endereço Impressora</label>
                                <input type="text" value={enderecoImpressora} onChange={e=> {setEnderecoImpressora(e.target.value)}}/>
                            </div>
                            <div>
                                <label>Usuário Runas</label>
                                <input type="text" value={usuarioRunas} onChange={e=> {setUsuarioRunas(e.target.value)}}/>
                            </div>
                        </div>
                        <div className='infs'>
                            <div>
                                <label>Quebra Linha</label>
                                <input type="text" value={quebraLinha} onChange={e=> {setQuebraLinha(e.target.value)}}/>
                            </div>
                            <div>
                                <label>Imprimir Descrição</label>
                                <select value={imprimirDescricao} onChange={e=> {setImprimirDescricao(e.target.value)}}>
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </select>
                            </div>
                        </div>
                        <div className='infs'>
                            <div>
                                <label>Pré-configuração - ZPL</label>
                                <input type="text" value={preSet} onChange={e=> {setPreSet(e.target.value)}}/>
                            </div>
                        </div>
                    </div>
                )
                break
            case 4:
                return (
                    montarlista()
                )
                break
            default:
                return (
                    <div className={sessionStorage.getItem('show-menu') == 'true' ? "contentContainer" : "m-5"}>Error</div>
                )
                break
        }
    }

    function destMontarLista() {
        let destlist = {
            categoria: '', 
            inf_compl_1: '',
            inf_compl_2: '',
            inf_compl_3: '',
            inf_compl_4: '',
            inf_compl_5: '',
            nivel_loc_1: '',
            nivel_loc_2: '',
            nivel_loc_3: '',
            nivel_loc_4: ''
        }

        if(qrcodeDestino != '') {
            destlist[qrcodeDestino] = 'campo1'
        }
        if(codbarDestino != '') {
            destlist[codbarDestino] = 'campo2'
        }
        if(destino01 != '') {
            destlist[destino01] = 'campo3'
        }
        if(destino02 != '') {
            destlist[destino02] = 'campo4'
        }
        if(destino03 != '') {
            destlist[destino03] = 'campo5'
        }
        if(destino04 != '') {
            destlist[destino04] = 'campo6'
        }
        if(destino05 != '') {
            destlist[destino05] = 'campo7'
        }
        if(destino06 != '') {
            destlist[destino06] = 'campo8'
        }
        if(destino07 != '') {
            destlist[destino07] = 'campo9'
        }
        if(destino08 != '') {
            destlist[destino08] = 'campo10'
        }
        if(destino09 != '') {
            destlist[destino09] = 'campo11'
        }
        if(destino10 != '') {
            destlist[destino10] = 'campo12'
        }

        setListDestino(destlist)
    }

    function montarlista() {
        let listOption = [
            {value:"", descricao: "Nenhum"},
            {value:"categoria", descricao: "Categoria"},
            {value:"inf_compl_1", descricao: "Inf. Compl. 1"},
            {value:"inf_compl_2", descricao: "Inf. Compl. 2"},
            {value:"inf_compl_3", descricao: "Inf. Compl. 3"},
            {value:"inf_compl_4", descricao: "Inf. Compl. 4"},
            {value:"inf_compl_5", descricao: "Inf. Compl. 5"},
            {value:"nivel_loc_1", descricao: "Loc. Nível 1"},
            {value:"nivel_loc_2", descricao: "Loc. Nível 2"},
            {value:"nivel_loc_3", descricao: "Loc. Nível 3"},
            {value:"nivel_loc_4", descricao: "Loc. Nível 4"}
        ]

        return (
            <div className="impressao">
                {qrcodeCsv != '' ?
                    <div className="dest">
                        <label>qrCode</label>
                        <select
                            value={qrcodeDestino}
                            onChange={e=> {setQrcodeDestino(e.target.value)}}
                        >
                            {listOption.map((item, index) => (
                                <option key={index} value={item.value}>{item.descricao}</option>
                            ))}
                        </select>
                    </div>
                : ''}
                {codbarCsv != '' ?
                    <div className="dest">
                        <label>Código de Barras</label>
                        <select
                            value={codbarDestino}
                            onChange={e=> {setCodbarDestino(e.target.value)}}
                        >
                            {listOption.map((item, index) => (
                                <option key={index} value={item.value}>{item.descricao}</option>
                            ))}
                        </select>
                    </div>
                : ''}
                {descricao01 != '' ?
                    <div className="dest">
                        <label>{descricao01}</label>
                        <select
                            value={destino01}
                            onChange={e=> {setDestino01(e.target.value)}}
                        >
                            {listOption.map((item, index) => (
                                <option key={index} value={item.value}>{item.descricao}</option>
                            ))}
                        </select>
                    </div>
                : ''}
                {descricao02 != '' ?
                    <div className="dest">
                        <label>{descricao02}</label>
                        <select
                            value={destino02}
                            onChange={e=> {setDestino02(e.target.value)}}
                        >
                            {listOption.map((item, index) => (
                                <option key={index} value={item.value}>{item.descricao}</option>
                            ))}
                        </select>
                    </div>
                : ''}
                {descricao03 != '' ?
                    <div className="dest">
                        <label>{descricao03}</label>
                        <select
                            value={destino03}
                            onChange={e=> {setDestino03(e.target.value)}}
                        >
                            {listOption.map((item, index) => (
                                <option key={index} value={item.value}>{item.descricao}</option>
                            ))}
                        </select>
                    </div>
                : ''}
                {descricao04 != '' ?
                    <div className="dest">
                        <label>{descricao04}</label>
                        <select
                            value={destino04}
                            onChange={e=> {setDestino04(e.target.value)}}
                        >
                            {listOption.map((item, index) => (
                                <option key={index} value={item.value}>{item.descricao}</option>
                            ))}
                        </select>
                    </div>
                : ''}
                {descricao05 != '' ?
                    <div className="dest">
                        <label>{descricao05}</label>
                        <select
                            value={destino05}
                            onChange={e=> {setDestino05(e.target.value)}}
                        >
                            {listOption.map((item, index) => (
                                <option key={index} value={item.value}>{item.descricao}</option>
                            ))}
                        </select>
                    </div>
                : ''}
                {descricao06 != '' ?
                    <div className="dest">
                        <label>{descricao06}</label>
                        <select
                            value={destino06}
                            onChange={e=> {setDestino06(e.target.value)}}
                        >
                            {listOption.map((item, index) => (
                                <option key={index} value={item.value}>{item.descricao}</option>
                            ))}
                        </select>
                    </div>
                : ''}
                {descricao07 != '' ?
                    <div className="dest">
                        <label>{descricao07}</label>
                        <select
                            value={destino07}
                            onChange={e=> {setDestino07(e.target.value)}}
                        >
                            {listOption.map((item, index) => (
                                <option key={index} value={item.value}>{item.descricao}</option>
                            ))}
                        </select>
                    </div>
                : ''}
                {descricao08 != '' ?
                    <div className="dest">
                        <label>{descricao08}</label>
                        <select
                            value={destino08}
                            onChange={e=> {setDestino08(e.target.value)}}
                        >
                            {listOption.map((item, index) => (
                                <option key={index} value={item.value}>{item.descricao}</option>
                            ))}
                        </select>
                    </div>
                : ''}
                {descricao09 != '' ?
                    <div className="dest">
                        <label>{descricao09}</label>
                        <select
                            value={destino09}
                            onChange={e=> {setDestino09(e.target.value)}}
                        >
                            {listOption.map((item, index) => (
                                <option key={index} value={item.value}>{item.descricao}</option>
                            ))}
                        </select>
                    </div>
                : ''}
                {descricao10 != '' ?
                    <div className="dest">
                        <label>{descricao10}</label>
                        <select
                            value={destino10}
                            onChange={e=> {setDestino10(e.target.value)}}
                        >
                            {listOption.map((item, index) => (
                                <option key={index} value={item.value}>{item.descricao}</option>
                            ))}
                        </select>
                    </div>
                : ''}
            </div>

        )
    }

    function save() {
        Swal.fire({
            title: "Atenção !",
            text: "Deseja realmente atualizar os parâmetros ?",
            icon: "warning",
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Salvar como global",
            denyButtonText: "Salvar para este usuário",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed  || result.isDenied) {
                    let reg = []
                    reg.push({
                        "_id": result.isConfirmed ? id : idtouser,
                        "id_conta": idConta,
                        "id_usuario": result.isConfirmed ? "*" : sessionStorage.getItem('idUser'),
                        "descricao_01": descricao01,
                        "posicaox_01": posicaox01,
                        "posicaoy_01": posicaoy01,
                        "fonte_01": fonte01,
                        "csv_01": csv01,
                        "destino_01": destino01,
                        "descricao_02": descricao02,
                        "posicaox_02": posicaox02,
                        "posicaoy_02": posicaoy02,
                        "fonte_02": fonte02,
                        "csv_02": csv02,
                        "destino_02": destino02,
                        "descricao_03": descricao03,
                        "posicaox_03": posicaox03,
                        "posicaoy_03": posicaoy03,
                        "fonte_03": fonte03,
                        "csv_03": csv03,
                        "destino_03": destino03,
                        "descricao_04": descricao04,
                        "posicaox_04": posicaox04,
                        "posicaoy_04": posicaoy04,
                        "fonte_04": fonte04,
                        "csv_04": csv04,
                        "destino_04": destino04,
                        "descricao_05": descricao05,
                        "posicaox_05": posicaox05,
                        "posicaoy_05": posicaoy05,
                        "fonte_05": fonte05,
                        "csv_05": csv05,
                        "destino_05": destino05,
                        "descricao_06": descricao06,
                        "posicaox_06": posicaox06,
                        "posicaoy_06": posicaoy06,
                        "fonte_06": fonte06,
                        "csv_06": csv06,
                        "destino_06": destino06,
                        "descricao_07": descricao07,
                        "posicaox_07": posicaox07,
                        "posicaoy_07": posicaoy07,
                        "fonte_07": fonte07,
                        "csv_07": csv07,
                        "destino_07": destino07,
                        "descricao_08": descricao08,
                        "posicaox_08": posicaox08,
                        "posicaoy_08": posicaoy08,
                        "fonte_08": fonte08,
                        "csv_08": csv08,
                        "destino_08": destino08,
                        "descricao_09": descricao09,
                        "posicaox_09": posicaox09,
                        "posicaoy_09": posicaoy09,
                        "fonte_09": fonte09,
                        "csv_09": csv09,
                        "destino_09": destino09,
                        "descricao_10": descricao10,
                        "posicaox_10": posicaox10,
                        "posicaoy_10": posicaoy10,
                        "fonte_10": fonte10,
                        "csv_10": csv10,
                        "destino_10": destino10,

                        "descricao_11": descricao11,
                        "posicaox_11": posicaox11,
                        "posicaoy_11": posicaoy11,
                        "fonte_11": fonte11,
                        "csv_11": csv11,
                        "destino_11": destino11,
                        "descricao_12": descricao12,
                        "posicaox_12": posicaox12,
                        "posicaoy_12": posicaoy12,
                        "fonte_12": fonte12,
                        "csv_12": csv12,
                        "destino_12": destino12,
                        "descricao_13": descricao13,
                        "posicaox_13": posicaox13,
                        "posicaoy_13": posicaoy13,
                        "fonte_13": fonte13,
                        "csv_13": csv13,
                        "destino_13": destino13,
                        "descricao_14": descricao14,
                        "posicaox_14": posicaox14,
                        "posicaoy_14": posicaoy14,
                        "fonte_14": fonte14,
                        "csv_14": csv14,
                        "destino_14": destino14,
                        "descricao_15": descricao15,
                        "posicaox_15": posicaox15,
                        "posicaoy_15": posicaoy15,
                        "fonte_15": fonte15,
                        "csv_15": csv15,
                        "destino_15": destino15,

                        "qrcodex": qrcodex,
                        "qrcodey": qrcodey,
                        "qrcode_fonte": qrcodeFonte,
                        "qrcode_csv": qrcodeCsv,
                        "qrcode_destino": qrcodeDestino,
                        "codbarx": codbarx,
                        "codbary": codbary,
                        "codbar_fonte": codbarFonte,
                        "codbar_csv": codbarCsv,
                        "codbar_destino": codbarDestino,
                        "logox": logox,
                        "logoy": logoy,
                        "logo_grf": logoGrf,
                        "logo_csv": logoCsv,

                        "mont_campo_01": montCampo01,
                        "mont_pini_01": montPini01,
                        "mont_compr_01": montCompr01,
                        "mont_campo_02": montCampo02,
                        "mont_pini_02": montPini02,
                        "mont_compr_02": montCompr02,
                        "mont_campo_03": montCampo03,
                        "mont_pini_03": montPini03,
                        "mont_compr_03": montCompr03,
                        "mont_campo_04": montCampo04,
                        "mont_pini_04": montPini04,
                        "mont_compr_04": montCompr04,
                        "mont_campo_05": montCampo05,
                        "mont_pini_05": montPini05,
                        "mont_compr_05": montCompr05,
                        "mont_digito": montDigito.replace('-ASC-', '').replace('-LEFT-', '') + epcForm + left,
                        "mont_digito_csv": montDigitoCsv,
                        "arquivo_importacao": arquivoImportacao,
                        "endereco_impressora": enderecoImpressora,
                        "quebra_linha": quebraLinha,
                        "imprimir_descricao": imprimirDescricao,
                        "pre_set": preSet,
                        "tipo_impressao": tipoImpressao,

                        "endereco_api": enderecoApi,
                        "usuario_runas": usuarioRunas
                    })

                    console.log(reg)

                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }

                    api.post('/impressao_config', reg, option
                    ).then(
                        response => {
                            getConfig()
                            Swal.fire({
                                title: "Sucesso !",
                                text: 'Os parâmetros de impressão foram salvos',
                                icon: 'success',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        },
                        response => {
                            Swal.fire({
                                title: "Ops... !",
                                text: 'Algo deu errado, por favor tente novamente mais tarde',
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }
                    )
                }
            }
        )
    }
    
    return (
        <div className="mainContainer"
        style={JSON.parse(sessionStorage.getItem('collors'))}
        >
            <Menu/>
            <div id="background">
            
                {listCenterloaded.length?
                    <>
                        <Modal show={true} onHide={e=>{clearListCenter()}} size="xl">
                            <Modal.Header closeButton>
                            <Modal.Title>Lista iNOVACENTER</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <div className='export'>
                                <Button onClick={e=> {addAllToList()}}>Marcar Todas</Button>
                                <Button variant="outline" onClick={e=> {setIndexCenterSelected([])}}>Desmarcar Todas</Button>
                            </div>
                            <div className="list"
                            style={{
                                height:'auto',
                                maxHeight: '620px'
                            }}>
                                <Table>
                                    <thead>
                                        <tr id="header">
                                            <th>#</th>
                                            <th>Coluna_0</th>
                                            <th>Coluna_1</th>
                                            <th>Coluna_2</th>
                                            <th>Coluna_3</th>
                                            <th>Coluna_4</th>
                                            <th>Coluna_5</th>
                                            <th>Coluna_6</th>
                                            <th>Coluna_7</th>
                                            <th>Coluna_8</th>
                                            <th>Coluna_9</th>
                                            <th>Coluna_10</th>
                                            <th>Coluna_11</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listCenterloaded.map((item, index) => (
                                            <tr 
                                                className='edit'
                                                style={selectedIndex(index) ? {background: '#919191', color: '#F8F8F8'} : {}}
                                                key={index}
                                                onClick={e=> {if(e.target.id != 'eventNone'){addOrRemoveListImport(index)}}}
                                            >
                                                <th>{index + 1}</th>
                                                <th>{item[0]}</th>
                                                <th>{item[1]}</th>
                                                <th>{item[2]}</th>
                                                <th>{item[3]}</th>
                                                <th>{item[4]}</th>
                                                <th>{item[5]}</th>
                                                <th>{item[6]}</th>
                                                <th>{item[7]}</th>
                                                {choiceQtd?
                                                    <th>
                                                        <input
                                                            id="eventNone"
                                                            value={item[8]}
                                                            type={'number'}
                                                            min={0}
                                                            onChange={e=>{editListCenterloadedQtd(index, e.target.value)}}
                                                        />
                                                    </th>
                                                :
                                                    <th>{item[8]}</th>
                                                }
                                                <th>{item[9]}</th>
                                                <th>{item[10]}</th>
                                                <th>{item[11]}</th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="primary" onClick={e=> {getSelectedListbyInovaCenter()}}>
                                Listar selecionadas
                            </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                :''}
                {listCatloaded.length?
                <div className='vh-100 vw-100'>
                    <Modal show={true} size="xl" dialogClassName="modal-90w">
                        <Modal.Header>
                            <Modal.Title >Lista de categorias</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className='container-btn'>
                                <Button className='m-1 col' onClick={e=> {addAllToListCat()}}>Marcar Todas</Button>
                                <Button className='m-1 col' onClick={e=> {setIndexCatSelected([])}}>Desmarcar Todas</Button>
                            </Row>
                            <div className="list"
                                style={{
                                height:'auto',
                                maxHeight: '620px'
                            }}>
                                <Table responsive hover>
                                    <thead>
                                        <tr id="header" style={{whiteSpace: 'nowrap'}}>
                                            <th>#</th>
                                            <th>ID</th>
                                            <th>Descrição</th>
                                            <th>Qtd</th>
                                            <th>EAN</th>
                                            <th>Inf Compl 1</th>
                                            <th>Inf Compl 2</th>
                                            <th>Inf Compl 3</th>
                                            <th>Inf Compl 4</th>
                                            <th>Inf Compl 5</th>
                                            <th>UP 1</th>
                                            <th>UP 2</th>
                                            <th>UP 3</th>
                                            <th>UP 4</th>
                                            <th>UP 5</th>
                                            <th>Observação</th>
                                            <th>Serialização</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listCatloaded.map((item, index) => (
                                            <tr 
                                                className='edit'
                                                style={selectedIndexCat(index) ? {background: '#919191', color: '#F8F8F8'} : {}}
                                                key={index}
                                                onClick={e=> {if(e.target.id != 'eventNone'){addOrRemoveListCatImport(index)}}}
                                            >
                                                <th>{index + 1}</th>
                                                <th>{item[0]}</th>
                                                <th>{item[1]}</th>
                                                <th>
                                                    <input
                                                        id="eventNone"
                                                        value={item[2]}
                                                        type={'number'}
                                                        min={0}
                                                        onChange={e=>{editListCatloadedQtd(index, e.target.value)}}
                                                    />
                                                </th>
                                                <th>{item[3]}</th>
                                                <th>{item[4]}</th>
                                                <th>{item[5]}</th>
                                                <th>{item[6]}</th>
                                                <th>{item[7]}</th>
                                                <th>{item[8]}</th>
                                                <th>{item[9]}</th>
                                                <th>{item[10]}</th>
                                                <th>{item[11]}</th>
                                                <th>{item[12]}</th>
                                                <th>{item[13]}</th>
                                                <th>{item[14]}</th>
                                                <th>{item[15]}</th>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-dark" onClick={e=>{clearListCat()}}>Cancelar</Button>
                            <Button variant="danger" onClick={e=> {getSelectedListbyCategories()}}>Listar selecionadas</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                :''}
                {showModalXml?
                    <Modal
                        show={showModalXml} 
                        centered 
                        size="xl"
                    >
                        <Modal.Header>
                            <Modal.Title>Lista Xml</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Ean</th>
                                        <th>Quantidade</th>
                                        <th>Valor de Venda</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listxml.map((item, index) => (
                                        <tr>
                                            <th style={{whiteSpace:'pre-wrap'}}>{item[1]}</th>
                                            <th>{item[2]}</th>
                                            <th>
                                                <Form.Control
                                                    placeholder={item[3]}
                                                    type='number'
                                                    min={0}
                                                    onChange={e=>{editXml(index, e.target.value, 3)}}
                                                />
                                            </th>
                                            <th>
                                                <Form.Control
                                                    placeholder={item[6]}
                                                    type='text'
                                                    onChange={e=>{editXml(index, e.target.value, 6)}}
                                                />
                                            </th>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-dark" onClick={e=>{setShowModalXml(false)}}>Cancelar</Button>
                            <Button variant="danger" onClick={e=>{saveXml()}}>Salvar</Button>
                        </Modal.Footer>
                    </Modal>
                :''}
                {loadingCenter?
                    <div className='extra'>
                        <div style={{marginLeft: 'calc(50vw - 75px)', marginTop: 'calc(50vh - 75px)', minHeight: 'auto', height: '200px'}}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <ReactLoading type={'bars'} color={'#8C0B3B'} height={150} width={150}/>
                            </div>
                        </div>
                    </div>
                :
                ''
                }
                {loading || orderLoading?
                    <div className='extra'>
                        <div style={{marginLeft: 'calc(50vw - 75px)', marginTop: 'calc(50vh - 75px)', minHeight: 'auto', height: '200px'}}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <ReactLoading type={'bars'} color={'#8C0B3B'} height={150} width={150}/>
                            </div>
                        </div>
                    </div>
                : ''}
                {changeFunction(change)}
            </div>
            
        </div>
    )
}

export default Print