import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';

import { Icon } from '@iconify/react/dist/iconify.js';

import { grey } from '@mui/material/colors';
import secureLocalStorage from 'react-secure-storage';

//-----------------------------------------------------------------

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function UploadPhoto() {
    const navigate = useNavigate()

    const [photo, setPhoto] = React.useState(secureLocalStorage.getItem('transport-user')?.photo || null)

    function handleCapture({ target }) {
        const file = target.files[0];
        const reader = new FileReader();
        
        reader.onloadend = () => {
          setPhoto(reader.result);
        };
        
        if (file) {
          reader.readAsDataURL(file);
        }
    }

    function handleSavePhoto(){
        const preValues = secureLocalStorage.getItem('transport-user')
        preValues.photo = photo
        secureLocalStorage.setItem('transport-user', preValues)
        navigate('/transport/confirm-data')
    }

    return (
        <>
            <Stack
                direction="column"
                justifyContent={{xs: "space-between", md: "flex-start"}}
                spacing={1}
                sx={{
                    minWidth: {xs: '100%', md: '30vw'},
                    height: '100%'
                }}
            >
                <Stack direction="row" spacing={1}>
                    <IconButton color='primary' sx={{boxShadow: 1}}>
                        <Icon icon="fontisto:cloud-down" width={16}/>
                    </IconButton>
                    <Typography variant="h1">Tire uma foto do objeto</Typography>
                </Stack>

                {photo ? 
                    <Stack direction="row" justifyContent="center" alignItems="flex-start" sx={{padding: 5}}>
                        <Box 
                            component="img"
                            src={photo}
                            sx={{
                                marginTop: 2,
                                borderRadius: 3, 
                                backgroundColor: grey[400],
                                width: '200px', 
                                height: '200px'
                            }}
                        ></Box>
                        <IconButton onClick={e=>{setPhoto(null)}}>
                            <Icon icon="iwwa:delete" width={14}/>
                        </IconButton>
                    </Stack>
                : 
                    <Stack direction="column" justifyContent="center" alignItems="center" sx={{padding: 5}}>
                        <Icon icon="solar:cloud-upload-bold-duotone" width="100"/>
                        <Typography variant="subtitle" align="center" paragraph={false}>Adicione fotos do produto</Typography>
                        <Typography variant="subtitle" align="center" paragraph={false}>em formato .png, .jpeg, .svg</Typography>
                    </Stack>
                }

                <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<Icon icon="fluent:scan-camera-16-regular" />}
                >
                    Upload file
                    <VisuallyHiddenInput type="file" onChange={e=>{handleCapture(e)}} />
                </Button>
                    
                {photo ? 
                    <Button
                        variant="contained"
                        color="dark"
                        onClick={e=>{handleSavePhoto()}}
                        endIcon={<Icon icon="ic:round-double-arrow" width={12}/>}
                        fullWidth
                    >
                        Continuar
                    </Button>
                : ''}

            </Stack>
            
        </>
    );
}
