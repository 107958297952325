import { useEffect, useState } from "react";

import { Grid, Stack, Typography } from "@mui/material";

import { common } from "@mui/material/colors";

import WidgetItemsByLevel from "../../Widgets/WidgetItemsByLevel.js";

import TableTracking from "../../Tables/TableTracking.js";

import ItemsByLevels from "./ItemsByLevels.js";

import ItemsByDays from "./ItemsByDays.js";


//------------------------------------------------------------------------------

export default function CBA(){
    return (
        <>
            <Stack spacing={3}>

                <Typography variant="h1">Dashboard</Typography>

                <Grid container justifyContent="space-between" spacing={1}>
                    <Grid item md={4}>
                        <ItemsByDays/>
                    </Grid>
                    <Grid item md={8}>
                        <ItemsByLevels/>
                    </Grid>
                </Grid>

                <TableTracking/>
            </Stack>
        </>
    )
}