import { useEffect, useState } from "react";

import { Outlet, useNavigate } from "react-router-dom";

import secureLocalStorage from "react-secure-storage";

import { Box, Container, Fab, Grid, Toolbar } from "@mui/material";

import Header from "./header";

import Menu from "./menu";

import { styled } from '@mui/material/styles';

import { Icon } from "@iconify/react";

const APP_BAR_MOBILE = 64;

const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

// ----------------------------------------------------------------

export default function Layout() {

  const [open, setOpen] = useState(true);
  
  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Menu openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}